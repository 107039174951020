// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoCoachShortForm } from "../models/DtoCoachShortForm";

@Injectable({providedIn: 'root'})
export class CoachesApiService {
    private readonly controller = "Coaches";

    constructor(private v2Api: V2ApiService) { }

    /** Gets all coaches for current user in given organization.
     * @param organizationID 
     */
    getCoachForCurrentUser(organizationID: Guid) {
        return this.v2Api.get<DtoCoachShortForm[]>(this.controller, "GetCoachForCurrentUser", { organizationID });
    }
}