import {Component} from "@angular/core";

@Component({
    selector: 'app-kentucky-component',
    templateUrl: './_kentucky_khsaa.html',
  styleUrl: './contract.scss',
})

export class KentuckyComponent{

}
