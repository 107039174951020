<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Accept Contracts</ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="acceptAssignment()"
        expand="block">
        <ion-text> Accept </ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div id="contract-container">
    <br />
    <div *ngIf="contract === 'colorado_chsaa'" class="scrollable-contract">
      <app-colorado-component></app-colorado-component>
    </div>
    <div *ngIf="contract === 'florida_fhsaa'" class="scrollable-contract">
      <app-florida-component></app-florida-component>
    </div>
    <div *ngIf="contract === 'georgia_ghsa'" class="scrollable-contract">
      <app-no-association-component></app-no-association-component>
    </div>
    <div *ngIf="contract === 'illinois_ihsa'" class="scrollable-contract">
      <app-illinois-component></app-illinois-component>
    </div>
    <div *ngIf="contract === 'indiana_ihsaa'" class="scrollable-contract">
      <app-indiana-component></app-indiana-component>
    </div>
    <div *ngIf="contract === 'kentucky_khsaa'" class="scrollable-contract">
      <app-kentucky-component></app-kentucky-component>
    </div>
    <div *ngIf="contract === 'michigan_mhsaa'" class="scrollable-contract">
      <app-michigan-component></app-michigan-component>
    </div>
    <div *ngIf="contract === 'missouri_mshsaa'" class="scrollable-contract">
      <app-missouri-component></app-missouri-component>
    </div>
    <div *ngIf="contract === 'noassociation'" class="scrollable-contract">
      <app-no-association-component></app-no-association-component>
    </div>
    <div *ngIf="contract === 'ohio_ohsaa'" class="scrollable-contract">
      <app-ohio-component></app-ohio-component>
    </div>
  </div>
</ion-content>
