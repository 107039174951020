import { Injectable } from '@angular/core';
import {
  EventlinkAllowedMethods,
  EventlinkApiServiceBase,
  EventlinkHeaders,
  EventlinkQueryParams,
  EventlinkRequest,
} from './EventlinkApiServiceBase';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../../error/error.service';
import { EventlinkV2HttpResponse } from './EventlinkV2HttpResponse';
import { LoadingService } from '../../loading/loading.service';
import { ContextRepository } from 'src/app/core/repositories/context.repository';
import { IsAuthenticatedService } from '../../auth/is-authenticated/is-authenticated.service';
import { AppInfoService } from '../../app-info/app-info.service';

@Injectable({
  providedIn: 'root',
})
export class V2ApiService extends EventlinkApiServiceBase {
  constructor(
    contextRepository: ContextRepository,
    errorService: ErrorService,
    logger: NGXLogger,
    router: Router,
    private readonly loadingService: LoadingService,
    isAuthenticatedService: IsAuthenticatedService,
    appInfoService: AppInfoService
  ) {
    super(contextRepository, errorService, logger, router, isAuthenticatedService, appInfoService);
  }

  // If you call `executeSilently` from within `executeSilently` we need to know how deep the rabbit hole goes to know when to turn on error handling again
  private silentExecutionsInProgress = 0;

  public async executeSilently<T>(inner: () => Promise<T>): Promise<T> {
    this.silentExecutionsInProgress++;
    try {
      return await inner();
    }
    finally {
      this.silentExecutionsInProgress--;
    }
  }

  /**
   * HTTP GET
   * @param controller /api/{controller}/{action}
   * @param action /api/{controller}/{action}
   * @param queryParams Additional query string parameters, if any
   * @returns Wrapped HTTP response
   */
  public async get<T>(
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any }
  ): Promise<EventlinkV2HttpResponse<T>> {
    return await this.loadingService.loadingWhile(
      this.buildAndExecuteRequest('GET', controller, action, queryParams)
    );
  }

  /**
   * HTTP POST
   * @param controller /api/{controller}/{action}
   * @param action /api/{controller}/{action}
   * @param queryParams Additional query string parameters, if any
   * @param body Payload data, if any
   * @param useFormData false = application/json and true = multipart/form-data (usually only want true for file uploads)
   * @returns Wrapped HTTP response
   */
  public async post<T>(
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any },
    body?: any,
    useFormData = false
  ): Promise<EventlinkV2HttpResponse<T>> {
    return await this.loadingService.loadingWhile(
      this.buildAndExecuteRequest('POST', controller, action, queryParams, body, useFormData)
    );
  }

  private async buildAndExecuteRequest<T>(
    type: 'GET' | 'POST',
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any },
    body?: any,
    useFormData = false
  ): Promise<EventlinkV2HttpResponse<T>> {
    let wrapped: EventlinkV2HttpResponse<T>;
    try {
      const request = await this.buildV2Request(type, controller, action, queryParams, body);
      this.logger.debug(`Sending ${request.method} request to /api/${controller}/${action}`);

      // Remove content type for form data so it can be generated by fetch
      if (useFormData) {
        delete request.headers['Content-Type'];
      }

      let fetchOptions: RequestInit = {
        method: request.method,
        headers: request.headers,
      };
      if (useFormData) {
        let formData = new FormData();

        for (let field in request.body) {
          let formValue = request.body[field];
          if (formValue instanceof FileList) {
            formValue = formValue[0];
          }

          if (Array.isArray(formValue)) {
            for (let i in formValue) {
              formData.set(`${field}[${i}]`, formValue[i]);
            }
          } else if (formValue != undefined && formValue != null) {
            formData.set(field, formValue);
          }
        }

        fetchOptions.body = formData;
      } else {
        fetchOptions.body = JSON.stringify(request.body);
      }

      const response = await fetch(request.urlWithQuery(), fetchOptions);

      let rawData: any = undefined;
      const contentType = response.headers.get('Content-Type');
      if (
        contentType &&
        ['application/json', 'application/problem+json; charset=utf-8', 'application/json; charset=utf-8'].indexOf(
          contentType
        ) >= 0
      ) {
        rawData = await response.json();
      } else if (contentType?.startsWith('image/')) {
        rawData = await response.blob();
      } else if (response.status != 204) {
        this.logger.info(
          `${this.formatControllerAction(
            controller,
            action,
            'v2'
          )} response content type couldn't be processed: ${contentType}`
        );
      }

      wrapped = new EventlinkV2HttpResponse<T>(response, rawData);
    } catch (exception: any) {
      this.logger.error(exception);
      wrapped = new EventlinkV2HttpResponse<T>({ status: -1 } as Response, undefined, exception);
    }

    if (wrapped.isAnyError) {
      const silent = this.silentExecutionsInProgress > 0;
      await super.tryHandleErrors(wrapped, controller, action, 'v2', silent);
    }

    return wrapped;
  }

  private async buildV2Request(
    type: EventlinkAllowedMethods,
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any },
    body?: any
  ): Promise<EventlinkRequest> {
    let query: EventlinkQueryParams = {};
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams[key] != undefined) query[key] = queryParams[key];
      }
    }

    if (type == 'GET' && body) {
      throw new Error("GET requests can't have a body!");
    }

    let headers: EventlinkHeaders = {};

    const context = await this.contextRepository.getContext();
    if (context?.AccessToken) {
      headers['Authorization'] = `Bearer ${context.AccessToken}`;
    }

    await this.addVersionHeader(headers);

    headers['Content-Type'] = 'application/json';

    return new EventlinkRequest(
      type,
      `${environment.api.V2Endpoint}${controller}/${action}`,
      query,
      headers,
      body
    );
  }
}
