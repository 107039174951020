import { Injectable, ErrorHandler } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private logger: NGXLogger, private toastController: ToastController) {}

  async handleError(error: any): Promise<void> {
    var errorMessage =
      error.error && error.error.errors && error.error.errors._User.length > 0
        ? error.error.errors._User[0]
        : error.message;

    this.logger.error(error);
    if (!environment.production && errorMessage) {
      const toast = await this.toastController.create({message: errorMessage, duration: 3500, color: 'danger'});
      await toast.present();
    }

    let loggerConfig = this.logger.getConfigSnapshot();
    let reportUrl = loggerConfig.serverLoggingUrl ?? environment.logging.url;

    if (reportUrl) {
      var response = await this.sendErrorReport(error, reportUrl);

      if (response.status >= 300) {
        this.logger.error('Failed to send error report:', reportError);
        return;
      }

      this.logger.debug('Error report sent successfully');
    }
  }

  private async sendErrorReport(error: any, reportUrl: string) {
    const errorReport = {
      error,
      timestamp: new Date(),
    };

    const options: HttpOptions = {
      url: reportUrl,
      data: errorReport,
    };

    return await CapacitorHttp.post(options);
  }
}
