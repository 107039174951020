<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" style="min-width: 25%">
      <ion-button type="submit" (click)="cancel()" expand="block" >
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Decline Assignment</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-row>Are you sure you want to decline this assignment?</ion-row>
  <ion-row class="ion-padding-top" *ngIf="!openAssign">Reason </ion-row>
  <ion-input *ngIf="!openAssign" [ngModel]="reason"></ion-input>
  <ion-buttons class="ion-padding-top ion-padding-bottom">
    <ion-button
      class="decline-assignment button-height"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="declineAssignment()"
      expand="block">
      <ion-text> Decline </ion-text>
    </ion-button>
  </ion-buttons>
</ion-content>
