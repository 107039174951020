import { Injectable, ViewContainerRef, ApplicationRef, EmbeddedViewRef } from '@angular/core';

import { ErrorComponent } from '../../../shared/components/modal/error/error.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  vcRef?: ViewContainerRef;

  constructor(private appRef: ApplicationRef) {}

  public static readonly defaultDuration: number = 3500;

  public showError(message: string, duration?: number, title?: string) {
    if (this.vcRef) {
      const componentRef = this.vcRef.createComponent(ErrorComponent);

      componentRef.instance.message = message;
      componentRef.instance.duration = duration ?? ErrorService.defaultDuration;
      if (title) {
        componentRef.instance.title = title;
      }

      const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

      document.body.appendChild(domElem);

      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }, componentRef.instance.duration * 1.25);
    }
  }
}
