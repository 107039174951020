<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
        <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
        </ion-buttons>
        <ion-title>Email {{isForOfficials? 'Officials' : 'Workers'}}</ion-title>
    </ion-toolbar>
</ion-header>
  
<ion-content>
  <div id="send-email-form" class="ion-padding-horizontal">

      <span class="input-row">
          <ion-item>
            <ion-select
              label-placement="stacked"
              placeholder="No Replies"
              [(ngModel)]="emailContent.FromContactPointID"
              [multiple]="false">
              <div slot="label">From</div>
              <ion-select-option *ngFor="let contactPoint of contactPoints" [value]="contactPoint.ID">
                  {{ contactPoint.Title }} ({{ contactPoint.Value }})
              </ion-select-option>
            </ion-select>
          </ion-item>
        </span>

      <span class="input-row">
          <ion-item>
            <ion-select
              label-placement="stacked"
              placeholder="None selected"
              [(ngModel)]="emailContent.ContactIDs"
              [multiple]="true">
              <div slot="label">Please enter one or more recipients <ion-text color="danger">*</ion-text></div>
              <ion-select-option *ngFor="let recipient of RecipientsList" [value]="recipient.ID">
                  {{ recipient.Name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </span>

      <span class="input-row">
        <ion-item>
          <ion-input
            labelPlacement="stacked"
            placeholder="Enter subject here..."
            type="text"
            [(ngModel)]="emailContent.Subject">
            <div slot="label">Subject <ion-text color="danger">*</ion-text></div>
          </ion-input>
        </ion-item>
      </span>

      <span class="input-row">
          <ion-item>
            <ion-textarea
              labelPlacement="stacked"
              placeholder="Enter body text here..."
              type="text"
              [autoGrow]="true"
              [(ngModel)]="emailContent.Message">
              <div slot="label">Message <ion-text color="danger">*</ion-text></div>
            </ion-textarea>
          </ion-item>
        </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
          <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="sendEmail()"
          expand="block">
          <fa-icon icon="envelope" size="lg"></fa-icon> <ion-text>Send</ion-text>
          </ion-button>
      </div>
    </div>
  </div>
  
</ion-content>