import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as actions from './create-account.actions';
import { dtoUserCreate } from 'src/app/core/services/api/models/legacy/dtoUserCreate';
import { dtoContactPointCreateUpdate } from 'src/app/core/services/api/models/legacy/dtoContactPointCreateUpdate';

export const initialUserState: dtoUserCreate = {
  FirstName: '',
  LastName: '',
  Username: '',
  Password: '',
  TimeZone: '',
  ContactPoints: [],
  Address1: undefined,
  Address2: undefined,
  County: undefined,
  City: undefined,
  State: undefined,
  Zip: undefined
};

export const initialContactPointState: dtoContactPointCreateUpdate[] = [
  {
    Title: '',
    ContactType: 'Unknown',
    Value: '',
    ID:  undefined,
    UserID: undefined,
  },
];

export const userInfoReducer = createReducer(
  initialUserState,
  on(actions.getUserInfo, (state) => {
    return state;
  }),
  on(actions.saveUserInfo, (state, action) => {
    state = {
      ...state,
      ...{
        FirstName: action.payload.FirstName,
        LastName: action.payload.LastName,
        Username: action.payload.Username,
        Password: action.payload.Password,
        TimeZone: action.payload.TimeZone,
      },
    };
    return state;
  })
);

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearState(state, action) {
    if (action.type === actions.CreateAccountActionTypes.ClearState) {
      state = {} as State;
    }
    return reducer(state, action);
  };
}

export const contactPointReducer = createReducer(
  initialContactPointState,
  on(actions.getContactPoints, (state) => {
    return state;
  }),
  on(actions.addContactPoint, (state, action) => {
    return [...state, action.payload];
  })
);
