
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Verify Password</ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="sendPassword()"
        expand="block"
        [disabled]="!formGroup.valid">
        <ion-text> Accept </ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="formGroup">
    <ion-text class="top-line prompt">Confirm your Eventlink password to securely access your sensitive tax information.</ion-text>
    <ion-text class="ion-padding-start text-start top-line"> Password:<span class="required">*</span></ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-input type="password" formControlName="password"></ion-input>
    </ion-item>
  </form>
</ion-content>
