// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid, int } from "../models/ApiTypes";
import { DtoKioskOrderRequest } from "../models/DtoKioskOrderRequest";
import { DtoKioskOrderResponse } from "../models/DtoKioskOrderResponse";
import { DtoKioskCashSubmission } from "../models/DtoKioskCashSubmission";
import { DtoKioskCashResult } from "../models/DtoKioskCashResult";

@Injectable({providedIn: 'root'})
export class KioskPurchasesApiService {
    private readonly controller = "KioskPurchases";

    constructor(private v2Api: V2ApiService) { }

    /** This is no longer needed and will be removed in a future version.
     * @param paymentIntentID 
     */
    captureIntent(paymentIntentID: Guid) {
        return this.v2Api.post<void>(this.controller, "CaptureIntent", { paymentIntentID });
    }

    /** Sends an SMS receipt for a kiosk purchase. Since sending the SMS is an async operation you should tell the user that their text will be sent shortly, not that the text has been sent.
     * @param kioskSessionID Session that the intent was created from.
     * @param paymentIntentID StripePaymentIntentID from a StartCheckout call.
     * @param phoneNumber Phone number to send the receipt to.
     */
    sendReceipt(kioskSessionID: int | undefined, paymentIntentID: Guid | undefined, phoneNumber: string) {
        return this.v2Api.post<void>(this.controller, "SendReceipt", { kioskSessionID, paymentIntentID, phoneNumber });
    }

    /** Gets a Payment Intent client secret for a purchase request.
     * 
     * For in-person card-present transactions Source needs to be "InPerson" to use the alternate payment flow. Currently only card-present transactions are accepted.
     * @param body 
     */
    startCheckout(body: DtoKioskOrderRequest) {
        return this.v2Api.post<DtoKioskOrderResponse>(this.controller, "StartCheckout", {  }, body);
    }

    /** 
     * @param body 
     */
    submitCash(body: DtoKioskCashSubmission) {
        return this.v2Api.post<DtoKioskCashResult>(this.controller, "SubmitCash", {  }, body);
    }
}