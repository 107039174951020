<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" type="submit" (click)="cancel()" expand="block">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Update Info</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="update-info-form" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item>
        <ion-input labelPlacement="stacked" type="text" autocapitalize="on" [(ngModel)]="FirstName">
          <div slot="label">First Name</div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input labelPlacement="stacked" autocapitalize="on" type="text" [(ngModel)]="LastName">
          <div slot="label">Last Name</div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input labelPlacement="stacked" type="text" [(ngModel)]="Email">
          <div slot="label">Email</div>
        </ion-input>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-06">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          type="submit"
          class="primary-button-footer"
          appPreventMultiClickEvents
          (preventMultiClicks)="save()"
          expand="block">
          <ion-text> Save </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
