// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { BusinessClassification } from "../models/legacy/BusinessClassification";
import { Guid, double, DateOnly, int } from "../models/ApiTypes";
import { CustomerDetail } from "../models/legacy/CustomerDetail";
import { CustomerVerifiedBusinessCreate } from "../models/legacy/CustomerVerifiedBusinessCreate";
import { dtoCommunityUserAddressInfo } from "../models/legacy/dtoCommunityUserAddressInfo";
import { CustomerVerifiedBusinessUpdate } from "../models/legacy/CustomerVerifiedBusinessUpdate";
import { CustomerUnverifiedUpdate } from "../models/legacy/CustomerUnverifiedUpdate";
import { DocumentDetail } from "../models/legacy/DocumentDetail";
import { FundingSourceDetail } from "../models/legacy/FundingSourceDetail";
import { FundingSourceCreate } from "../models/legacy/FundingSourceCreate";
import { FundingSourceMicroDepositDetail } from "../models/legacy/FundingSourceMicroDepositDetail";
import { FundingSourceMicroDepositVerification } from "../models/legacy/FundingSourceMicroDepositVerification";
import { dtoDwollaFee } from "../models/legacy/dtoDwollaFee";
import { TransferDetailWithFees } from "../models/legacy/TransferDetailWithFees";
import { TransferStatuses } from "../models/legacy/legacy-enums";
import { TransferDetail } from "../models/legacy/TransferDetail";
import { dtoOfficialPayment } from "../models/legacy/dtoOfficialPayment";
import { dtoWorkerPayment } from "../models/legacy/dtoWorkerPayment";
import { OrganizationBalanceTransfer } from "../models/legacy/OrganizationBalanceTransfer";

@Injectable({providedIn: 'root'})
export class PaymentLegacyApiService {
    private readonly module = "Payment";

    constructor(private legacyApi: LegacyApiService) { }

    /** 
     */
    checkAccess() {
        return this.legacyApi.get<void>(this.module, "CheckAccess", {  });
    }

    /** Lists and returns all of the business classifications available from Dwolla.
     */
    getBusinessClassifications() {
        return this.legacyApi.get<BusinessClassification[] | undefined>(this.module, "GetBusinessClassifications", {  });
    }

    /** Retrieves Dwolla customer details associated with an organization, user must have Bank Account Permissions or Manage Bank Account Permissions. Returns 400 if organization doesn't have a Dwolla Customer ID.
     * @param id Organization ID
     */
    getCustomerForOrganization(id: Guid) {
        return this.legacyApi.get<CustomerDetail | undefined>(this.module, "GetCustomerForOrganization", { id });
    }

    /** Retrieves Dwolla customer details associated with a user ID. Returns 400 if user doesn't have a Dwolla Customer ID.
     * @param id User ID
     */
    getCustomerForUser(id: Guid) {
        return this.legacyApi.get<CustomerDetail | undefined>(this.module, "GetCustomerForUser", { id });
    }

    /** Registers a new Dwolla customer account for an organization, user must have either Bank Account Permissions or Manage Bank Account Permissions. Registration can be forced if an account already exists for the organization.
     * @param id Organization ID
     * @param force 
     * @param body 
     */
    registerCustomerForOrganization(id: Guid, force: boolean | undefined, body: CustomerVerifiedBusinessCreate) {
        return this.legacyApi.post<CustomerDetail | undefined>(this.module, "RegisterCustomerForOrganization", { id, force }, body);
    }

    /** Registers a new Dwolla customer account for a user. Registration can be forced if an account already exists for a user. Returns 400 if invalid address. Additional body input of type CustomerReceiveOnlyCreate.
     * @param id User ID
     * @param force 
     * @param body 
     */
    registerCustomerForUser(id: Guid, force: boolean | undefined, body: dtoCommunityUserAddressInfo) {
        return this.legacyApi.post<CustomerDetail | undefined>(this.module, "RegisterCustomerForUser", { id, force }, body);
    }

    /** Retries retrieving customer details for an organization, user must have Bank Account Permissions or Manage Bank Account Permissions.
     * @param id Organization ID
     * @param body 
     */
    retryCustomerForOrganization(id: Guid, body: CustomerVerifiedBusinessCreate) {
        return this.legacyApi.post<CustomerDetail | undefined>(this.module, "RetryCustomerForOrganization", { id }, body);
    }

    /** Update the customer details for an organization, user must have Bank Account Permissions or Manage Bank Account Permissions.
     * @param id Organization ID
     * @param body 
     */
    updateCustomerForOrganization(id: Guid, body: CustomerVerifiedBusinessUpdate) {
        return this.legacyApi.post<CustomerDetail | undefined>(this.module, "UpdateCustomerForOrganization", { id }, body);
    }

    /** Update customer details for a user
     * @param id User ID
     * @param body 
     */
    updateCustomerForUser(id: Guid, body: CustomerUnverifiedUpdate) {
        return this.legacyApi.post<CustomerDetail | undefined>(this.module, "UpdateCustomerForUser", { id }, body);
    }

    /** Retrieves documents associated with a customer payment
     * @param id Payments customer ID
     */
    getDocumentsForCustomer(id: string) {
        return this.legacyApi.get<DocumentDetail[] | undefined>(this.module, "GetDocumentsForCustomer", { id });
    }

    /** Creates a new document for a customer
     */
    createDocument() {
        return this.legacyApi.post<DocumentDetail | undefined>(this.module, "CreateDocument", {  });
    }

    /** Generates a new IAV token for a given customer ID. If customer ID is associated with an organization user must have Manage Bank Account Permissions.
     * @param id Payments customer ID
     */
    newIavTokenForCustomer(id: string) {
        return this.legacyApi.post<string | undefined>(this.module, "NewIavTokenForCustomer", { id });
    }

    /** Generates a new Funding Source token for a given customer ID. If customer ID is associated with an organization user must have Manage Bank Account Permissions.
     * @param id Payments customer ID
     */
    createFundingSourceToken(id: string) {
        return this.legacyApi.post<string | undefined>(this.module, "CreateFundingSourceToken", { id });
    }

    /** Generates a funding source token for a customer after validating a token from the TokenExchangeTokens table.
     * @param token 
     */
    createExchangedFundingSourceToken(token: Guid) {
        return this.legacyApi.post<string | undefined>(this.module, "CreateExchangedFundingSourceToken", { token });
    }

    /** For DWOLLA use only
     * @param id 
     * @param body 
     */
    createClientToken(id: string, body: any) {
        return this.legacyApi.post<void>(this.module, "CreateClientToken", { id }, body);
    }

    /** For DWOLLA use only
     * @param token 
     * @param body 
     */
    createExchangeClientToken(token: Guid, body: string) {
        return this.legacyApi.post<void>(this.module, "CreateExchangeClientToken", { token }, body);
    }

    /** Retrieves a customer's ID associated with an exchangeable token. Output is in a specific format : new { customerID }
     * @param token 
     */
    getExchangeClientCustomer(token: Guid) {
        return this.legacyApi.post<void>(this.module, "GetExchangeClientCustomer", { token });
    }

    /** Process sandbox transactions and returns a response indicating that the request was successful
     */
    processSandboxTransactions() {
        return this.legacyApi.post<void>(this.module, "ProcessSandboxTransactions", {  });
    }

    /** Retrieves details for a specific funding source based on its ID.
     * @param id Payments funding source ID
     */
    getFundingSource(id: string) {
        return this.legacyApi.get<FundingSourceDetail | undefined>(this.module, "GetFundingSource", { id });
    }

    /** Retrieves all funding sources for a specific customer based on their ID.
     * @param id Payments customer ID
     * @param removed 
     */
    getFundingSourcesForCustomer(id: string, removed: boolean | undefined) {
        return this.legacyApi.get<FundingSourceDetail[] | undefined>(this.module, "GetFundingSourcesForCustomer", { id, removed });
    }

    /** Creates a new funding source for a specified customer.
     * @param id Payments customer ID
     * @param body 
     */
    createFundingSourceForCustomer(id: string, body: FundingSourceCreate) {
        return this.legacyApi.post<FundingSourceDetail | undefined>(this.module, "CreateFundingSourceForCustomer", { id }, body);
    }

    /** Removes a specified Funding Source, if the funding source customer ID is an organization the user must have Manage Bank Account Permissions for that organization.
     * @param id Payments customer ID
     */
    removeFundingSource(id: string) {
        return this.legacyApi.post<FundingSourceDetail | undefined>(this.module, "RemoveFundingSource", { id });
    }

    /** Retrieves the status of micro deposits for a specified funding source.
     * @param id Payments funding source ID
     */
    getFundingSourceMicroDepositStatus(id: string) {
        return this.legacyApi.get<FundingSourceMicroDepositDetail | undefined>(this.module, "GetFundingSourceMicroDepositStatus", { id });
    }

    /** Initiates the process of micro deposits for a specified funding source.
     * @param id Payments funding source ID
     */
    initiateFundingSourceMicroDeposits(id: string) {
        return this.legacyApi.post<void>(this.module, "InitiateFundingSourceMicroDeposits", { id });
    }

    /** Verifies the micro deposits for a specified funding source, if the funding source customer ID is an organization the user must have Manage Bank Account Permissions for that organization.
     * @param id Payments funding source ID
     * @param body 
     */
    verifyFundingSourceMicroDeposits(id: string, body: FundingSourceMicroDepositVerification) {
        return this.legacyApi.post<void>(this.module, "VerifyFundingSourceMicroDeposits", { id }, body);
    }

    /** Retrieves the transfer fee associated with transfer transactions.
     */
    getTransferFee() {
        return this.legacyApi.get<dtoDwollaFee | undefined>(this.module, "GetTransferFee", {  });
    }

    /** Retrieves the details of a specified transfer, along with its associated fees.
     * @param id Transfer ID
     */
    getTransfer(id: string) {
        return this.legacyApi.get<TransferDetailWithFees | undefined>(this.module, "GetTransfer", { id });
    }

    /** Retrieves a list of transfers associated with a specific customer. At least one search option is required.
     * @param id Customer ID
     * @param Search I think this is for searching payer/payee names
     * @param StartAmount Inclusive amount to search for
     * @param EndAmount Inclusive amount to search for
     * @param StartDate Inclusive date to search for
     * @param EndDate Inclusive date to search for
     * @param Status 
     * @param Limit Number of results to return. Defaults to 25.
     * @param Offset Number of results to skip before returning the rest. Defaults to 0.
     */
    getTransfersForCustomer(id: string, Search: string | undefined, StartAmount: double | undefined, EndAmount: double | undefined, StartDate: DateOnly | undefined, EndDate: DateOnly | undefined, Status: TransferStatuses | undefined, Limit: int | undefined, Offset: int | undefined) {
        return this.legacyApi.get<TransferDetail[] | undefined>(this.module, "GetTransfersForCustomer", { id, Search, StartAmount, EndAmount, StartDate, EndDate, Status, Limit, Offset });
    }

    /** Retrieves a list of transfers associated with a specific customer and exports this list to Excel format
     * @param id Customer ID
     * @param Search I think this is for searching payer/payee names
     * @param StartAmount Inclusive amount to search for
     * @param EndAmount Inclusive amount to search for
     * @param StartDate Inclusive date to search for
     * @param EndDate Inclusive date to search for
     * @param Status 
     * @param Limit Number of results to return. Defaults to 25.
     * @param Offset Number of results to skip before returning the rest. Defaults to 0.
     */
    exportTransfersForCustomer(id: string, Search: string, StartAmount: double | undefined, EndAmount: double | undefined, StartDate: DateOnly | undefined, EndDate: DateOnly | undefined, Status: TransferStatuses | undefined, Limit: int | undefined, Offset: int | undefined) {
        return this.legacyApi.post<Blob | undefined>(this.module, "ExportTransfersForCustomer", { id, Search, StartAmount, EndAmount, StartDate, EndDate, Status, Limit, Offset });
    }

    /** Pay an official for a specific event and updates the status of the official's slot for that event to 'Paid'.
     * @param force 
     * @param body 
     * @returns This endpoint can return a 409 Conflict response.
     */
    payOfficial(force: boolean | undefined, body: dtoOfficialPayment) {
        return this.legacyApi.post<TransferDetail | undefined, double | undefined>(this.module, "PayOfficial", { force }, body);
    }

    /** Pays a worker for a specific event, updates the status of the worker's job slot for that event to 'Paid', and also updates the payment amount and working hours. Returns conflict if transfer result is null.
     * @param force 
     * @param body 
     * @returns This endpoint can return a 409 Conflict response.
     */
    payWorker(force: boolean | undefined, body: dtoWorkerPayment) {
        return this.legacyApi.post<TransferDetail | undefined, double | undefined>(this.module, "PayWorker", { force }, body);
    }

    /** Transfers funds from one funding source of an organization to another (balance funding source). User must have Manage Bank Account Permissions. 
     * @param body 
     */
    transferToBalance(body: OrganizationBalanceTransfer) {
        return this.legacyApi.post<TransferDetail | undefined>(this.module, "TransferToBalance", {  }, body);
    }

    /** Processes a withdrawal from the balance of an organization to another funding source. User must have Manage Bank Account Permissions.
     * @param body 
     */
    withdrawFromBalance(body: OrganizationBalanceTransfer) {
        return this.legacyApi.post<TransferDetail | undefined>(this.module, "WithdrawFromBalance", {  }, body);
    }

    /** Cancels a specific transfer given its ID
     * @param transferID 
     */
    cancelTransfer(transferID: string) {
        return this.legacyApi.post<void>(this.module, "CancelTransfer", { transferID });
    }
}