<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title style="text-align: center">Multi-factor Auth</ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="verifyMultiFactorAuth()"
        expand="block">
        <ion-text> Submit </ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-text class="mfa-description">Your account has additional security enabled. Please select how you would like to receive your verification code</ion-text>
  <ion-list
    [inset]="true"
    *ngFor="let contact of this.contactPoints"
    class="mfa-list">
    <ion-row class="option-row">
      <ion-item class="option-name"> {{contact.Title}}</ion-item>
      <ion-item class="option-type"> {{contact.ContactType}}</ion-item>
      <ion-button class="mfa-button" appPreventMultiClickEvents (preventMultiClicks)="startMultiFactorAuth(contact.ID)">
        <ion-text *ngIf="contact.ContactType === 'Email'">Send Email</ion-text>
        <ion-text *ngIf="contact.ContactType === 'Phone'">Send Text</ion-text>
      </ion-button>
    </ion-row>
  </ion-list>
  <ion-input labelPlacement="stacked" placeholder="Click to enter code here." [(ngModel)]="mfaCode" class="remember-device"></ion-input>
  <ion-row class="remember-device">  <ion-checkbox [(ngModel)]="this.rememberDevice" size=""></ion-checkbox><ion-text class="checkbox-text">&nbsp; Remember Device?</ion-text></ion-row>

</ion-content>
