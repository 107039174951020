<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title> Season Pass Tickets </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="container ion-padding">
    <div class="list-container">
      <ion-label *ngIf="tickets.length < 1" class="ion-padding"> No tickets available. </ion-label>

      <div *ngIf="tickets.length > 0">
        <div class="card-container" *ngFor="let ticket of tickets">
          <div class="card-header">
            <ion-text class="header-text">
              Calendar -
              {{ ticket.TicketConfiguration.ConfigurationEvents![0].Event.PrimaryCalendar.Title }}</ion-text
            >
          </div>

          <div class="content" *ngFor="let event of ticket.TicketConfiguration.ConfigurationEvents">
            <ion-text class="details-title"
              >Event:
              <ion-text style="font-weight: 400">{{
                event.Event.Title && event.Event.Title !== '' ? event.Event.Title : 'N/A'
              }}</ion-text></ion-text
            >
            <ion-text class="details-title"
              >Starts At:
              <ion-text style="font-weight: 400">{{
                formatDateTime(event.Event.StartDateTime!)
              }}</ion-text></ion-text
            >
            <ion-text style="font-weight: 400; margin-bottom: 5px">{{ qrCodeValue }}</ion-text>
          </div>

          <ion-button expand="block" class="select-btn button-height" (click)="selectTicket(ticket)">
            Select Ticket</ion-button
          >
        </div>
      </div>
    </div>
  </div>
</ion-content>
