import {Component} from "@angular/core";

@Component({
    selector: 'app-indiana-component',
    templateUrl: './_indiana_ihsaa.html',
    styleUrl: './contract.scss',
})

export class IndianaComponent{

}
