// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoWorkerLongForm } from "../models/legacy/dtoWorkerLongForm";
import { dtoWorkerShortForm } from "../models/legacy/dtoWorkerShortForm";
import { dtoWorkerCreateUpdate } from "../models/legacy/dtoWorkerCreateUpdate";
import { dtoWorkerNewUser } from "../models/legacy/dtoWorkerNewUser";
import { dtoWorkerActivateAccount } from "../models/legacy/dtoWorkerActivateAccount";

@Injectable({providedIn: 'root'})
export class WorkerLegacyApiService {
    private readonly module = "Worker";

    constructor(private legacyApi: LegacyApiService) { }

    /** Finds an existing worker by ID, user must have Worker Permissions
     * @param id Worker ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoWorkerLongForm | undefined>(this.module, "Find", { id });
    }

    /** Gets the workers for the current user
     */
    getWorkersForUser() {
        return this.legacyApi.get<dtoWorkerShortForm[] | undefined>(this.module, "GetWorkersForUser", {  });
    }

    /** Gets all workers within an organization
     * @param organizationid 
     */
    getAllByOrganizationID(organizationid: Guid) {
        return this.legacyApi.get<dtoWorkerShortForm[] | undefined>(this.module, "GetAllByOrganizationID", { organizationid });
    }

    /** Creates a new worker from an existing user, user must have Worker Permissions
     * @param body 
     */
    create(body: dtoWorkerCreateUpdate) {
        return this.legacyApi.post<dtoWorkerLongForm | undefined>(this.module, "Create", {  }, body);
    }

    /** Creates new user that is a worker, user must have Worker Permissions
     * @param body 
     */
    createNewUserFromWorkerData(body: dtoWorkerNewUser) {
        return this.legacyApi.post<dtoWorkerLongForm | undefined>(this.module, "CreateNewUserFromWorkerData", {  }, body);
    }

    /** Sends an activation email to a worker's email, user must have Worker Permissions
     * @param body 
     */
    sendActivationEmail(body: dtoWorkerActivateAccount) {
        return this.legacyApi.post<void>(this.module, "SendActivationEmail", {  }, body);
    }

    /** Updates an existing worker by ID, user must have Worker Permissions
     * @param body 
     */
    update(body: dtoWorkerCreateUpdate) {
        return this.legacyApi.post<dtoWorkerLongForm | undefined>(this.module, "Update", {  }, body);
    }

    /** Deletes an existing worker by ID, user must have Worker Permissions
     * @param id Worker ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }
}