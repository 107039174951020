// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoOrganizationSitesConfigurationPublic } from "../models/DtoOrganizationSitesConfigurationPublic";
import { DtoOrganizationSiteConfigurationPublic } from "../models/DtoOrganizationSiteConfigurationPublic";

@Injectable({providedIn: 'root'})
export class OrganizationSiteConfigurationPublicApiService {
    private readonly controller = "OrganizationSiteConfigurationPublic";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param organizationID 
     */
    logo(organizationID: Guid) {
        return this.v2Api.get<void>(this.controller, "Logo", { organizationID });
    }

    /** 
     * @param slug 
     * @param domain 
     * @param organizationID 
     */
    getV2(slug: string | undefined, domain: string | undefined, organizationID: Guid | undefined) {
        return this.v2Api.get<DtoOrganizationSitesConfigurationPublic>(this.controller, "GetV2", { slug, domain, organizationID });
    }

    /** 
     * @param slug 
     * @param domain 
     * @param organizationID 
     */
    get(slug: string | undefined, domain: string | undefined, organizationID: Guid | undefined) {
        return this.v2Api.get<DtoOrganizationSiteConfigurationPublic>(this.controller, "Get", { slug, domain, organizationID });
    }
}