import { Component, Input } from '@angular/core';
import { ModalController, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import * as moment from 'moment';
import { DtoArticleCreateUpdate } from 'src/app/core/services/api/models/DtoArticleCreateUpdate';
import { dtoCalendarSubShortForm } from 'src/app/core/services/api/models/legacy/dtoCalendarSubShortForm';
import { ArticlesApiService } from 'src/app/core/services/api/v2/ArticlesApi.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { Guid } from 'src/app/core/services/api/models/ApiTypes';

@Component({
  selector: 'app-create-article',
  templateUrl: './create-article-modal.component.html',
  styleUrls: ['./create-article-modal.component.scss'],
})
export class CreateArticleComponent implements ViewDidEnter, ViewDidLeave {
  @Input() Article?: DtoArticleCreateUpdate;
  @Input() Edit!: boolean;
  @Input() CanAddSchool!: boolean;
  @Input() CalendarID?: Guid;
  @Input() OrganizationID?: Guid;
  @Input() Calendars?: dtoCalendarSubShortForm[];
  @Input() ReadOnlyCalendars?: dtoCalendarSubShortForm[];
  @Input() Timezone!: string;

  article: DtoArticleCreateUpdate = {
    ID: undefined,
    OrganizationID: '',
    VisibleAt: undefined,
    VisibleUntil: undefined,
    ArticleType: 'Unknown',
    Title: '',
    Body: '',
    EventID: undefined,
    CalendarIDs: [],
  };
  isPublishedNow: boolean | undefined = false;

  constructor(
    public readonly modalCtrl: ModalController,
    private readonly dialogService: DialogService,
    public readonly articlesApiService: ArticlesApiService,
    private readonly utilsService: UtilsService
  ) {}

  async ionViewDidEnter() {
    this.article = this.Article!;
    this.article.VisibleAt = this.article.VisibleAt
      ? this.formatDateTimeCustomFormat(this.article.VisibleAt)
      : undefined;
    this.article.VisibleUntil = this.article.VisibleUntil
      ? this.formatDateTimeCustomFormat(this.article.VisibleUntil)
      : undefined;

    if (!this.Calendars) {
      this.Calendars = [];
    }
    if (this.Calendars?.length) {
      // sort alphabetically
      this.Calendars = [...this.Calendars].sort((a, b) => {
        return a.Title!.localeCompare(b.Title!);
      });
      // place organization's calendar to the top of the list
      this.Calendars = [...this.Calendars].sort((a) => {
        if (a.ID === this.CalendarID) {
          return -1;
        }
        return 0;
      });
    }
  }

  async ionViewDidLeave() {
    this.isPublishedNow = false;
  }

  async saveAndPostToSocials(article: DtoArticleCreateUpdate) {
    await this.save(article, 'post');
  }

  async saveAndAddAttachments(article: DtoArticleCreateUpdate) {
    await this.save(article, 'add-attachments');
  }

  async save(article: DtoArticleCreateUpdate, moreActions: string) {
    if (await this.isInvalidForm()) return;

    let articleModel: DtoArticleCreateUpdate = this.Edit
      ? {
          ID: article.ID,
          OrganizationID: article.OrganizationID,
          VisibleAt: this.utilsService.getDateTimeISOFormat(this.article.VisibleAt),
          VisibleUntil: this.utilsService.getDateTimeISOFormat(this.article.VisibleUntil),
          ArticleType: article.ArticleType,
          Title: article.Title,
          Body: article.Body,
          EventID: article.EventID,
          CalendarIDs: article.CalendarIDs,
        }
      : {
          ID: undefined,
          OrganizationID: this.OrganizationID!,
          VisibleAt: this.utilsService.getDateTimeISOFormat(this.article.VisibleAt),
          VisibleUntil: this.utilsService.getDateTimeISOFormat(this.article.VisibleUntil),
          ArticleType: article.ArticleType,
          Title: article.Title,
          Body: article.Body,
          EventID: article.EventID,
          CalendarIDs: article.CalendarIDs,
        };

    const saveMessageResponse = await this.articlesApiService.save(articleModel);
    if (saveMessageResponse.isAnyError) {
      return;
    }

    if (saveMessageResponse.isSuccess) {
      if (moreActions === 'post') {
        this.modalCtrl.dismiss({ status: 'successAndPost', articleID: saveMessageResponse.data });
      } else if (moreActions === 'add-attachments') {
        this.modalCtrl.dismiss({ status: 'successAndAdd', articleID: saveMessageResponse.data });
      }
      this.modalCtrl.dismiss('success');
    }
  }

  private async isInvalidForm(): Promise<boolean> {
    if (this.article.Title === '' || this.article.Body === '' || !this.article.ArticleType) {
      await this.dialogService.showAlert({
        header: 'Missing Information',
        message: 'Please fill out all required fields',
        buttons: ['OK'],
      });
      return true;
    }

    if (this.article.CalendarIDs?.length === 0) {
      await this.dialogService.showAlert({
        header: 'Missing Information',
        message: 'Please select calendars',
        buttons: ['OK'],
      });
      return true;
    }

    return false;
  }

  async publishNow() {
    if (this.isPublishedNow) {
      this.article.VisibleAt = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    } else {
      this.article.VisibleAt = undefined;
    }
  }

  isArticleReadyToPost(visibleAt: string) {
    if (moment(visibleAt).isBefore()) {
      return true;
    }
    return false;
  }

  cancel() {
    this.modalCtrl.dismiss('cancel');
  }

  formatDateTimeCustomFormat(dateTime: string) {
    return this.utilsService.getDateTimeCustomFormat(dateTime, this.Timezone, 'YYYY-MM-DDTHH:mm:ssZ');
  }
}
