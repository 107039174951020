import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-tax-profile',
  templateUrl: './tax-profile.component.html',
  styleUrls: ['./tax-profile.component.scss'],
})
export class TaxProfileComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() payor: string;
  @Input() upperAmount?: number;
  @Input() lowerAmount?: number;
  @Input() classification: string;

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly fb: FormBuilder,
  ) {
    this.payor = '';
    this.classification = '';

    this.formGroup = this.fb.group({
      payor: [''],
      upperAmount : [undefined],
      lowerAmount : [undefined],
      classification : [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async search() {
    this.modalCtrl.dismiss();
  }
}
