// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoPayItemsSpecialUserRead } from "../models/DtoPayItemsSpecialUserRead";

@Injectable({providedIn: 'root'})
export class PayItemSpecialUsersApiService {
    private readonly controller = "PayItemSpecialUsers";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param organizationID 
     */
    getUsersSpecialAccessPayItemsByOrganization(organizationID: Guid) {
        return this.v2Api.get<DtoPayItemsSpecialUserRead[]>(this.controller, "GetUsersSpecialAccessPayItemsByOrganization", { organizationID });
    }
}