<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" type="submit" (click)="cancel()">
        <ion-text> Cancel</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Add Workers</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding">
    <ion-grid>
      <ion-row class="underline  ion-margin-bottom">
        <ion-select labelPlacement="stacked" placeholder="No type selected" [(ngModel)]="assignmentType">
          <div slot="label">Assignment Type</div>
          <ion-select-option value="Select">Select</ion-select-option>
          <ion-select-option value="Open">Open</ion-select-option>
        </ion-select>
      </ion-row>
      <ion-row *ngIf="assignmentType === 'Select'" class="underline ion-margin-bottom">
        <ion-select labelPlacement="stacked" placeholder="No worker selected" [(ngModel)]="selectedWorker">
          <div slot="label">Worker</div>
          <ion-select-option *ngFor="let worker of workers"
                             [value]="worker">{{worker.FirstName + " " + worker.LastName}}</ion-select-option>
        </ion-select>
      </ion-row>
      <ion-row class="underline ion-margin-bottom">
        <ion-select labelPlacement="stacked" placeholder="No job selected" [(ngModel)]="selectedJob">
          <div slot="label">Job<ion-text color="danger"> *</ion-text></div>
          <ion-select-option *ngFor="let job of jobs" [value]="job">{{job.Title}}</ion-select-option>
        </ion-select>
      </ion-row>
      <ion-row class="underline ion-margin-bottom">
        <ion-select labelPlacement="stacked" placeholder="No method selected" [(ngModel)]="methodOfPayment">
          <div slot="label">Method of Payment</div>
          <ion-select-option value="EventPayment">Event Payment</ion-select-option>
          <ion-select-option value="HourlyPayment">Hourly Payment</ion-select-option>
        </ion-select>
      </ion-row>

      <ion-row class="input-row ion-margin-bottom" *ngIf="methodOfPayment === 'EventPayment'">
        <ion-input labelPlacement="stacked" type="number" placeholder="" [(ngModel)]="amount" min="0" inputmode="numeric">
          <div slot="label">Amount ($)</div>
        </ion-input>
      </ion-row>
      <ion-row class="input-row ion-margin-bottom" *ngIf="methodOfPayment === 'HourlyPayment'">
        <ion-input class="underline" labelPlacement="stacked" type="number"  placeholder="" [(ngModel)]="hourlyAmount" min="0" inputmode="numeric">
          <div slot="label">Hourly Amount ($)</div>
        </ion-input>
        <ion-input labelPlacement="stacked" type="number"  placeholder="#" [(ngModel)]="hours" min="0" inputmode="numeric">
          <div slot="label">Hours</div>
        </ion-input>
      </ion-row>
    </ion-grid>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="save()"
          expand="block">
          <ion-text> Save Worker</ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>


