import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() size: 'lg' | 'sm' | '' = '';

  @Input() clear: boolean = false;

  @Input() outline: boolean = false;

  @Input() inverted: boolean = false;

  @Input() arrow: boolean = false;

  @Input() icon: string | null = null;

  @Input() loading: boolean = false;

  @Input() disabled: boolean = false;
}
