import { INGXLoggerMonitor, INGXLoggerMetadata, INGXLoggerConfig } from 'ngx-logger';
import { AuthService } from '../services/auth/auth.service';
import { inject } from '@angular/core';

export class NgxLoggerMonitor implements INGXLoggerMonitor {
  private readonly authService = inject(AuthService);

  async onLog(logObject: INGXLoggerMetadata, _config: INGXLoggerConfig) {
    const context = await this.authService.getContext();
    const message = logObject.additional?.map((e) => e).join(' | ');

    await this.authService.insertLog({
      Message: `${logObject.message}: ${message}`,
      File: 'ngx-logger-monitor.ts',
      Method: 'onLog()',
      DateTime: new Date(logObject.timestamp!),
      UserID: context?.UserID,
    });
  }
}
