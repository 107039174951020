import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { GlobalErrorHandlerService } from '../../services/global-error-handler/global-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(private globalErrorHandlerService: GlobalErrorHandlerService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        this.globalErrorHandlerService.handleError(err);
        return this.customErrorHandler(err);
      })
    );
  }

  private customErrorHandler(error: any) {
    let errorMessage: string;

    if (error === 'ERROR_NO_INTERNET' || error === 'ERR_INTERNET_DISCONNECTED' || error.name === 'TimeoutError') {
      errorMessage = 'HTTP_ERRORS.NO_INTERNET_CONNECTION';
    } else {
      errorMessage =
        error && error.error && error.error.message ? error.error.message : 'HTTP_ERRORS.DEFAULT_MESSAGE';
    }

    return throwError(() => ({ message: errorMessage, original: error }));
  }
}
