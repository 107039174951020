import {Component} from "@angular/core";

@Component({
    selector: 'app-missouri-component',
    templateUrl: './_missouri_mshsaa.html',
  styleUrl: './contract.scss',
})

export class MissouriComponent{

}
