import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {
  public VersionName: string = "";
  public VersionBuildCode: string = "";

  constructor(
    private logger: NGXLogger
  ) { }

  public getVersionDisplay(): string {
    if (this.VersionName != "" && this.VersionBuildCode != "")
    {
      return `${this.VersionName} (${this.VersionBuildCode})`;
    }

    return "";
  }

  public async init() {
    try {
      const info = await App.getInfo();
      this.VersionName = info.version;
      this.VersionBuildCode = info.build;
    } catch (error) {
      this.logger.info('Error getting version info.', error);
    }
  }
}
