<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Manage Calendars</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row class="title-text">
    <span class="ion-padding-horizontal top-text background-transparent title-text" *ngIf="contextOrganization">{{ contextOrganization.OrganizationDisplayTitle || contextOrganization.OrganizationTitle }} Calendars</span>
  </ion-row>
  <ion-row class="ion-padding-horizontal top-text background-transparent">
    Scroll down and select which calendars from this organization you would like to see. When you are finished, hit the "Save" button.
  </ion-row>
  <div style="border-top: solid grey; margin-top: 8px">
    <ion-row class="ion-padding-horizontal top-text background-transparent centered-text">Added Calendars</ion-row>
    <ion-list [inset]="true"
              class="school-list ion-padding" slot="content" style="margin-bottom: 0;">
      <div *ngFor="let selectedCalendar of selectedCalendarsForUser" class="school-info" >
        <ion-icon class="first-element" #refEl name="checkmark-circle-outline" size="large"
                  (click)="removeCalendar(selectedCalendar, refEl)"/>
        <span class="second-element">{{selectedCalendar.Title}}</span>
      </div>
    </ion-list>
  </div>
  <div>
    <ion-row class="ion-padding-horizontal top-text background-transparent centered-text">Available Calendars</ion-row>
    <ion-list [inset]="true"
              class="school-list ion-padding" slot="content">
      <div *ngFor="let calendar of calendarsForSchool" class="school-info" >
        <ion-icon class="first-element" #refEl name="ellipse-outline" size="large"
                  (click)="addCalendar(calendar, refEl)"/>&nbsp;
        <span class="second-element">{{calendar.Title}}</span>
      </div>
    </ion-list>
  </div>
  <div class="footer-background">
    <ion-button class="ion-padding save-button"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="saveCalendars()"
      expand="block">
      <ion-text> Save </ion-text>
    </ion-button>
  </div>
</ion-content>
