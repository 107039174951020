<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Share {{ Title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true">Submit</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item>
    <ion-input
      labelPlacement="stacked"
      label="Enter an email"
      [(ngModel)]="shareToEmail"
      placeholder="email@email.com"></ion-input>
  </ion-item>
</ion-content>
