import {Component} from "@angular/core";

@Component({
    selector: 'app-illinois-component',
    templateUrl: './_illinois_ihsa.html',
    styleUrl: './contract.scss'
})

export class IllinoisComponent{

}
