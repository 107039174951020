<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div *ngIf="title === 'Send Receipt'">
    <ion-row>
      <ion-label>Phone Number:</ion-label>
      <ion-input type="tel" [(ngModel)]="sendReceiptPhoneNumber" placeholder="(123) 123-1234"> </ion-input>
    </ion-row>
    <ion-row>
      <ion-button (click)="skipPhoneNumber()" [strong]="true" class="skipPhoneButton">Do Not Send</ion-button>
      <ion-button (click)="sendPhoneNumber()" [strong]="true" class="sendNumberButton">Send</ion-button>
    </ion-row>
  </div>

  <div *ngIf="title === 'Checkout'">
    <div *ngIf="!!checkoutResponse">
      <ion-row *ngIf="!checkoutResponse.FeeAbsorbed">
        <ion-text class="checkout-title">Subtotal:</ion-text>
        <ion-text> {{ formatCurrency(checkoutResponse.SubTotalDollars) }}</ion-text>
      </ion-row>

      <ion-row *ngIf="!checkoutResponse.FeeAbsorbed">
        <ion-text class="checkout-title">Fee:</ion-text>
        <ion-text>{{ formatCurrency(checkoutResponse.FeeDollars) }}</ion-text>
      </ion-row>

      <ion-row>
        <ion-text class="checkout-title">Total:</ion-text>
        <ion-text> {{ formatCurrency(checkoutResponse.TotalDueDollars) }}</ion-text>
      </ion-row>
    </div>

    <div *ngIf="!!!checkoutResponse">
      <ion-row>
        <ion-skeleton-text [animated]="true" class="checkout-title-skeleton"></ion-skeleton-text>
      </ion-row>

      <ion-row>
        <ion-skeleton-text [animated]="true" class="checkout-title-skeleton"></ion-skeleton-text>
      </ion-row>

      <ion-row>
        <ion-skeleton-text [animated]="true" class="checkout-title-skeleton"></ion-skeleton-text>
      </ion-row>
    </div>

    <ion-row>
      <ion-button (click)="swipeCard()" [strong]="true" expand="block" class="swipeCardButton"
        >Swipe/Tap Card</ion-button
      >
    </ion-row>
  </div>
</ion-content>
