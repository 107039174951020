import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import IContextModel from '../../../core/models/context/context.model';
import { ModalController, ViewDidEnter } from '@ionic/angular';
import { ApplicationTypeService } from '../../../core/services/auth/application-type/application-type.service';
import { ErrorService } from '../../../core/services/error/error.service';
import { WorkerLegacyApiService } from '../../../core/services/api/v1/WorkerLegacyApi.service';
import { dtoWorkerShortForm } from '../../../core/services/api/models/legacy/dtoWorkerShortForm';
import { dtoJobTypeShortForm } from '../../../core/services/api/models/legacy/dtoJobTypeShortForm';
import { dtoEventLongForm } from '../../../core/services/api/models/legacy/dtoEventLongForm';
import IContextOrganizationModel from '../../../core/models/context/context-organization.models';
import { JobTypesApiService } from '../../../core/services/api/v2/JobTypesApi.service';
import { EventJobSlotLegacyApiService } from '../../../core/services/api/v1/EventJobSlotLegacyApi.service';
import { dtoEventJobSlotCreateUpdate } from '../../../core/services/api/models/legacy/dtoEventJobSlotCreateUpdate';
import { EventJobSlotTypes } from '../../../core/services/api/models/legacy/legacy-enums';
import { dtoEventJob } from '../../../core/services/api/models/legacy/dtoEventJob';

@Component({
  selector: 'app-add-worker',
  templateUrl: 'add-worker-modal.component.html',
  styleUrls: ['add-worker-modal.component.scss'],
})
export class AddWorkerModalComponent implements ViewDidEnter, OnInit {
  context!: IContextModel;
  @Input() event: dtoEventLongForm | undefined;
  @Input() contextOrganization: IContextOrganizationModel | undefined;
  @Input() editWorker: boolean | undefined;
  @Input() job: dtoEventJob | undefined;
  assignmentType: string = 'Select';
  selectedWorker: dtoWorkerShortForm | undefined;
  workers: dtoWorkerShortForm[] = [];
  selectedJob: dtoJobTypeShortForm | undefined;
  jobs: dtoJobTypeShortForm[] = [];
  note: string | undefined = '';
  methodOfPayment: string = 'Event Payment';
  amount: number | undefined;
  hourlyAmount: number | undefined;
  hours: number | undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly modalCtrl: ModalController,
    protected readonly applicationTypeService: ApplicationTypeService,
    private readonly jobTypes: JobTypesApiService,
    private readonly workerService: WorkerLegacyApiService,
    private readonly eventJobSlotService: EventJobSlotLegacyApiService,
    private readonly errorService: ErrorService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.ionViewDidEnter();
  }
  async ionViewDidEnter() {
    this.context = await this.authService.getContext();
    this.contextOrganization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);
    if (this.contextOrganization && this.contextOrganization.OrganizationId) {
      let jobResponse = await this.jobTypes.getAllByOrganizationID(this.contextOrganization.OrganizationId);
      if (jobResponse.isSuccess && jobResponse.data) {
        this.jobs = jobResponse.data;
      }
      let workerResponse = await this.workerService.getAllByOrganizationID(
        this.contextOrganization.OrganizationId
      );
      if (workerResponse.isSuccess && workerResponse.data) {
        this.workers = workerResponse.data;
        this.workers = this.workers.sort((n1, n2) => {
          if (n1.LastName && n2.LastName && n1.LastName.toLowerCase() > n2.LastName.toLowerCase()) {
            return 1;
          }

          if (n1.LastName && n2.LastName && n1.LastName.toLowerCase() < n2.LastName.toLowerCase()) {
            return -1;
          }

          return 0;
        });
      }
    }
    // Check for Edit job details
    if (this.editWorker && this.job) {
      for (let worker of this.workers) {
        if (worker.ID === this.job.Worker?.ID) {
          this.selectedWorker = worker;
        }
      }
      for (let job of this.jobs) {
        if (job.ID === this.job.JobType?.ID) {
          this.selectedJob = job;
        }
      }
      this.note = this.job.Note;
      if (this.job.Hours) {
        this.hours = this.job.Hours;
        this.hourlyAmount = this.job.Pay;
        this.methodOfPayment = 'HourlyPayment';
      } else {
        this.amount = this.job.Pay;
        this.methodOfPayment = 'EventPayment';
      }

      this.amount = this.job.Pay;
    }
  }

  async save() {
    if (!this.selectedJob) {
      this.errorService.showError('Error', 15, 'You must select a job.');
      return;
    }
    let body: dtoEventJobSlotCreateUpdate[] = [];
    let newEventJobSlot: dtoEventJobSlotCreateUpdate;
    let selectOrOpenAssign: EventJobSlotTypes;
    let workerId: string | undefined;
    if (this.assignmentType === 'Select') {
      selectOrOpenAssign = 'SelectiveAssign';
      workerId = this.selectedWorker?.ID;
    } else {
      selectOrOpenAssign = 'OpenAssign';
      workerId = undefined;
    }
    let id: string | undefined = undefined;
    if (this.editWorker && this.job) {
      id = this.job.ID;
    }
    if (this.methodOfPayment === 'HourlyPayment') {
      newEventJobSlot = {
        ID: id,
        EventID: this.event?.ID,
        WorkerID: workerId,
        Pay: this.hourlyAmount,
        Hours: this.hours,
        JobTypeID: this.selectedJob?.ID,
        SlotType: selectOrOpenAssign,
        Note: this.note,
      };
    } else {
      newEventJobSlot = {
        ID: id,
        EventID: this.event?.ID,
        WorkerID: workerId,
        Pay: this.amount,
        Hours: undefined,
        JobTypeID: this.selectedJob?.ID,
        SlotType: selectOrOpenAssign,
        Note: this.note,
      };
    }
    body.push(newEventJobSlot);
    if (this.editWorker) {
      let response = await this.eventJobSlotService.updateMany(body);
      if (response.isSuccess) {
        return this.modalCtrl.dismiss(true, 'save');
      } else {
        this.errorService.showError(
          'Error',
          15,
          'There was an issue with saving the job. Please try again later.'
        );
        return;
      }
    } else {
      let response = await this.eventJobSlotService.createMany(body);
      if (response.isSuccess) {
        return this.modalCtrl.dismiss(true, 'save');
      } else {
        this.errorService.showError(
          'Error',
          15,
          'There was an issue with saving the job. Please try again later.'
        );
        return;
      }
    }
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
