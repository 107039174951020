<ion-header>
  <ion-toolbar>
    <ion-title>Linked Events</ion-title>

    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Close </ion-text>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button disabled="true" expand="block">
        <ion-text></ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-accordion-group>
    <ion-accordion *ngFor="let element of this.linkedGames">
      <ion-item slot="header">
        <ion-label>{{ element.Team.Title }}</ion-label>
      </ion-item>
      <div slot="content">
        <ion-row class="ion-padding-start ion-padding-end">
          Date & Time: {{ fixStartDateTime(element.StartDateTime) }}
        </ion-row>
        <ion-row class="ion-padding-start ion-padding-end"> Host: {{ element.Organization.Title }} </ion-row>
        <ion-row class="ion-padding-start ion-padding-end"> Type: {{ element.EventType }} </ion-row>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</ion-content>
