<ion-header id="tab-header-icons">
  <ion-toolbar class="toolbar">
    <div id="container">
      <ion-img class="header-logo" src="../../../../assets/images/logos/logo-mini.svg"></ion-img>
      <div id="header-container" *ngIf="this.applicationTypeService.applicationType$">
        <ion-text
          class="school-name"
          *ngIf="this.applicationTypeService.applicationType$.getValue() !== 'Officials'"
          >{{ this.applicationTypeService.organizationName$ | async }}</ion-text
        >
        <div>
          <ion-text
            class="type-name"
            *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'"
            class="type-text"
            slot="end">
            Staff
          </ion-text>
          <ion-text
            class="type-name"
            *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Community'"
            class="type-text"
            slot="end">
            Community
          </ion-text>
          <ion-text
            class="type-name"
            *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Officials'"
            class="type-text"
            slot="end">
            Officials
          </ion-text>
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
