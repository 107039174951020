import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { BarcodeFormat, BarcodeScanner, LensFacing, StartScanOptions } from '@capacitor-mlkit/barcode-scanning';
import { InputCustomEvent, IonRange, ViewDidEnter, LoadingController } from '@ionic/angular';
import { DialogService } from '../../../core/services/dialog/dialog.service';
import { ConnectionStatus, Network } from '@capacitor/network';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth/auth.service';
import { TicketTakersApiService } from 'src/app/core/services/api/v2/TicketTakersApi.service';
import { DtoTicketTakerScan } from 'src/app/core/services/api/models/DtoTicketTakerScan';
import * as moment from 'moment-timezone';
import { SeasonPassesApiService } from '../../../core/services/api/v2/SeasonPassesApi.service';
import IContextModel from '../../../core/models/context/context.model';
import { SelectSeasonPassEventComponent } from '../../../modules/tickets/components/select-season-pass-event/select-season-pass-event-modal.component';
import { ITicketTakerEvent } from '../../../core/models/tickets/ticket-taker-event.model';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-barcode-scanning',
  templateUrl: './barcode-scanning-modal.component.html',
  styleUrls: ['./barcode-scanning-modal.component.scss'],
})
export class BarcodeScanningModalComponent implements AfterViewInit, OnDestroy {
  @Input()
  ticketConfigurationID: string | undefined;
  @Input() context!: IContextModel;
  @Input() tickets!: ITicketTakerEvent[];

  @ViewChild('square')
  squareElement: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('zoomRange') ionRange!: IonRange;

  minZoomRatio: number = 0;
  maxZoomRatio: number = 10;
  private readonly ticketIDLength = environment.qrCodeExactLength - 3 - 1 - 1;
  private readonly modIterations = 3;
  private readonly modKey = 19;
  private readonly modInverseValue = this.modInverse(this.modKey, Number.MAX_VALUE);
  private readonly charactersInBase = '0123456789ABCDEF';
  qrCode!: string;
  scanning: boolean = true;
  exited: boolean = false;
  isSupported = false;

  constructor(
    private readonly dialogService: DialogService,
    private readonly ngZone: NgZone,
    private readonly authService: AuthService,
    private readonly ticketTakersService: TicketTakersApiService,
    private readonly seasonPassesApiService: SeasonPassesApiService,
    private readonly loadingController: LoadingController
  ) {}

  async ngAfterViewInit(): Promise<void> {
    let loadingUI = await this.loadingController.create({
      spinner: 'circular',
      message: 'Starting Barcode Scanner...',
      mode: 'ios',
    });

    await loadingUI.present();

    setTimeout(async () => {
      await this.startScan();
    }, 500);
  }

  setZoomRatio(event: InputCustomEvent): void {
    if (!event.detail.value) {
      return;
    }

    BarcodeScanner.setZoomRatio({
      zoomRatio: parseInt(event.detail.value),
    });
  }

  public ngOnDestroy(): void {
    this.stopScan();
  }

  async closeModal() {
    await this.stopScan();

    await this.dialogService.dismissModal();
    await this.dialogService.dismissAlert();

    this.exited = true;
  }

  private async startScan(): Promise<void> {
    document.querySelector('body')?.classList.add('barcode-scanning-active');

    BarcodeScanner.setZoomRatio({
      zoomRatio: 0,
    });

    this.ionRange.value = 0;

    const options: StartScanOptions = {
      formats: [BarcodeFormat.QrCode],
      lensFacing: LensFacing.Back,
      videoElement: undefined,
    };

    const squareElementBoundingClientRect = this.squareElement?.nativeElement.getBoundingClientRect();

    const scaledRect = squareElementBoundingClientRect
      ? {
          left: squareElementBoundingClientRect.left * window.devicePixelRatio,
          right: squareElementBoundingClientRect.right * window.devicePixelRatio,
          top: squareElementBoundingClientRect.top * window.devicePixelRatio,
          bottom: squareElementBoundingClientRect.bottom * window.devicePixelRatio,
          width: squareElementBoundingClientRect.width * window.devicePixelRatio,
          height: squareElementBoundingClientRect.height * window.devicePixelRatio,
        }
      : undefined;

    const detectionCornerPoints = scaledRect
      ? [
          [scaledRect.left, scaledRect.top],
          [scaledRect.left + scaledRect.width, scaledRect.top],
          [scaledRect.left + scaledRect.width, scaledRect.top + scaledRect.height],
          [scaledRect.left, scaledRect.top + scaledRect.height],
        ]
      : undefined;

    const listener = await BarcodeScanner.addListener('barcodesScanned', async (event) => {
      this.ngZone.run(async () => {
        const firstBarcode = event.barcodes[0];

        if (!firstBarcode) {
          return;
        }

        const cornerPoints = firstBarcode.cornerPoints;
        if (detectionCornerPoints && cornerPoints && Capacitor.getPlatform() !== 'web') {
          if (
            detectionCornerPoints[0][0] > cornerPoints[0][0] ||
            detectionCornerPoints[0][1] > cornerPoints[0][1] ||
            detectionCornerPoints[1][0] < cornerPoints[1][0] ||
            detectionCornerPoints[1][1] > cornerPoints[1][1] ||
            detectionCornerPoints[2][0] < cornerPoints[2][0] ||
            detectionCornerPoints[2][1] < cornerPoints[2][1] ||
            detectionCornerPoints[3][0] > cornerPoints[3][0] ||
            detectionCornerPoints[3][1] < cornerPoints[3][1]
          ) {
            return;
          }
        }

        listener.remove();

        await this.scan(firstBarcode.displayValue);
      });
    });

    await BarcodeScanner.startScan(options);

    void BarcodeScanner.getMinZoomRatio().then((result: { zoomRatio: number | undefined }) => {
      this.minZoomRatio = result.zoomRatio ?? 0;
    });

    void BarcodeScanner.getMaxZoomRatio().then((result: { zoomRatio: number | undefined }) => {
      this.maxZoomRatio = result.zoomRatio ?? 10;
    });

    await this.loadingController.dismiss();
  }

  async submitCode() {
    await this.scan(this.qrCode);
  }

  private async stopScan(): Promise<void> {
    document.querySelector('body')?.classList.remove('barcode-scanning-active');
    await BarcodeScanner.stopScan();
  }

  private async scan(barcode?: string) {
    if (!barcode || !this.scanning) {
      return;
    }

    this.scanning = false;

    await this.stopScan();

    const connectionStatus = await Network.getStatus();

    if (barcode.startsWith('P')) {
      this.seasonPassScan(connectionStatus, barcode);
      return;
    }

    this.ticketScan(connectionStatus, barcode);
  }

  async seasonPassScan(connectionStatus: ConnectionStatus, barcode: string) {
    if (!connectionStatus.connected) {
      const qrCode = barcode;
      const qrCodeValidation = this.tryGetIDsFromQrCode(qrCode);

      if (!qrCodeValidation) {
        await this.dialogService.showAlert({
          header: 'Invalid Season Pass Scanned',
          message: 'Invalid QR Code scanned. Please scan another season pass.',
          backdropDismiss: false,
          buttons: [
            {
              text: 'OK',
              handler: async () => {
                if (this.exited) {
                  return;
                }

                this.scanning = true;
                await this.startScan();
              },
            },
          ],
        });

        return;
      }

      const modal = await this.dialogService.showModal({
        component: SelectSeasonPassEventComponent,
        componentProps: {
          tickets: this.tickets,
          context: this.context,
          qrCodeValue: barcode,
          offlineValidation: true,
        },
        cssClass: 'auto-height',
        backdropDismiss: false,
        initialBreakpoint: 1,
      });

      const { data } = await modal.onWillDismiss();

      if (data?.ticket) {
        await this.authService.insertScannedQRCode({
          QrCodeValue: qrCode,
          ScannedAt: new Date(),
          TicketConfigurationID: data?.ticket.TicketConfiguration.ConfigurationEvents![0].TicketConfigurationID,
        });

        await this.dialogService.showAlert({
          header: 'Season Pass Successfully Scanned',
          message: 'This season pass has been scanned successfully',
          backdropDismiss: false,
          buttons: [
            {
              text: 'OK',
              handler: async () => {
                if (this.exited) {
                  return;
                }

                this.scanning = true;
                await this.startScan();
              },
            },
          ],
        });
      }
    }

    const seasonPassResponse = await this.seasonPassesApiService.getByQrCodeValue(barcode);

    if (seasonPassResponse.isAnyError || !seasonPassResponse.data) {
      await this.dialogService.showAlert({
        header: 'Invalid Season Pass Scanned',
        message: 'Invalid QR Code scanned. Please scan another season pass.',
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            handler: async () => {
              if (this.exited) {
                return;
              }

              this.scanning = true;
              await this.startScan();
            },
          },
        ],
      });

      return;
    }

    const modalResponse = await this.dialogService.showModal({
      component: SelectSeasonPassEventComponent,
      componentProps: {
        tickets: this.tickets,
        context: this.context,
        qrCodeValue: barcode,
      },
      cssClass: 'auto-height',
      backdropDismiss: false,
      initialBreakpoint: 1,
    });

    await modalResponse.onDidDismiss();

    if (this.exited) {
      return;
    }

    this.scanning = true;
    await this.startScan();
  }

  async ticketScan(connectionStatus: ConnectionStatus, barcode: string) {
    if (!connectionStatus.connected) {
      const qrCode = barcode;
      const qrCodeValidation = this.tryGetIDsFromQrCode(qrCode);

      this.dialogService.dismissAlert();

      if (qrCodeValidation) {
        await this.authService.insertScannedQRCode({
          QrCodeValue: qrCode,
          ScannedAt: new Date(),
          TicketConfigurationID: qrCodeValidation.ticketConfigurationReferenceID,
        });

        await this.dialogService.showAlert({
          header: 'Ticket Successfully Scanned',
          message: 'This ticket has been scanned successfully',
          backdropDismiss: false,
          buttons: [
            {
              text: 'OK',
              handler: async () => {
                if (this.exited) {
                  return;
                }

                this.scanning = true;
                await this.startScan();
              },
            },
          ],
        });

        return;
      }

      await this.dialogService.showAlert({
        header: 'Invalid Ticket Scanned',
        message: 'Invalid QR Code scanned. Please scan another ticket.',
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            handler: async () => {
              if (this.exited) {
                return;
              }

              this.scanning = true;
              await this.startScan();
            },
          },
        ],
      });

      return;
    }

    const scan: DtoTicketTakerScan[] = [{ QrCodeValue: barcode, ScannedAt: moment().toISOString() }];

    const response = await this.ticketTakersService.submitScan(parseInt(this.ticketConfigurationID!), scan);

    if (response.isAnyError) {
      return;
    }

    const data = response.data!;

    this.dialogService.dismissAlert();

    if (data.ScanResults![0].IsValid) {
      await this.dialogService.showAlert({
        header: 'Ticket Successfully Scanned',
        message: 'This ticket has been scanned successfully',
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            handler: async () => {
              if (this.exited) {
                return;
              }

              this.scanning = true;
              await this.startScan();
            },
          },
        ],
      });

      return;
    }

    await this.dialogService.showAlert({
      header: 'Invalid Ticket Scanned',
      backdropDismiss: false,
      message: data.ScanResults![0].MessageIfInvalid,
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            if (this.exited) {
              return;
            }

            this.scanning = true;
            await this.startScan();
          },
        },
      ],
    });
  }

  tryGetIDsFromQrCode(input: string) {
    let ticketID = -1;
    let ticketConfigurationReferenceID = '';

    if (input.length != environment.qrCodeExactLength) {
      return false;
    }

    input = input.toUpperCase();

    ticketConfigurationReferenceID = input.substring(9, 3);

    if (!this.isValidWithCheckCharacter(input.substring(1))) {
      return false;
    }

    let ticketIDPart = input.substring(1, this.ticketIDLength);

    let decodedTicketID = this.fromBase16(ticketIDPart);
    if (decodedTicketID < 0) {
      return false;
    }

    let decryptedTicketID = decodedTicketID;
    for (let i = 0; i < this.modIterations; i++) {
      if (decryptedTicketID == Number.MAX_VALUE) {
        return false;
      }

      decryptedTicketID = this.reverseShuffleInt(decryptedTicketID);
    }

    ticketID = decryptedTicketID;

    return { ticketID, ticketConfigurationReferenceID };
  }

  private isValidWithCheckCharacter(input: string) {
    if (input.length === 0) {
      throw new Error('isValidWithCheckCharacter(): Value cannot be null or empty.');
    }

    let factor = 1;
    let sum = 0;
    let n = 16;

    for (let i = input.length - 1; i >= 0; i--) {
      let codePoint = this._baseCharToInt(input.charAt(i));
      let addend = factor * codePoint;

      factor = factor == 2 ? 1 : 2;

      addend = Math.floor(addend / n) + (addend % n);
      sum += addend;
    }

    let remainder = sum % n;

    return remainder == 0;
  }

  private _baseCharToInt(baseChar: string) {
    if (baseChar.length !== 1) {
      throw new Error('_baseCharToInt(): Base character should be a single character.');
    }

    let codePoint = this.charactersInBase.indexOf(baseChar);

    if (codePoint < 0) {
      throw new Error('_baseCharToInt(): Value should be in set of base characters.');
    }

    return codePoint;
  }

  private reverseShuffleInt(input: number) {
    if (input == Number.MAX_VALUE) {
      throw new Error("reverseShuffleInt(): Value shouldn't be max int.");
    }

    let scaled = (input * this.modInverseValue) % Number.MAX_VALUE;

    return scaled;
  }

  private fromBase16(input: string) {
    if (!this.isValidBase16(input)) {
      throw new Error('fromBase16(): Base16 input is invalid.');
    }

    let reversed = input.trimStart().split('').reverse();
    let result = 0;

    for (let i = 0; i < reversed.length; i++) {
      result += this.base16CharToCodePoint(reversed[i]) * Math.pow(this.charactersInBase.length, i);
    }

    return result;
  }

  private isValidBase16(base16: string) {
    if (base16.length === 0) {
      throw new Error('isValidBase16(): Value cannot be null or empty.');
    }

    for (const element of base16) {
      if (this.charactersInBase.indexOf(element) < 0) {
        return false;
      }
    }

    return true;
  }

  private base16CharToCodePoint(base16: string) {
    let codePoint = this.charactersInBase.indexOf(base16);

    if (codePoint < 0) {
      throw new Error('base16CharToCodePoint(): Value should be in set of base characters.');
    }

    return codePoint;
  }

  private modInverse(a: number, m: number) {
    if (m == 1) return 0;

    let m0 = m;
    let x = 1,
      y = 0;

    while (a > 1) {
      let q = a / m;

      a = m;
      m = a % m;
      x = y;
      y = x - q * y;
    }

    return x < 0 ? x + m0 : x;
  }
}
