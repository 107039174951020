// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoFeatureFlagEnabled } from "../models/DtoFeatureFlagEnabled";

@Injectable({providedIn: 'root'})
export class FeatureFlagApiService {
    private readonly controller = "FeatureFlag";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param organizationID 
     */
    getEnabled(organizationID: Guid | undefined) {
        return this.v2Api.get<DtoFeatureFlagEnabled>(this.controller, "GetEnabled", { organizationID });
    }
}