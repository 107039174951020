<div id="accept-contract">
  <div class="text-center" style="margin-bottom:24px">
    <h2><u>Officiating Contract for Athletic Contests</u></h2>
    <div style="font-size: small; margin-top:24px; margin-bottom:8px">Approved by:</div>
    <div>
      <b>KENTUCKY HIGH SCHOOL ATHLETIC ASSOCIATION</b><br>
      2280 Executive Drive, Lexington, KY 40505<br>
      (T) <a href="tel:859-299-5472">859.299.5472</a>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      (F) <a href="fax:859-293-5999">859.293.5999</a><br>
      <a rel="noopener" href="https://khsaa.org/" target="_blank">https://khsaa.org</a>
    </div>
  </div>
  <div>
    <p>
      This is a Contract to officiate an Athletic Contest between Official and the Contest Sponsor.
    </p>
    <ol>
      <li>
        <b>Athletic Contest.</b> The Athletic Contest is a season contest or an KHSAA Tournament Series contest in the sport for which Official is licensed. The Athletic Contest's data, including its date, time, location, pay and mileage has previously been provided and is incorporated into this Contract.
      </li>
      <li>
        <b>Official.</b> Official, licensed by the Kentucky High School Athletic Association (KHSAA), agrees to officiate the Athletic Contest; Official's identifying data has been provided and is incorporated into this Contract.
      </li>
      <li>
        <b>Contest Sponsor.</b> Contest Sponsor, an KHSAA member school or the KHSAA, is the sponsor of the Athletic Contest and hires Official to officiate the Athletic Contest; Contest Sponsor's identifying data has been provided and is incorporated into this Contract.
      </li>
      <li>
        <b>KHSAA Tournament Series.</b> If the Athletic Contest is part of an KHSAA Tournament Series,  the terms and conditions agreed to by the Official and the KHSAA when Official applied for an KHSAA Tournament Series assignment are incorporated into this Contract.
      </li>
      <li>
        <b>Obligations.</b> The obligations of Contest Sponsor to Official end if Official is no longer a licensed official or if the Contest is cancelled or postponed. Official is in default of the Officiating Contract if Official fails to substantially fulfill Official's obligations and shall pay a penalty to the Contest Sponsor equal to the Pay, no later than ten (10) days after the date of the Contest.
      </li>
      <li>
        <b>Acknowledgement.</b> The parties acknowledge that  Official is an independent contractor and not an employee of the Contest Sponsor or any school involved in the Contest or any tournament, (ii) no medical insurance, workers compensation, unemployment insurance or other benefit are accorded Official by this Contract, (iii) Official is self-employed, has no employees and/or is not required to enroll in and verify the work eligibility status of newly hired employees through E-Verify, or to sign an affidavit affirming that Official does not knowingly employ unauthorized aliens or to otherwise comply with state and local law, (iv) Official assumes full and complete responsibility and liability for Official's safety and for injury to Official and waives any claim against the Contest Sponsor for injury, loss or damage which may be sustained by the Official when traveling to the Contest, before, during or after the Contest, and when traveling from the Contest, including any which is the result of any act or omission of the Contest Sponsor, including any from the negligence of the Contest Sponsor, (v) the current National Federation Rules govern play and it is the Official's responsibility to determine the final score and to declare a winner, and (vi) Official shall fully comply with any KHSAA rule and policy related to the Contest.
      </li>
    </ol>
    <p>
      By posting this Officiating Contract, Contest Sponsor is agreeing to its terms and conditions, and by accepting this officiating assignment, Official is agreeing to the terms and conditions of this Officiating Contract.
    </p>
  </div>
</div>
