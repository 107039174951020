import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { OrganizationMemberLegacyApiService } from '../../../../core/services/api/v1/OrganizationMemberLegacyApi.service';
import IContextOrganizationModel from '../../../../core/models/context/context-organization.models';
import { dtoINamed } from '../../../../core/services/api/models/legacy/dtoINamed';
import { HallPassLegacyApiService } from '../../../../core/services/api/v1/HallPassLegacyApi.service';
import { dtoHallPassShortForm } from '../../../../core/services/api/models/legacy/dtoHallPassShortForm';
import { FacilitiesApiService } from '../../../../core/services/api/v2/FacilitiesApi.service';
import { DtoFacilityWithManagers } from '../../../../core/services/api/models/DtoFacilityWithManagers';
import * as moment from 'moment-timezone';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { dtoHallPassCreate } from '../../../../core/services/api/models/legacy/dtoHallPassCreate';
import { ErrorService } from '../../../../core/services/error/error.service';

@Component({
  selector: 'app-issue-pass',
  templateUrl: './issue-pass.component.html',
  styleUrls: ['./issue-pass.component.scss'],
})
export class IssuePassComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  students: dtoINamed[] = [];
  public results = [...this.students];
  selectedFacility: DtoFacilityWithManagers | undefined;
  facilities: DtoFacilityWithManagers[] = [];
  currentStudent: dtoINamed | undefined;
  currentHistory: dtoHallPassShortForm[] = [];
  @Input() reason: string;
  protected organization: IContextOrganizationModel | undefined;
  selectingTime: boolean = false;
  selectedTime: string = moment().format('YYYY-MM-DDTHH:mm');
  formGroup: FormGroup;

  constructor(
    private readonly authService: AuthService,
    public readonly modalCtrl: ModalController,
    private readonly organizationMemberService: OrganizationMemberLegacyApiService,
    private readonly hallPassService: HallPassLegacyApiService,
    private readonly facilitiesService: FacilitiesApiService,
    private readonly errorService: ErrorService,
    private readonly fb: FormBuilder
  ) {
    this.reason = '';

    this.formGroup = this.fb.group({
      selectTimeForm: [moment().format('YYYY-MM-DDTHH:mm')],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    this.organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);
    this.selectedTime = moment().format('YYYY-MM-DDTHH:mm');
    if (this.organization) {
      let response = await this.organizationMemberService.getAllByOrganizationID(
        this.organization.OrganizationId!
      );
      if (response.isAnyError) {
        return;
      } else if (response.data) {
        this.students = response.data;
        this.results = [...this.students];
      }
    }
    let facilityResponse = await this.facilitiesService.search(
      this.organization.OrganizationId!,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    if (facilityResponse.isAnyError) {
      return;
    } else if (facilityResponse.data) {
      this.facilities = facilityResponse.data;
    }
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async memberChanged(ev: any) {
    if (ev && ev.target && ev.target.value) {
      this.currentStudent = ev.target.value;
      let { data: newStudentHistory } = await this.hallPassService.getAllByOrganizationID(
        this.organization?.OrganizationId!,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ev.target.value.ID,
        5
      );
      if (newStudentHistory) {
        for (let pass of newStudentHistory) {
          if (pass.TimeIn) {
            pass.TimeIn = moment(pass.TimeIn).format(environment.moment.format);
          }
          if (pass.TimeOut) {
            pass.TimeOut = moment(pass.TimeOut).format(environment.moment.format);
          }
        }
        this.currentHistory = newStudentHistory;
      } else {
        this.currentHistory = [];
      }
    } else {
      this.currentHistory = [];
    }
  }

  async destinationChanged(ev: any) {
    if (ev && ev.target && ev.target.value) {
      this.selectedFacility = ev.target.value;
    }
  }

  async issuePass() {
    if (!this.currentStudent || !this.selectedFacility) {
      this.errorService.showError('Verify you filled out all fields correctly.', 3000, 'Creation Failed');
      return;
    }
    let time;
    if (this.selectingTime) {
      time = moment.utc(this.formGroup.get('selectTimeForm')?.value);
    } else {
      time = moment.utc(moment());
    }

    let body: dtoHallPassCreate = {
      PassHolderUserID: this.currentStudent!.ID!,
      DestinationID: this.selectedFacility.ID,
      TimeOut: time.format(),
    };
    const response = await this.hallPassService.create(this.organization?.OrganizationId!, true, body);
    if (response.isAnyError) {
      return;
    }
    this.modalCtrl.dismiss(this);
  }

  async setStudent(student: dtoINamed) {
    this.currentStudent = student;
    this.memberChanged({ target: { value: student } });
    this.modal.dismiss();
  }

  handleInput(event: any) {
    this.results = [...this.students];
    const query = event.target.value.toLowerCase();
    this.results = this.results.filter((d) => d.Name!.toLowerCase().indexOf(query) > -1);
  }
}
