<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Cancel Event</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [ngSwitch]="pageState">
  <div *ngSwitchCase="1">
    <ion-grid class="ion-padding">
      <ion-row class="table-header">
        <ion-col class="table-column-front">Group</ion-col>
        <ion-col size="7" class="table-column">Notify?</ion-col>
      </ion-row>
      <ion-row class="table-body">
        <ion-col class="table-column-front">Social Media</ion-col>
        <ion-col size="7" class="table-column">
          <ion-radio-group [(ngModel)]="groupsToNotify.SocialAccounts">
            <ion-col class="ion-padding-end">
              <ion-radio value="true" labelPlacement="end">Yes</ion-radio>
            </ion-col>
            <ion-col class="ion-padding-start">
              <ion-radio value="false" labelPlacement="end">No</ion-radio>
            </ion-col>
          </ion-radio-group>
        </ion-col>
      </ion-row>
      <ion-row class="table-body">
        <ion-col class="table-column-front">Subscribers</ion-col>
        <ion-col size="7" class="table-column">
          <ion-radio-group [(ngModel)]="groupsToNotify.Subscribers">
            <ion-col class="ion-padding-end">
              <ion-radio value="true" labelPlacement="end">Yes</ion-radio>
            </ion-col>
            <ion-col class="ion-padding-start">
              <ion-radio value="false" labelPlacement="end">No</ion-radio>
            </ion-col>
          </ion-radio-group>
        </ion-col>
      </ion-row>
      <ion-row class="table-body" *ngIf="IsAthletic">
        <ion-col class="table-column-front">Opponents</ion-col>
        <ion-col size="7" class="table-column">
          <ion-radio-group [(ngModel)]="groupsToNotify.Opponents">
            <ion-col class="ion-padding-end">
              <ion-radio value="true" labelPlacement="end">Yes</ion-radio>
            </ion-col>
            <ion-col class="ion-padding-start">
              <ion-radio value="false" labelPlacement="end">No</ion-radio>
            </ion-col>
          </ion-radio-group>
        </ion-col>
      </ion-row>
      <ion-row class="table-body">
        <ion-col class="table-column-front">Workers</ion-col>
        <ion-col size="7" class="table-column">
          <ion-radio-group [(ngModel)]="groupsToNotify.Workers">
            <ion-col class="ion-padding-end">
              <ion-radio value="true" labelPlacement="end">Yes</ion-radio>
            </ion-col>
            <ion-col class="ion-padding-start">
              <ion-radio value="false" labelPlacement="end">No</ion-radio>
            </ion-col>
          </ion-radio-group>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="ion-padding-horizontal ion-padding-bottom">
      <span class="input-row">
        <ion-item>
          <ion-input labelPlacement="stacked" placeholder="Optional" autocapitalize="on" [(ngModel)]="cancelNote">
            <div slot="label">Cancel Note</div>
          </ion-input>
        </ion-item>
      </span>
    </div>
    <div class="ion-padding-horizontal" style="font-style: italic">
      This note will be included in notifications and shown to the public.
    </div>
  </div>
  <div *ngSwitchCase="2">
    <ion-grid *ngIf="ticketPoolsWithBoolean && ticketPoolsWithBoolean.length > 0" class="ion-padding-horizontal">
      <div class="ion-padding-bottom">
        Please select the tickets you would like to refund. Any purchase that contains at least one of the selected
        tickets will have all of its tickets refunded.
        <span style="font-weight: bold"
          >There is no way to cancel or undo refunds once they have been initiated.</span
        >
      </div>
      <ion-row class="table-header">
        <ion-col class="table-column">Ticket</ion-col>
        <ion-col size="7" class="table-column">Refund?</ion-col>
      </ion-row>
      <ion-row class="table-body" *ngFor="let pool of ticketPoolsWithBoolean">
        <ion-col class="table-column">{{ pool.dtoTicketPoolPublic.Title }}</ion-col>
        <ion-col size="7" class="table-column">
          <ion-radio-group [(ngModel)]="pool.refund">
            <ion-col class="ion-padding-end">
              <ion-radio (ionFocus)="refundUnChecked(pool)" value="true" labelPlacement="end">Yes</ion-radio>
            </ion-col>
            <ion-col class="ion-padding-start">
              <ion-radio (ionFocus)="refundChecked()" value="false" labelPlacement="end">No</ion-radio>
            </ion-col>
          </ion-radio-group>
        </ion-col>
      </ion-row>
      <div *ngIf="refundCheckedVar" style="padding-bottom: 25dvh">
        <ion-row class="ion-padding-vertical">
          You elected not to refund at least one pool of tickets. Would you like to send all non-refunded ticket
          holders a message?
        </ion-row>
        <ion-row>
          <ion-select
            [(ngModel)]="selectedRefundContactPoints"
            placeholder="No replies"
            labelPlacement="stacked"
            label="From">
            <ion-select-option *ngFor="let contactPoint of contactPoints" [value]="contactPoint">{{
              contactPoint.Value
            }}</ion-select-option>
          </ion-select>
        </ion-row>
        <ion-row class="ion-padding-vertical">
          <ion-input
            labelPlacement="stacked"
            placeholder="Enter message here"
            autocapitalize="on"
            [(ngModel)]="refundPoolMessage">
            <div slot="label">Message</div>
          </ion-input>
        </ion-row>
      </div>
    </ion-grid>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="submitOrNext()"
          expand="block">
          <ion-text *ngIf="!(ticketPoolsWithBoolean && ticketPoolsWithBoolean.length > 0) || this.pageState === 2">
            Submit
          </ion-text>
          <ion-text *ngIf="ticketPoolsWithBoolean && ticketPoolsWithBoolean.length > 0 && this.pageState === 1">
            Next
          </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
