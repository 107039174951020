<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Close </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Invitation</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container margin-top ion-padding-horizontal">
    <div class="card">
      <div class="card-header">
        <ion-text class="header-text">
          About the invitation
        </ion-text>
      </div>

      <div class="card-content">
        <ion-text class="details">
          Invited by:
          <ion-text style="font-weight: 400" *ngIf="inviter.Name">{{ inviter.Name }}</ion-text>
          <ion-text style="font-weight: 400" *ngIf="!inviter.Name"><i>Unknown</i></ion-text>
        </ion-text>
        <ion-text class="details" *ngFor="let contact of inviterContactPoints">
          <span *ngIf="contact.ContactType === 'Email'">
            Email:
            <ion-text style="font-weight: 400" class="link-text">
              <a href="mailto:{{contact.Value}}">{{ contact.Value }}</a>
            </ion-text>
          </span>
          <span *ngIf="contact.ContactType === 'Phone'">
            Phone:
            <ion-text style="font-weight: 400" class="link-text">
              <a href="tel:{{contact.Value}}">{{ contact.Value }}</a>
            </ion-text>
          </span>
        </ion-text>

        <ion-text class="details" *ngIf="statusChange">
          {{ statusChange.Status }} on:
          <ion-text style="font-weight: 400">{{ getDateTime(statusChange.ChangedAt) }}</ion-text>
        </ion-text>
      </div>
    </div>
  </div>
</ion-content>

