<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modalCtrl.dismiss()"> Close </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card *ngIf="foundEvent">
    <ion-card-header>
      <ion-card-title *ngIf="foundEvent.Team">
        {{ foundEvent.Team.Title }} <span *ngIf="isHomeEvent">(H)</span> <span *ngIf="!isHomeEvent && this.foundEvent.ContractID">(A)</span>
      </ion-card-title>
      <ion-card-subtitle *ngIf="foundEvent.Title">
        {{ foundEvent.Title }}
      </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ul>
        <li *ngIf="foundEvent.StartDateTime">When: {{ foundEvent.StartDateTime}}</li>
        <li *ngFor="let rule of foundEvent.RepeatRules">Repeat: {{rule.RepeatType}} <span *ngIf="rule.Values">{{this.returnRepeatValuesStatement(rule)}}</span></li>
        <li *ngIf="foundEvent.Calendar && foundEvent.Calendar.Title">Calendar: {{foundEvent.Calendar.Title}}</li>
        <li *ngIf="foundEvent.Location">Location: {{foundEvent.Location}}</li>
        <li *ngIf="foundEvent.Facilities && foundEvent.Facilities.length === 1">Facility: {{ event.Facilities[0].Title }}</li>
        <li *ngIf="foundEvent.Organization && foundEvent.Organization.Address1">Address: {{ foundEvent.Organization.Address1 }}</li>
        <li *ngIf="foundEvent.Opponents && foundEvent.Opponents.length === 1">Opponent: {{ foundEvent.Opponents[0].Title}}</li>
        <li *ngIf="foundEvent.EventType">Type: {{ foundEvent.EventType}}</li>
      </ul>
      <ul *ngIf="foundEvent.OfficialSlots && foundEvent.OfficialSlots > 0">
        <h4>Official Invitation Information</h4>
        <li>Position: {{ foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Title }}</li>
        <li>Fee: {{ foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Fee | currency }}</li>
        <li>
          Invited By:
          {{
            foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Official
              ? foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Official.User.Name
              : ''
          }}
        </li>
      </ul>
    </ion-card-content>
  </ion-card>
</ion-content>
