// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { PotentialOrganization } from "../models/legacy/PotentialOrganization";
import { int, Guid } from "../models/ApiTypes";

@Injectable({providedIn: 'root'})
export class PotentialOrganizationLegacyApiService {
    private readonly module = "PotentialOrganization";

    constructor(private legacyApi: LegacyApiService) { }

    /** Use V2 endpoint at PotentialOrganizationsPublic/Search Retrieves all Potential Organization by either Zip Code or School Name
     * @param param Zip Code or School Name
     * @param state 
     * @param activeOnly 
     */
    getAllByZipOrName(param: string, state: string | undefined, activeOnly: boolean | undefined) {
        return this.legacyApi.get<PotentialOrganization[] | undefined>(this.module, "GetAllByZipOrName", { param, state, activeOnly });
    }

    /** Use V2 endpoint at PotentialOrganizationsCsr/GetByID Find an existing Potential Organization by ID
     * @param id 
     */
    find(id: int) {
        return this.legacyApi.get<PotentialOrganization | undefined>(this.module, "Find", { id });
    }

    /** Use V2 endpoint at PotentialOrganizationsCsr/Create Create a new Potential Organization
     * @param body 
     */
    create(body: PotentialOrganization) {
        return this.legacyApi.post<PotentialOrganization | undefined>(this.module, "Create", {  }, body);
    }

    /** Use V2 endpoint at PotentialOrganizationsCsr/Update Update an existing Potential Organization
     * @param body 
     */
    update(body: PotentialOrganization) {
        return this.legacyApi.post<PotentialOrganization | undefined>(this.module, "Update", {  }, body);
    }

    /** Makes an Organization inactive
     * @param id Organization ID
     */
    makeInactive(id: Guid) {
        return this.legacyApi.post<PotentialOrganization | undefined>(this.module, "MakeInactive", { id });
    }

    /** Makes an Organization active
     * @param id Organization ID
     */
    makeActive(id: Guid) {
        return this.legacyApi.post<PotentialOrganization | undefined>(this.module, "MakeActive", { id });
    }

    /** Imports contacts for a potential organization from a CSV File 
     */
    importContacts() {
        return this.legacyApi.post<void>(this.module, "ImportContacts", {  });
    }
}