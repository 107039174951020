import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { OfficialLegacyApiService } from 'src/app/core/services/api/v1/OfficialLegacyApi.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  @Input() contact?: any;
  @Input() sport?: any;
  @Input() modalType?: string;
  @Input() sportsList?: any[] = [];
  @Input() mode: string = '';

  constructor(
    public modalCtrl: ModalController,
    private readonly alertController: AlertController,
    private readonly officialService: OfficialLegacyApiService
  ) {}

  async save() {
    if (
      this.contact &&
      (this.contact.ContactType === '' || this.contact.Title === '' || this.contact.Value === '')
    ) {
      return await this.invalidInputAlert();
    }

    if (this.contact && this.contact.ContactType === 'Phone') {
      if (!isValidPhoneNumber(`+1 ${this.contact.Value}`)) {
        const alert = await this.alertController.create({
          header: 'Invalid Phone Number',
          message: 'Please enter a valid phone number.',
          buttons: ['OK'],
        });

        await alert.present();

        return;
      }
    }

    if (this.contact && this.contact.ContactType === 'Email') {
      const regexPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (!regexPattern.test(this.contact.Value)) {
        const alert = await this.alertController.create({
          header: 'Invalid Email',
          message: 'Please enter a valid email address.',
          buttons: ['OK'],
        });

        await alert.present();

        return;
      }
    }

    if (
      this.sport &&
      (this.sport.YearsOfExperience === null ||
        this.sport.Sport === '' ||
        this.sport.YearsOfExperience < 0 ||
        this.sport.SportID === '' ||
        this.sport.Level === '' ||
        this.sport.Experience === '')
    ) {
      return await this.invalidInputAlert();
    }

    this.modalCtrl.dismiss({ data: this.modalType === 'contact' ? this.contact : this.sport, operation: 'save' });
  }

  async invalidInputAlert() {
    const alert = await this.alertController.create({
      header: 'Invalid Input',
      message: 'Please fill out all fields marked with a red asterisk before submitting.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async deleteSport() {
    const alert = await this.alertController.create({
      header: 'Delete Sport',
      message: 'Are you sure you want to delete this sport?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          handler: async () => {
            await this.officialService.deleteSport(this.sport.ID);
            this.modalCtrl.dismiss({ operation: 'delete' });
          },
        },
      ],
    });

    await alert.present();
  }
}
