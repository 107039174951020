<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()"> Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ Edit ? 'Edit' : 'Add' }} Score</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="score-form" class="ion-padding-horizontal">
    <!-- Description -->
    <span class="input-row">
      <ion-item>
        <ion-textarea
          id="body-text"
          labelPlacement="stacked"
          autocapitalize="on"
          placeholder="Optional"
          type="text"
          [autoGrow]="true"
          [(ngModel)]="score!.Description">
          <div slot="label">Description</div>
        </ion-textarea>
      </ion-item>
    </span>

    <!-- Team/Level -->
    <span class="input-row">
      <ion-item>
        <ion-select
          label-placement="stacked"
          [(ngModel)]="score!.LevelID"
          [multiple]="false"
          placeholder="None selected">
          <div slot="label">Team/Level <ion-text color="danger">*</ion-text></div>
          <ion-select-option *ngFor="let level of Levels" [value]="level.ID">
            {{ level.Title }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </span>

    <!-- Score -->
    <span class="input-row">
      <ion-item>
        <ion-input labelPlacement="stacked" placeholder="Enter score" type="text" [(ngModel)]="score!.ScoreSelf">
          <div slot="label">Score</div>
        </ion-input>
      </ion-item>
    </span>

    <!-- Opponent -->
    <span class="input-row">
      <ion-item>
        <ion-select
          label-placement="stacked"
          [(ngModel)]="score!.OpponentID"
          [multiple]="false"
          placeholder="None selected">
          <div slot="label">Opponent <ion-text color="danger">*</ion-text></div>
          <ion-select-option *ngFor="let opp of Opponents" [value]="opp?.ID!">
            {{ opp?.Title! }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </span>

    <!-- Opponent Score -->
    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter score"
          type="text"
          [(ngModel)]="score!.ScoreOpponent">
          <div slot="label">Opponent Score</div>
        </ion-input>
      </ion-item>
    </span>

    <!-- Result -->
    <!-- Team/Level -->
    <span class="input-row">
      <ion-item>
        <ion-select
          label-placement="stacked"
          [(ngModel)]="score!.Result"
          [multiple]="false"
          placeholder="None selected">
          <div slot="label">Result</div>
          <ion-select-option *ngFor="let res of results" [value]="res.key">
            {{ res.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="confirmScore()"
          expand="block">
          <fa-icon icon="check" size="lg"></fa-icon> <ion-text> Confirm </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
