// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoDistrictLongForm } from "../models/legacy/dtoDistrictLongForm";
import { dtoDistrictShortForm } from "../models/legacy/dtoDistrictShortForm";
import { dtoINamed } from "../models/legacy/dtoINamed";
import { dtoDistrictCreateUpdate } from "../models/legacy/dtoDistrictCreateUpdate";
import { dtoDistrictAddRemoveManagers } from "../models/legacy/dtoDistrictAddRemoveManagers";
import { dtoUserShortForm } from "../models/legacy/dtoUserShortForm";

@Injectable({providedIn: 'root'})
export class DistrictLegacyApiService {
    private readonly module = "District";

    constructor(private legacyApi: LegacyApiService) { }

    /** Find a District by District ID.
     * @param id ID for District ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoDistrictLongForm | undefined>(this.module, "Find", { id });
    }

    /** Gets all Districts in the Database.
     */
    getAll() {
        return this.legacyApi.get<dtoDistrictShortForm[] | undefined>(this.module, "GetAll", {  });
    }

    /** Gets all managers for the districts in the database.
     * @param id ID for District ID
     */
    getManagers(id: Guid) {
        return this.legacyApi.get<dtoINamed[] | undefined>(this.module, "GetManagers", { id });
    }

    /** Searches for a District by Title.
     * @param title 
     */
    search(title: string) {
        return this.legacyApi.get<dtoDistrictShortForm[] | undefined>(this.module, "Search", { title });
    }

    /** Creates a new District.
     * @param body 
     */
    create(body: dtoDistrictCreateUpdate) {
        return this.legacyApi.post<dtoDistrictShortForm | undefined>(this.module, "Create", {  }, body);
    }

    /** Updates a District's Information, current user must be a District Manager.
     * @param body 
     */
    update(body: dtoDistrictCreateUpdate) {
        return this.legacyApi.post<dtoDistrictShortForm | undefined>(this.module, "Update", {  }, body);
    }

    /** Deletes a District by its ID.
     * @param id ID for District ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** Either allows the district manager to add or remove managers from a District.
     * @param body 
     */
    addRemoveManagers(body: dtoDistrictAddRemoveManagers) {
        return this.legacyApi.post<dtoUserShortForm[] | undefined>(this.module, "AddRemoveManagers", {  }, body);
    }
}