
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Register Account</ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="saveAccount()"
        expand="block"
        [disabled]="!formGroup.valid">
        <ion-text> Accept </ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="formGroup">
  <ion-text class="ion-padding-start text-start top-line"> First name:<span class="required">*</span></ion-text>
  <ion-row class=" background-transparent ">
    <ion-input formControlName="firstName"></ion-input>
  </ion-row>
  <ion-text class="ion-padding-start text-start top-line"> Last name:<span class="required">*</span></ion-text>
  <ion-row class=" background-transparent ">
    <ion-input formControlName="lastName"></ion-input>
  </ion-row>
  <ion-text class="ion-padding-start text-start top-line"> Email:<span class="required">*</span></ion-text>
  <ion-row class=" background-transparent ">
    <ion-input formControlName="email"></ion-input>
  </ion-row>
  <ion-text class="ion-padding-start text-start top-line"> Address 1:<span class="required">*</span></ion-text>
  <ion-row class=" background-transparent ">
    <ion-input formControlName="address1"></ion-input>
  </ion-row>
  <ion-text class="ion-padding-start text-start top-line"> Address 2:</ion-text>
  <ion-row class=" background-transparent ">
    <ion-input formControlName="address2"></ion-input>
  </ion-row>
  <ion-text class="ion-padding-start text-start top-line"> City:<span class="required">*</span></ion-text>
  <ion-row class=" background-transparent ">
    <ion-input formControlName="city"></ion-input>
  </ion-row>
  <ion-text class="ion-padding-start text-start top-line"> State:<span class="required">*</span></ion-text>
    <ion-select label-placement="stacked" formControlName="state" class="states">
      <ion-select-option *ngFor="let state of states" [value]="state.key">
        {{ state.label }}
      </ion-select-option>
    </ion-select>
  <ion-text class="ion-padding-start text-start top-line"> Zip Code:<span class="required">*</span></ion-text>
  <ion-row class=" background-transparent ">
    <ion-input formControlName="zipCode"></ion-input>
  </ion-row>
  </form>
</ion-content>
