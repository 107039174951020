<div class="card-header">
  <fa-icon icon="thumbs-up" size="lg" class="score-result-icon" *ngIf="Score.Result === 'Win'"></fa-icon>
  <fa-icon icon="thumbs-down" size="lg" class="score-result-icon" *ngIf="Score.Result === 'Loss'"></fa-icon>
  <fa-icon icon="handshake" size="lg" class="score-result-icon" *ngIf="Score.Result === 'Tie'"></fa-icon>

  <ion-text class="header-text uppercase"> {{ Score.Result }} </ion-text>
  <fa-icon icon="ellipsis-vertical" size="lg" class="option-icon" (click)="presentPopover($event)"></fa-icon>

  <ion-popover #Popover [isOpen]="isPopoverOpen" (didDismiss)="isPopoverOpen = false">
    <ng-template>
      <ion-content class="ion-padding">
        <ion-button expand="block" class="edit-btn" (click)="editScore(Score.ID!)">Edit</ion-button>
        <ion-button expand="block" class="delete-btn" (click)="deleteScore(Score.ID!)">Delete</ion-button>
      </ion-content>
    </ng-template>
  </ion-popover>
</div>

<div class="score-card-content">
  <ion-text class="score-details">
    <ion-text
      >{{ level?.Title }} -
      <span [ngClass]="{ 'red-text': Score.Result === 'Loss', 'green-text': Score.Result === 'Win' }">{{
        Score.ScoreSelf
      }}</span></ion-text
    >
  </ion-text>
  <ion-text class="score-details">
    <ion-text style="font-weight: 400"
      >{{ opponent?.Title }} -
      <span [ngClass]="{ 'red-text': Score.Result === 'Loss', 'green-text': Score.Result === 'Win' }">{{
        Score.ScoreOpponent
      }}</span></ion-text
    >
  </ion-text>

  <ion-text class="score-details margin-top" *ngIf="Score.Description">
    Description:
    <ion-text  style="font-weight: 400">{{
      Score.Description
    }}</ion-text>
  </ion-text>
</div>
