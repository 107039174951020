import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() progress: string = '0';

  @Input() text: string = '';

  get textPosition(): string {
    return `calc(${Number(this.progress) * 100}% / 2)`;
  }
}
