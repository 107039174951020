<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Accept Privacy Policy</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="contract-container" class="ion-padding-horizontal" style="padding-bottom: 8em">
    <h2>Privacy Policy</h2>
    <p>Protecting your privacy is important to SDI Innovations d/b/a Eventlink. We
      strive to keep your personal information confidential, to the extent that
      such information is not provided on the public areas of our website and
      pursuant to our Terms and Conditions of Service ("User Agreement") found on
      our website. Please take a moment to read the following policy to learn how
      we handle your personal information.</p>

    <p><strong>Why we collect personal information</strong></p>
    <p>Eventlink collects and uses your personal information to give you superior
      customer service, to provide you with convenient access to our products,
      and to make a wider range available to you. In addition, we use your
      personal information to keep you up to date on the latest product
      announcements, special offers, and other information we think you'd like to
      hear about.</p>

    <p><strong>How we collect personal information</strong></p>
    <p>
      We collect information about you in several ways. For example, we might ask
      for your contact information when you correspond with us or make a
      purchase. In addition, when you register to purchase products, or ask to be
      included in an email mailing list, we collect and store the information you
      provide.
    </p>

    <p><strong>When we disclose personal information</strong></p>
    <p>
      We do not sell our mailing list. At times we may be required by law or
      legal process to disclose your personal information.
    </p>
    <p>
      Except as otherwise described in SDI's User Agreement, we restrict access
      to personal information to SDI Innovations and its affiliates, employees,
      contractors and agents or parties wishing to request your officiating
      services, who may need to know that information in order to operate,
      develop or improve our services. These individuals are bound by
      confidentiality obligations and may be subject to discipline, including
      termination and criminal prosecution, if they fail to meet these
      obligations.
    </p>

    <p *ngIf="applicationType !== 'Staff'">
      If you are an Account Owner as described in SDI's User Agreement, you are
      also bound by Dwolla Inc.'s <a href="https://www.dwolla.com/legal/tos" target="_blank">Terms of Service</a> and Dwolla&rsquo;s <a href="https://www.dwolla.com/legal/privacy" target="_blank">Privacy Policy</a>
    </p>
    <p>
      <strong>How we protect your personal information</strong>
    </p>
    <p>
      SDI Innovations safeguards the security of the data you send us with
      physical, electronic, and managerial procedures. We urge you to take every
      precaution to protect your personal data when you are on the Internet.
      Change your passwords often, use a combination of letters and numbers, and
      make sure you use a secure browser. You are responsible for the security
      and upkeep of your password to third parties outside of our Services.
    </p>
    <p>
      SDI Innovations uses industry-standard Transport Layer Security (TLS)
      encryption on all web pages where personal information is required. This
      protects the confidentiality of your personal and bank account information
      while it is transmitted over the Internet.
    </p>
    <p>
      <strong>Access to your personal information</strong>
    </p>
    <p>
      You always have access to the information we have about you. To review and
      update your personal contact information, simply logon to the eventlink.com
      website and click your name in the upper right corner of your screen.
    </p>
    <p>
      <strong>Marketing</strong>
    </p>
    <p>
      SDI Innovations may use your name as a User or Account Owner in its
      marketing efforts to provide information to other parties on the people
      that use the eventlink.com website. This may also include any public
      reviews that you have posted regarding your use of the eventlink.com
      website. By using the eventlink.com website, you agree to this limited use
      of your name and/or comments/reviews.
    </p>
    <p>
      <strong>Information We Collect</strong>
    </p>
    <p>
      When you browse the public areas of the evenlink.com website, you are able
      to do so anonymously. Generally, we don't collect personal information when
      you browse -- not even your email address. Your browser, however, does
      automatically tell us the type of computer and operating system you are
      using.
    </p>
    <p>
      If you have logged into the eventlink.com website with your user name and
      password, we do keep logs of what links you have clicked on and information
      you have entered while visiting our site. This information can include
      contact information such as your name, address, e-mail address, phone
      number, and similar information. In addition, financial information such as
      a bank account number is collected if you choose to use SDI's Payment
      Services and may be shared with a third party<span *ngIf="applicationType !== 'Staff'">, like Dwolla, Inc</span>.
    </p>
    <p>
      Like many websites, the eventlink.com website uses "cookie" technology.
      When you first connect to our site, the cookie identifies your browser with
      a unique, random number. The cookies we use do not reveal any personal
      information about you, except perhaps your first name so we can welcome you
      on your next visit. Cookies help us understand which parts of our websites
      are the most popular, where our visitors are going, and how long they spend
      there. We use cookies to study traffic patterns on our site so we can make
      the site even better.
    </p>
    <p>
      In some of our email to you, we use a "click-through URL." When you click
      one of these URLs, you pass through our web server before arriving at the
      website that is your destination. We track click-throughs to help us
      determine your interest in particular topics and measure the effectiveness
      of our customer communications.
    </p>
    <p>
      SDI Innovations does not knowingly solicit personal information from
      children or send them requests for personal information. Please check your
      local laws regarding the minimum age for use of websites which collect
      personal information.
    </p>
    <p>
      The eventlink.com website contains links to other sites. SDI Innovations
      does not share your personal information with those websites and is not
      responsible for their privacy practices. We encourage you to learn about
      the privacy policies of those companies.
    </p>
    <p>
      SDI Innovations does collect confidential personally identifiable
      information of Account Owners, Authorized Users and Users, known as "CPII".
      CPII is collected on portions of the website(s) in which https:// is part
      of the website address and/or when a password is required to enter;
      otherwise, it is assumed that portion of the website(s) is an unsecure or
      publicly-available portion of the website(s). CPII includes any information
      which if lost, compromised, or disclosed without authorization, could
      result in substantial harm, embarrassment, inconvenience, or unfairness to
      an individual. This information includes, but is not limited to, Federal
      Identification number, Social Security number, Social Insurance number,
      driver's license number, passport number, national identification number,
      national identity number or national insurance number. All information that
      we collect may be shared with a third party vendors<span *ngIf="applicationType !== 'Staff'"> like Dwolla</span>.
    </p>
    <p>
      If we are going to use your personal information differently from that
      stated at the time of collection, we will try to contact you via email
      using the most recent information we have. If you have not given us
      permission to communicate with you, you will not be contacted, nor will we
      use your personal information in a new manner, except as allowed by our
      updated Terms and Conditions of Service.
    </p>
    <p>
      The SDI Innovations Customer Privacy Policy is subject to change at any
      time. We encourage you to review the privacy policy regularly for any
      changes.
    </p>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="acceptTerms()"
          expand="block">
          <ion-text> I Accept </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
