import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { AboundApiService } from 'src/app/core/services/api/v2/AboundApi.service';

@Component({
  selector: 'app-accept-1099-terms',
  templateUrl: './1099-accept-terms.component.html',
  styleUrls: ['./1099-accept-terms.component.scss'],
})
export class Accept1099TermsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly aboundService: AboundApiService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async acceptTerms() {
    const response = await this.aboundService.setElectronicConsentForUser(this.context.AccessToken as string)
    if (response.isAnyError) {
      return;
    }
    this.modalCtrl.dismiss(true);
  }


}
