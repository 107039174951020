<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()" onkeydown="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Checkout Cash (Optional)</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="container">
    <ion-row class="cash-given-row">
      <ion-input
        type="number"
        fill="outline"
        labelPlacement="stacked"
        label="Enter how much cash you were given:"
        [(ngModel)]="cashGiven"
        placeholder="123.45"
        (keyup.enter)="submitCash()">
      </ion-input>
    </ion-row>
    <ion-row class="total-row">
      <ion-label class="title-bold">Total:</ion-label>
      <ion-label> {{ total | currency }} </ion-label>
    </ion-row>
    <ion-row class="submit-cash-row">
      <div class="submit-cash-container">
        <ion-button
          (click)="submitCash()"
          [strong]="true"
          onkeydown="submitCash()"
          expand="block"
          class="submit-cash-button">
          <fa-icon icon="money-bill-1" class="cash-icon" size="1x"></fa-icon>
          <ion-text class="submit-text"> Submit {{ cashGiven | currency }} </ion-text></ion-button
        >
      </div>
    </ion-row>
  </div>
</ion-content>
