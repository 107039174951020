import * as JSZip from 'jszip';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MobileLogsSubmissionsApiService } from '../api/v2/MobileLogsSubmissionsApi.service';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class UploadLogsService {
  constructor(
    private readonly mobileLogsSubmissionsApi: MobileLogsSubmissionsApiService,
    private readonly dialogService: DialogService,
    private readonly authService: AuthService
  ) {}

  async uploadLogs() {
    const logs = await this.authService.getLogEntries();

    if (logs.length == 0) {
      await this.dialogService.showAlert({
        header: 'No Logs to Upload',
        message: 'There are no logs to upload.',
        buttons: ['OK'],
      });

      return;
    }

    let zip = new JSZip();

    const csvFile = logs
      .map((e) => {
        return `${e.DateTime},${e.UserID},${e.Message?.replace(',', '')},${e.File},${e.Method}`;
      })
      .join('\n');

    zip.file('eventlink/log.csv', csvFile);

    const response = await this.mobileLogsSubmissionsApi.submit(await zip.generateAsync({ type: 'blob' }));

    if (response.isSuccess) {
      const referenceNumberString = response.data?.ID
        ? `Your upload reference number is ${response.data.ID}.`
        : '';

      await this.dialogService.showAlert({
        header: 'Log Submission Uploaded',
        message: `
        Your logs have been successfully uploaded. Thank you!
        
        ${referenceNumberString}
        `,
        buttons: ['OK'],
      });

      await this.authService.deleteLogs();
    }
  }
}
