<ion-header>
  <ion-toolbar class="scanner-toolbar">
    <ion-title>Scanning</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content id="content-main">
  <div #square class="square"></div>

  <div class="zoom-ratio-wrapper">
    <ion-range
      [min]="minZoomRatio"
      [max]="maxZoomRatio"
      [disabled]="minZoomRatio === undefined || maxZoomRatio === undefined"
      (ionInput)="setZoomRatio($any($event))">
      <fa-icon icon="circle-plus" slot="end" size="2x" class="zoom-icons"></fa-icon>
      <fa-icon icon="circle-minus" slot="start" size="2x" class="zoom-icons"></fa-icon>
    </ion-range>
  </div>

  @if (isTorchAvailable) {
  <ion-fab slot="fixed" horizontal="end" vertical="top">
    <ion-fab-button (click)="toggleTorch()" class="torch">
      <ion-icon name="flashlight"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  }

  <div class="content-sticky">
    <ion-input
      labelPlacement="stacked"
      label="Enter a code to submit"
      [(ngModel)]="qrCode"
      placeholder="1A2B3C4D"
      class="qr-code-input"></ion-input>

    <ion-button expand="block" (click)="submitCode()" [disabled]="!qrCode" class="qr-code-button">
      Submit
    </ion-button>
  </div>
</ion-content>
