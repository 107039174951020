import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { UserLegacyApiService } from "../../../../core/services/api/v1/UserLegacyApi.service";

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss'],
})
export class AcceptTermsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly userLegacyService : UserLegacyApiService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  acceptTerms() {
    this.userLegacyService.acceptTermsOfService();
    this.modalCtrl.dismiss(true);
  }
}
