import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import IContextModel from 'src/app/core/models/context/context.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { PaymentLegacyApiService } from 'src/app/core/services/api/v1/PaymentLegacyApi.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import IContextOrganizationModel from 'src/app/core/models/context/context-organization.models';
import { dtoEventBulkPayUser } from 'src/app/core/services/api/models/legacy/dtoEventBulkPayUser';
import { CustomerDetail } from 'src/app/core/services/api/models/legacy/CustomerDetail';
import { ViewDidEnter } from '@ionic/angular';
import { EventAthleticsLegacyApiService } from 'src/app/core/services/api/v1/EventAthleticsLegacyApi.service';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-pay-modal',
  templateUrl: './pay-modal.component.html',
  styleUrls: ['./pay-modal.component.scss'],
})
export class PayUserModalComponent implements ViewDidEnter, OnInit, AfterViewInit {
  @Input() payment!: dtoEventBulkPayUser;
  @Input() isOfficial!: boolean;
  context!: IContextModel;
  payOutsideEventlink: boolean = false;
  amount: number = 0;
  notes: string = '';
  contextOrganization!: IContextOrganizationModel;
  dwollaCustomer!: CustomerDetail;
  fundingSource!: any;
  disableButton = true;
  isPayable: boolean = true;

  constructor(
    private readonly dialogService: DialogService,
    private readonly authService: AuthService,
    private readonly paymentLegacyApiService: PaymentLegacyApiService,
    private readonly eventAthleticsLegacyApiService: EventAthleticsLegacyApiService
  ) {}

  ngOnInit() {
    this.isPayable = this.payment.UserHasVerifiedFundingSource! && this.payment.UserHasVerifiedTaxProfile!;
    this.payOutsideEventlink = !this.isPayable;
  }

  async ngAfterViewInit() {
    const form = document.getElementById('block-form');
    const stickyFooter = document.getElementById('sticky-footer');
    let clickPositionY: number;

    document.addEventListener('click', (e) => {
      clickPositionY = e.clientY;
    });

    Keyboard.addListener('keyboardDidShow', () => {
      // if we click close to the footer, we want to translate the form up as the bottom of the form might be covered by the keyboard
      if (clickPositionY > form!.clientHeight) {
        form!.style.setProperty('transform', `translateY(-${stickyFooter!.clientHeight}px)`);
      }
    });

    Keyboard.addListener('keyboardDidHide', () => {
      form!.style.removeProperty('transform');
    });
  }

  async ionViewDidEnter() {
    this.context = await this.authService.getContext();
    this.contextOrganization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

    const customerResponse = await this.paymentLegacyApiService.getCustomerForOrganization(
      this.contextOrganization.OrganizationId!
    );

    if (customerResponse.isAnyError) {
      return;
    }

    this.dwollaCustomer = customerResponse.data!;

    const customerFundingSourceResponse = await this.paymentLegacyApiService.getFundingSourcesForCustomer(
      customerResponse.data?.ID!,
      false
    );

    if (customerFundingSourceResponse.isAnyError) {
      return;
    }

    this.fundingSource = customerFundingSourceResponse.data!.find((x) => x.Type === 'Balance');

    this.amount = this.payment.PaymentAmount!;

    this.disableButton = this.payment.PaymentAmount === 0 ? !this.payOutsideEventlink : false;
  }

  async pay() {
    const body = {
      Amount: this.amount,
      OrganizationID: this.contextOrganization.OrganizationId,
      OrganizationFundingSourceID: this.fundingSource.ID,
      Metadata: {
        destination: `${this.payment.User?.LastName}, ${this.payment.User?.FirstName}`,
        event: this.payment.Event?.ID,
        source: 'Balance Account',
        note: this.notes,
        payorSchool: this.contextOrganization.OrganizationTitle,
        payorUser: `${this.context.FirstName} ${this.context.LastName}`,
        user: this.payment.User?.ID,
      },
      EventOfficialSlotID: this.payment.SlotID,
      EventJobSlotID: this.payment.SlotID,
    };

    let payResponse = null;

    if (!this.isPayable && !this.payOutsideEventlink) {
      this.dialogService.showAlert({
        message: 'This official has not registered their banking information and cannot be paid!',
        buttons: ['OK'],
      });
      return;
    }

    if (this.isOfficial) {
      payResponse = this.payOutsideEventlink
        ? await this.eventAthleticsLegacyApiService.payOfficialOutsideEventlink(body)
        : await this.paymentLegacyApiService.payOfficial(undefined, body);
    } else {
      payResponse = this.payOutsideEventlink
        ? await this.eventAthleticsLegacyApiService.payWorkerOutsideEventlink({
            ...body,
            Hours: this.payment.Hours,
          })
        : await this.paymentLegacyApiService.payWorker(undefined, {
            ...body,
            Hours: this.payment.Hours,
          });
    }

    if (payResponse.isAnyError) {
      return;
    }

    this.dialogService.dismissModal('success');
  }

  cancel() {
    this.dialogService.dismissModal();
  }

  togglePayOutsideEventlink() {
    this.disableButton = this.payment.PaymentAmount === 0 ? !this.payOutsideEventlink : false;
  }
}
