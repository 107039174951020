import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentLegacyApiService } from 'src/app/core/services/api/v1/PaymentLegacyApi.service';
import { FundingSourceMicroDepositVerification } from 'src/app/core/services/api/models/legacy/FundingSourceMicroDepositVerification';

@Component({
  selector: 'app-verify-micro-deposits',
  templateUrl: './verify-micro-deposits.component.html',
  styleUrls: ['./verify-micro-deposits.component.scss'],
})
export class VerifyMicroDepositsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() dwollaID: string = '';
  @Input() amount1?: number;
  @Input() amount2?: number;

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly paymentService: PaymentLegacyApiService,
    private readonly fb: FormBuilder
  ) {
    this.formGroup = this.fb.group({
      amount1: [undefined],
      amount2: [undefined],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async submit() {
    let body: FundingSourceMicroDepositVerification = {
      Amount1: this.formGroup.get('amount1')?.value,
      Amount2: this.formGroup.get('amount2')?.value,
    };
    const response = await this.paymentService.verifyFundingSourceMicroDeposits(this.dwollaID, body);
    if (response.isAnyError) {
      return;
    }
    this.modalCtrl.dismiss(true);
  }
}
