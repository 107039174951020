import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { OfficialLegacyApiService } from '../../../../core/services/api/v1/OfficialLegacyApi.service';

@Component({
  selector: 'app-official-information',
  templateUrl: './official-information.component.html',
  styleUrls: ['./official-information.component.scss'],
})
export class OfficialInformationComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  eventId: any;
  linkedId: any;
  official: any;
  officialContactPoints?: any = new Array<any>();

  constructor(
    private readonly authService: AuthService,
    public readonly modalCtrl: ModalController,
    private readonly officialServices: OfficialLegacyApiService
  ) {
    if (!this.eventId) {
      this.eventId = '';
    }

    if (!this.linkedId) {
      this.linkedId = false;
    }
  }
  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();

    let response = await this.officialServices.find(this.official.ID);

    if (response.isAnyError) {
      return;
    }

    if (this.official == null || !response.data) return;
    this.officialContactPoints = response.data.ContactPoints?.map((contactPoint: any) => {
      return {
        Nickname: contactPoint['Title'],
        Value: contactPoint['Value'],
        ContactType: contactPoint['ContactType'],
      };
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  copyText(value: string) {
    navigator.clipboard.writeText(value);
  }
}
