<ion-header>
  <ion-toolbar class="toolbar" (click)="expanded = !expanded">
    <div id="container">
      <div id="header-container">
        <ion-text class="type-text" *ngIf="this.context && (this.applicationTypeService.applicationType$.getValue() === 'Officials')">
          <ion-icon name="calendar-outline" class="dropdown-arrow"></ion-icon> My Calendar
        </ion-text>
        <ion-text class="type-text" *ngIf="this.context && (this.applicationTypeService.applicationType$.getValue() === 'Staff' || this.applicationTypeService.applicationType$.getValue() === 'Community')">
          <ion-icon name="calendar-outline" class="dropdown-arrow"></ion-icon>
          {{this.convertStaffFilter(this.staffFilter)}}
        </ion-text>
      </div>
      <ion-icon name="chevron-up" class="dropdown-arrow" *ngIf="expanded"></ion-icon>
      <ion-icon name="chevron-down" class="dropdown-arrow" *ngIf="!expanded"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="expanded" class="dropdown">
  <div id="list-container">
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff' || this.applicationTypeService.applicationType$.getValue() === 'Community'" appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('myCalendars')">
      <ion-icon name="logo-rss" class="staff-icons"></ion-icon>
      My Calendars
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Community'" appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('allMyCalendars')">
      <ion-icon name="logo-rss" class="staff-icons"></ion-icon>
      All My Calendars
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('sports')">
      <ion-icon name="trophy-outline" class="staff-icons"></ion-icon>Sports
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('home')">
      <ion-icon name="trophy-outline" class="staff-icons"></ion-icon>Home Sports
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters"appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('away')">
      <ion-icon name="trophy-outline" class="staff-icons"></ion-icon>Away Sports
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters"  appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('practices')">
      <ion-icon name="trophy-outline" class="staff-icons"></ion-icon>Practices
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('sportsAndPractices')">
      <ion-icon name="trophy-outline" class="staff-icons"></ion-icon>Sports + Practices
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('nonSports')">
      <ion-icon name="calendar-outline" class="staff-icons"></ion-icon>Non-sports
    </ion-item>
    <ion-item *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Staff'  || this.applicationTypeService.applicationType$.getValue() === 'Community'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="this.filterCalendars('masterCalendar')">
      <ion-icon name="calendar-outline" class="staff-icons"></ion-icon>Master Calendar
    </ion-item>
    <ion-item lines="none" class="school-row" *ngFor="let item of calendars" [style.background-color]="item.BackColor" [style.color]="item.TextColor">
      <ion-checkbox (ionChange)="updateParent($event, item)" [(ngModel)]="item.Visible" labelPlacement="end">
        <span style="white-space: normal;">{{item.Title}} &nbsp;</span> <span *ngIf="item.OrganizationTitle !== item.Title && this.applicationTypeService.applicationType$.getValue() !== 'Officials' && this.staffFilter === 'allMyCalendars'" style="white-space: normal;">({{item.OrganizationTitle}})</span></ion-checkbox>
    </ion-item>
    <ion-button *ngIf="this.applicationTypeService.applicationType$.getValue() === 'Community' || this.applicationTypeService.applicationType$.getValue() === 'Staff'"
      class="manage-button button-height"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="openManageCalendarsModal()"
      expand="block">
      <ion-icon name="calendar-outline" class="manage-icon"></ion-icon><ion-text>Manage My Calendars</ion-text>
    </ion-button>
  </div>
</ion-content>
