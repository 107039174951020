@if(loading$ | async) {

<div class="spinner-container">
  @if(customLoadingIndicator) {

  <ng-container *ngTemplateOutlet="customLoadingIndicator" />

  } @else {
  <ion-spinner name="circular"></ion-spinner>
  }
</div>

}
