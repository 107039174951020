import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { SuccessService } from '../../../../core/services/success/success.service';
import { ErrorService } from '../../../../core/services/error/error.service';
import { EventLegacyApiService } from '../../../../core/services/api/v1/EventLegacyApi.service';

@Component({
  selector: 'app-share-event-modal',
  templateUrl: './share-event-modal.component.html',
})
export class ShareEventModalComponent {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  eventId: string | undefined;
  @Input() email: string = '';

  constructor(
    private readonly successService: SuccessService,
    private readonly errorService: ErrorService,
    public readonly modalCtrl: ModalController,
    private readonly eventService: EventLegacyApiService
  ) {
    this.email = '';
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submit() {
    if (!this.email || this.email.startsWith('@') || !this.email.includes('@')) {
      this.errorService.showError('You must enter a valid email.');
      return;
    }

    const response = await this.eventService.shareEvent(this.eventId!, this.email);
    if (response.isSuccess) {
      this.successService.showSuccess('Your email has been sent successfully.', 5000, 'Email Sent');
      await this.modalCtrl.dismiss();
    } else if (response.isNotFound) {
      this.errorService.showError('Your email failed to send. Please try again later.', 5000, 'Error');
    }
  }
}
