<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Decline Invitation</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="decline-form" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item lines="none">
        <ion-text> Declining an invitation will not prevent you from receiving other invitations. </ion-text>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          autocapitalize="on"
          placeholder="Optional"
          type="text"
          [(ngModel)]="reason">
          <div slot="label">Reason</div>
        </ion-input>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-07">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="declineAssignment()"
          expand="block">
          <fa-icon icon="check" size="lg"></fa-icon> <ion-text>Confirm</ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
