import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, MenuController, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { ErrorService } from '../../../../core/services/error/error.service';
import { UserLegacyApiService } from '../../../../core/services/api/v1/UserLegacyApi.service';
import { dtoUserUpdatePassword } from '../../../../core/services/api/models/legacy/dtoUserUpdatePassword';
import { DialogService } from '../../../../core/services/dialog/dialog.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() oldPassword: string;
  @Input() newPassword: string;
  @Input() confirmPassword: string;
  dtoUserUpdatePassword: dtoUserUpdatePassword = new (class implements dtoUserUpdatePassword {
    CurrentPassword: string | undefined;
    NewPassword: string | undefined;
  })();

  constructor(
    private readonly errorService: ErrorService,
    private readonly userService: UserLegacyApiService,
    private readonly menuCtrl: MenuController,
    private readonly dialogService: DialogService,
    public modalCtrl: ModalController
  ) {
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submit() {
    if (!this.oldPassword || this.oldPassword === '') {
      this.errorService.showError('Current password is required.');
      return;
    }

    if (!this.newPassword || this.newPassword === '') {
      this.errorService.showError('New password is required.');
      return;
    }

    if (this.newPassword !== this.confirmPassword) {
      this.errorService.showError('Your new passwords must match.');
      return;
    }
    if (this.newPassword.length < 8) {
      this.errorService.showError('Your new password must be at least 8 characters');
      return;
    }

    this.dtoUserUpdatePassword.CurrentPassword = this.oldPassword;
    this.dtoUserUpdatePassword.NewPassword = this.newPassword;

    const response = await this.userService.changePassword(this.dtoUserUpdatePassword);

    if (response.isSuccess) {
      this.dialogService.showAlert({
        header: 'Success',
        message: 'Your password has been changed.',
        buttons: [
          {
            text: 'OK',
            handler: async () => {
              await this.dialogService.dismissAlert();
              await this.modalCtrl.dismiss();
              await this.menuCtrl.close();
            },
          },
        ],
      });
    } else if (response.isNotFound) {
      this.errorService.showError('There was an issue with changing your password.');
    }
  }
}
