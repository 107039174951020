import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PopoverController, ViewDidEnter } from '@ionic/angular';
import { dtoEventResult } from 'src/app/core/services/api/models/legacy/dtoEventResult';
import { dtoOpponentLongForm } from 'src/app/core/services/api/models/legacy/dtoOpponentLongForm';
import { Level } from 'src/app/core/services/api/models/legacy/Level';
import { LevelLegacyApiService } from 'src/app/core/services/api/v1/LevelLegacyApi.service';
import { OpponentLegacyApiService } from 'src/app/core/services/api/v1/OpponentLegacyApi.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss'],
})
export class ScoreCardComponent implements OnInit, ViewDidEnter {
  @Input() Score!: dtoEventResult;
  @Output() DeleteScore = new EventEmitter();
  @Output() EditScore = new EventEmitter<string>();

  @ViewChild('Popover') popover!: HTMLIonPopoverElement;

  isPopoverOpen: boolean = false;
  opponent?: dtoOpponentLongForm;
  level?: Level;

  constructor(
    private readonly dialogService: DialogService,
    private readonly popoverController: PopoverController,
    private readonly opponentLegacyApiService: OpponentLegacyApiService,
    private readonly levelLegacyApiService: LevelLegacyApiService
  ) {}

  ngOnInit(): void {
    this.refreshData();
  }

  ionViewDidEnter() {
    this.refreshData();
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isPopoverOpen = true;
  }

  async refreshData() {
    const responseOpponent = await this.opponentLegacyApiService.find(this.Score.OpponentID!);
    if (responseOpponent.isAnyError) {
      return;
    }
    this.opponent = responseOpponent.data;

    const responseLevel = await this.levelLegacyApiService.find(this.Score.LevelID!);
    if (responseLevel.isAnyError) {
      return;
    }
    this.level = responseLevel.data;
  }

  editScore(scoreID: string) {
    this.popoverController.dismiss();
    this.EditScore.emit(scoreID);
  }

  async deleteScore(scoreID: string) {
    await this.dialogService.showAlert({
      header: 'Remove Score',
      message: 'Are you sure you want to remove this score?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Remove',
          handler: async () => {
            this.popoverController.dismiss();
            this.DeleteScore.emit(scoreID);
          },
        },
      ],
    });
  }
}
