import { FormsModule } from '@angular/forms';
import { MbscModule } from '@mobiscroll/angular';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  faCalendarDays,
  faTicket,
  faEnvelope,
  faPersonRunning,
  faBars,
  faQrcode,
  faListUl,
  faBlockQuestion,
  faCameraViewfinder,
  faMoneyBill1,
  faBatteryLow,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBatteryFull,
  faCreditCard,
  faShare,
  faBan,
  faCirclePlus,
  faCircleMinus,
  faTrash,
  faStore,
  faSquareQuestion,
  faCashRegister,
  faCalendarPen,
  faEnvelopeCircle,
  faNewspaper,
  faIdBadge,
  faPaintBrush,
  faKey,
  faUser,
  faBellOn,
  faSchool,
  faPlus,
  faCopy,
  faCheck,
  faEllipsisVertical,
  faArrowsRotate,
  faWheelchair,
  faUserCheck,
  faPaperPlane,
  faArrowLeftLong,
  faBullhorn,
  faImages,
  faImage,
  faRotateRight,
  faGraduationCap,
  faClock,
  faStar,
  faUpload,
  faTriangleExclamation,
  faCircle,
  faCircleCheck,
  faDollarSign,
  faBuilding,
  faTrophy,
  faRss,
  faFileText,
  faFile,
  faFilePdf,
  faFileExcel,
  faFileDoc,
  faFileCsv,
  faTimes,
  faUsers,
  faCalendarClock,
  faGoalNet,
  faNote,
  faFileSignature,
  faFloppyDisk,
  faRightLong,
  faShareNodes,
  faPencil,
  faSignOut,
  faExchange,
  faMap,
  faTag,
  faExclamation,
  faExclamationCircle,
  faHandshake,
  faThumbsUp,
  faCalendar,
  faCalendarPlus,
  faChevronDown,
  faChevronUp,
  faThumbsDown,
  faUserPlus,
  faNotes,
  faMoneyBill,
  faUsersMedical,
  faCircleQuestion,
  faMagnifyingGlass,
  faIdCard,
  faLinkHorizontal,
  faSubtitlesSlash,
  faUserHelmetSafety,
  faBell,
  faPhone,
  faSquareCheck,
  faRightFromBracket,
  faShop,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule } from 'ngx-logger';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalErrorHandlerService } from './core/services/global-error-handler/global-error-handler.service';
import { environment } from '../environments/environment';
import { InitializeAppService } from './core/services/initalize/initialize.app.service';
import { ApiInterceptor } from './core/interceptors/api/api.interceptor';
import { SharedModule } from './shared/shared.module';
import { ENV, getEnv } from '../environments/environment.provider';
import { Store, StoreModule } from '@ngrx/store';
import { kioskEventsReducer } from './modules/kiosk/store/reducer';
import { availableEventsReducer } from './modules/tickets/store/available-events/reducer';
import { userInfoReducer, clearStateMetaReducer } from './modules/create-account/store/create-account.reducer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function initializeFactory(init: InitializeAppService) {
  return () => init.initializeApp();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.logging.analyticsEndpoint,
      level: environment.logging.logLevel,
      serverLogLevel: environment.logging.serverLogLevel,
      disableConsoleLogging: false,
    }),
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SharedModule,
    StoreModule.forRoot(
      { availableEvents: availableEventsReducer, kioskEvents: kioskEventsReducer, userInfo: userInfoReducer },
      { metaReducers: [clearStateMetaReducer] }
    ),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ApiInterceptor, useClass: ApiInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: ENV, useFactory: getEnv },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFactory,
      deps: [InitializeAppService],
      multi: true,
    },
    Store,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCalendarDays,
      faTicket,
      faEnvelope,
      faBars,
      faQrcode,
      faListUl,
      faBlockQuestion,
      faCameraViewfinder,
      faMoneyBill1,
      faUserCheck,
      faBatteryLow,
      faBatteryQuarter,
      faArrowLeftLong,
      faBatteryHalf,
      faBatteryThreeQuarters,
      faBatteryFull,
      faCreditCard,
      faShare,
      faBan,
      faSquareQuestion,
      faPersonRunning,
      faCirclePlus,
      faCircleMinus,
      faTrash,
      faStore,
      faCashRegister,
      faCalendarPen,
      faEnvelopeCircle,
      faNewspaper,
      faIdBadge,
      faPaintBrush,
      faMoneyBill1,
      faKey,
      faUser,
      faBellOn,
      faSchool,
      faWheelchair,
      faPlus,
      faCopy,
      faEllipsisVertical,
      faCheck,
      faArrowsRotate,
      faPaperPlane,
      faBullhorn,
      faImages,
      faImage,
      faGraduationCap,
      faRotateRight,
      faStar,
      faUpload,
      faTriangleExclamation,
      faCircle,
      faCircleCheck,
      faBuilding,
      faTrophy,
      faRss,
      faFileText,
      faFile,
      faFilePdf,
      faFileExcel,
      faFileDoc,
      faFileCsv,
      faClock,
      faDollarSign,
      faTimes,
      faUsers,
      faCalendarClock,
      faGoalNet,
      faNote,
      faFileSignature,
      faFloppyDisk,
      faRightLong,
      faShareNodes,
      faPencil,
      faSignOut,
      faExchange,
      faMap,
      faTag,
      faExclamation,
      faExclamationCircle,
      faHandshake,
      faThumbsUp,
      faThumbsDown,
      faCalendar,
      faCalendarPlus,
      faChevronDown,
      faChevronUp,
      faUserPlus,
      faNotes,
      faMoneyBill,
      faBan,
      faUsersMedical,
      faCircleQuestion,
      faMagnifyingGlass,
      faIdCard,
      faSubtitlesSlash,
      faUserHelmetSafety,
      faLinkHorizontal,
      faBell,
      faPhone,
      faSquareCheck,
      faRightFromBracket,
      faShop
    );
  }
}
