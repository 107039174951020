<ion-app *ngIf="isAuthenticated$ | async; let isAuthenticated; else: notAuthorized">
  <app-header></app-header>
  <ion-router-outlet></ion-router-outlet>

  <app-loading-indicator />

  <app-staff-footer></app-staff-footer>
</ion-app>

<ng-template #notAuthorized>
  <ion-app>
    <ion-router-outlet></ion-router-outlet>
  </ion-app>
</ng-template>
