import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DtoTicketTakerEvent } from 'src/app/core/services/api/models/DtoTicketTakerEvent';

export const AvailableEventsActions = createActionGroup({
  source: 'AvailableEvents',
  events: {
    'Load All': emptyProps(),
    'Add Events': props<{ availableEvents: DtoTicketTakerEvent[] }>(),
  },
});
