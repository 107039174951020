<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block" >
        <ion-text> Close </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Linked Events</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="page-container ion-padding-horizontal">
    
    <div class="event-card" *ngFor="let game of linkedGames">
      <div class="card-header">
        <div class="header-details">
            <ion-text class="header-details-text">{{ getStartDateTime(game.StartDateTime!, game.IsAllDay!)}}</ion-text>
            <ion-text class="header-details-text bold-text">{{game.Team?.Title}}</ion-text>
        </div>
      </div>
      <div class="card-content">
        <ion-text class="card-details">
          Host:
            <ion-text style="font-weight: 400">{{ game.Organization?.Title }}</ion-text>
        </ion-text>
        <ion-text class="card-details">
          Type:
            <ion-text style="font-weight: 400">{{ game.EventType }}</ion-text>
        </ion-text>
      </div>
    </div>
  </div>

</ion-content>
