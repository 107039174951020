// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { Level } from "../models/legacy/Level";

@Injectable({providedIn: 'root'})
export class LevelLegacyApiService {
    private readonly module = "Level";

    constructor(private legacyApi: LegacyApiService) { }

    /** Use v2 Levels/GetByID instead. Finds A Level with a specific ID
     * @param id ID for Level ID
     */
    find(id: Guid) {
        return this.legacyApi.get<Level | undefined>(this.module, "Find", { id });
    }

    /** Use v2 Levels/GetAll instead. Gets All Levels in the database
     */
    getAll() {
        return this.legacyApi.get<Level[] | undefined>(this.module, "GetAll", {  });
    }
}