// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoAthleticsContactMessage } from "../models/DtoAthleticsContactMessage";

@Injectable({providedIn: 'root'})
export class AthleticsContactApiService {
    private readonly controller = "AthleticsContact";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param organizationID 
     * @param eventID 
     * @param body 
     */
    messageEventWorkers(organizationID: Guid, eventID: Guid, body: DtoAthleticsContactMessage) {
        return this.v2Api.post<void>(this.controller, "MessageEventWorkers", { organizationID, eventID }, body);
    }

    /** 
     * @param organizationID 
     * @param eventID 
     * @param body 
     */
    messageEventOfficials(organizationID: Guid, eventID: Guid, body: DtoAthleticsContactMessage) {
        return this.v2Api.post<void>(this.controller, "MessageEventOfficials", { organizationID, eventID }, body);
    }
}