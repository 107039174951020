import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  private inProgressCount = 0;

  loading$ = this.loadingSubject.asObservable();

  /**
   * Loading will stay on until the promise is resolved or errors.
   * @param promise
   * @returns The original promise
   */
  public loadingWhile<T>(promise: Promise<T>): Promise<T> {
    this.addLoading();

    promise.finally(() => {
      this.removeLoading();
    });

    return promise;
  }

  private addLoading() {
    this.inProgressCount++;
    this.ensureLoadingState();
  }

  private removeLoading() {
    this.inProgressCount--;
    this.ensureLoadingState();
  }

  private ensureLoadingState() {
    // Loading is true while in progress count is > 0
    this.loadingSubject.next(!!this.inProgressCount);
  }
}
