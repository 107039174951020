import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { EventAthleticsLegacyApiService } from '../../../../core/services/api/v1/EventAthleticsLegacyApi.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';

@Component({
  selector: 'app-linked-events',
  templateUrl: './linked-events.component.html',
  styleUrls: ['./linked-events.component.scss'],
})
export class LinkedEventsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  linkedId: any;
  linkedGames: any = [];
  constructor(
    private readonly authService: AuthService,
    public readonly modalCtrl: ModalController,
    private readonly eventAthleticsLegacyApiService: EventAthleticsLegacyApiService,
    private readonly utilsService: UtilsService
  ) {
    if (!this.linkedId) {
      this.linkedId = false;
    }
  }
  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();

    let response = await this.eventAthleticsLegacyApiService.getLinkedByEventID(this.linkedId);

    if (response.isAnyError) {
      return;
    }

    this.linkedGames = response.data;
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  fixStartDateTime(dateTime: string) {
    return this.utilsService.getDateTime(dateTime, this.context.TimeZone!);
  }
}
