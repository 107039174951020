<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title style="text-align: center">Update Info</ion-title>
    <ion-textarea style="width: 25%;" slot="end"></ion-textarea>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-top">
    <ion-text class="ion-padding-start text-start top-line"> First Name</ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-input [(ngModel)]="FirstName"></ion-input>
    </ion-item>
    <ion-text class="ion-padding-start text-start top-line text-top-margin"> Last Name</ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-input [(ngModel)]="LastName"></ion-input>
    </ion-item>
    <ion-text class="ion-padding-start text-start top-line text-top-margin"> Email</ion-text>
    <ion-item>
      <ion-input [(ngModel)]="Email"></ion-input>
    </ion-item>
  <ion-buttons class="ion-padding">
    <ion-button
      type="submit"
      class="submit-buttons"
      appPreventMultiClickEvents
      (preventMultiClicks)="save()"
      expand="block">
      <ion-text> Submit </ion-text>
    </ion-button>
  </ion-buttons>
</ion-content>
