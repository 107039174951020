import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { PaymentLegacyApiService } from 'src/app/core/services/api/v1/PaymentLegacyApi.service';
import { UserLegacyApiService } from 'src/app/core/services/api/v1/UserLegacyApi.service';
import { dtoUserLongForm } from 'src/app/core/services/api/models/legacy/dtoUserLongForm';

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss'],
})
export class RegisterAccountComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  states: any = [];
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() address1: string;
  @Input() address2: string;
  @Input() city: string;
  @Input() state: string;
  @Input() zipCode: string;
  user: dtoUserLongForm = {
    ID: undefined,
    Username: undefined,
    FirstName: undefined,
    LastName: undefined,
    Address1: undefined,
    Address2: undefined,
    County: undefined,
    City: undefined,
    State: undefined,
    Zip: undefined,
    TimeZone: undefined,
    Latitude: undefined,
    Longitude: undefined,
    LastSignedIn: undefined,
    DefaultApplication: undefined,
    ContactPoints: undefined,
  };

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly paymentService: PaymentLegacyApiService,
    private readonly fb: FormBuilder,
    private readonly utilsService: UtilsService,
    private readonly userService: UserLegacyApiService
  ) {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.zipCode = '';

    this.formGroup = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.states = this.utilsService.getStates();
    this.context = await this.authService.getContext();
    const userResponse = await this.userService.find(this.context.UserID!);
    if (userResponse.isAnyError) {
      return;
    }
    this.user = userResponse.data!;
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async saveAccount() {
    let customerData: any = {
      FirstName: this.formGroup.get('firstName')?.value,
      LastName: this.formGroup.get('lastName')?.value,
      Address1: this.formGroup.get('address1')?.value,
      Address2: this.formGroup.get('address2')?.value,
      City: this.formGroup.get('city')?.value,
      State: this.formGroup.get('state')?.value,
      Zip: this.formGroup.get('zipCode')?.value,
      Email: this.formGroup.get('email')?.value,
      ShouldHaveAddress: undefined,
      TimeZone: undefined,
      County: undefined,
      DefaultApplication: undefined,
    };
    const response = await this.paymentService.registerCustomerForUser(this.user.ID!, undefined, customerData);
    if (response.isAnyError) {
      return;
    }
    this.modalCtrl.dismiss(true);
  }
}
