import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventOfficialSlotLegacyApiService } from 'src/app/core/services/api/v1/EventOfficialSlotLegacyApi.service';
import { dtoEventOfficialSlotAccept } from 'src/app/core/services/api/models/legacy/dtoEventOfficialSlotAccept';

@Component({
  selector: 'app-accept-assignment',
  templateUrl: './accept-assignment.component.html',
  styleUrls: ['./accept-assignment.component.scss'],
})
export class AcceptAssignmentComponent {
  @Input() body!: dtoEventOfficialSlotAccept;
  @Input() contract!: string;

  constructor(
    public modalCtrl: ModalController,
    private readonly assignmentsService: EventOfficialSlotLegacyApiService
  ) {}

  cancel() {
    this.modalCtrl.dismiss();
  }

  acceptAssignment() {
    this.assignmentsService.accept(undefined, this.body);
    this.modalCtrl.dismiss(true);
  }
}
