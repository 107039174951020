import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventOfficialSlotLegacyApiService } from 'src/app/core/services/api/v1/EventOfficialSlotLegacyApi.service';
import { dtoEventOfficialSlotAccept } from 'src/app/core/services/api/models/legacy/dtoEventOfficialSlotAccept';
import { SuccessService } from 'src/app/core/services/success/success.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

@Component({
  selector: 'app-accept-assignment',
  templateUrl: './accept-assignment.component.html',
  styleUrls: ['./accept-assignment.component.scss'],
})
export class AcceptAssignmentComponent {
  @Input() body!: dtoEventOfficialSlotAccept;
  @Input() contract!: string;
  @Input() organizationTitle!: string;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly eventOfficialSlotLegacyApiService: EventOfficialSlotLegacyApiService,
    private readonly successService: SuccessService,
    private readonly dialogService: DialogService,
  ) {}

  cancel() {
    this.modalCtrl.dismiss('cancel');
  }

  async acceptAssignment(force: boolean) {
    const response = await this.eventOfficialSlotLegacyApiService.accept(force, this.body);
    if (response.isSuccess) {
      this.successService.showSuccess('Game invitation accepted successfully.', 5000, 'Accepted');
      await this.dialogService.dismissModal('success');
    }
    else if (response.isAnyError && response.isConflictError) {
      if (response.statusCode === 409) { 
        let conflict = response.conflictData!;
        await this.dialogService.showAlert({
          backdropDismiss: false,
          header: 'Conflict Detected',
          message: conflict,
          buttons: [
            {
              text: 'No',
              handler: async () => {
                await this.dialogService.dismissModal('cancel');
              },
            },
            {
              text: 'Yes',
              handler: async () => {
                await this.acceptAssignment(true);
              },
            },
          ],
        });
      }
      else if (response.statusCode === 403) {
        await this.dialogService.showAlert({
          backdropDismiss: false,
          header: 'Inactive',
          message: `Sorry! It looks like the invitation you are trying to accept was created by a School whose Eventlink Subscription has expired! <br><br> Please contact the administrators of ${this.organizationTitle} for further assistance.`,
          buttons: [
            {
              text: 'OK',
              handler: async () => {
                await this.dialogService.dismissModal('cancel');
              },
            }
          ],
        });
      }
    }
    else {
      await this.dialogService.dismissModal('cancel');
    }
  }

}
