// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { int, Guid, DateOnly } from "../models/ApiTypes";
import { DtoTicketConfigurationPublic } from "../models/DtoTicketConfigurationPublic";
import { DtoTicketAccessStatus } from "../models/DtoTicketAccessStatus";
import { DtoTicketConfigurationSummary } from "../models/DtoTicketConfigurationSummary";
import { DtoTicketConfigurationUpdate } from "../models/DtoTicketConfigurationUpdate";
import { DtoTicketConfigurationCreate } from "../models/DtoTicketConfigurationCreate";
import { DtoTicketConfigurationTicketGrouping } from "../models/DtoTicketConfigurationTicketGrouping";
import { DtoTicketConfigurationPaymentIntent } from "../models/DtoTicketConfigurationPaymentIntent";
import { DtoStripeRefund } from "../models/DtoStripeRefund";
import { DtoTicketConfigurationEmailInfo } from "../models/DtoTicketConfigurationEmailInfo";
import { DtoTicketConfigurationEmail } from "../models/DtoTicketConfigurationEmail";
import { DtoTicketConfigurationEmailAll } from "../models/DtoTicketConfigurationEmailAll";

@Injectable({providedIn: 'root'})
export class TicketConfigurationsApiService {
    private readonly controller = "TicketConfigurations";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param id 
     * @param restrictTicketPoolID 
     */
    getPublicByID(id: int, restrictTicketPoolID: int | undefined) {
        return this.v2Api.get<DtoTicketConfigurationPublic>(this.controller, "GetPublicByID", { id, restrictTicketPoolID });
    }

    /** 
     * @param organizationID 
     */
    getTicketAccessStatus(organizationID: Guid) {
        return this.v2Api.get<DtoTicketAccessStatus>(this.controller, "GetTicketAccessStatus", { organizationID });
    }

    /** 
     * @param organizationID 
     */
    acceptTicketTerms(organizationID: Guid) {
        return this.v2Api.post<void>(this.controller, "AcceptTicketTerms", { organizationID });
    }

    /** 
     * @param organizationID 
     * @param from 
     * @param to 
     */
    getSummaryForOrganizationID(organizationID: Guid, from: DateOnly | undefined, to: DateOnly | undefined) {
        return this.v2Api.get<DtoTicketConfigurationSummary[]>(this.controller, "GetSummaryForOrganizationID", { organizationID, from, to });
    }

    /** 
     * @param organizationID 
     */
    getListedByOrganizationID(organizationID: Guid) {
        return this.v2Api.get<DtoTicketConfigurationPublic[]>(this.controller, "GetListedByOrganizationID", { organizationID });
    }

    /** 
     * @param organizationID 
     */
    getListedAsOpponentByOrganizationID(organizationID: Guid) {
        return this.v2Api.get<DtoTicketConfigurationPublic[]>(this.controller, "GetListedAsOpponentByOrganizationID", { organizationID });
    }

    /** Gets a Ticket Configuration by EventID.
     * 
     * Permissions: User Access
     * @param eventID 
     */
    getByEventID(eventID: Guid) {
        return this.v2Api.get<DtoTicketConfigurationPublic>(this.controller, "GetByEventID", { eventID });
    }

    /** 
     * @param eventIDs 
     * @param organizationID 
     */
    getByEventIDs(eventIDs: Guid[], organizationID: Guid | undefined) {
        return this.v2Api.get<DtoTicketConfigurationPublic[]>(this.controller, "GetByEventIDs", { eventIDs, organizationID });
    }

    /** 
     * @param body 
     */
    update(body: DtoTicketConfigurationUpdate) {
        return this.v2Api.post<void>(this.controller, "Update", {  }, body);
    }

    /** 
     * @param body 
     */
    create(body: DtoTicketConfigurationCreate) {
        return this.v2Api.post<int>(this.controller, "Create", {  }, body);
    }

    /** 
     * @param id 
     * @param organizationID 
     */
    delete(id: int, organizationID: Guid) {
        return this.v2Api.post<void>(this.controller, "Delete", { id, organizationID });
    }

    /** 
     * @param ticketConfigurationID 
     */
    getTickets(ticketConfigurationID: int) {
        return this.v2Api.get<DtoTicketConfigurationTicketGrouping[]>(this.controller, "GetTickets", { ticketConfigurationID });
    }

    /** Deprecated.
     * @param ticketConfigurationID 
     */
    getPurchases(ticketConfigurationID: int) {
        return this.v2Api.get<DtoTicketConfigurationPaymentIntent[]>(this.controller, "GetPurchases", { ticketConfigurationID });
    }

    /** 
     * @param ticketID 
     */
    invalidateTicket(ticketID: int) {
        return this.v2Api.post<void>(this.controller, "InvalidateTicket", { ticketID });
    }

    /** 
     * @param id 
     * @param body 
     */
    refundSingle(id: Guid, body: DtoStripeRefund | undefined) {
        return this.v2Api.post<void>(this.controller, "RefundSingle", { id }, body);
    }

    /** 
     * @param ticketPoolIDs 
     * @param includeSessions 
     * @param body 
     */
    refundMultiple(ticketPoolIDs: int[], includeSessions: boolean | undefined, body: DtoStripeRefund | undefined) {
        return this.v2Api.post<void>(this.controller, "RefundMultiple", { ticketPoolIDs, includeSessions }, body);
    }

    /** 
     * @param id 
     * @param email 
     */
    shareIntentTickets(id: Guid, email: string) {
        return this.v2Api.post<void>(this.controller, "ShareIntentTickets", { id, email });
    }

    /** 
     * @param ticketConfigurationID 
     */
    getEmailInfo(ticketConfigurationID: int) {
        return this.v2Api.get<DtoTicketConfigurationEmailInfo>(this.controller, "GetEmailInfo", { ticketConfigurationID });
    }

    /** 
     * @param ticketConfigurationID 
     * @param body 
     */
    sendEmail(ticketConfigurationID: int, body: DtoTicketConfigurationEmail) {
        return this.v2Api.post<void>(this.controller, "SendEmail", { ticketConfigurationID }, body);
    }

    /** 
     * @param ticketConfigurationID 
     */
    getPurchaseLinkQRCodePage(ticketConfigurationID: int) {
        return this.v2Api.get<void>(this.controller, "GetPurchaseLinkQRCodePage", { ticketConfigurationID });
    }

    /** Deletes all TicketAttendances for a given TicketConfiguration.
     * 
     * Required Ticket permissions.
     * @param id 
     */
    resetTicketAttendance(id: int) {
        return this.v2Api.post<void>(this.controller, "ResetTicketAttendance", { id });
    }

    /** Email all valid ticket holders for a given TicketConfiguration.
     * 
     * FromContactPointID is optional. If provided, the email will be sent from the user's email address.
     * 
     * Permissions: Tickets
     * @param ticketConfigurationID 
     * @param body 
     */
    messageTicketHolders(ticketConfigurationID: int, body: DtoTicketConfigurationEmailAll) {
        return this.v2Api.post<void>(this.controller, "MessageTicketHolders", { ticketConfigurationID }, body);
    }
}