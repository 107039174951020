import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '../../../../core/services/dialog/dialog.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import IContextModel from '../../../../core/models/context/context.model';
import * as moment from 'moment-timezone';
import { TicketTakersApiService } from '../../../../core/services/api/v2/TicketTakersApi.service';
import { DtoTicketTakerScan } from '../../../../core/services/api/models/DtoTicketTakerScan';
import { ITicketTakerEvent } from '../../../../core/models/tickets/ticket-taker-event.model';

@Component({
  selector: 'app-select-season-pass-event',
  templateUrl: './select-season-pass-event-modal.component.html',
  styleUrls: ['./select-season-pass-event-modal.component.scss'],
})
export class SelectSeasonPassEventComponent implements OnInit {
  @Input() tickets!: ITicketTakerEvent[];
  @Input() context!: IContextModel;
  @Input() qrCodeValue!: string;
  @Input() offlineValidation?: boolean;

  constructor(
    private readonly dialogService: DialogService,
    private readonly utilsService: UtilsService,
    private readonly ticketTakersApiService: TicketTakersApiService
  ) {}

  ngOnInit() {
    this.tickets = this.tickets
      .map((ticket: ITicketTakerEvent) => {
        const currentConfigurationEvents = [...ticket.TicketConfiguration.ConfigurationEvents!];

        currentConfigurationEvents.sort((a, b) => {
          return moment(a.Event.StartDateTime).diff(moment(b.Event.StartDateTime));
        });

        return {
          ...ticket,
          displayValidateButton: true,
          isValid: false,
          ticketResultInnerText: '',
          ConfigurationEvents: currentConfigurationEvents,
        };
      })
      .sort((a, b) => {
        return moment(a.ConfigurationEvents[0].Event.StartDateTime).diff(
          moment(b.ConfigurationEvents[0].Event.StartDateTime)
        );
      });
  }

  async cancel() {
    await this.dialogService.dismissModal();
  }

  async selectTicket(ticket: ITicketTakerEvent) {
    if (this.offlineValidation) {
      this.dialogService.dismissAlert({ ticket });

      return;
    }

    const scan: DtoTicketTakerScan[] = [{ QrCodeValue: this.qrCodeValue, ScannedAt: moment().toISOString() }];

    const response = await this.ticketTakersApiService.submitScan(
      ticket.TicketConfiguration.ConfigurationEvents![0].TicketConfigurationID,
      scan
    );

    if (response.isAnyError) {
      return;
    }

    const data = response.data!;

    if (data.ScanResults![0].IsValid) {
      await this.dialogService.showAlert({
        header: 'Season Pass Successfully Scanned',
        message: 'This ticket has been scanned successfully for this event.',
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            handler: async () => {
              await this.dialogService.dismissModal();
            },
          },
        ],
      });

      return;
    }

    await this.dialogService.showAlert({
      header: 'Invalid Season Pass Scanned',
      backdropDismiss: false,
      message: data.ScanResults![0].MessageIfInvalid,
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            await this.dialogService.dismissModal();
          },
        },
      ],
    });
  }

  formatDateTime(dateTime: string) {
    return this.utilsService.getDateTimeWithTimezoneAbbr(
      dateTime,
      this.context ? this.context.TimeZone! : moment.tz.guess()
    );
  }
}
