<ion-header>
  <ion-toolbar class="toolbar" (click)="expanded = !expanded">
    <div id="container">
      <div id="header-container">
        <ion-text class="type-text" *ngIf="context && (applicationTypeService.applicationType$.getValue() === 'Officials')">
          <fa-icon icon="calendar" class="dropdown-arrow"></fa-icon> My Calendar
        </ion-text>
        <ion-text class="type-text" *ngIf="context && (applicationTypeService.applicationType$.getValue() === 'Staff' || applicationTypeService.applicationType$.getValue() === 'Community')">
          <fa-icon icon="calendar" class="dropdown-arrow"></fa-icon>
          {{convertStaffFilter(staffFilter)}}
        </ion-text>
      </div>
      <fa-icon icon="chevron-up" class="dropdown-arrow" *ngIf="expanded"></fa-icon>
      <fa-icon icon="chevron-down" class="dropdown-arrow" *ngIf="!expanded"></fa-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="expanded" class="dropdown">
  <div id="list-container">
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff' || applicationTypeService.applicationType$.getValue() === 'Community'" appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('myCalendars')">
      <fa-icon icon="rss" class="option-icon" size="lg"></fa-icon>
      My Calendars
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Community'" appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('allMyCalendars')">
      <fa-icon icon="rss" class="option-icon" size="lg"></fa-icon>
      All My Calendars
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('sports')">
      <fa-icon icon="trophy" class="option-icon" size="lg"></fa-icon>Sports
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('home')">
      <fa-icon icon="trophy" class="option-icon" size="lg"></fa-icon>Home Sports
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters"appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('away')">
      <fa-icon icon="trophy" class="option-icon" size="lg"></fa-icon>Away Sports
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters"  appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('practices')">
      <fa-icon icon="trophy" class="option-icon" size="lg"></fa-icon>Practices
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('sportsAndPractices')">
      <fa-icon icon="trophy" class="option-icon" size="lg"></fa-icon>Sports + Practices
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('nonSports')">
      <fa-icon icon="calendar-days" class="option-icon" size="lg"></fa-icon>Non-sports
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="switchToFacilities()">
      <fa-icon icon="building" size="lg" class="option-icon"/>Facilities
    </ion-item>
    <ion-item *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'  || applicationTypeService.applicationType$.getValue() === 'Community'" class="staff-filters" appPreventMultiClickEvents (preventMultiClicks)="filterCalendars('masterCalendar')">
      <fa-icon icon="calendar-days" class="option-icon" size="lg"></fa-icon>Master Calendar
    </ion-item>
    <ion-item lines="none" class="school-row" *ngFor="let item of calendars" [style.background-color]="item.BackColor" [style.color]="item.TextColor">
      <ion-checkbox (ionChange)="updateParent($event, item)" [(ngModel)]="item.Visible" labelPlacement="end">
        <span style="white-space: normal;">{{item.Title}} &nbsp;</span> <span *ngIf="item.OrganizationTitle !== item.Title && applicationTypeService.applicationType$.getValue() !== 'Officials' && staffFilter === 'allMyCalendars'" style="white-space: normal;">({{item.OrganizationTitle}})</span></ion-checkbox>
    </ion-item>
    <ion-button *ngIf="showManageModal"
      class="manage-button button-height"
      style="margin-bottom: 12em"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="openManageCalendarsModal()"
      expand="block">
      <fa-icon icon="calendar-plus" class="manage-icon"></fa-icon><ion-text>Manage My Calendars</ion-text>
    </ion-button>
  </div>
</ion-content>
