// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoOpponentLongForm } from "../models/legacy/dtoOpponentLongForm";
import { dtoOpponentShortForm } from "../models/legacy/dtoOpponentShortForm";
import { dtoOpponentCreateUpdate } from "../models/legacy/dtoOpponentCreateUpdate";
import { EventOpponentDetail } from "../models/legacy/EventOpponentDetail";
import { dtoEventOpponentDetail } from "../models/legacy/dtoEventOpponentDetail";
import { dtoEventOpponentDetailUpdate } from "../models/legacy/dtoEventOpponentDetailUpdate";

@Injectable({providedIn: 'root'})
export class OpponentLegacyApiService {
    private readonly module = "Opponent";

    constructor(private legacyApi: LegacyApiService) { }

    /** Find an Opponent by ID, user must have either Athletic Permission or be a Coach that has Permission Access to the Organization 
     * @param id Opponent ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoOpponentLongForm | undefined>(this.module, "Find", { id });
    }

    /** Get all opponents of an Organization
     * @param id Organization ID
     */
    getAllByOrganizationID(id: Guid) {
        return this.legacyApi.get<dtoOpponentShortForm[] | undefined>(this.module, "GetAllByOrganizationID", { id });
    }

    /** Creates an opponent for an organization, user must have Athletic Permissions
     * @param body 
     */
    create(body: dtoOpponentCreateUpdate) {
        return this.legacyApi.post<dtoOpponentLongForm | undefined>(this.module, "Create", {  }, body);
    }

    /** Updates an opponent of an organization, user must have Athletic Permissions
     * @param body 
     */
    update(body: dtoOpponentCreateUpdate) {
        return this.legacyApi.post<dtoOpponentLongForm | undefined>(this.module, "Update", {  }, body);
    }

    /** Deletes an opponent of an organization by Opponent ID, user must have Athletic Permissions
     * @param id Opponent ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** Creates a new opponent detail, user must have Athletic Permissions or be a coach for the given team associated with that specific opponent
     * @param applyToContract 
     * @param body 
     */
    createDetails(applyToContract: boolean | undefined, body: EventOpponentDetail) {
        return this.legacyApi.post<dtoEventOpponentDetail | undefined>(this.module, "CreateDetails", { applyToContract }, body);
    }

    /** Updates existing details associated with an opponent, user must have Athletic Permissions or coach with CanEditGames permission. Reutrns 400 when trying to update an opponent that is apart of an E-Contract.
     * @param body 
     */
    updateDetails(body: EventOpponentDetail) {
        return this.legacyApi.post<dtoEventOpponentDetail | undefined>(this.module, "UpdateDetails", {  }, body);
    }

    /** Update many Event Opponent Details at once
     * @param body 
     */
    updateManyDetails(body: dtoEventOpponentDetailUpdate) {
        return this.legacyApi.post<dtoEventOpponentDetail[] | undefined>(this.module, "UpdateManyDetails", {  }, body);
    }

    /** Delete Event Opponent Details by ID, user must have Athletic Permissions or be a coach with CanEditGames permission
     * @param id Event Opponent Details ID
     * @param applytoContract 
     */
    deleteDetails(id: Guid, applytoContract: boolean | undefined) {
        return this.legacyApi.post<void>(this.module, "DeleteDetails", { id, applytoContract });
    }

    /** Retrieves Opponent Details for a given Event ID, user must have Athletic Permissions.
     * @param id Event ID
     */
    getDetailsByEventID(id: Guid) {
        return this.legacyApi.get<dtoEventOpponentDetail[] | undefined>(this.module, "GetDetailsByEventID", { id });
    }

    /** Retrieves Opponent Details for a given Opponent ID, user must have Athletic Permissions.
     * @param id Opponent ID
     */
    getDetailsByOpponentID(id: Guid) {
        return this.legacyApi.get<dtoEventOpponentDetail[] | undefined>(this.module, "GetDetailsByOpponentID", { id });
    }
}