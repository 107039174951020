<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Close </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Eventlink Official</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-row style="padding-left: 5px">
    <ion-label *ngIf="official && official.User"
      ><span style="font-weight: bold">Name: </span> {{ official.User['Name'] }}</ion-label
    >
  </ion-row>
  <ion-grid *ngIf="official !== undefined">
    <ion-row size="2">
      <ion-col class="first-column"><ion-label style="font-weight: bold">Nickname</ion-label></ion-col>
      <ion-col class="second-column"><ion-label style="font-weight: bold">Address/Number</ion-label></ion-col>
    </ion-row>
    <ion-row *ngFor="let element of this.officialContactPoints" size="2">
      <ion-col class="first-column"><ion-label class="ion-text-start">{{element["Nickname"]}}</ion-label></ion-col>
      <ion-col *ngIf="element['ContactType'] === 'Phone'" class="second-column link-text">
        <ion-label><a href="tel:{{element['Value']}}">{{element["Value"]}}</a></ion-label>
      </ion-col>
      <ion-col *ngIf="element['ContactType'] === 'Email'" class="second-column link-text">
        <ion-label><a href="mailto:{{element['Value']}}">{{element["Value"]}}</a></ion-label>
      </ion-col>
      <ion-col *ngIf="element['ContactType'] !== 'Email' && element['ContactType'] !== 'Phone'" class="second-column link-text">
        <ion-label>{{element["Value"]}}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
