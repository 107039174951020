import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ViewDidEnter } from '@ionic/angular';
import { ICustomITitled } from 'src/app/core/services/api/models/events/ICustomITitled';
import { dtoEventResult } from 'src/app/core/services/api/models/legacy/dtoEventResult';
import { Level } from 'src/app/core/services/api/models/legacy/Level';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { dtoTeamLevel } from '../../../../../core/services/api/models/legacy/dtoTeamLevel';

@Component({
  selector: 'app-add-score',
  templateUrl: './add-score-modal.component.html',
  styleUrls: ['./add-score-modal.component.scss'],
})
export class AddScoreModalComponent implements OnInit, ViewDidEnter {
  @Input() Score!: dtoEventResult;
  @Input() Edit!: boolean;
  @Input() Levels!: Level[];
  @Input() Opponents!: (ICustomITitled | undefined)[];
  @Input() DefaultLevel: dtoTeamLevel | undefined;

  score?: dtoEventResult;
  results!: { key: string; label: string }[];

  constructor(
    public readonly modalCtrl: ModalController,
    private readonly utilsService: UtilsService,
    private readonly dialogService: DialogService
  ) {}

  ngOnInit() {
    this.setupScores();
  }

  ionViewDidEnter() {
    this.setupScores();
  }

  setupScores() {
    this.score = this.Score;
    if (this.DefaultLevel) {
      for (let level of this.Levels) {
        if (this.DefaultLevel.ID === level.ID) {
          this.score.Level = level;
          this.score.LevelID = level.ID;
        }
      }
    }
    if (this.Opponents.length === 1) {
      this.score.Opponent = this.Opponents[0];
      this.score.OpponentID = this.Opponents[0]?.ID;
    }
    this.results = this.utilsService.getEventResults();
  }

  async confirmScore() {
    if (await this.isInvalidForm()) return;
    this.modalCtrl.dismiss({ status: 'success', score: this.score });
  }

  private async isInvalidForm(): Promise<boolean> {
    if (!this.score?.OpponentID) {
      await this.dialogService.showAlert({
        header: 'Missing Information',
        message: 'Please select an opponent',
        buttons: ['OK'],
      });
      return true;
    }

    if (!this.score?.LevelID) {
      await this.dialogService.showAlert({
        header: 'Missing Information',
        message: 'Please select a team/level',
        buttons: ['OK'],
      });
      return true;
    }

    return false;
  }

  cancel() {
    this.modalCtrl.dismiss('cancel');
  }
}
