import { Injectable } from '@angular/core';
import { FirebaseCrashlytics, RecordExceptionOptions } from '@capacitor-firebase/crashlytics';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class CrashlyticsService {
  platform: string;

  constructor() {
    this.platform = Capacitor.getPlatform();
  }

  crash = async (message: string) => {
    if (!this.isPlatformSupported()) return;

    await FirebaseCrashlytics.crash({ message });
  };

  setCustomKey = async (
    key: string,
    value: string | boolean | number,
    type: 'string' | 'long' | 'double' | 'boolean' | 'int' | 'float'
  ) => {
    if (!this.isPlatformSupported()) return;

    await FirebaseCrashlytics.setCustomKey({
      key,
      value,
      type,
    });
  };

  setUserId = async (userId: string) => {
    if (!this.isPlatformSupported()) return;

    await FirebaseCrashlytics.setUserId({
      userId,
    });
  };

  log = async (message: string) => {
    if (!this.isPlatformSupported()) return;

    await FirebaseCrashlytics.log({
      message,
    });
  };

  setEnabled = async (enabled: boolean) => {
    if (!this.isPlatformSupported()) return;

    await FirebaseCrashlytics.setEnabled({
      enabled,
    });
  };

  isEnabled = async () => {
    if (!this.isPlatformSupported(['web', 'android'])) return;

    const { enabled } = await FirebaseCrashlytics.isEnabled();
    return enabled;
  };

  didCrashOnPreviousExecution = async () => {
    if (!this.isPlatformSupported()) return;

    const { crashed } = await FirebaseCrashlytics.didCrashOnPreviousExecution();
    return crashed;
  };

  sendUnsentReports = async () => {
    if (!this.isPlatformSupported()) return;

    await FirebaseCrashlytics.sendUnsentReports();
  };

  deleteUnsentReports = async () => {
    if (!this.isPlatformSupported()) return;

    await FirebaseCrashlytics.deleteUnsentReports();
  };

  recordException = async (message: string = 'This is a non-fatal message', error?: Error) => {
    if (!this.isPlatformSupported()) return;

    const options: RecordExceptionOptions = { message };

    if (error) {
      options.message += ` | Stacktrace: ${error.stack}`;
    }

    await FirebaseCrashlytics.recordException(options);
  };

  isPlatformSupported = (nonSupportedPlatforms?: string[]) => {
    if (nonSupportedPlatforms?.includes(this.platform) || this.platform === 'web') {
      return false;
    }

    return true;
  };
}
