<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text>Cancel</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Manage Schools</ion-title>
    <ion-buttons slot="end"> </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-segment class="segment-header" [(ngModel)]="currentView" style="margin-top: 0">
  <ion-segment-button value="subscriptions" (click)="currentView = 'subscriptions'"
    >Subscriptions
  </ion-segment-button>
  <ion-segment-button value="notifications" (click)="currentView = 'notifications'"
    >Notifications
  </ion-segment-button>
</ion-segment>
<ion-content class="ion-padding-horizontal" style="--padding-top: 16px">
  <div *ngIf="currentView === 'subscriptions'">
    <div class="ion-padding-bottom">
      <ion-item>
        <ion-input labelPlacement="stacked" autocapitalize="on" placeholder="Enter here..." [(ngModel)]="name">
          <div slot="label">
            School Name
            <ion-text color="danger">*</ion-text>
          </div>
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-select placeholder="Optional" label-placement="stacked" [(ngModel)]="state">
          <div slot="label">State</div>
          <ion-select-option *ngFor="let state of states" [value]="state.key">
            {{ state.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-input
          label-placement="stacked"
          type="number"
          id="zip"
          maxlength="5"
          placeholder="Optional"
          [(ngModel)]="zip">
          <div slot="label">Zip Code</div>
        </ion-input>
      </ion-item>
      <ion-button
        class="secondary-button"
        type="submit"
        (click)="searchSchools()"
        appPreventMultiClickEvents
        expand="block">
        <fa-icon icon="magnifying-glass" size="lg"></fa-icon> <ion-text>Search</ion-text>
      </ion-button>
    </div>
    <ion-text class="section-header" *ngIf="searchedSchools.length" style="font-size: large">
      <b>Available</b>
    </ion-text>
    <div *ngIf="searching" class="margin-top">
      <ion-item class="school-padding">
        <ion-grid style="min-width: 100%">
          <ion-row>
            <ion-col style="max-width: 20%">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
    <div *ngIf="!searching" class="margin-top">
      <ion-item *ngFor="let school of searchedSchools" class="school-padding">
        <ion-grid style="min-width: 100%" (click)="addSchool(school)">
          <ion-row>
            <ion-col style="max-width: 20%">
              <fa-icon icon="circle-plus" size="xl" class="first-element"></fa-icon>
            </ion-col>
            <ion-col>
              <ion-row>
                <span class="second-element" style="text-transform: uppercase">{{ school.SchoolName }}</span>
              </ion-row>
              <ion-row>
                <div class="second-element">{{ school.City }}, {{ school.State }} {{ school.Zip }}</div>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <div *ngIf="!searchedSchools.length" class="no-results">
        <ion-text><b>No results.</b></ion-text>
      </div>
    </div>
    <div *ngIf="firstLoading">
      <ion-item class="school-padding">
        <ion-grid style="min-width: 100%">
          <ion-row>
            <ion-col style="max-width: 20%">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
    <div *ngIf="!firstLoading" style="padding-bottom: 15dvh; margin-top: 24px">
      <ion-text style="font-size: large">
        <b>Subscriptions</b>
      </ion-text>
      <div *ngIf="newSchools.length" class="margin-top">
        <ion-item *ngFor="let school of newSchools" class="school-padding">
          <ion-grid style="min-width: 100%" (click)="removeSchool(school)">
            <ion-row>
              <ion-col style="max-width: 20%">
                <fa-icon icon="circle-check" size="xl" class="first-element green-text"></fa-icon>
              </ion-col>
              <ion-col>
                <ion-row>
                  <span class="second-element" style="text-transform: uppercase">{{ school.SchoolName }}</span>
                </ion-row>
                <ion-row>
                  <div class="second-element">{{ school.City }}, {{ school.State }} {{ school.Zip }}</div>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </div>
      <div *ngIf="!newSchools.length" class="no-results">
        <ion-text>No schools found.</ion-text>
      </div>
    </div>
  </div>
  <div *ngIf="currentView === 'notifications'">
    <div *ngIf="firstLoading">
      <div class="school-card">
        <div class="card-header">
          <ion-text class="header-text"><ion-skeleton-text [animated]="true"></ion-skeleton-text></ion-text>
        </div>
        <div class="school-card-content">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </div>
      </div>
    </div>
    <div *ngIf="!firstLoading">
      <div *ngIf="schoolNotificationMapping.length" class="schools-container">
        <div *ngFor="let school of schoolNotificationMapping" class="school-card">
          <div class="card-header">
            <ion-text class="header-text">{{ school.organization.SchoolName }}</ion-text>
          </div>
          <div class="school-card-content">
            <div class="checkbox-container">
              <ion-checkbox
                labelPlacement="start"
                [(ngModel)]="school.notification"
                [checked]="school.notification"
                class="first-checkbox">
                Notification
              </ion-checkbox>
              <ion-checkbox
                labelPlacement="start"
                [(ngModel)]="school.dailySummary"
                [checked]="school.dailySummary">
                Daily Summary
              </ion-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!schoolNotificationMapping.length" class="schools-container">
        <ion-text>No notifications found.</ion-text>
      </div>
    </div>
  </div>
</ion-content>

<div class="content-sticky-footer">
  <div class="footer-container">
    <div class="action-button-container">
      <ion-button
        class="primary-button-footer"
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="save()"
        expand="block">
        <fa-icon icon="floppy-disk" size="lg"></fa-icon> Save
      </ion-button>
    </div>
  </div>
</div>
