<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Pay {{ payment.User?.FirstName }} {{ payment.User?.LastName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="block-form" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item>
        <ion-checkbox
          [(ngModel)]="payOutsideEventlink"
          justify="start"
          labelPlacement="end"
          (ionChange)="togglePayOutsideEventlink()">
          I will pay this {{ this.isOfficial ? 'official' : 'worker' }} outside Eventlink
        </ion-checkbox>
      </ion-item>
    </span>

    <span class="input-row" *ngIf="!payOutsideEventlink">
      <ion-item>
        <div style="display: flex; flex-direction: column">
          <ion-text style="font-weight: bold">Source</ion-text>
          <ion-text style="font-weight: bold">{{ fundingSource ? fundingSource.Name : '' }} Account</ion-text>
          <ion-text style="font-weight: bold; color: var(--eventlink-primary); padding-bottom: 1em"
            >Balance: {{ fundingSource?.BalanceDetail?.Balance | currency }}
          </ion-text>
        </div>
      </ion-item>
    </span>

    <span class="input-row" [ngClass]="{ 'padding-bottom': payOutsideEventlink }">
      <ion-item>
        <ion-input labelPlacement="stacked" type="number" [(ngModel)]="amount">
          <div slot="label">Amount <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row" *ngIf="!payOutsideEventlink">
      <ion-item>
        <ion-textarea
          label="Notes"
          labelPlacement="stacked"
          placeholder="Enter notes here..."
          [(ngModel)]="notes"></ion-textarea>
      </ion-item>
    </span>

    <div
      style="margin-left: 1em"
      *ngIf="!payOutsideEventlink"
      [ngClass]="{ 'padding-bottom': !payOutsideEventlink }">
      <i>A $1 transaction fee will be deducted from your Eventlink Pay balance account.</i>
    </div>
  </div>

  <div class="content-sticky" id="sticky-footer">
    <div class="save-container">
      <div class="save-button-container">
        <ion-button expand="block" class="save-btn button-height" (click)="pay()" [disabled]="disableButton">
          <fa-icon icon="money-bill-1" class="cash-icon" size="1x"></fa-icon> Pay
          {{ payment.PaymentAmount | currency }}</ion-button
        >
      </div>
    </div>
  </div>
</ion-content>
