import {Component} from "@angular/core";

@Component({
    selector: 'app-michigan-component',
    templateUrl: './_michigan_mhsaa.html',
  styleUrl: './contract.scss',
})

export class MichiganComponent{

}
