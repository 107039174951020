import { Component, ViewChild } from '@angular/core';
import { IonModal, ModalController, ViewDidEnter } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { ErrorService } from '../../../../core/services/error/error.service';
import { UserLegacyApiService } from '../../../../core/services/api/v1/UserLegacyApi.service';
import { PotentialOrganization } from '../../../../core/services/api/models/legacy/PotentialOrganization';
import { PotentialOrganizationLegacyApiService } from '../../../../core/services/api/v1/PotentialOrganizationLegacyApi.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { OrganizationLegacyApiService } from '../../../../core/services/api/v1/OrganizationLegacyApi.service';
import IContextOrganizationModel from '../../../../core/models/context/context-organization.models';
import { dtoOrganizationSubscriptionSync } from '../../../../core/services/api/models/legacy/dtoOrganizationSubscriptionSync';
import { SuccessService } from 'src/app/core/services/success/success.service';

@Component({
  selector: 'app-manage-schools',
  templateUrl: './manage-schools.component.html',
  styleUrls: ['./manage-schools.component.scss'],
})
export class ManageSchoolsComponent implements ViewDidEnter {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  staffOrgs: IContextOrganizationModel[] = [];
  newSchools: PotentialOrganization[] = [];
  searchedSchools: PotentialOrganization[] = [];
  zip: string = '';
  name: string = '';
  state: string = '';
  states: any = [];
  currentView: string = 'subscriptions';
  firstLoading = true;
  firstSearchDone = false;
  searching = false;
  schoolNotificationMapping: {
    organization: PotentialOrganization;
    notification: boolean;
    dailySummary: boolean;
  }[] = [];

  constructor(
    private readonly successService: SuccessService,
    private readonly errorService: ErrorService,
    private readonly userService: UserLegacyApiService,
    public modalCtrl: ModalController,
    private readonly potentialOrganizationLegacyApiService: PotentialOrganizationLegacyApiService,
    private readonly utilsService: UtilsService,
    private readonly authService: AuthService,
    private readonly organizationService: OrganizationLegacyApiService
  ) {}

  async ionViewDidEnter() {
    this.searching = false;
    this.firstLoading = true;
    await this.refreshData();
    this.firstLoading = false;
  }

  private async refreshData() {
    this.states = this.utilsService.getStates();
    this.zip = '';
    this.state = '';
    this.name = '';
    this.searchedSchools = [];
    this.newSchools = [];
    this.schoolNotificationMapping = [];
    this.currentView = 'subscriptions';
    this.staffOrgs = [];
    let authResponse = await this.authService.getContextOrganizations();
    for (let authOrganization of authResponse) {
      if (authOrganization.IsStaffOrganization) {
        this.staffOrgs.push(authOrganization);
      }
    }

    let orgResponse = await this.userService.getOrganizationSubscriptions(undefined);
    if (orgResponse.isAnyError) {
      return;
    }
    
    let organizations = orgResponse.data ?? [];
    for (let organization of organizations) {
      const response = await this.organizationService.find(organization.ID!);
      if (response.isAnyError) {
        return;
      }
      
      let foundOrganization = response.data!;
      let convertedOrg: PotentialOrganization = {
        Address1: foundOrganization.Address1,
        Address2: foundOrganization.Address2,
        City: foundOrganization?.City,
        Contacts: undefined,
        ID: undefined,
        IsActive: true,
        IsCustomer: true,
        OrganizationID: foundOrganization.ID,
        PhoneAreaCode: undefined,
        PhoneExtension: undefined,
        PhoneNumber: undefined,
        SchoolName: foundOrganization.Title,
        State: foundOrganization.State,
        StateAthleticAssociationID: undefined,
        SubscriptionExpirationDate: undefined,
        TimeZone: foundOrganization.TimeZone,
        TimeZoneAbbreviation: undefined,
        TimeZoneDescription: undefined,
        Zip: foundOrganization.Zip,
      };
      this.newSchools.push(convertedOrg);
      this.sortSchools(this.newSchools);

      let notification: boolean;
      let dailySummary: boolean;
      notification = organization.AllowNotifications ?? false;
      dailySummary = organization.AllowDailyEmails ?? false;

      this.schoolNotificationMapping.push({
        organization: convertedOrg,
        notification: notification,
        dailySummary: dailySummary,
      });
      this.sortSchoolsForNotifications(this.schoolNotificationMapping);
    }
  }

  sortSchoolsForNotifications(schools: {
    organization: PotentialOrganization,
    notification: boolean,
    dailySummary: boolean,
  }[]) {
    return schools.sort((a, b) => a.organization.SchoolName!.localeCompare(b.organization.SchoolName!));
  }

  sortSchools(schools: PotentialOrganization[]) {
    return schools.sort((a, b) => a.SchoolName!.localeCompare(b.SchoolName!));
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async save() {
    let orgSync: dtoOrganizationSubscriptionSync[] = [];
    for (let schoolMap of this.schoolNotificationMapping) {
      orgSync.push({
        ID: schoolMap.organization.OrganizationID,
        AllowNotifications: schoolMap.notification,
        AllowDailyEmails: schoolMap.dailySummary,
      });
    }
    let response = await this.organizationService.syncSubscriptions(orgSync);
    if (response.isAnyError) {
      this.errorService.showError(
        'There was an issue trying to save your subscriptions. Please try again later.',
        6000,
        "Error!"
      );
      return;
    }
    
    this.successService.showSuccess('School subscriptions have been updated successfully.', 5000, 'Schools Updated');
    return this.modalCtrl.dismiss(true, 'save');
  }

  async removeSchool(school: PotentialOrganization) {
    for (let staffOrg of this.staffOrgs) {
      if (staffOrg.OrganizationId?.trim() === school.OrganizationID!.trim()) {
        this.errorService.showError(
          'You must remain subscribed to schools where you are a staff member.',
          5000,
          "Error!"
        );
        return;
      }
    }

    if (this.newSchools.length == 1) {
      this.errorService.showError('You must be subscribed to at least one school.', 5000, "Error!");
      return;
    }
    this.newSchools = [...this.newSchools].filter((newSchool) => { return newSchool.OrganizationID !== school.OrganizationID });

    this.schoolNotificationMapping = [...this.schoolNotificationMapping].filter((schoolNotificationMapping) => { return schoolNotificationMapping.organization.OrganizationID !== school.OrganizationID });

    if (this.searchedSchools.length) {
      this.searchedSchools.push(school);
      this.sortSchools(this.searchedSchools);
    }
    if (this.firstSearchDone) {
      await this.queryOrganizations();
    }
  }

  async addSchool(school: PotentialOrganization) {
    if (!school.OrganizationID) {
      this.errorService.showError(
        "The school you have selected is not an Eventlink customer. Please feel free to contact the school's administration and recommend Eventlink. ",
        5000,
        "Error!"
      );
      return;
    }
    
    let found = false;
    for (let old of this.newSchools) {
      if (old.OrganizationID === school.OrganizationID) {
        found = true;
      }
    }
    if (!found) {
      this.newSchools.push(school);
      this.sortSchools(this.newSchools);

      this.searchedSchools = [...this.searchedSchools].filter(searchedSchool => searchedSchool.ID !== school.ID);

      this.schoolNotificationMapping.push({
        organization: school,
        notification: true,
        dailySummary: true,
      });      
      this.sortSchoolsForNotifications(this.schoolNotificationMapping);

      if (this.firstSearchDone) {
        await this.queryOrganizations();
      }
    }
  }

  async queryOrganizations() {
    this.firstSearchDone = false;
    this.searching = true;
    const response = await this.potentialOrganizationLegacyApiService.getAllByZipOrName(
      this.name,
      undefined,
      undefined
    );
    if (response.isAnyError) {
      return;
    }
    this.searchedSchools = response.data!;

    if (this.state !== '') {
      this.searchedSchools = [...this.searchedSchools].filter((school) => school.State === this.state);
    }
    if (this.zip !== '') {
      this.searchedSchools = [...this.searchedSchools].filter((school) => school.Zip === this.zip);
    }
    if (this.newSchools && this.newSchools.length > 0) {
      for (let newSchool of this.newSchools) {
        this.searchedSchools = [...this.searchedSchools].filter((school) => school.ID !== newSchool.ID);
      }
    }
    this.sortSchools(this.searchedSchools);
    this.searching = false;
  }

  async searchSchools() {
    if (this.name && this.name !== '') {
      await this.queryOrganizations();
    }
  }

}
