import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { StripeTerminal } from '@capacitor-community/stripe-terminal';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TicketPurchasesApiService } from 'src/app/core/services/api/v2/TicketPurchasesApi.service';
import { DtoTicketCheckoutRequestDtoTicketCheckoutResponse } from 'src/app/core/services/api/models/DtoTicketCheckoutRequestDtoTicketCheckoutResponse';
import { ITicketCheckoutRequest } from '../../../../core/models/ticket-purchases/ticket-checkout-request.model';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout-modal.component.html',
  styleUrls: ['./checkout-modal.component.scss'],
})
export class CheckoutComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() checkoutRequest!: ITicketCheckoutRequest;
  @Input() accessToken!: string;

  sendReceiptPhoneNumber: string = '';
  checkoutResponse!: DtoTicketCheckoutRequestDtoTicketCheckoutResponse;
  title: string = 'Send Receipt';

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly alertController: AlertController,
    private readonly ticketPurchasesService: TicketPurchasesApiService,
    private readonly errorService: ErrorService,
    private readonly authService: AuthService
  ) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async skipPhoneNumber() {
    await this.checkout();
  }

  async sendPhoneNumber() {
    if (!isValidPhoneNumber('+1 ' + this.sendReceiptPhoneNumber)) {
      const alert = await this.alertController.create({
        header: 'Invalid Phone Number',
        message: 'Please enter a valid phone number.',
        buttons: ['OK'],
      });

      await alert.present();

      return;
    }

    this.checkoutRequest.ReceiptPhoneNumber = this.sendReceiptPhoneNumber;

    await this.checkout();
  }

  async checkout() {
    this.title = 'Checkout';

    const checkoutResponse = await this.ticketPurchasesService.startCheckout(this.checkoutRequest);

    if (checkoutResponse.isAnyError || !checkoutResponse.data || !checkoutResponse.data.StripePaymentIntentID) {
      return this.modalCtrl.dismiss(
        {
          success: false,
          phoneNumber: '',
          paymentIntentID: '',
        },
        'cancel'
      );
    }

    const confirmAvailabilityResponse = await this.ticketPurchasesService.confirmAvailability(
      checkoutResponse.data.StripePaymentIntentID,
      this.checkoutRequest
    );

    if (confirmAvailabilityResponse.isAnyError) {
      return;
    }

    this.checkoutResponse = checkoutResponse.data!;

    const context = await this.authService.getContext();

    let confirmAvailabilityData = confirmAvailabilityResponse.data!;

    if (!confirmAvailabilityData.Valid) {
      await this.authService.insertLog({
        Message: `'Transaction Error': ${confirmAvailabilityData.Message}`,
        DateTime: new Date(),
        UserID: context.UserID!,
      });

      return this.errorService.showError(confirmAvailabilityData.Message!, 3000, 'Transaction Error');
    }
  }

  async swipeCard() {
    const alert = await this.alertController.create({
      header: 'Transaction in Progress',
      message: 'Please swipe, tap, or insert your card to complete the transaction.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          handler: async () => {
            await StripeTerminal.cancelCollectPaymentMethod();
            await alert.dismiss();
            return this.modalCtrl.dismiss(
              {
                success: false,
                phoneNumber: '',
                paymentIntentID: '',
              },
              'cancel'
            );
          },
        },
      ],
    });

    await alert.present();

    await StripeTerminal.collectPaymentMethod({
      paymentIntent: this.checkoutResponse.PaymentIntentClientSecret!,
    });

    try {
      await StripeTerminal.confirmPaymentIntent();
    } catch (error: any) {
      await alert.dismiss();

      const declineAlert = await this.alertController.create({
        header: 'Transaction Declined',
        message: error.message,
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
          },
        ],
      });

      await declineAlert.present();
      await StripeTerminal.cancelCollectPaymentMethod();

      return;
    }

    return this.modalCtrl.dismiss(
      {
        success: true,
        phoneNumber: this.sendReceiptPhoneNumber,
        paymentIntentID: this.checkoutResponse.StripePaymentIntentID,
      },
      'confirm'
    );
  }

  formatCurrency(amount: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(amount);
  }
}
