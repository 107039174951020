import { createReducer, on } from '@ngrx/store';
import { availableEventsAdapter } from './state';
import { AvailableEventsActions } from './actions';

export const availableEventsReducer = createReducer(
  availableEventsAdapter.getInitialState(),
  on(AvailableEventsActions.addEvents, (state, { availableEvents }) =>
    availableEventsAdapter.setAll(availableEvents, state)
  )
);
