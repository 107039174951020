// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid, DateOnly, DateTime, int } from "../models/ApiTypes";
import { dtoEventContractForm } from "../models/legacy/dtoEventContractForm";
import { dtoEventLongForm } from "../models/legacy/dtoEventLongForm";
import { GetAllWithSlotParamsForBackwardsCompat } from "../models/legacy/GetAllWithSlotParamsForBackwardsCompat";
import { dtoEventAthleticOfficialSlots } from "../models/legacy/dtoEventAthleticOfficialSlots";
import { dtoEventOfficialForm } from "../models/legacy/dtoEventOfficialForm";
import { dtoEventLink } from "../models/legacy/dtoEventLink";
import { dtoLinkID } from "../models/legacy/dtoLinkID";
import { dtoEventAthleticCreate } from "../models/legacy/dtoEventAthleticCreate";
import { dtoEventAthleticExtend } from "../models/legacy/dtoEventAthleticExtend";
import { dtoEventAthleticUpdate } from "../models/legacy/dtoEventAthleticUpdate";
import { dtoEventCopyContract } from "../models/legacy/dtoEventCopyContract";
import { dtoTeamPublicEvents } from "../models/legacy/dtoTeamPublicEvents";
import { dtoEventAthleticAssignersEventUpdate } from "../models/legacy/dtoEventAthleticAssignersEventUpdate";
import { dtoEventBulkCancel } from "../models/legacy/dtoEventBulkCancel";
import { dtoEventShortForm } from "../models/legacy/dtoEventShortForm";
import { dtoEventBulkPayUser } from "../models/legacy/dtoEventBulkPayUser";
import { dtoOfficialPayment } from "../models/legacy/dtoOfficialPayment";
import { dtoWorkerPayment } from "../models/legacy/dtoWorkerPayment";
import { MessageInput } from "../models/legacy/MessageInput";

@Injectable({providedIn: 'root'})
export class EventAthleticsLegacyApiService {
    private readonly module = "EventAthletics";

    constructor(private readonly legacyApi: LegacyApiService) { }

    /** Retrieves events associated with a Contract ID, user must either have Athletic Permissions, be a calendar admin for calendar associated with events, or be a coach for the teams associated with the events.
     * @param id Contract ID
     * @param start 
     * @param tz 
     */
    getAllByContractID(id: Guid, start: DateOnly | undefined, tz: string | undefined) {
        return this.legacyApi.get<dtoEventContractForm[] | undefined>(this.module, "GetAllByContractID", { id, start, tz });
    }

    /** Retrieves events associated with a Link ID, user must either have Athletic Permissions, or be a calendar admin for the calendar of the base event.
     * @param id Link ID
     */
    getAllByLinkID(id: Guid) {
        return this.legacyApi.get<dtoEventLongForm[] | undefined>(this.module, "GetAllByLinkID", { id });
    }

    /** Retrieves all linked events associated with an event, user must either have Athletic Permissions, be a calendar admin for the calendar of the base event, or be an official.
     * @param id Event ID
     */
    getLinkedByEventID(id: Guid) {
        return this.legacyApi.get<dtoEventLongForm[] | undefined>(this.module, "GetLinkedByEventID", { id });
    }

    /** Retrieves all events with slots, can add search options. Returns 400 if you do not have at least one organization ID or one team ID specified. Can include organizations, teams, opponents, assigner, statuses, facilities, already accepted events, and date ranges as options.
     * @param organizationIDs string of organization ids seperated by commas
     * @param teamIDs string of team ids seperated by commas
     * @param opponentIDs string of opponent ids seperated by commas
     * @param assignerID 
     * @param statuses 
     * @param facilityIDs string of facility ids seperated by commas
     * @param excludeAccepted 
     * @param ranges 
     * @param body 
     */
    getAllWithSlots(organizationIDs: string | undefined, teamIDs: string | undefined, opponentIDs: string | undefined, assignerID: Guid | undefined, statuses: string | undefined, facilityIDs: string | undefined, excludeAccepted: boolean | undefined, ranges: string | undefined, body: GetAllWithSlotParamsForBackwardsCompat) {
        return this.legacyApi.post<dtoEventAthleticOfficialSlots[] | undefined>(this.module, "GetAllWithSlots", { organizationIDs, teamIDs, opponentIDs, assignerID, statuses, facilityIDs, excludeAccepted, ranges }, body);
    }

    /** Retrieves all Events an official has accepted a slot to, user must be an official
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getAllWithAcceptedSlotsByCurrentOfficial(start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoEventOfficialForm[] | undefined>(this.module, "GetAllWithAcceptedSlotsByCurrentOfficial", { start, end, tz });
    }

    /** Retrieves all events with open assign currently available in a certain time range, user must be an official
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getAllWithOpenAssignAvailable(start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoEventOfficialForm[] | undefined>(this.module, "GetAllWithOpenAssignAvailable", { start, end, tz });
    }

    /** Retrieves all events with selective assign available in a certain time range, user must be an official
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getAllWithSelectiveAssignAvailableByCurrentOfficial(start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoEventOfficialForm[] | undefined>(this.module, "GetAllWithSelectiveAssignAvailableByCurrentOfficial", { start, end, tz });
    }

    /** Retrieves all events that have been declined by the current user, user must be an official
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getAllDeclinedByCurrentOfficial(start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoEventOfficialForm[] | undefined>(this.module, "GetAllDeclinedByCurrentOfficial", { start, end, tz });
    }

    /** Links retrieved events together, all events must have the same organization ID attached to them. The user must have Athletic Permissions or be a calendar admin for the calendar with the default event.
     * @param body 
     */
    linkEvents(body: dtoEventLink) {
        return this.legacyApi.post<dtoLinkID | undefined>(this.module, "LinkEvents", {  }, body);
    }

    /** Creates a new athletic event including the slots needed for the event, user must either be a calendar admin for the teams game calendar or they must have Athletic Permissions.
     * @param body 
     */
    create(body: dtoEventAthleticCreate) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "Create", {  }, body);
    }

    /** Extends an existing event contract, user must have Athletic Permissions, be a calendar admin for the calendar associated with that event, or be a coach with access to the calendar.
     * @param force 
     * @param body 
     */
    extend(force: boolean | undefined, body: dtoEventAthleticExtend) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "Extend", { force }, body);
    }

    /** Updates an existing athletic event, user must have Athletic Permissions, be a calendar admin for the calendar associated with that event, or be a coach with access to the calendar.
     * @param force 
     * @param body 
     */
    update(force: boolean | undefined, body: dtoEventAthleticUpdate) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "Update", { force }, body);
    }

    /** Changes home or away status of a game, user must have Athletic Permissions, be a calendar admin for the calendar associated with that event, or be a coach with access to the calendar. Returns 400 in the case of changing from a home game to an away in these cases: officials are still in event slots, there are pending official invitations, or if open assign slots have not been removed from the event.
     * @param id Event ID
     */
    toggleHomeAndAwayStatus(id: Guid) {
        return this.legacyApi.post<dtoEventLongForm | undefined>(this.module, "ToggleHomeAndAwayStatus", { id });
    }

    /** Deletes a future contract event, user must have Athletic Permissions, or be a calendar admin for the calendar associated with that event. Returns 400 if trying to delete an event attached to payments.
     * @param id Event ID
     */
    deleteFutureContractEvents(id: Guid) {
        return this.legacyApi.post<void>(this.module, "DeleteFutureContractEvents", { id });
    }

    /** Duplicates an event contract based on provided settings from a body input. Can return conflict based upon specified settings.
     * @param force 
     * @param body 
     */
    copyEventContracts(force: boolean | undefined, body: dtoEventCopyContract) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "CopyEventContracts", { force }, body);
    }

    /** Retrieves all public event for a team given a school year
     * @param teamID 
     * @param schoolYear Must be between 1900-3000
     */
    getPublicTeamEvents(teamID: Guid, schoolYear: int) {
        return this.legacyApi.get<dtoTeamPublicEvents | undefined>(this.module, "GetPublicTeamEvents", { teamID, schoolYear });
    }

    /** Reschedules Officials when an Event is updated. User must be an assigner, have Atheletic Permissions, or a calendar admin for the appropriate calendar related to the event.
     * @param body 
     */
    scheduleOfficialsEventUpdate(body: dtoEventAthleticAssignersEventUpdate) {
        return this.legacyApi.post<void>(this.module, "ScheduleOfficialsEventUpdate", {  }, body);
    }

    /** Allows for bulk cancellation of events, user must have Athletic Permissions. Returns 400 if trying to cancel over 3 months of events at once. Returns Conflict when tickets have already been sold for an event being cancelled.
     * @param body 
     */
    bulkCancel(body: dtoEventBulkCancel) {
        return this.legacyApi.post<dtoEventShortForm[] | undefined>(this.module, "BulkCancel", {  }, body);
    }

    /** Retrieves all users within an organization that need paid, if some of the users retrieved are officials or workers involved with athletics then the user must have Athletic Permissions within the organization.
     * @param orgID Organization ID
     * @param isAthletics 
     * @param recipientType 
     * @param start 
     * @param end 
     * @param includeUnverified 
     */
    getUsersNeedingPayment(orgID: Guid, isAthletics: boolean, recipientType: string, start: DateOnly, end: DateOnly, includeUnverified: boolean | undefined) {
        return this.legacyApi.get<dtoEventBulkPayUser[] | undefined>(this.module, "GetUsersNeedingPayment", { orgID, isAthletics, recipientType, start, end, includeUnverified });
    }

    /** Allows an organization to update paid status on a official, when they have been paid outside of Eventlink.
     * @param body 
     */
    payOfficialOutsideEventlink(body: dtoOfficialPayment) {
        return this.legacyApi.post<void>(this.module, "PayOfficialOutsideEventlink", {  }, body);
    }

    /** Allows an organization to update paid status on a worker, when they have been paid outside of Eventlink.
     * @param body 
     */
    payWorkerOutsideEventlink(body: dtoWorkerPayment) {
        return this.legacyApi.post<void>(this.module, "PayWorkerOutsideEventlink", {  }, body);
    }

    /** Sends a message out to one particular user from an organization that they are not set up to receive payments
     * @param orgID 
     * @param userID 
     * @param body 
     */
    remindNonPayableUser(orgID: Guid, userID: Guid, body: MessageInput) {
        return this.legacyApi.post<void>(this.module, "RemindNonPayableUser", { orgID, userID }, body);
    }

    /** Sends a message out to all users within an organization that are not set up to receive payments
     * @param orgID 
     * @param body 
     */
    remindNonPayableUsersInOrg(orgID: Guid, body: MessageInput) {
        return this.legacyApi.post<void>(this.module, "RemindNonPayableUsersInOrg", { orgID }, body);
    }
}