import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { ContextRepository } from '../../repositories/context.repository';
import { SQLiteService } from '../sqlite/sqlite.service';
import { ContextUpgradeStatements } from '../sqlite/upgrades/context.upgrade.statements';
import IContextModel from '../../models/context/context.model';
import IContextOrganizationModel from '../../models/context/context-organization.models';
import ITransactionHistoryModel from '../../models/context/transaction-history/transaction-history.model';
import { ITicketTakerOfflineScan } from '../../models/ticket-takers/ticket-taker-offline-scan.model';
import IKioskSessionContext from '../../models/context/kiosk/kiosk-session-context.model';
import ILogEntry from '../../models/context/logs/log-entry.model';
import { IsAuthenticatedService } from './is-authenticated/is-authenticated.service';
import { ApplicationTypeService } from './application-type/application-type.service';
import { OAuthApiService } from '../api/v2/OAuthApi.service';
import { DtoOAuthMfaVerification } from '../api/models/DtoOAuthMfaVerification';
import { Guid, int } from '../api/models/ApiTypes';
import IPersistentContextModel from '../../models/context/persistent-context.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly updateStatements: ContextUpgradeStatements = new ContextUpgradeStatements();
  private db!: SQLiteDBConnection;

  constructor(
    private readonly oAuthService: OAuthApiService,
    private readonly contextRepository: ContextRepository,
    private readonly sqliteService: SQLiteService,
    private readonly applicationTypeService: ApplicationTypeService,
    private readonly isAuthenticatedService: IsAuthenticatedService
  ) {}

  async initializeDatabase(dbName: string) {
    await this.sqliteService.addUpgradeStatement({
      database: dbName,
      upgrade: this.updateStatements.upgrades,
    });

    this.db = await this.sqliteService.openDatabase(dbName, false, 'no-encryption', 1);

    await this.contextRepository.initRepository(this.db);

    let context = await this.getContext();

    this.isAuthenticatedService.isAuthenticated$.next(context && context.AccessToken != '');
    this.applicationTypeService.applicationType$.next(context ? context.ApplicationType! : '');
  }

  async validate() {
    const response = await this.oAuthService.validate();
    if (response.isAnyError) {
      return;
    }
    return response.data;
  }

  async isLoggedIn() {
    const context = await this.getContext();

    return !(context == undefined || context.AccessToken == '');
  }

  async getContextOrganizations() {
    return await this.contextRepository.getContextOrganizations();
  }

  async getContextOrganizationById(ID: number) {
    return await this.contextRepository.getContextOrganizationByID(ID);
  }

  async getContextOrganizationByOrganizationId(organizationID: string) {
    return await this.contextRepository.getContextOrganizationByOrganizationID(organizationID);
  }

  async insertContextOrganization(contextOrganization: IContextOrganizationModel) {
    await this.contextRepository.insertContextOrganization(contextOrganization);
  }

  async updateContextOrganization(contextOrganization: IContextOrganizationModel) {
    await this.contextRepository.updateContextOrganization(contextOrganization);
  }

  async deleteContextOrganizations() {
    await this.contextRepository.deleteContextOganizations();
  }

  async deleteContext() {
    this.isAuthenticatedService.isAuthenticated$.next(false);

    await this.contextRepository.deleteContext();
    await this.contextRepository.deleteContextOganizations();
    await this.contextRepository.deleteTransactionHistory();
    await this.contextRepository.deleteScannedQRCodes();
    await this.contextRepository.deleteKiosk();
    await this.contextRepository.deleteLogEntries();
  }

  async getContext() {
    return await this.contextRepository.getContext();
  }

  async insertContext(context: IContextModel) {
    await this.contextRepository.insertContext(context);
  }

  async updateContext(context: IContextModel) {
    await this.contextRepository.updateContext(context);
  }

  async getTransactionHistory() {
    return await this.contextRepository.getTransactionHistory();
  }

  async insertTransaction(transaction: ITransactionHistoryModel) {
    if ((await this.getTransactionHistory()).length >= 10)
      await this.contextRepository.deleteOldestTransactionHistory();

    await this.contextRepository.insertTransaction(transaction);
  }

  async updateTransaction(transaction: ITransactionHistoryModel) {
    await this.contextRepository.updateTransaction(transaction);
  }

  async verifyMultiFactor(model: DtoOAuthMfaVerification) {
    const response = await this.oAuthService.verifyMultiFactor(model);
    if (response.isAnyError) {
      return;
    }

    return response.data;
  }

  async startMultiFactor(sessionID: int, sessionToken: Guid, methodID: Guid) {
    const response = await this.oAuthService.startMultiFactor(sessionID, sessionToken, methodID);
    if (response.isAnyError) {
      return;
    }

    return response.data;
  }

  async getKiosk() {
    return await this.contextRepository.getKiosk();
  }

  async insertKiosk(kiosk: IKioskSessionContext) {
    await this.contextRepository.insertKiosk(kiosk);
  }

  async updateKiosk(kiosk: IKioskSessionContext) {
    await this.contextRepository.updateKiosk(kiosk);
  }

  async deleteKiosk() {
    await this.contextRepository.deleteKiosk();
  }

  async getScannedQRCodes() {
    return await this.contextRepository.getScannedQRCodes();
  }

  async insertScannedQRCode(ticketScan: ITicketTakerOfflineScan) {
    return await this.contextRepository.insertScannedQRCode(ticketScan);
  }

  async deleteScannedQRCodes() {
    return await this.contextRepository.deleteScannedQRCodes();
  }

  async insertLog(logEntry: ILogEntry) {
    return await this.contextRepository.insertLogEntry(logEntry);
  }

  async getLogEntries() {
    return await this.contextRepository.getLogEntries();
  }

  async deleteLogs() {
    return await this.contextRepository.deleteLogEntries();
  }

  async getTableSizeInBytes(tableName: string) {
    return await this.contextRepository.getTableSizeInBytes(tableName);
  }

  async getTableRowCount(tableName: string) {
    return await this.contextRepository.getTableRowCount(tableName);
  }

  async insertPersistentContext(persistentContext: IPersistentContextModel) {
    return await this.contextRepository.insertPersistentContext(persistentContext);
  }

  async updatePersistentContext(persistentContext: IPersistentContextModel) {
    return await this.contextRepository.updatePersistentContext(persistentContext);
  }

  async deletePersistentContext() {
    return await this.contextRepository.deletePersistentContext();
  }

  async getPersistentContext() {
    return await this.contextRepository.getPersistentContext();
  }
}
