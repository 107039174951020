import { NgxLoggerLevel } from 'ngx-logger';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  authentication: {
    clientID: 'ca5bde45-01b7-4cfb-8983-0df20e654381',
    clientSecret: '8e767da8-1f51-4df0-8f93-16dbf05dbaa2',
  },
  qrCodeExactLength: 13,
  sqliteDatabase: {
    name: 'eventlinkdb',
    version: 1,
    encrypted: false,
    mode: 'no-encryption',
  },
  api: {
    authHeader: 'BASIC',
    endpoint: 'https://api-qa.eventlink.com/',
    maxWaiting: 10000,
    external: 'https://localhost:5001/',
    webcal: 'webcal://localhost:5001/',
    V2Endpoint: 'https://api-qa.eventlink.com/api/',
    support: 'https://eventlink.com/contact-support/',
  },
  dwolla: {
    mode: 'sandbox',
    url: 'https://api-sandbox.dwolla.com'
  },
  stripe: {
    stripeTestMode: false,
    tokenProviderEndpoint: 'https://api-qa.eventlink.com/api/Stripe/GetTerminalConnectionToken',
  },
  logging: {
    logLevel: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.OFF,
    url: '',
    enabled: true,
  },
  tickets: {
    purchase: 'https://public-qa.eventlink.com/find-tickets',
    sitesUrl: 'https://websites-qa.eventlink.com/s/',
  },
  moment: {
    format: 'M/D/YYYY h:mm A',
  },
};
