import { Component, OnInit } from '@angular/core';
import IContextOrganizationModel from 'src/app/core/models/context/context-organization.models';
import IContextModel from 'src/app/core/models/context/context.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { FeatureFlagApiService } from '../../../../core/services/api/v2/FeatureFlagApi.service';
import {ApplicationTypeService} from "../../../../core/services/auth/application-type/application-type.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  expanded: boolean = false;
  context!: IContextModel;
  organizations!: IContextOrganizationModel[];
  manageNotificationFeatureFlag: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private readonly featureFlagApiService: FeatureFlagApiService,
    protected readonly applicationTypeService: ApplicationTypeService,
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();

    if (this.context.OrganizationId && this.context.OrganizationId !== 0) {
      this.organizations = (await this.authService.getContextOrganizations()).sort((a, b) =>
        a.OrganizationTitle!.localeCompare(b.OrganizationTitle!)
      );
      const organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

      const enabledFeatureFlagResponse = await this.featureFlagApiService.getEnabled(organization.OrganizationId);

      if (enabledFeatureFlagResponse.isSuccess) {
        const enabledFeatureFlags = enabledFeatureFlagResponse.data;
        this.manageNotificationFeatureFlag = enabledFeatureFlags!.FeatureFlags!['manage-notifications'];
      }
    }
  }

}
