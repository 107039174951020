// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { DtoTicketTakerEvent } from "../models/DtoTicketTakerEvent";
import { int, Guid } from "../models/ApiTypes";
import { DtoTicketTakerScanResult } from "../models/DtoTicketTakerScanResult";
import { DtoTicketTakerScan } from "../models/DtoTicketTakerScan";
import { DtoTicketTakerScanSummary } from "../models/DtoTicketTakerScanSummary";

@Injectable({providedIn: 'root'})
export class TicketTakersApiService {
    private readonly controller = "TicketTakers";

    constructor(private v2Api: V2ApiService) { }

    /** 
     */
    getAvailableEventsForCurrentUser() {
        return this.v2Api.get<DtoTicketTakerEvent[]>(this.controller, "GetAvailableEventsForCurrentUser", {  });
    }

    /** 
     * @param qrCodeValue 
     * @param ticketConfigurationID 
     */
    submitSelfValidation(qrCodeValue: string, ticketConfigurationID: int | undefined) {
        return this.v2Api.post<DtoTicketTakerScanResult>(this.controller, "SubmitSelfValidation", { qrCodeValue, ticketConfigurationID });
    }

    /** Submit scans (or manual entries) to check for validity and track attendance. If possible submit scans in chronological order they were scanned.
     * @param ticketConfigurationID 
     * @param body 
     */
    submitScan(ticketConfigurationID: int, body: DtoTicketTakerScan[]) {
        return this.v2Api.post<DtoTicketTakerScanSummary>(this.controller, "SubmitScan", { ticketConfigurationID }, body);
    }

    /** Resends SMS receipt for the given payment intent. If phoneNumber is not included, the original payment's phone number will be used.
     * @param paymentIntentID 
     * @param ticketConfigurationID 
     * @param overridePhoneNumber 
     */
    resendSmsReceipt(paymentIntentID: Guid, ticketConfigurationID: int, overridePhoneNumber: string | undefined) {
        return this.v2Api.post<void>(this.controller, "ResendSmsReceipt", { paymentIntentID, ticketConfigurationID, overridePhoneNumber });
    }
}