// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { DtoKioskAccess } from "../models/DtoKioskAccess";

@Injectable({providedIn: 'root'})
export class KioskAccessApiService {
    private readonly controller = "KioskAccess";

    constructor(private v2Api: V2ApiService) { }

    /** 
     */
    getForUser() {
        return this.v2Api.get<DtoKioskAccess[]>(this.controller, "GetForUser", {  });
    }
}