import { Component, OnInit } from '@angular/core';
import { IonIcon, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import IContextOrganizationModel from '../../../../core/models/context/context-organization.models';
import { UserLegacyApiService } from 'src/app/core/services/api/v1/UserLegacyApi.service';
import { dtoCalendarShortForm } from 'src/app/core/services/api/models/legacy/dtoCalendarShortForm';
import { CalendarLegacyApiService } from 'src/app/core/services/api/v1/CalendarLegacyApi.service';
import { dtoCalendarSubSync } from 'src/app/core/services/api/models/legacy/dtoCalendarSubSync';

@Component({
  selector: 'app-manage-calendars',
  templateUrl: './manage-calendars.component.html',
  styleUrls: ['./manage-calendars.component.scss'],
})
export class ManageCalendarsComponent implements OnInit {
  context!: ContextModel;
  calendarsForSchool: dtoCalendarShortForm[] = [];
  selectedCalendarsForUser: dtoCalendarShortForm[] = [];
  currentView: string = 'Available Calendars';
  itemClass: string = 'background-color: lightgrey;';
  contextOrganization!: IContextOrganizationModel;
  organizations: IContextOrganizationModel[] = [];

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly calendarService: CalendarLegacyApiService,
    private readonly userService: UserLegacyApiService
  ) {}

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    this.contextOrganization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);
    this.organizations = (await this.authService.getContextOrganizations()).sort((a, b) =>
      a.OrganizationTitle!.localeCompare(b.OrganizationTitle!)
    );

    const response = await this.userService.getCalendarSubscriptionsByOrganizationID(
      this.contextOrganization?.OrganizationId as string
    );
    if (response.isAnyError) {
      return;
    }
    for (let calendar of response.data ?? []) {
      if (calendar.Type !== 'District' && calendar.Type !== 'Organization') {
        this.selectedCalendarsForUser.push(calendar);
        for (let i = 0; i < this.calendarsForSchool.length; i++) {
          if (this.calendarsForSchool[i].ID === calendar.ID) {
            this.calendarsForSchool.splice(i, 1);
            i = this.calendarsForSchool.length;
          }
        }
      }
    }
    const responseCalendar = await this.calendarService.getPublicByOrganizationID(
      this.contextOrganization.OrganizationId!
    );
    if (responseCalendar.isAnyError) {
      return;
    }
    this.calendarsForSchool = responseCalendar.data ?? [];
    this.sortCalendars();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  saveCalendars() {
    let calendarIds: string[] = [];
    for (let calendar of this.selectedCalendarsForUser) {
      calendarIds.push(calendar.ID!);
    }
    const body: dtoCalendarSubSync = {
      OrganizationID: this.contextOrganization.OrganizationId,
      Calendars: calendarIds,
    };
    this.calendarService.syncSubscriptions(body);
    this.modalCtrl.dismiss(true);
  }

  setAvailableCalendars() {
    this.currentView = 'Available Calendars';
    this.calendarsForSchool.sort((a, b) => a.Title!.localeCompare(b.Title!));
  }

  setMyCalendars() {
    this.currentView = 'My Calendars';
    this.selectedCalendarsForUser.sort((a, b) => a.Title!.localeCompare(b.Title!));
  }

  addCalendar(calendar: dtoCalendarShortForm, element: IonIcon) {
    this.selectedCalendarsForUser.push(calendar);
    for (let i = 0; i < this.calendarsForSchool.length; i++) {
      if (this.calendarsForSchool[i].ID === calendar.ID) {
        this.calendarsForSchool.splice(i, 1);
      }
    }
    this.sortCalendars();
  }

  removeCalendar(calendar: dtoCalendarShortForm, element: IonIcon) {
    this.calendarsForSchool.push(calendar);
    for (let i = 0; i < this.selectedCalendarsForUser.length; i++) {
      if (this.selectedCalendarsForUser[i].ID === calendar.ID) {
        this.selectedCalendarsForUser.splice(i, 1);
      }
    }
    this.sortCalendars();
  }

  sortCalendars() {
    this.selectedCalendarsForUser = this.selectedCalendarsForUser.sort((a, b) => a.Title!.localeCompare(b.Title!));
    this.calendarsForSchool = this.calendarsForSchool.sort((a, b) => a.Title!.localeCompare(b.Title!));
  }
}
