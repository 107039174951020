import {Component} from "@angular/core";

@Component({
    selector: 'app-ohio-component',
    templateUrl: './_ohio_ohsaa.html',
  styleUrl: './contract.scss',
})

export class OhioComponent{

}
