// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid, int } from "../models/ApiTypes";
import { DtoSeasonPassOwnerForm } from "../models/DtoSeasonPassOwnerForm";
import { DtoSeasonPassAddEdit } from "../models/DtoSeasonPassAddEdit";

@Injectable({providedIn: 'root'})
export class SeasonPassesApiService {
    private readonly controller = "SeasonPasses";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param userID 
     * @param includeExpired 
     */
    getByUserID(userID: Guid, includeExpired: boolean | undefined) {
        return this.v2Api.get<DtoSeasonPassOwnerForm[]>(this.controller, "GetByUserID", { userID, includeExpired });
    }

    /** 
     * @param qrCodeValue 
     */
    getByQrCodeValue(qrCodeValue: string) {
        return this.v2Api.get<DtoSeasonPassOwnerForm>(this.controller, "GetByQrCodeValue", { qrCodeValue });
    }

    /** 
     * @param body 
     */
    addPass(body: DtoSeasonPassAddEdit) {
        return this.v2Api.post<void>(this.controller, "AddPass", {  }, body);
    }

    /** 
     * @param body 
     */
    editPass(body: DtoSeasonPassAddEdit) {
        return this.v2Api.post<void>(this.controller, "EditPass", {  }, body);
    }

    /** 
     * @param passHolderID 
     * @param shareToEmail 
     */
    share(passHolderID: int, shareToEmail: string) {
        return this.v2Api.post<void>(this.controller, "Share", { passHolderID, shareToEmail });
    }

    /** Returns the PDF version of the pass that includes QR code, school info, etc.
     * @param passHolderID 
     */
    getPassHolderImageByID(passHolderID: int) {
        return this.v2Api.get<void>(this.controller, "GetPassHolderImageByID", { passHolderID });
    }

    /** Returns the QR code image for the pass.
     * @param passID 
     */
    getPassQrCodeImageByID(passID: int) {
        return this.v2Api.get<void>(this.controller, "GetPassQrCodeImageByID", { passID });
    }
}