import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DtoEventShortForm } from '../../../core/services/api/models/DtoEventShortForm';

export const KioskEventsActions = createActionGroup({
  source: 'KioskEvents',
  events: {
    'Load All': emptyProps(),
    'Add Kiosk Events': props<{ kioskEvents: DtoEventShortForm[] }>(),
  },
});
