// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { DtoMobileLogsSubmissionResponse } from "../models/DtoMobileLogsSubmissionResponse";
import { int, DateTime } from "../models/ApiTypes";
import { DtoMobileLogsSubmissionCsrForm } from "../models/DtoMobileLogsSubmissionCsrForm";

@Injectable({providedIn: 'root'})
export class MobileLogsSubmissionsApiService {
    private readonly controller = "MobileLogsSubmissions";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param logsFile A file upload | Due to a Swagger problem, this field is marked as optional but MAY actually be required.
     */
    submit(logsFile: Blob | undefined) {
        return this.v2Api.post<DtoMobileLogsSubmissionResponse>(this.controller, "Submit", {  }, { LogsFile: logsFile }, true);
    }

    /** 
     * @param id 
     */
    delete(id: int) {
        return this.v2Api.post<void>(this.controller, "Delete", { id });
    }

    /** 
     * @param from 
     */
    get(from: DateTime) {
        return this.v2Api.get<DtoMobileLogsSubmissionCsrForm[]>(this.controller, "Get", { from });
    }

    /** 
     * @param submissionID 
     */
    getDownloadUrl(submissionID: int) {
        return this.v2Api.get<string>(this.controller, "GetDownloadUrl", { submissionID });
    }
}