import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-updates',
  templateUrl: './no-updates.component.html',
  styleUrls: ['./no-updates.component.scss'],
})
export class NoUpdatesComponent {
  @Input() title: string = 'updates';
}
