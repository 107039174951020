import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedService {
  isAuthenticated$ = new BehaviorSubject<boolean>(false);

  constructor() {
  }
}
