import { createAction, props } from '@ngrx/store';
import { dtoContactPointCreateUpdate } from 'src/app/core/services/api/models/legacy/dtoContactPointCreateUpdate';
import { dtoUserCreate } from 'src/app/core/services/api/models/legacy/dtoUserCreate';

export enum CreateAccountActionTypes {
  GetUserInfo = '[Create Account] Get User',
  SaveUserInfo = '[Create Account] Persist User',
  ClearState = '[Create Account] Clear State',
  AddContactPoint = '[Create Account] Add Contact Point',
  GetContactPoints = '[Create Account] Get Contact Points',
  SaveSelectedSchools = '[Create Account] Save Selected Schools',
  GetSelectedSchools = '[Create Account] Get Selected Schools',
  SaveSelectedCalendars ='[Create Account] Save Selected Calendars',
}

export const getUserInfo = createAction(CreateAccountActionTypes.GetUserInfo, props<{ payload: dtoUserCreate }>());
export const saveUserInfo = createAction(
  CreateAccountActionTypes.SaveUserInfo,
  props<{ payload: dtoUserCreate }>()
);

export const clearState = createAction(CreateAccountActionTypes.ClearState);
export const getContactPoints = createAction(
  CreateAccountActionTypes.GetUserInfo,
  props<{ payload: dtoContactPointCreateUpdate[] }>()
);

export const addContactPoint = createAction(
  CreateAccountActionTypes.AddContactPoint,
  props<{ payload: dtoContactPointCreateUpdate }>()
);

export const saveSelectedSchools = createAction(
    CreateAccountActionTypes.SaveSelectedSchools,
    props<{ payload: string[] }>()
);

export const getSelectedSchools = createAction(
  CreateAccountActionTypes.GetSelectedSchools, props<{ payload: string[] }>()
);

export const saveSelectedCalendars = createAction(
  CreateAccountActionTypes.SaveSelectedCalendars,
  props<{ payload: string[] }>()
);
