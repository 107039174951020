<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Register Account</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="register-account-form" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter your first name..."
          autocapitalize="on"
          type="text"
          [(ngModel)]="customerData.FirstName">
          <div slot="label">First name <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          autocapitalize="on"
          placeholder="Enter your last name..."
          type="text"
          [(ngModel)]="customerData.LastName">
          <div slot="label">Last name <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter your email..."
          type="text"
          [(ngModel)]="customerData.Email">
          <div slot="label">Email <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="saveAccount(customerData)"
          expand="block">
          <ion-text> Save </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
