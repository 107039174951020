import { NGXLogger } from 'ngx-logger';
import { ErrorService } from '../../error/error.service';
import { EventlinkHttpResponse } from './EventlinkHttpResponse';
import { Router } from '@angular/router';
import { ContextRepository } from 'src/app/core/repositories/context.repository';
import { IsAuthenticatedService } from '../../auth/is-authenticated/is-authenticated.service';

/**
 * Shared API handling
 */
export abstract class EventlinkApiServiceBase {
  constructor(
    protected contextRepository: ContextRepository,
    protected errorService: ErrorService,
    protected logger: NGXLogger,
    protected router: Router,
    protected isAuthenticatedService: IsAuthenticatedService
  ) {}

  protected formatControllerAction(controller: string, action: string, version: 'legacy' | 'v2'): string {
    if (version === 'legacy') {
      return `?m=${controller}&a=${action}`;
    }
    if (version == 'v2') {
      return `/api/${controller}/${action}`;
    }

    throw new Error('Unhandled version: ' + version);
  }

  /**
   * Handle errors, including redirecting to login page for unauthenticated errors.
   */
  protected async tryHandleErrors(
    response: EventlinkHttpResponse<any>,
    controller: string,
    action: string,
    version: 'legacy' | 'v2'
  ) {
    this.logger.info(
      `API ${this.formatControllerAction(controller, action, version)} Error ${response.statusCode}: ${
        response.errorMessage ?? 'no error message'
      }`
    );

    if (
      response.isUserOrValidationError ||
      response.isInternalError ||
      response.isUnavailableError ||
      response.isTimeoutError ||
      response.isAccessError
    ) {
      if (response.errorMessage) {
        this.errorService.showError(response.errorMessage);
      } else {
        this.errorService.showError('An unknown error has occurred.');
      }
    } else if (response.isClientError) {
      this.errorService.showError('A network or temporary error has occurred. Please try again later.');
    } else if (response.isConflictError || response.isIdempotencyError || response.isNotFound) {
      // These should be handled downstream since the handling depends on why it was called
    } else if (response.isAuthError) {
      this.errorService.showError('Your session has expired. Please login again.');

      this.isAuthenticatedService.isAuthenticated$.next(false);

      await this.contextRepository.deleteContext();
      await this.contextRepository.deleteContextOganizations();
      await this.contextRepository.deleteTransactionHistory();
      await this.contextRepository.deleteScannedQRCodes();
      await this.contextRepository.deleteKiosk();
      await this.contextRepository.deleteLogEntries();

      this.router.navigate(['/login']);
    }
  }
}

/**
 * Not strictly necessary but I've refactored the HTTP request stuff enough that I'm willing to abstract it now.
 */
export class EventlinkRequest {
  constructor(
    public method: EventlinkAllowedMethods,
    public url: string,
    public queryParams: EventlinkQueryParams,
    public headers: EventlinkHeaders,
    public body: any | undefined
  ) {
    // TODO: Need to figure this out. If we use a custom header then CORS policy needs to allow it (at least for web to work).
    // If possible I'd like to just override user agent but definitely can't do that on web.
    //headers["X-EL-VERSION"] = "1.0.0-temp";
  }

  urlWithQuery(): string {
    // We are doing this because urlSearchParams constructor comma separates array rather than splitting them up
    let searchParams = new URLSearchParams();
    for (let param in this.queryParams) {
      let paramValue = this.queryParams[param];
      if (Array.isArray(paramValue)) {
        for (let value of paramValue) {
          searchParams.append(param, value.toString());
        }
      }
      else {
        searchParams.append(param, paramValue.toString());
      }
    }

    return this.url + '?' + searchParams;
  }
}

export type EventlinkQueryParams = { [Key: string]: any };
export type EventlinkHeaders = { [Key: string]: any };
export type EventlinkAllowedMethods = 'GET' | 'POST';
