import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-contact-partner',
  templateUrl: './contact-partner.component.html',
  styleUrls: ['./contact-partner.component.scss'],
})
export class ContactPartnerComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  eventId: any;
  linkedId: any;
  inviter: any;
  inviterContactPoints?: any = new Array<any>();

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController
  ) {
    if (!this.eventId) {
      this.eventId = '';
    }

    if (!this.linkedId) {
      this.linkedId = false;
    }
  }
  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();

    if (this.inviter == null) return;

    this.inviterContactPoints = this.inviter['ContactPoints'].map((contactPoint: any) => {
      return {
        type: contactPoint['Title'],
        value: contactPoint['Value'],
        contactType: contactPoint['ContactType'],
      };
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  copyText(value: string) {
    navigator.clipboard.writeText(value);
  }
}
