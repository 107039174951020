// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoEventOfficialSlot } from "../models/legacy/dtoEventOfficialSlot";
import { dtoEventOfficialSlotWithPayment } from "../models/legacy/dtoEventOfficialSlotWithPayment";
import { dtoEventOfficialInvitation } from "../models/legacy/dtoEventOfficialInvitation";
import { dtoEventOfficialSlotAccept } from "../models/legacy/dtoEventOfficialSlotAccept";
import { dtoEventOfficialInvitationCreate } from "../models/legacy/dtoEventOfficialInvitationCreate";
import { dtoEventOfficialSlotsAndInvites } from "../models/legacy/dtoEventOfficialSlotsAndInvites";
import { dtoEventOfficialSlotCreateUpdate } from "../models/legacy/dtoEventOfficialSlotCreateUpdate";
import { dtoEventOfficialSlotMultipleEventSlots } from "../models/legacy/dtoEventOfficialSlotMultipleEventSlots";

@Injectable({providedIn: 'root'})
export class EventOfficialSlotLegacyApiService {
    private readonly module = "EventOfficialSlot";

    constructor(private legacyApi: LegacyApiService) { }

    /** Find Event Official Slot by ID, user must have Athletic Permissions
     * @param id Event Official Slot ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoEventOfficialSlot | undefined>(this.module, "Find", { id });
    }

    /** Retrieves all Event Official Slots associated with an event, user must have Athletic Permissions
     * @param id Event ID
     */
    getAllByEventID(id: Guid) {
        return this.legacyApi.get<dtoEventOfficialSlotWithPayment[] | undefined>(this.module, "GetAllByEventID", { id });
    }

    /** Retrieves all accepted Event Official Slots for an event, user must have Athletic Permissions
     * @param id Event ID
     */
    getAllAcceptedByEventID(id: Guid) {
        return this.legacyApi.get<dtoEventOfficialSlot[] | undefined>(this.module, "GetAllAcceptedByEventID", { id });
    }

    /** Retrieves all accepted Event Official Slots by a specific official
     * @param id Official ID
     */
    getAllAcceptedByOfficialID(id: Guid) {
        return this.legacyApi.get<dtoEventOfficialSlot[] | undefined>(this.module, "GetAllAcceptedByOfficialID", { id });
    }

    /** Finds an Official Invitation by ID, user must have Athletic Permissions
     * @param id Event Official Invitation ID
     */
    findInvite(id: Guid) {
        return this.legacyApi.get<dtoEventOfficialInvitation | undefined>(this.module, "FindInvite", { id });
    }

    /** Retrieves all Event Official Invites by event, user must either have Athletic Permissions or be a team assigner.
     * @param id Event ID
     */
    getAllInvitesByEventID(id: Guid) {
        return this.legacyApi.get<dtoEventOfficialInvitation[] | undefined>(this.module, "GetAllInvitesByEventID", { id });
    }

    /** Allows an Official to accept an invite, user must be an official. 
     * @param force Force Accept
     * @param body 
     */
    accept(force: boolean | undefined, body: dtoEventOfficialSlotAccept) {
        return this.legacyApi.post<dtoEventOfficialSlot[] | undefined>(this.module, "Accept", { force }, body);
    }

    /** This allows an official to decline an Event Official Invitation, user must be the official who received the invitation. Returns Conflict if event is linked to more then one event.
     * @param linked 
     * @param eventIDs string of Event IDs seperated by commas
     * @param token 
     * @param declineReason 
     */
    decline(linked: boolean | undefined, eventIDs: string, token: Guid | undefined, declineReason: string | undefined) {
        return this.legacyApi.post<void>(this.module, "Decline", { linked, eventIDs, token, declineReason });
    }

    /** Revokes an Event Official Slot from an official, user must have Athletic Permissions or be a team assigner.
     * @param slotID 
     */
    revoke(slotID: Guid) {
        return this.legacyApi.post<dtoEventOfficialSlot[] | undefined>(this.module, "Revoke", { slotID });
    }

    /** Force accepts an Event Official Slot for an official. Returns conflict when official is blocked. 
     * @param id Slot ID
     */
    forceAcceptBySlotID(id: Guid) {
        return this.legacyApi.post<dtoEventOfficialSlot[] | undefined>(this.module, "ForceAcceptBySlotID", { id });
    }

    /** Creates Event Official Invitations, user must have Athletic Permissions or be a  team assigner.
     * @param force 
     * @param checkConflicts 
     * @param body 
     */
    createInvitations(force: boolean | undefined, checkConflicts: boolean | undefined, body: dtoEventOfficialInvitationCreate) {
        return this.legacyApi.post<dtoEventOfficialSlotsAndInvites | undefined>(this.module, "CreateInvitations", { force, checkConflicts }, body);
    }

    /** Cancels Event Official Invitations, user must have Athletic Permissions or be a team assigner.
     * @param body 
     */
    cancelInvitations(body: Guid[]) {
        return this.legacyApi.post<dtoEventOfficialInvitation[] | undefined>(this.module, "CancelInvitations", {  }, body);
    }

    /** Creates a new Event Official Slot, user must have Athletic Permissions or be a team assigner.
     * @param id Event ID
     */
    create(id: Guid) {
        return this.legacyApi.post<dtoEventOfficialSlot[] | undefined>(this.module, "Create", { id });
    }

    /** Updates an Event Official Slot, user must have either Athletic Permissions or be a team assigner.
     * @param body 
     */
    update(body: dtoEventOfficialSlotCreateUpdate) {
        return this.legacyApi.post<dtoEventOfficialSlot[] | undefined>(this.module, "Update", {  }, body);
    }

    /** Update multiple Event Official Slots at once and sends out invite emails for those slots, user must have Athletic Permissions or be a team assigner.
     * @param force 
     * @param body 
     */
    updateMultipleEventSlots(force: boolean | undefined, body: dtoEventOfficialSlotMultipleEventSlots) {
        return this.legacyApi.post<dtoEventOfficialSlotsAndInvites[] | undefined>(this.module, "UpdateMultipleEventSlots", { force }, body);
    }

    /** Deletes an Event Official Slot ID, user must have Athletic Permissions or be a team assigner.
     * @param id Event Official Slot ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** Updates the status of Event Official Invitations to Notified
     * @param invitationIDs String of invitationIDs seperated by commas
     */
    setNotifiedStatus(invitationIDs: string | undefined) {
        return this.legacyApi.get<Blob | undefined>(this.module, "SetNotifiedStatus", { invitationIDs });
    }
}