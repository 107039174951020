import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  CapacitorSQLite,
  SQLiteConnection,
  SQLiteDBConnection,
  CapacitorSQLitePlugin,
  capSQLiteUpgradeOptions,
  capNCDatabasePathResult,
  capSQLiteChanges,
  capSQLiteResult,
  capSQLiteValues,
} from '@capacitor-community/sqlite';
import { GlobalErrorHandlerService } from '../global-error-handler/global-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SQLiteService {
  sqlite!: SQLiteConnection;
  isService: boolean = false;
  platform!: string;
  sqlitePlugin!: CapacitorSQLitePlugin;
  native: boolean = false;

  constructor(private globalErrorHandlerService: GlobalErrorHandlerService) {}
  async initializePlugin(): Promise<boolean> {
    this.platform = Capacitor.getPlatform();
    if (this.platform === 'ios' || this.platform === 'android') {
      this.native = true;
    }
    this.sqlitePlugin = CapacitorSQLite;
    this.sqlite = new SQLiteConnection(this.sqlitePlugin);
    this.isService = true;
    return true;
  }

  async isSecretStored(): Promise<capSQLiteResult> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.isSecretStored();
  }

  async setEncryptionSecret(passphrase: string): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.setEncryptionSecret(passphrase);
  }

  async changeEncryptionSecret(passphrase: string, oldPassphrase: string): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.changeEncryptionSecret(passphrase, oldPassphrase);
  }

  async addUpgradeStatement(options: capSQLiteUpgradeOptions): Promise<void> {
    this.sqlitePlugin.addUpgradeStatement(options);
  }

  async getNCDatabasePath(folderPath: string, database: string): Promise<capNCDatabasePathResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.getNCDatabasePath(folderPath, database);
  }

  async createNCConnection(databasePath: string, version: number): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();
    const db: SQLiteDBConnection = await this.sqlite.createNCConnection(databasePath, version);
    if (db == null) {
      this.globalErrorHandlerService.handleError(
        new Error(`SQLiteService - createNCConnection(): No db returned is null`)
      );
      throw new Error();
    }
    return db;
  }

  async closeNCConnection(databasePath: string): Promise<void> {
    this.ensureConnectionIsOpen();
    return this.sqlite.closeNCConnection(databasePath);
  }

  async isNCConnection(databasePath: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isNCConnection(databasePath);
  }

  async retrieveNCConnection(databasePath: string): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();
    return this.sqlite.retrieveNCConnection(databasePath);
  }

  async isNCDatabase(databasePath: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isNCDatabase(databasePath);
  }

  async createConnection(
    database: string,
    encrypted: boolean,
    mode: string,
    version: number
  ): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();
    const db: SQLiteDBConnection = await this.sqlite.createConnection(database, encrypted, mode, version, false);

    if (db == null) {
      this.globalErrorHandlerService.handleError(
        new Error(`SQLiteService - createConnection(): No db returned is null`)
      );
      throw new Error();
    }

    return db;
  }

  async closeConnection(database: string): Promise<void> {
    this.ensureConnectionIsOpen();
    return this.sqlite.closeConnection(database, false);
  }

  async retrieveConnection(database: string): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();
    return this.sqlite.retrieveConnection(database, false);
  }

  async retrieveAllConnections(): Promise<Map<string, SQLiteDBConnection>> {
    this.ensureConnectionIsOpen();
    return this.sqlite.retrieveAllConnections();
  }

  async closeAllConnections(): Promise<void> {
    this.ensureConnectionIsOpen();
    return this.sqlite.closeAllConnections();
  }

  async isConnection(database: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isConnection(database, false);
  }

  async checkConnectionsConsistency(): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.checkConnectionsConsistency();
  }

  async isDatabase(database: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isDatabase(database);
  }

  async getDatabaseList(): Promise<capSQLiteValues> {
    this.ensureConnectionIsOpen();
    return this.sqlite.getDatabaseList();
  }

  async getMigratableDbList(folderPath?: string): Promise<capSQLiteValues> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();

    if (!folderPath || folderPath.length === 0) {
      this.globalErrorHandlerService.handleError(
        new Error(`SQLiteService - getMigratableDbList(): You must provide a folder path`)
      );
      throw new Error();
    }

    return this.sqlite.getMigratableDbList(folderPath);
  }

  async addSQLiteSuffix(folderPath?: string, dbNameList?: string[]): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    const path: string = folderPath ?? 'default';
    const dbList: string[] = dbNameList || [];
    return this.sqlite.addSQLiteSuffix(path, dbList);
  }

  async deleteOldDatabases(folderPath?: string, dbNameList?: string[]): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    const path: string = folderPath ?? 'default';
    const dbList: string[] = dbNameList || [];
    return this.sqlite.deleteOldDatabases(path, dbList);
  }

  async importFromJson(jsonstring: string): Promise<capSQLiteChanges> {
    this.ensureConnectionIsOpen();
    return this.sqlite.importFromJson(jsonstring);
  }

  async isJsonValid(jsonString: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isJsonValid(jsonString);
  }

  async copyFromAssets(overwrite?: boolean): Promise<void> {
    const mOverwrite: boolean = overwrite ?? true;
    this.ensureConnectionIsOpen();
    return this.sqlite.copyFromAssets(mOverwrite);
  }

  async initWebStore(): Promise<void> {
    this.ensureIsWebPlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.initWebStore();
  }

  async saveToStore(database: string): Promise<void> {
    this.ensureIsWebPlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.saveToStore(database);
  }

  async openDatabase(
    dbName: string,
    encrypted: boolean,
    mode: string,
    version: number
  ): Promise<SQLiteDBConnection> {
    let db: SQLiteDBConnection;
    const retCC = (await this.checkConnectionsConsistency()).result;
    let isConn = (await this.isConnection(dbName)).result;

    if (retCC && isConn) {
      db = await this.retrieveConnection(dbName);
    } else {
      db = await this.createConnection(dbName, encrypted, mode, version);
    }

    await db.open();

    return db;
  }

  async updateDatabase(db: SQLiteDBConnection) {
    try {
      // Define tables and their columns with default values
      const tables = [
        {
          name: 'Context',
          columns: [
            { name: 'ID', type: 'INTEGER', defaultValue: "''" },
            { name: 'ApplicationType', type: 'TEXT', defaultValue: "''" },
            { name: 'Community', type: 'BOOLEAN', defaultValue: '0' },
            { name: 'Staff', type: 'BOOLEAN', defaultValue: '0' },
            { name: 'Official', type: 'BOOLEAN', defaultValue: '0' },
            { name: 'UserToken', type: 'TEXT', defaultValue: "''" },
            { name: 'AccessToken', type: 'TEXT', defaultValue: "''" },
            { name: 'TokenType', type: 'TEXT', defaultValue: "''" },
            { name: 'RefreshExpiresIn', type: 'INTEGER', defaultValue: '1' },
            { name: 'RefreshToken', type: 'TEXT', defaultValue: "''" },
            { name: 'Username', type: 'TEXT', defaultValue: "''" },
            { name: 'FirstName', type: 'TEXT', defaultValue: "''" },
            { name: 'LastName', type: 'TEXT', defaultValue: "''" },
            { name: 'UserID', type: 'TEXT', defaultValue: "''" },
            { name: 'TimeZone', type: 'TEXT', defaultValue: "''" },
            { name: 'DeviceTicket', type: 'TEXT', defaultValue: "''" },
            { name: 'DefaultApplication', type: 'TEXT', defaultValue: "''" },
            { name: 'OrganizationId', type: 'INTEGER', defaultValue: '0' },
          ],
        },
        {
          name: 'ContextOrganizations',
          columns: [
            { name: 'ID', type: 'INTEGER', defaultValue: "''" },
            { name: 'OrganizationId', type: 'TEXT', defaultValue: "''" },
            { name: 'IsStaffOrganization', type: 'BOOLEAN', defaultValue: '0' },
            { name: 'OrganizationTimezone', type: 'TEXT', defaultValue: "''" },
            { name: 'OrganizationTitle', type: 'TEXT', defaultValue: "''" },
            { name: 'OrganizationDisplayTitle', type: 'TEXT', defaultValue: "''" },
          ],
        },
        {
          name: 'TransactionHistory',
          columns: [
            { name: 'ID', type: 'INTEGER', defaultValue: "''" },
            { name: 'Date', type: 'TEXT', defaultValue: "''" },
            { name: 'Time', type: 'BOOLEAN', defaultValue: '0' },
            { name: 'Title', type: 'TEXT', defaultValue: "''" },
            { name: 'TicketQuantity', type: 'INTEGER', defaultValue: '0' },
            { name: 'EventTitle', type: 'TEXT', defaultValue: "''" },
            { name: 'Total', type: 'TEXT', defaultValue: "''" },
            { name: 'IsPhoneNumberAccepted', type: 'BOOLEAN', defaultValue: '0' },
            { name: 'PaymentIntentID', type: 'TEXT', defaultValue: "''" },
            { name: 'TicketConfigurationID', type: 'INTEGER', defaultValue: '1' },
          ],
        },
        {
          name: 'ScannedQRCodes',
          columns: [
            { name: 'ID', type: 'INTEGER', defaultValue: "''" },
            { name: 'QrCodeValue', type: 'TEXT', defaultValue: "''" },
            { name: 'ScannedAt', type: 'TEXT', defaultValue: "''" },
            { name: 'TicketConfigurationID', type: 'TEXT', defaultValue: "''" },
          ],
        },
        {
          name: 'Kiosk',
          columns: [
            { name: 'ID', type: 'INTEGER', defaultValue: "''" },
            { name: 'KioskSessionID', type: 'INTEGER', defaultValue: '0' },
            { name: 'SelectedKioskID', type: 'INTEGER', defaultValue: '0' },
            { name: 'CashBoxAmount', type: 'INTEGER', defaultValue: '0' },
          ],
        },
        {
          name: 'Logs',
          columns: [
            { name: 'ID', type: 'INTEGER', defaultValue: "''" },
            { name: 'DateTime', type: 'TEXT', defaultValue: "''" },
            { name: 'Message', type: 'TEXT', defaultValue: "''" },
            { name: 'Method', type: 'TEXT', defaultValue: "''" },
            { name: 'File', type: 'TEXT', defaultValue: "''" },
            { name: 'UserID', type: 'TEXT', defaultValue: "''" },
          ],
        },
        {
          name: 'PersistentContext',
          columns: [
            { name: 'ID', type: 'INTEGER', defaultValue: "''" },
            { name: 'DeviceTicket', type: 'TEXT', defaultValue: "''" },
            { name: 'UpdateVersionCode', type: 'TEXT', defaultValue: "''" },
          ],
        },
      ];

      // Iterate through tables and create missing ones
      for (const table of tables) {
        // Create table if it doesn't exist
        let createTableSQL = `CREATE TABLE IF NOT EXISTS ${table.name} (`;
        createTableSQL += table.columns
          .map((col) => {
            if (col.name === 'ID') return `${col.name} ${col.type} PRIMARY KEY NOT NULL`;

            return `${col.name} ${col.type} DEFAULT ${col.defaultValue}`;
          })
          .join(', ');
        createTableSQL += ');';

        await db.execute(createTableSQL);

        // Check for missing columns and add them
        for (const column of table.columns) {
          const checkColumnSQL = `PRAGMA table_info(${table.name});`;
          const res = await db.query(checkColumnSQL);

          const columnExists = res.values?.some((row) => row.name === column.name);
          if (!columnExists) {
            const alterTableSQL = `ALTER TABLE ${table.name} ADD COLUMN ${column.name} ${column.type} DEFAULT ${column.defaultValue};`;
            await db.execute(alterTableSQL);
          }
        }
      }
    } catch (error) {
      this.globalErrorHandlerService.handleError(
        new Error('SQLiteService - updateDatabase(): Error with updating database | ' + JSON.stringify(error))
      );
    }
  }

  private ensureConnectionIsOpen() {
    if (this.sqlite == null) {
      this.globalErrorHandlerService.handleError(
        new Error(`SQLiteService - ensureConnectionIsOpen(): No connection open`)
      );
      throw new Error();
    }
  }

  private ensureIsNativePlatform() {
    if (!this.native) {
      this.globalErrorHandlerService.handleError(
        new Error(`SQLiteService - ensureIsNativePlatform(): Not implemented for ${this.platform} platform`)
      );
      throw new Error();
    }
  }

  private ensureIsWebPlatform() {
    if (this.platform !== 'web') {
      this.globalErrorHandlerService.handleError(
        new Error(`SQLiteService - ensureIsWebPlatform(): Not implemented for ${this.platform} platform`)
      );
      throw new Error();
    }
  }
}
