import { Component, OnInit } from '@angular/core';
import { MenuController, AlertController, ModalController } from '@ionic/angular';
import IContextModel from 'src/app/core/models/context/context.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UploadLogsService } from 'src/app/core/services/upload-logs/upload-logs.service';
import IContextOrganizationModel from '../../../../../core/models/context/context-organization.models';
import { Router } from '@angular/router';
import { ApplicationTypeService } from '../../../../../core/services/auth/application-type/application-type.service';
import { BehaviorSubject } from 'rxjs';
import { IsAuthenticatedService } from '../../../../../core/services/auth/is-authenticated/is-authenticated.service';
import { FeatureFlagApiService } from '../../../../../core/services/api/v2/FeatureFlagApi.service';
import { KiosksService } from '../../../../../core/services/kiosks/kiosks.service';
import { KioskAccessApiService } from 'src/app/core/services/api/v2/KioskAccessApi.service';
import { ChangePasswordComponent } from '../../../modal/change-password/change-password.component';

@Component({
  selector: 'app-staff-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  context!: IContextModel;
  filteredOrganizations!: IContextOrganizationModel[];
  organizations!: IContextOrganizationModel[];
  staffOrganizations!: IContextOrganizationModel[];
  communityOrganizations!: IContextOrganizationModel[];
  expanded: boolean = false;
  userHasKioskAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showRefresh: boolean = true;
  manageNotificationFeatureFlag: boolean = false;

  constructor(
    private readonly menu: MenuController,
    private readonly authService: AuthService,
    private readonly uploadLogsService: UploadLogsService,
    private readonly router: Router,
    protected readonly applicationTypeService: ApplicationTypeService,
    private readonly kioskAccessService: KioskAccessApiService,
    private readonly isAuthenticatedService: IsAuthenticatedService,
    private readonly featureFlagApiService: FeatureFlagApiService,
    private readonly kioskService: KiosksService,
    private readonly alertController: AlertController,
    private readonly modalCtrl: ModalController
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();
    await this.refresh();
    this.organizations = (await this.authService.getContextOrganizations()).sort((a, b) =>
      a.OrganizationTitle!.localeCompare(b.OrganizationTitle!)
    );
    const organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);
    let organizationName = organization ? organization.OrganizationTitle! : 'No Organization';
    this.applicationTypeService.organizationName$.next(organizationName);
    if (organization) {
      this.communityOrganizations = this.organizations;
      this.staffOrganizations = this.organizations.filter((org) => org.IsStaffOrganization);
      const enabledFeatureFlagResponse = await this.featureFlagApiService.getEnabled(organization.OrganizationId);
      if (enabledFeatureFlagResponse.isSuccess) {
        const enabledFeatureFlags = enabledFeatureFlagResponse.data;
        this.manageNotificationFeatureFlag = enabledFeatureFlags!.FeatureFlags!['manage-notifications'];
      }
    }
  }

  async uploadLogs() {
    const alert = await this.alertController.create({
      header: 'Upload Logs',
      message: 'Did Eventlink support ask you to upload these logs?',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            await this.uploadLogsService.uploadLogs();
            await this.menu.close();
          },
        },
        {
          text: 'No',
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  async accountSelection(accountSelection: string) {
    if (
      accountSelection === this.applicationTypeService.applicationType$.getValue() &&
      this.organizations?.length < 1
    ) {
      return;
    }

    this.applicationTypeService.applicationType$.next(accountSelection);

    this.filteredOrganizations =
      accountSelection === 'Staff'
        ? this.organizations.filter((org) => org.IsStaffOrganization)
        : this.organizations;

    if (accountSelection === 'Officials') {
      await this.completeContextChange(accountSelection);
      return;
    }

    if (!this.filteredOrganizations || this.filteredOrganizations.length < 1) {
      await this.completeContextChange(accountSelection);
    }
  }

  async setActiveOrganization(applicationType: string, organization?: IContextOrganizationModel) {
    if (applicationType === 'Officials') {
      await this.completeContextChange(applicationType);
      this.applicationTypeService.applicationType$.next(this.context.ApplicationType!);
      return;
    }
    this.applicationTypeService.applicationType$.next(applicationType);
    if (organization) {
      await this.completeContextChange(this.applicationTypeService.applicationType$.getValue(), organization);
    } else {
      await this.completeContextChange(this.applicationTypeService.applicationType$.getValue());
    }
    this.applicationTypeService.applicationType$.next(this.context.ApplicationType!);
  }

  async completeContextChange(applicationType: string, organization?: IContextOrganizationModel) {
    this.context.ApplicationType = applicationType;
    this.applicationTypeService.applicationType$.next(applicationType);
    if (organization) {
      this.context.OrganizationId = organization.ID;
      this.applicationTypeService.organizationName$.next(organization.OrganizationTitle!);
    }
    await this.authService.updateContext(this.context);
    this.router.navigate(['/']);
    this.applicationTypeService.applicationType$.next(this.context.ApplicationType!);
    await this.menu.close();
  }

  async navigateTo(flag: string) {
    this.expanded = false;
    this.router.navigate([flag]);
    await this.menu.close();
  }

  async openMenu() {
    this.expanded = true;
    await this.menu.open();
  }

  logout() {
    this.authService.deleteContext();
    this.isAuthenticatedService.isAuthenticated$.next(false);
    this.router.navigate(['/login']);
  }

  async refresh() {
    if (this.userHasKioskAccess.getValue()) {
      return;
    }

    const response = await this.kioskAccessService.getForUser();
    if (response.isAnyError) {
      return;
    }

    if (response.data!.length < 1) {
      this.userHasKioskAccess.next(false);
      return;
    }

    this.showRefresh = false;

    this.kioskService.KiosksOrganizationID$.next(response.data![0].OrganizationID);
    this.userHasKioskAccess.next(true);
  }
  async kiosk() {
    await this.router.navigate(['/kiosk']);
    this.isAuthenticatedService.isAuthenticated$.next(false);
  }

  async openChangePassword() {
    const modal = await this.modalCtrl.create({
      component: ChangePasswordComponent,
      componentProps: {},
      breakpoints: [0.8],
      initialBreakpoint: 0.8,
    });
    modal.present();
  }
}
