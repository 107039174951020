// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid, DateTime, int } from "../models/ApiTypes";
import { dtoHallPassShortForm } from "../models/legacy/dtoHallPassShortForm";
import { dtoHallPassCreate } from "../models/legacy/dtoHallPassCreate";

@Injectable({providedIn: 'root'})
export class HallPassLegacyApiService {
    private readonly module = "HallPass";

    constructor(private legacyApi: LegacyApiService) { }

    /** Gets all Hall Passes within an Organization, users organization must have SubscriptionTypes.Everything or the user must be an admin. User must also have Hall Pass Permissions.
     * @param id ID for Organization ID
     * @param name 
     * @param destinationID 
     * @param destination 
     * @param start 
     * @param end 
     * @param tz 
     * @param passHolderUserID 
     * @param limit 
     */
    getAllByOrganizationID(id: Guid, name: string | undefined, destinationID: Guid | undefined, destination: string | undefined, start: DateTime | undefined, end: DateTime | undefined, tz: DateTime | undefined, passHolderUserID: Guid | undefined, limit: int | undefined) {
        return this.legacyApi.get<dtoHallPassShortForm[] | undefined>(this.module, "GetAllByOrganizationID", { id, name, destinationID, destination, start, end, tz, passHolderUserID, limit });
    }

    /** Creates a new Hall Pass given an organization, users organization must have SubscriptionTypes.Everything or the user must be an admin. User must also have Hall Pass Permissions.
     * @param orgID ID for Organization ID
     * @param force Force a double book if there is already an active hall pass
     * @param body 
     */
    create(orgID: Guid, force: boolean, body: dtoHallPassCreate) {
        return this.legacyApi.post<dtoHallPassShortForm | undefined>(this.module, "Create", { orgID, force }, body);
    }

    /** Checkouts a Hall Pass, users organization must have SubscriptionTypes.Everything or the user must be an admin. User must also have Hall Pass Permissions.
     * @param id ID for Hall Pass
     */
    checkOut(id: Guid) {
        return this.legacyApi.post<void>(this.module, "CheckOut", { id });
    }

    /** Checks-In a Hall Pass, users organization must have SubscriptionTypes.Everything or the user must be an admin. User must also have Hall Pass Permissions.
     * @param id ID for Hall Pass
     */
    checkIn(id: Guid) {
        return this.legacyApi.post<void>(this.module, "CheckIn", { id });
    }

    /** Deletes a Hall Pass with a given ID, users organization must have SubscriptionTypes.Everything or the user must be an admin. User must also have Hall Pass Permissions.
     * @param id ID for Hall Pass
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }
}