/**
 * Base class that represents common response from Eventlink APIs
 */
export abstract class EventlinkHttpResponse<T, TConflict = void> {
    /**
     * The data returned from the API call. This can be undefined if the request did not complete successfully!
     */
    public data?: T;
    /**
     * The data returned from the API call, if the call failed with a 409 Conflict response.
     */
    public conflictData?: TConflict;
    /**
     * If the API call was not successful this will be the error message returned.
     */
    public errorMessage?: string;

    /**
     * Raw data parsed from response
     */
    protected rawData?: any;

    constructor(protected rawResponse: Response, rawData?: any, private clientError?: any) {
        this.rawData = rawData;

        if (this.isSuccess) {
            this.data = this.parseData<T>();
        }
        else {
            if (this.isConflictError) {
                // It's possible for conflict data AND an error message to get returned in legacy. V2 is one or the other.
                this.conflictData = this.parseData<TConflict>();
            }
            this.errorMessage = this.parseErrorMessage();
        }
    }

    /**
     * Parses data, only called if response was successful or conflict
     */
    protected abstract parseData<TData>(): TData | undefined;
    /**
     * Sets errorMessage, only called if response was error
     */
    protected abstract parseErrorMessage(): string | undefined;

    /**
     * Raw numeric status code for the response (e.g. 200 for OK, 204 for NoContent)
     */
    public get statusCode(): number {
        return this.rawResponse.status;
    }

    /**
     * Response indicates success (200 OK or 204 No Content)
     */
    public get isSuccess(): boolean {
        return this.statusCodeIs(200) || this.statusCodeIs(204);
    }

    /**
     * No response was received from server - client error happened
     */
    public get isClientError(): boolean {
        return this.statusCodeIs(-1);
    }

    /**
     * Response indicates any kind of error (shortcut for !isSuccess)
     */
    public get isAnyError(): boolean {
        return !this.isSuccess;
    }

    /**
     * Response indicates user needs to be logged in (401 Unauthorized)
     */
    public get isAuthError(): boolean {
        return this.statusCodeIs(401);
    }

    /**
     * Response indicates logged in user does not have the correct access level (e.g. endpoint needs CSR access and the user only has Staff access) (403 Forbidden)
     */
    public get isAccessError(): boolean {
        return this.statusCodeIs(403);
    }

    /**
     * Server error :( (500 Internal Server Error)
     */
    public get isInternalError(): boolean {
        return this.statusCodeIs(500);
    }

    /**
     * Server is temporarily unavailable (503 Service Unavailable)
     */
    public get isUnavailableError(): boolean {
        return this.statusCodeIs(503);
    }

    /**
     * Server responded too slowly (504 Gateway Timeout)
     */
    public get isTimeoutError(): boolean {
        return this.statusCodeIs(504);
    }

    /**
     * Response indicates a conflict, typically the intention is for the UI to display a prompt to resolve the conflict. (409 Conflict)
     */
    public get isConflictError(): boolean {
        return this.statusCodeIs(409)
    }

    /**
     * Response indicates a problem the user can solve on their own, e.g. validation error (400 Bad Request)
     */
    public get isUserOrValidationError(): boolean {
        return this.statusCodeIs(400)
    }

    /**
     * Response indicates another request with the same idempotency key has already been processed (423 Locked)
     */
    public get isIdempotencyError(): boolean {
        return this.statusCodeIs(423)
    }

    /**
     * For most routes this indicates a routing error (wrong controller or action), though a few routes use 404 to indicate the resource requested was not found. (404 Not Found)
     */
    public get isNotFound(): boolean {
        return this.statusCodeIs(404);
    }



    private statusCodeIs(value: number) {
        return this.statusCode === value;
    }
}