<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Tax Profile</ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="search()"
        expand="block"
        [disabled]="!formGroup.valid">
        <ion-text> Search </ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-top">

</ion-content>
