
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title style="text-align: center">Register Account</ion-title>
    <ion-textarea style="width: 25%;" slot="end"></ion-textarea>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="formGroup">
    <ion-text class="ion-padding-start text-start top-line"> Name<span class="required">*</span></ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-input formControlName="name"></ion-input>
    </ion-item>
    <ion-text class="ion-padding-start text-start top-line"> Type<span class="required">*</span></ion-text>
    <ion-select label-placement="stacked" formControlName="type" class="types" placeholder="Select">
      <ion-select-option value="checking">
        Checking
      </ion-select-option>
      <ion-select-option value="savings">
        Savings
      </ion-select-option>
    </ion-select>
    <ion-text class="ion-padding-start text-start top-line"> Routing Number<span class="required">*</span></ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-input formControlName="routingNumber"></ion-input>
    </ion-item>
    <ion-text class="ion-padding-start text-start top-line"> Account Number<span class="required">*</span></ion-text>
    <ion-item class="show-inputs background-transparent p3-padding ion-padding-bottom">
      <ion-input formControlName="accountNumber"></ion-input>
    </ion-item>
  </form>
  <ion-item style="--background: rgb(0, 0, 0, 0);">Your account must be verified before you can send or receive payments. We will send the listed account 2 small deposits. Once you receive the deposits you will need to return to Eventlink to verify the deposit amounts.</ion-item>
  <ion-buttons class="ion-padding">
    <ion-button
      class="terms-button"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="saveAccount()"
      expand="block"
      [disabled]="!formGroup.valid">
      <ion-text> Accept </ion-text>
    </ion-button>
  </ion-buttons>
</ion-content>
