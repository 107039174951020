// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoEventResult } from "../models/legacy/dtoEventResult";
import { dtoEventResultsLongForm } from "../models/legacy/dtoEventResultsLongForm";
import { EventResult } from "../models/legacy/EventResult";

@Injectable({providedIn: 'root'})
export class EventResultLegacyApiService {
    private readonly module = "EventResult";

    constructor(private legacyApi: LegacyApiService) { }

    /** Finds an Event Result by ID, user must have Athletic Permissions
     * @param id Event Result ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoEventResult | undefined>(this.module, "Find", { id });
    }

    /** Gets all Event Results by an Event ID, user must either have Athletic Permissions or be a coach who is allowed to add results and is a coach for the correct team
     * @param id Event ID
     */
    getAllByEventID(id: Guid) {
        return this.legacyApi.get<dtoEventResultsLongForm | undefined>(this.module, "GetAllByEventID", { id });
    }

    /** Saves Event Results, user must have Athletic Permissions or be a coach who is allowed to add results and is a coach for the correct team
     * @param id Event ID
     * @param body 
     */
    save(id: Guid, body: EventResult[]) {
        return this.legacyApi.post<void>(this.module, "Save", { id }, body);
    }
}