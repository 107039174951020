import { Component, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { ErrorService } from './core/services/error/error.service';
import { SuccessService } from './core/services/success/success.service';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Network } from '@capacitor/network';
import { NGXLogger } from 'ngx-logger';
import { NgxLoggerMonitor } from './core/ngx/ngx-logger-monitor';
import { Capacitor } from '@capacitor/core';
import { ApplicationTypeService } from './core/services/auth/application-type/application-type.service';
import { IsAuthenticatedService } from './core/services/auth/is-authenticated/is-authenticated.service';
import { TicketTakersApiService } from './core/services/api/v2/TicketTakersApi.service';
import { DtoTicketTakerScan } from './core/services/api/models/DtoTicketTakerScan';
import { ITicketTakerOfflineScan } from './core/models/ticket-takers/ticket-taker-offline-scan.model';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated$ = this.isAuthenticatedService.isAuthenticated$;
  applicationType$ = this.applicationTypeService.applicationType$;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private successService: SuccessService,
    private viewContainerRef: ViewContainerRef,
    private ticketTakersService: TicketTakersApiService,
    private ngZone: NgZone,
    private logger: NGXLogger,
    protected applicationTypeService: ApplicationTypeService,
    private isAuthenticatedService: IsAuthenticatedService
  ) {
    this.errorService.vcRef = this.viewContainerRef;
    this.successService.vcRef = this.viewContainerRef;

    this.logger.registerMonitor(new NgxLoggerMonitor());
  }

  async ngOnInit() {
    await this.purgeLogsCheck();

    try {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    } catch (error) {
      this.logger.info('Error setting screen orientation.', error);
    }

    await Network.addListener('networkStatusChange', async (status) => {
      await this.ngZone.run(async () => {
        const scannedQRCodes = await this.authService.getScannedQRCodes();

        if (scannedQRCodes.length < 1) return;

        if (!status.connected) return;

        const ticketConfigurationIDs = [...new Set(scannedQRCodes.map((code) => code.TicketConfigurationID))];

        ticketConfigurationIDs.forEach(async (ticketConfigurationID) => {
          let filteredScannedQRCodes: ITicketTakerOfflineScan[] = scannedQRCodes.filter(
            (code) => code.TicketConfigurationID === ticketConfigurationID
          );

          let body: DtoTicketTakerScan[] = filteredScannedQRCodes.map((code) => {
            return { QrCodeValue: code.QrCodeValue, ScannedAt: new Date(code.ScannedAt!).toUTCString() };
          });

          const response = await this.ticketTakersService.submitScan(parseInt(ticketConfigurationID), body);
          if (response.isAnyError) {
            return;
          }
        });

        await this.authService.deleteScannedQRCodes();
      });
    });
  }

  async purgeLogsCheck() {
    const logTableRowCount = await this.authService.getTableRowCount('Logs');

    if (logTableRowCount > 10000) {
      this.logger.info('Purging logs');
      await this.authService.deleteLogs();
    }
  }
}
