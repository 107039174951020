import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, AlertController, ModalController, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import IContextModel from 'src/app/core/models/context/context.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UploadLogsService } from 'src/app/core/services/upload-logs/upload-logs.service';
import IContextOrganizationModel from '../../../../core/models/context/context-organization.models';
import { Router } from '@angular/router';
import { ApplicationTypeService } from '../../../../core/services/auth/application-type/application-type.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IsAuthenticatedService } from '../../../../core/services/auth/is-authenticated/is-authenticated.service';
import { FeatureFlagApiService } from '../../../../core/services/api/v2/FeatureFlagApi.service';
import { KiosksService } from '../../../../core/services/kiosks/kiosks.service';
import { KioskAccessApiService } from 'src/app/core/services/api/v2/KioskAccessApi.service';
import { ChangePasswordComponent } from '../../modal/change-password/change-password.component';
import { AppInfoService } from '../../../../core/services/app-info/app-info.service';
import { UserApiService } from 'src/app/core/services/api/v2/UserApi.service';
import { DtoUserAccessGridRead } from 'src/app/core/services/api/models/DtoUserAccessGridRead';
import { OrganizationLegacyApiService } from 'src/app/core/services/api/v1/OrganizationLegacyApi.service';
import { DistrictLegacyApiService } from 'src/app/core/services/api/v1/DistrictLegacyApi.service';
import {
  OrganizationSubscriptionTypes,
  OrganizationTypes,
} from 'src/app/core/services/api/models/legacy/legacy-enums';
import { CoachesApiService } from 'src/app/core/services/api/v2/CoachesApi.service';
import { DtoCoachShortForm } from 'src/app/core/services/api/models/DtoCoachShortForm';
import { OrganizationSiteConfigurationPublicApiService } from 'src/app/core/services/api/v2/OrganizationSiteConfigurationPublicApi.service';
import { environment } from '../../../../../environments/environment';
import { PayItemSpecialUsersApiService } from 'src/app/core/services/api/v2/PayItemSpecialUsersApi.service';
import { Store } from '@ngrx/store';
import { DtoEventShortForm } from '../../../../core/services/api/models/DtoEventShortForm';
import { KioskEventsActions } from '../../../../modules/kiosk/store/actions';
import { ManageSchoolsComponent } from '../../modal/manage-schools/manage-schools.component';
import { UserLegacyApiService } from '../../../../core/services/api/v1/UserLegacyApi.service';
import { EventBusService } from '../../../../core/services/bus/event-bus.service';

@Component({
  selector: 'app-staff-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy, ViewDidEnter, ViewDidLeave {
  context!: IContextModel;
  organizations!: IContextOrganizationModel[];
  staffOrganizations!: IContextOrganizationModel[];
  communityOrganizations!: IContextOrganizationModel[];
  expanded: boolean = false;
  userHasKioskAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showRefresh: boolean = true;
  manageNotificationFeatureFlag: boolean = false;
  version!: string;
  permissionsGrid?: DtoUserAccessGridRead;
  isDistrictManager: boolean = false;
  isOrganizationManager: boolean = false;
  subscriptionLevel: OrganizationSubscriptionTypes = 'Unknown';
  organizationType?: OrganizationTypes;
  userCoaches?: DtoCoachShortForm[] = []; // used for v1 coach
  hasOrganizationSites: boolean = false;
  hasUsersWithSpecialAccess: boolean = false;
  refreshing = false;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly menu: MenuController,
    private readonly authService: AuthService,
    private readonly uploadLogsService: UploadLogsService,
    private readonly router: Router,
    protected readonly applicationTypeService: ApplicationTypeService,
    private readonly kioskAccessService: KioskAccessApiService,
    private readonly isAuthenticatedService: IsAuthenticatedService,
    private readonly featureFlagApiService: FeatureFlagApiService,
    private readonly kioskService: KiosksService,
    private readonly alertController: AlertController,
    private readonly modalCtrl: ModalController,
    private readonly appInfoService: AppInfoService,
    private readonly userAccessGridService: UserApiService,
    private readonly organizationLegacyApiService: OrganizationLegacyApiService,
    private readonly districtLegacyApiService: DistrictLegacyApiService,
    private readonly coachesApiService: CoachesApiService,
    private readonly organizationSiteConfigurationPublicApiService: OrganizationSiteConfigurationPublicApiService,
    private readonly payItemSpecialUsersApiService: PayItemSpecialUsersApiService,
    private readonly store: Store<DtoEventShortForm[]>,
    private readonly userLegacyService: UserLegacyApiService,
    private readonly eventBusService: EventBusService
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      this.applicationTypeService.applicationTypeDidChange.subscribe((_) => this.completeContextChange())
    );
    this.subscriptions.push(
      this.eventBusService.subscribe((e) => {
        if (e.name == 'FooterOpenSubscriptions') {
          this.openManageSchools();
        }
      })
    );
    await this.refreshData();
  }

  async ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async ionViewDidEnter() {
    await this.refreshData();
  }

  async refreshData() {
    if (this.refreshing) return;
    this.refreshing = true;
    try {
      this.context = await this.authService.getContext();

      await this.refresh();

      this.organizations = (await this.authService.getContextOrganizations()).sort((a, b) =>
        a.OrganizationTitle!.localeCompare(b.OrganizationTitle!)
      );

      const organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

      let organizationName = organization ? organization.OrganizationTitle! : '';
      this.applicationTypeService.organizationName$.next(organizationName);

      if (organization) {
        this.communityOrganizations = this.organizations;
        this.staffOrganizations = this.organizations.filter((org) => org.IsStaffOrganization);
        const enabledFeatureFlagResponse = await this.featureFlagApiService.getEnabled(
          organization.OrganizationId
        );
        if (enabledFeatureFlagResponse.isSuccess) {
          const enabledFeatureFlags = enabledFeatureFlagResponse.data;
          this.manageNotificationFeatureFlag = enabledFeatureFlags!.FeatureFlags!['manage-notifications'];
        }

        //get permissions
        await this.getPermissions(organization.OrganizationId!);
      }

      this.version = this.appInfoService.getVersionDisplay();
      if (!this.version && !environment.production) {
        // On web it can't read a version so make one up
        this.version = '1.0.0 (web-dev)';
      }
    } finally {
      this.refreshing = false;
    }
  }

  async ionViewDidLeave() {
    this.isDistrictManager = false;
    this.isOrganizationManager = false;
    this.userCoaches = [];
    this.hasOrganizationSites = false;
    this.hasUsersWithSpecialAccess = false;
  }

  async getPermissions(organizationID: string) {
    // get user permissions
    await this.getUserSubscriptionAndManagement(organizationID);
    if (this.applicationTypeService.applicationType$.getValue() === 'Staff') {
      await this.getUserAccessGrid(organizationID);
    }
    // get user coaches
    await this.getUserCoaches(organizationID);

    // verify if organization has sites setup
    await this.getOrganizationSitesSetup(organizationID);

    // check if organization has users with special access for pay items (registrations)
    await this.checkUserSpecialAccess(organizationID);
  }

  async getUserAccessGrid(organizationID: string) {
    const permissionsResponse = await this.userAccessGridService.getUserAccessGrid(
      organizationID,
      this.context.UserID!
    );
    if (permissionsResponse.isAnyError) {
      return;
    }
    this.permissionsGrid = permissionsResponse.data;
  }

  async getUserSubscriptionAndManagement(organizationID: string) {
    let districtID = '';
    this.isOrganizationManager = false;
    this.subscriptionLevel = 'Unknown';
    this.isDistrictManager = false;
    this.organizationType = undefined;

    // check if user is organization manager
    const orgResponse = await this.organizationLegacyApiService.getAllByUserID(this.context.UserID!);
    if (orgResponse.isAnyError) {
      return;
    }

    let organizationWithManagers = orgResponse.data?.Organizations?.find((org) => org.ID == organizationID);

    if (organizationWithManagers) {
      districtID = organizationWithManagers.DistrictID ?? '';

      this.isOrganizationManager = organizationWithManagers.Managers
        ? organizationWithManagers.Managers.filter((manager) => manager.ID == this.context.UserID).length > 0
        : false;

      // get subscription level
      this.subscriptionLevel = organizationWithManagers.SubscriptionType!;
    }

    if (districtID) {
      // check if user is district manager
      const districtManagersResponse = await this.districtLegacyApiService.getManagers(districtID);
      if (districtManagersResponse.isAnyError) {
        return;
      }
      this.isDistrictManager = districtManagersResponse.data
        ? districtManagersResponse.data.filter((manager) => manager.ID == this.context.UserID).length > 0
        : false;

      //get organization Type
      const organizationsResponse = await this.organizationLegacyApiService.getByDistrictID(districtID);
      if (organizationsResponse.isAnyError) {
        return;
      }
      let organizationDetails = organizationsResponse.data?.find((org) => org.ID === organizationID);
      this.organizationType = organizationDetails ? organizationDetails?.OrganizationType : undefined;
    }
  }

  async getUserCoaches(organizationID: string) {
    const response = await this.coachesApiService.getCoachForCurrentUser(organizationID);
    if (response.isAnyError) {
      return;
    }
    this.userCoaches = response.data ?? [];
  }

  async getOrganizationSitesSetup(organizationID: string) {
    let baseUrl = await this.getV2SiteBaseUrl(organizationID, true);
    // check if organization has Sites setup
    if (baseUrl) {
      this.hasOrganizationSites = true;
    }
  }

  async getV2SiteBaseUrl(organizationID: string, silentlyFail: boolean) {
    // In the doc, there are 2 more parameters (slug and domain) but they are used for Sites only. Here we only use the organizationID param
    const response = await this.organizationSiteConfigurationPublicApiService.get(
      undefined,
      undefined,
      organizationID
    );
    if (response.isAnyError) {
      return;
    }

    let organizationSiteConfiguration = response.data;
    if (organizationSiteConfiguration) {
      if (organizationSiteConfiguration.Domain) {
        return 'https://' + organizationSiteConfiguration.Domain + '/';
      } else {
        return environment.ui.sitesV2 + 's/' + organizationSiteConfiguration.Slug + '/';
      }
    } else {
      if (!silentlyFail) {
        const alert = await this.alertController.create({
          header: 'Sites Error',
          message: 'Your public site has not been setup yet.',
          buttons: [{ text: 'OK' }],
        });

        await alert.present();

        return;
      }
      return '';
    }
  }

  async checkUserSpecialAccess(organizationID: string) {
    this.hasUsersWithSpecialAccess = false;
    const response =
      await this.payItemSpecialUsersApiService.getUsersSpecialAccessPayItemsByOrganization(organizationID);
    if (response.isAnyError) {
      return;
    }

    if (response.data?.length) {
      this.hasUsersWithSpecialAccess = true;
    }
  }

  hasPermission(permissionsName: string, specific: string, disableInherit: boolean) {
    let hasGridPerm: boolean = false;

    if (this.permissionsGrid && permissionsName && permissionsName !== 'Manager') {
      hasGridPerm = specific
        ? this.permissionsGrid[(permissionsName + 'Flags') as keyof DtoUserAccessGridRead]![specific as keyof {}]
        : !this.permissionsGrid[(permissionsName + 'Flags') as keyof DtoUserAccessGridRead]!['None' as keyof {}];
    }

    if (disableInherit) {
      return hasGridPerm;
    }

    return this.isDistrictManager || this.isOrganizationManager || hasGridPerm;
  }

  hasSubscriptionLevel(section: string) {
    return this.hasSubscription(this.subscriptionLevel, '/' + section);
  }

  hasSubscription(subscriptionType: OrganizationSubscriptionTypes, subscriptionSection: string) {
    let section = subscriptionSection.split('/')[1];
    let athleticsOnly = [
      '',
      'athletics',
      'bank-accounts',
      'settings',
      'facilities',
      'workers',
      'transportation',
      'users-and-permissions',
      'events',
      'registrationsV2-ao',
      'rewards',
      'store',
      'stores',
      'socials',
      'sites-start',
      'ao-sites',
      'tickets',
      'kiosks',
      'ao-athletes',
      'ao-messaging',
      'ao-hallpass',
      'ao-fundraising',
      'full-package',
    ];
    let everything = athleticsOnly.concat([
      'athletes',
      'messaging',
      'hallpass',
      'calendar-create',
      'payments',
      'registrations',
      'registrationsV2',
      'sites',
      'studio',
      'fundraising',
    ]);

    if (subscriptionType && subscriptionType === 'AthleticsOnly') {
      return athleticsOnly.indexOf(section) > -1;
    } else if (subscriptionType && subscriptionType === 'Everything') {
      return everything.indexOf(section) > -1;
    }

    return false;
  }

  isOrganizationRestricted(section: string) {
    return this.isAccessRestricted(this.organizationType!, section);
  }

  isAccessRestricted(organizationType: OrganizationTypes, section: string) {
    // school type has no restriction and can access all sections (based on subscription level)
    let associationRestriction = ['athletes', 'messaging', 'hallpass', 'transportation', 'vnn-links'];
    let leagueRestriction = ['athletes', 'messaging', 'hallpass', 'transportation', 'vnn-links'];

    if (organizationType && organizationType === 'Association') {
      return associationRestriction.indexOf(section) > -1;
    } else if (organizationType && organizationType === 'League') {
      return leagueRestriction.indexOf(section) > -1;
    }

    return false;
  }

  async uploadLogs() {
    const alert = await this.alertController.create({
      header: 'Upload Logs',
      message: 'Did Eventlink support ask you to upload these logs?',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            await this.uploadLogsService.uploadLogs();
            await this.menu.close();
          },
        },
        {
          text: 'No',
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  async setActiveOrganization(applicationType: string, organization?: IContextOrganizationModel) {
    await this.applicationTypeService.setActive(applicationType, organization, this.context, (context) =>
      this.authService.updateContext(context)
    );
  }

  async completeContextChange() {
    this.context = await this.authService.getContext();
    if (this.context.OrganizationId) {
      const organization = await this.authService.getContextOrganizationById(this.context.OrganizationId);
      await this.getPermissions(organization.OrganizationId!);
    }
    await this.menu.close();
  }

  async navigateTo(flag: string) {
    this.expanded = false;
    this.router.navigate([flag]);
    await this.menu.close();
  }

  async openMenu() {
    this.expanded = true;
    await this.menu.open();
  }

  logout() {
    this.authService.deleteContext();
    this.isAuthenticatedService.isAuthenticated$.next(false);
    this.router.navigate(['/login']);
  }

  async refresh() {
    if (this.userHasKioskAccess.getValue()) {
      return;
    }

    const response = await this.kioskAccessService.getForUser();
    if (response.isAnyError) {
      return;
    }

    if (response.data!.length < 1) {
      this.userHasKioskAccess.next(false);
      return;
    }

    this.showRefresh = false;

    const kioskEvents = response.data!.map((kiosk) => kiosk.Events!).flat();

    this.kioskService.KiosksOrganizationID$.next(response.data![0].OrganizationID);
    this.store.dispatch(KioskEventsActions.addKioskEvents({ kioskEvents }));
    this.userHasKioskAccess.next(true);
  }
  async kiosk() {
    await this.router.navigate(['/kiosk']);
    this.isAuthenticatedService.isAuthenticated$.next(false);
  }

  async openChangePassword() {
    const modal = await this.modalCtrl.create({
      component: ChangePasswordComponent,
      componentProps: {},
      initialBreakpoint: 0.8,
    });
    await modal.present();

    return await modal.onWillDismiss();
  }

  async openManageSchools() {
    const modal = await this.modalCtrl.create({
      component: ManageSchoolsComponent,
      componentProps: {},
      initialBreakpoint: 1.0,
    });
    await modal.present();

    const { data: dataChanged } = await modal.onDidDismiss();

    if (dataChanged) {
      const organizationsContext: IContextOrganizationModel[] = [];
      const response = await this.userLegacyService.getOrganizationSubscriptions(undefined);
      if (response.isSuccess && response.data) {
        let organizations = response.data;
        for (const organization of organizations) {
          organizationsContext.push({
            OrganizationDisplayTitle: organization.DisplayTitle,
            OrganizationId: organization.ID,
            OrganizationTimezone: organization.TimeZone,
            OrganizationTitle: organization.Title,
            IsStaffOrganization: false,
          });
        }
        const orgResponse = await this.organizationLegacyApiService.getAllByUserID(this.context.UserID!);
        if (orgResponse.isAnyError) {
          return;
        }
        const staffOrganizations = orgResponse.data;
        for (const organizationContext of organizationsContext) {
          organizationContext.IsStaffOrganization =
            staffOrganizations?.Organizations?.find((x) => x.ID === organizationContext.OrganizationId) !==
            undefined;
        }
        await this.authService.deleteContextOrganizations();
        for (const organizationContext of organizationsContext) {
          await this.authService.insertContextOrganization(organizationContext);
        }
      }
      await this.refreshData();
    }
  }
}
