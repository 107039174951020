import { Injectable } from '@angular/core';

import { SQLiteService } from '../sqlite/sqlite.service';
import { AuthService } from '../auth/auth.service';
import { AppInfoService } from '../app-info/app-info.service';
import { CrashlyticsService } from '../firebase/crashlytics/crashlytics.service';

@Injectable({
  providedIn: 'root',
})
export class InitializeAppService {
  isAppInit: boolean = false;
  platform!: string;

  constructor(
    private readonly sqliteService: SQLiteService,
    private readonly authService: AuthService,
    private readonly appInfoService: AppInfoService,
    private readonly crashlyticsService: CrashlyticsService
  ) {}

  async initializeApp() {
    // Init needs to pull data but no need to await it
    this.appInfoService.init().then(() => {});

    await this.sqliteService.initializePlugin().then(async () => {
      this.platform = this.sqliteService.platform;
      const DB_EVENTLINK = 'eventlinkdb';

      try {
        if (this.sqliteService.platform === 'web') {
          await this.sqliteService.initWebStore();
        }

        await this.authService.initializeDatabase(DB_EVENTLINK);

        if (this.sqliteService.platform === 'web') {
          await this.sqliteService.saveToStore(DB_EVENTLINK);
        }

        this.isAppInit = true;
      } catch (error: any) {
        await this.crashlyticsService.recordException('InitializeAppError', error);
      }
    });
  }
}
