import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { ModalController } from '@ionic/angular';
import { ManageCalendarsComponent } from '../modal/calendar/manage-calendars.component';
import ContextModel from '../../../core/models/context/context.model';
import { DtoCalendarSubscriptionShortForm } from '../../../core/services/api/models/DtoCalendarSubscriptionShortForm';
import { ApplicationTypeService } from '../../../core/services/auth/application-type/application-type.service';

@Component({
  selector: 'app-calendar-dropdown',
  templateUrl: './calendar-dropdown.component.html',
  styleUrls: ['./calendar-dropdown.component.scss'],
})
export class CalendarDropdownComponent implements OnInit {
  @Input() calendars: DtoCalendarSubscriptionShortForm[] = [];
  @Output() calendarTapped = new EventEmitter<DtoCalendarSubscriptionShortForm[]>();
  @Output() managedCalendars = new EventEmitter<boolean>();
  context!: ContextModel;
  showManageModal: boolean = false;
  expanded: boolean = false;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly authService: AuthService,
    protected readonly applicationTypeService: ApplicationTypeService
  ) {}

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    if (this.context.ApplicationType === 'Community' && this.context.OrganizationId) {
      this.showManageModal = true;
    }
  }

  updateParent(ev: any, item: DtoCalendarSubscriptionShortForm) {
    this.calendarTapped.emit(this.calendars); // Emit the updated calendars array
  }

  getDropdownHeight(): string {
    const itemHeight = 50; // Adjust this based on your actual item height
    let calculatedHeight = 1;
    if (this.context.ApplicationType === 'Officials') {
      calculatedHeight = this.calendars.length * itemHeight;
    } else {
      calculatedHeight = (this.calendars.length + 1) * itemHeight;
    }
    return `${calculatedHeight}px`;
  }

  async openManageCalendarsModal() {
    const modal = await this.modalCtrl.create({
      component: ManageCalendarsComponent,
      componentProps: {},
      breakpoints: [1.0],
      initialBreakpoint: 1.0,
    });

    modal.present();
    const { data } = await modal.onDidDismiss();

    if (!data) {
      return;
    } else {
      this.managedCalendars.emit(true);
    }
  }
}
