<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Add Bank Account</ion-title>
    <ion-title>Add Bank Account</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formGroup" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item class="ion-margin-top">
        <ion-input
          labelPlacement="stacked"
          autocapitalize="on"
          placeholder="Enter your bank's name..."
          type="text"
          formControlName="name">
          <div slot="label">Name <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-select label-placement="stacked" formControlName="type">
          <div slot="label">Type <ion-text color="danger">*</ion-text></div>
          <ion-select-option value="checking">Checking</ion-select-option>
          <ion-select-option value="savings">Savings</ion-select-option>
        </ion-select>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter the routing number..."
          type="text"
          formControlName="routingNumber">
          <div slot="label">Routing Number <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter the account number..."
          type="text"
          formControlName="accountNumber">
          <div slot="label">Account Number <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>
  </form>

  <div class="ion-padding-horizontal">
    <ion-item lines="none" class="ion-padding-vertical">
      <b
        >Your account must be verified before you can send or receive payments. We will send the listed account 2
        small deposits. Once you receive the deposits you will need to return to Eventlink to verify the deposit
        amounts.</b
      >
    </ion-item>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="saveAccount()"
          expand="block"
          [disabled]="!formGroup.valid">
          <ion-text> Submit </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
