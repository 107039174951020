// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoUserLongForm } from "../models/legacy/dtoUserLongForm";
import { dtoOrganizationSubscriptionShortForm } from "../models/legacy/dtoOrganizationSubscriptionShortForm";
import { dtoCalendarSubShortForm } from "../models/legacy/dtoCalendarSubShortForm";
import { dtoUserSearchResults } from "../models/legacy/dtoUserSearchResults";
import { dtoUserCreate } from "../models/legacy/dtoUserCreate";
import { dtoUserUpdate } from "../models/legacy/dtoUserUpdate";
import { dtoUserUpdateCurrent } from "../models/legacy/dtoUserUpdateCurrent";
import { User } from "../models/legacy/User";
import { dtoUserUpdatePassword } from "../models/legacy/dtoUserUpdatePassword";
import { dtoUserPasswordReset } from "../models/legacy/dtoUserPasswordReset";
import { dtoUserActivate } from "../models/legacy/dtoUserActivate";
import { dtoAccessGridRead } from "../models/legacy/dtoAccessGridRead";
import { dtoAccessGridUpdate } from "../models/legacy/dtoAccessGridUpdate";
import { dtoUserShortForm } from "../models/legacy/dtoUserShortForm";
import { dtoUserMerge } from "../models/legacy/dtoUserMerge";

@Injectable({providedIn: 'root'})
export class UserLegacyApiService {
    private readonly module = "User";

    constructor(private legacyApi: LegacyApiService) { }

    /** Find a user by ID
     * @param id User ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoUserLongForm | undefined>(this.module, "Find", { id });
    }

    /** Retrieves organization subscriptions associated with current user ID
     * @param userID CSR can optionally specify a user ID to retrieve.
     */
    getOrganizationSubscriptions(userID: Guid | undefined) {
        return this.legacyApi.get<dtoOrganizationSubscriptionShortForm[] | undefined>(this.module, "GetOrganizationSubscriptions", { userID });
    }

    /** Use V2 CalendarSubscriptions/GetByOrganizationIDForCurrentUser instead Retrieves all calendar subscriptions associated with an organization ID
     * @param id Organization ID
     */
    getCalendarSubscriptionsByOrganizationID(id: Guid) {
        return this.legacyApi.get<dtoCalendarSubShortForm[] | undefined>(this.module, "GetCalendarSubscriptionsByOrganizationID", { id });
    }

    /** Allows for searching of users by optional parameters
     * @param firstname 
     * @param lastname 
     * @param username 
     * @param email 
     * @param phone 
     * @param orgTitle 
     * @param includeInactive 
     */
    search(firstname: string | undefined, lastname: string | undefined, username: string | undefined, email: string | undefined, phone: string | undefined, orgTitle: string | undefined, includeInactive: boolean | undefined) {
        return this.legacyApi.get<dtoUserSearchResults[] | undefined>(this.module, "Search", { firstname, lastname, username, email, phone, orgTitle, includeInactive });
    }

    /** Finds a user through passing in a query string with search parameters. Search parameters include FirstName, LastName, and Emails.
     * @param search JSON formatted search parameters, e.g. {"FirstName": "Sally"}
     */
    searchExisting(search: string) {
        return this.legacyApi.get<dtoUserSearchResults[] | undefined>(this.module, "SearchExisting", { search });
    }

    /** Searches for all users that meet optional parameters
     * @param firstname 
     * @param lastname 
     * @param email 
     */
    allUserSearch(firstname: string | undefined, lastname: string | undefined, email: string | undefined) {
        return this.legacyApi.get<dtoUserSearchResults[] | undefined>(this.module, "AllUserSearch", { firstname, lastname, email });
    }

    /** Create a new user. Returns 400 if password is not provided, no email address is specified, or username is already taken.
     * @param body 
     */
    create(body: dtoUserCreate) {
        return this.legacyApi.post<dtoUserLongForm | undefined>(this.module, "Create", {  }, body);
    }

    /** Updates an existing users information
     * @param body 
     */
    update(body: dtoUserUpdate) {
        return this.legacyApi.post<dtoUserLongForm | undefined>(this.module, "Update", {  }, body);
    }

    /** Allows the current user to update either their first name, last name, address, or time zone.
     * @param body 
     */
    updateCurrent(body: dtoUserUpdateCurrent) {
        return this.legacyApi.post<User | undefined>(this.module, "UpdateCurrent", {  }, body);
    }

    /** Allows current user to update their password
     * @param body 
     */
    changePassword(body: dtoUserUpdatePassword) {
        return this.legacyApi.post<void>(this.module, "ChangePassword", {  }, body);
    }

    /** Accepts Terms of Service for current user
     */
    acceptTermsOfService() {
        return this.legacyApi.post<void>(this.module, "AcceptTermsOfService", {  });
    }

    /** Allows current user to receive a password reset.
     * @param body 
     */
    passwordReset(body: dtoUserPasswordReset) {
        return this.legacyApi.post<void>(this.module, "PasswordReset", {  }, body);
    }

    /** Activates a user account, by activating the contact points
     * @param body 
     */
    activate(body: dtoUserActivate) {
        return this.legacyApi.post<void>(this.module, "Activate", {  }, body);
    }

    /** Force Activates a user account
     * @param id User ID
     */
    forceActivate(id: Guid) {
        return this.legacyApi.post<void>(this.module, "ForceActivate", { id });
    }

    /** Deletes a user account by ID
     * @param id User ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** Checks if current user is admin
     */
    isAdminUser() {
        return this.legacyApi.get<boolean | undefined>(this.module, "IsAdminUser", {  });
    }

    /** Makes an existing user that is not an admin into an admin
     * @param id User ID
     */
    addAdminUser(id: Guid) {
        return this.legacyApi.post<void>(this.module, "AddAdminUser", { id });
    }

    /** Removes a current admin user by ID, throws 400 if user is trying to remove themself from admin user.
     * @param id 
     */
    removeAdminUser(id: Guid) {
        return this.legacyApi.post<void>(this.module, "RemoveAdminUser", { id });
    }

    /** Creates a new Access Grid for a user within an organization
     * @param orgID 
     */
    accessGrid(orgID: Guid) {
        return this.legacyApi.get<dtoAccessGridRead | undefined>(this.module, "AccessGrid", { orgID });
    }

    /** UserController.GetUserAccessGrid Retrieve Access Grid for a user
     * @param orgID 
     * @param userID 
     */
    getUserAccessGrid(orgID: Guid, userID: Guid) {
        return this.legacyApi.get<dtoAccessGridRead | undefined>(this.module, "GetUserAccessGrid", { orgID, userID });
    }

    /** Updates a user's access within an organization, user must be an organization manager. If trying to update banking information that must be a CSR.
     * @param orgID 
     * @param userID 
     * @param body 
     */
    updateAccess(orgID: Guid, userID: Guid, body: dtoAccessGridUpdate) {
        return this.legacyApi.post<void>(this.module, "UpdateAccess", { orgID, userID }, body);
    }

    /** Creates many users in an organization at one time
     * @param id Organization ID
     */
    createManyInOrganization(id: Guid) {
        return this.legacyApi.post<void>(this.module, "CreateManyInOrganization", { id });
    }

    /** Activates a user account
     * @param id User ID
     */
    activateUserAccount(id: Guid) {
        return this.legacyApi.post<dtoUserShortForm | undefined>(this.module, "ActivateUserAccount", { id });
    }

    /** Deactivates a user account. Returns 400 if user is a organization manager, district manager, or AD. Returns conflict in any of the following scenarios: user has future Worker obligations, user has future Official obligations, user is a calendar admin, user is a facility manager, user is a team manager, or user is a team coach. 
     * @param force 
     * @param id User ID
     * @returns This endpoint can return a 409 Conflict response.
     */
    deactivateUserAccount(force: boolean | undefined, id: Guid) {
        return this.legacyApi.post<dtoUserShortForm | undefined>(this.module, "DeactivateUserAccount", { force, id });
    }

    /** Send account activation email to email associated with user ID
     * @param id User ID
     */
    sendAccountActivationEmail(id: Guid) {
        return this.legacyApi.get<void>(this.module, "SendAccountActivationEmail", { id });
    }

    /** Merges two user accounts together. Returns 400 when multiple of the accounts have Dwolla accounts, when merging an account with Dwolla account info into an account with a weak password, or when merging multiple accounts with Abound accounts.
     * @param body 
     */
    mergeUsers(body: dtoUserMerge) {
        return this.legacyApi.post<void>(this.module, "MergeUsers", {  }, body);
    }
}