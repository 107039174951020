<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Change Password</ion-title>
    <ion-buttons slot="end"> </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-input
    class="ion-margin-bottom"
    labelPlacement="stacked"
    type="password"
    fill="solid"
    label="Current Password"
    placeholder="Type here..."
    [(ngModel)]="oldPassword"
    style="margin-bottom: 20px">
  </ion-input>
  <ion-input
    class="ion-margin-bottom"
    labelPlacement="stacked"
    type="password"
    fill="solid"
    label="New Password"
    placeholder="Type here..."
    [(ngModel)]="newPassword">
  </ion-input>
  <ion-input
    class="ion-margin-bottom"
    labelPlacement="stacked"
    type="password"
    fill="solid"
    label="Retype New Password"
    placeholder="Type here..."
    [(ngModel)]="confirmPassword">
  </ion-input>
  <ion-button
    class="button-height submit-button"
    type="submit"
    appPreventMultiClickEvents
    (preventMultiClicks)="submit()"
    expand="block">
    <ion-text> Submit </ion-text>
  </ion-button>
</ion-content>
