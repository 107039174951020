import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  @Input() duration!: number;
  @Input() message!: string;
  @Input() show!: boolean;
  @Input() title?: string;

  ngOnInit() {
    document.getElementById('success-container')?.classList.add('slide-in');
    let element = document.getElementById('success-container');
    if (element) {
      element.style.display = 'block';
    }
    setTimeout(() => {
      document.getElementById('success-container')?.classList.remove('slide-in');
      document.getElementById('success-container')?.classList.add('slide-out');
      setTimeout(() => {
        let element = document.getElementById('success-container');
        if (element) {
          element.style.display = 'none';
        }
      }, 800);
    }, this.duration);
  }
}
