// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid, DateTime, DateOnly } from "../models/ApiTypes";
import { dtoOfficialLongForm } from "../models/legacy/dtoOfficialLongForm";
import { dtoReportOfficial } from "../models/legacy/dtoReportOfficial";
import { dtoOfficialAssociation } from "../models/legacy/dtoOfficialAssociation";
import { dtoOfficialsContractTable } from "../models/legacy/dtoOfficialsContractTable";
import { dtoOfficialNewUser } from "../models/legacy/dtoOfficialNewUser";
import { Official } from "../models/legacy/Official";
import { dtoOfficialSportShortForm } from "../models/legacy/dtoOfficialSportShortForm";
import { dtoDistancedOfficialLongForm } from "../models/legacy/dtoDistancedOfficialLongForm";
import { dtoOfficialSportCreateUpdate } from "../models/legacy/dtoOfficialSportCreateUpdate";
import { dtoOfficialSportLongForm } from "../models/legacy/dtoOfficialSportLongForm";
import { OfficialBlackoutRule } from "../models/legacy/OfficialBlackoutRule";
import { dtoOfficialBlackoutRuleCreateUpdate } from "../models/legacy/dtoOfficialBlackoutRuleCreateUpdate";
import { dtoOfficialBlackoutRule } from "../models/legacy/dtoOfficialBlackoutRule";
import { dtoOfficialShortForm } from "../models/legacy/dtoOfficialShortForm";
import { dtoOfficialOrganizationBlock } from "../models/legacy/dtoOfficialOrganizationBlock";
import { dtoOrganizationOfficialBlocks } from "../models/legacy/dtoOrganizationOfficialBlocks";
import { dtoOfficialOrganizationBlocks } from "../models/legacy/dtoOfficialOrganizationBlocks";
import { dtoOfficialLevelBlocks } from "../models/legacy/dtoOfficialLevelBlocks";
import { dtoOfficialMerge } from "../models/legacy/dtoOfficialMerge";

@Injectable({providedIn: 'root'})
export class OfficialLegacyApiService {
    private readonly module = "Official";

    constructor(private legacyApi: LegacyApiService) { }

    /** Find Official by their ID
     * @param id ID for Official ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoOfficialLongForm | undefined>(this.module, "Find", { id });
    }

    /** Finds an Official by their ID, user must be an official
     * @param id ID for Official
     */
    getByUserID(id: Guid) {
        return this.legacyApi.get<dtoOfficialLongForm | undefined>(this.module, "GetByUserID", { id });
    }

    /** Get all officials associated with an organization, user must have Athletic Permission.
     * @param id ID for Organization ID
     */
    getAllByOrganizationID(id: Guid) {
        return this.legacyApi.get<dtoReportOfficial[] | undefined>(this.module, "GetAllByOrganizationID", { id });
    }

    /** Gets the state associations an official is associated with
     * @param id ID for Official ID
     */
    getAssociationsByOfficialID(id: Guid) {
        return this.legacyApi.get<dtoOfficialAssociation[] | undefined>(this.module, "GetAssociationsByOfficialID", { id });
    }

    /** Gets all officials associated with a team, user must have Athletic Permission
     * @param id ID for Team ID
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getAllByTeamID(id: Guid, start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoOfficialsContractTable[] | undefined>(this.module, "GetAllByTeamID", { id, start, end, tz });
    }

    /** Creates a new user that is an official
     * @param body 
     */
    createNewUserFromOfficialData(body: dtoOfficialNewUser) {
        return this.legacyApi.post<dtoOfficialLongForm | undefined>(this.module, "CreateNewUserFromOfficialData", {  }, body);
    }

    /** Creates an official from an existing user
     * @param body 
     */
    create(body: Official) {
        return this.legacyApi.post<dtoOfficialLongForm | undefined>(this.module, "Create", {  }, body);
    }

    /** Creates a request for a new official to be added, returns 400 if at least one contact point or sport is not specified.
     * @param OrganizationID ID for Organization ID
     */
    createNewOfficialRequest(OrganizationID: Guid) {
        return this.legacyApi.post<void>(this.module, "CreateNewOfficialRequest", { OrganizationID });
    }

    /** Update an officials information
     * @param body 
     */
    update(body: Official) {
        return this.legacyApi.post<dtoOfficialLongForm | undefined>(this.module, "Update", {  }, body);
    }

    /** Delete a Official by ID
     * @param id ID for Official ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** Gets all sports associated with an official
     * @param id ID for Official ID
     */
    getAllSportsByOfficialID(id: Guid) {
        return this.legacyApi.get<dtoOfficialSportShortForm[] | undefined>(this.module, "GetAllSportsByOfficialID", { id });
    }

    /** new v2 endpoint to use Officials/Search 
     */
    search() {
        return this.legacyApi.get<dtoDistancedOfficialLongForm[] | undefined>(this.module, "Search", {  });
    }

    /** Allows an admin to create a new sport for an official
     * @param body 
     */
    adminCreateSport(body: dtoOfficialSportCreateUpdate) {
        return this.legacyApi.post<dtoOfficialSportLongForm | undefined>(this.module, "AdminCreateSport", {  }, body);
    }

    /** Allows an admin to update a sport for an official
     * @param body 
     */
    adminUpdateSport(body: dtoOfficialSportCreateUpdate) {
        return this.legacyApi.post<dtoOfficialSportLongForm | undefined>(this.module, "AdminUpdateSport", {  }, body);
    }

    /** Allows an admin to delete a sport for an official
     * @param id Sport ID
     */
    adminDeleteSport(id: Guid) {
        return this.legacyApi.post<void>(this.module, "AdminDeleteSport", { id });
    }

    /** Creates a new OfficialSport object for an official, user must be an official
     * @param body 
     */
    createSport(body: dtoOfficialSportCreateUpdate) {
        return this.legacyApi.post<dtoOfficialSportShortForm | undefined>(this.module, "CreateSport", {  }, body);
    }

    /** Updates a sport for an official, the user must be an official. Returns 400 if trying to update a sport that is licensed.
     * @param body 
     */
    updateSport(body: dtoOfficialSportCreateUpdate) {
        return this.legacyApi.post<dtoOfficialSportShortForm | undefined>(this.module, "UpdateSport", {  }, body);
    }

    /** Deletes a Sport for an official, the user must be an official. Returns 400 if trying to delete a licensed sport.
     * @param id Official Sport ID
     */
    deleteSport(id: Guid) {
        return this.legacyApi.post<void>(this.module, "DeleteSport", { id });
    }

    /** Get all blackout rules associated with an official
     * @param id Official ID
     */
    getAllBlackoutRulesByOfficial(id: Guid) {
        return this.legacyApi.get<OfficialBlackoutRule[] | undefined>(this.module, "GetAllBlackoutRulesByOfficial", { id });
    }

    /** Creates a blackout rule for an official, current user must be an official.
     * @param body 
     */
    createBlackoutRule(body: dtoOfficialBlackoutRuleCreateUpdate) {
        return this.legacyApi.post<dtoOfficialBlackoutRule | undefined>(this.module, "CreateBlackoutRule", {  }, body);
    }

    /** Updates a blackout rule for an official, current user must be an official.
     * @param alterFor 
     * @param body 
     */
    updateBlackoutRule(alterFor: string, body: dtoOfficialBlackoutRuleCreateUpdate) {
        return this.legacyApi.post<dtoOfficialBlackoutRule | undefined>(this.module, "UpdateBlackoutRule", { alterFor }, body);
    }

    /** Deletes blackout rule from an official, user must be an official. Throws 400 if trying to delete blackout rule that was imported from another system.
     * @param id Official Blackout Rule ID
     * @param alterFor 
     * @param alterForDate 
     */
    deleteBlackoutRule(id: Guid, alterFor: string, alterForDate: DateOnly) {
        return this.legacyApi.post<void>(this.module, "DeleteBlackoutRule", { id, alterFor, alterForDate });
    }

    /** Uploads a photo for an official
     */
    uploadPhoto() {
        return this.legacyApi.post<void>(this.module, "UploadPhoto", {  });
    }

    /** Gets an official's photo
     * @param id Official ID
     */
    getPhoto(id: Guid | undefined) {
        return this.legacyApi.get<Blob | undefined>(this.module, "GetPhoto", { id });
    }

    /** Deletes an official's photo.
     * @param id Official ID
     */
    deletePhoto(id: Guid) {
        return this.legacyApi.post<void>(this.module, "DeletePhoto", { id });
    }

    /** Gets all officials blocked from an organization, user must have Athletic Permission.
     * @param id Organization ID
     */
    getBlockedOfficialsForOrganization(id: Guid) {
        return this.legacyApi.get<dtoOfficialShortForm[] | undefined>(this.module, "GetBlockedOfficialsForOrganization", { id });
    }

    /** Gets all organization's blocked for an official, current user must be an official.
     * @param id Organization ID
     */
    getBlockedOrganizationsForOfficial(id: Guid) {
        return this.legacyApi.get<dtoOfficialOrganizationBlock[] | undefined>(this.module, "GetBlockedOrganizationsForOfficial", { id });
    }

    /** Blocks officials from an organization, user must have Athletic Permissions.
     * @param body 
     */
    blockOfficialsFromOrganization(body: dtoOrganizationOfficialBlocks) {
        return this.legacyApi.post<dtoOfficialShortForm[] | undefined>(this.module, "BlockOfficialsFromOrganization", {  }, body);
    }

    /** Unblocks officials from an organization, user must have Athletic Permissions.
     * @param body 
     */
    unblockOfficialsFromOrganization(body: dtoOrganizationOfficialBlocks) {
        return this.legacyApi.post<dtoOfficialShortForm[] | undefined>(this.module, "UnblockOfficialsFromOrganization", {  }, body);
    }

    /** Blocks organizations from an official, user must be an official.
     * @param body 
     */
    blockOrganizationsFromOfficial(body: dtoOfficialOrganizationBlocks) {
        return this.legacyApi.post<dtoOfficialOrganizationBlock[] | undefined>(this.module, "BlockOrganizationsFromOfficial", {  }, body);
    }

    /** Gets the level blocks associated with an official
     * @param id Official ID
     */
    getLevelBlocksByOfficialID(id: Guid) {
        return this.legacyApi.get<dtoOfficialLevelBlocks | undefined>(this.module, "GetLevelBlocksByOfficialID", { id });
    }

    /** Creates an update for official level blocks
     * @param body 
     */
    createUpdateOfficialLevelBlocks(body: dtoOfficialLevelBlocks) {
        return this.legacyApi.post<void>(this.module, "CreateUpdateOfficialLevelBlocks", {  }, body);
    }

    /** Merges official accounts. Can't merge account with any of the following: Both are licensed, only one is licensed, both/multiple accounts being merged have bank info, if one account doesn't have a strong password and being merged with an account with bank info.
     * @param keepAccounts 
     * @param body 
     */
    mergeOfficials(keepAccounts: boolean | undefined, body: dtoOfficialMerge) {
        return this.legacyApi.post<dtoOfficialLongForm | undefined>(this.module, "MergeOfficials", { keepAccounts }, body);
    }
}