import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DtoStripeLocationID } from '../api/models/DtoStripeLocationID';

@Injectable({
  providedIn: 'root',
})
export class KiosksService {
  KiosksOrganizationID$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  StripeLocationID$: BehaviorSubject<DtoStripeLocationID> = new BehaviorSubject<DtoStripeLocationID>(
    {} as DtoStripeLocationID
  );
}
