import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController, ViewDidEnter } from '@ionic/angular';
import ContextModel from '../../../core/models/context/context.model';
import { SuccessService } from '../../../core/services/success/success.service';
import { ErrorService } from '../../../core/services/error/error.service';
import { EventLegacyApiService } from '../../../core/services/api/v1/EventLegacyApi.service';
import { dtoEventChangeStatus } from '../../../core/services/api/models/legacy/dtoEventChangeStatus';
import { TicketConfigurationsApiService } from '../../../core/services/api/v2/TicketConfigurationsApi.service';
import { DtoTicketPoolPublic } from '../../../core/services/api/models/DtoTicketPoolPublic';
import { DtoTicketConfigurationPublic } from '../../../core/services/api/models/DtoTicketConfigurationPublic';
import { ContactPointLegacyApiService } from '../../../core/services/api/v1/ContactPointLegacyApi.service';
import { dtoContactPointShortForm } from '../../../core/services/api/models/legacy/dtoContactPointShortForm';
import { AuthService } from '../../../core/services/auth/auth.service';
import { DtoTicketConfigurationEmailAll } from '../../../core/services/api/models/DtoTicketConfigurationEmailAll';

@Component({
  selector: 'app-cancel-event-modal',
  templateUrl: './cancel-event-modal.component.html',
  styleUrls: ['./cancel-event-modal.component.scss'],
})
export class CancelEventModalComponent implements ViewDidEnter {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() IsAthletic: boolean | undefined;
  context!: ContextModel;
  eventId: string | undefined;
  groupsToNotify: { SocialAccounts: string; Workers: string; Opponents: string | undefined; Subscribers: string } =
    {
      SocialAccounts: 'false',
      Subscribers: 'false',
      Workers: 'false',
      Opponents: undefined,
    };
  @Input() cancelNote: string = '';
  ticketInfo: DtoTicketConfigurationPublic | undefined;
  ticketPools: DtoTicketPoolPublic[] | undefined;
  ticketPoolsWithBoolean:
    | {
        dtoTicketPoolPublic: DtoTicketPoolPublic;
        refund: string;
      }[]
    | undefined = [];
  contactPoints: dtoContactPointShortForm[] = [];
  refundCheckedVar: boolean = false;
  selectedRefundContactPoints: dtoContactPointShortForm | undefined;
  refundPoolMessage: string = '';
  pageState: number = 1;

  constructor(
    private readonly successService: SuccessService,
    private readonly errorService: ErrorService,
    public modalCtrl: ModalController,
    private readonly eventService: EventLegacyApiService,
    private readonly ticketConfigurationService: TicketConfigurationsApiService,
    private readonly contactPointService: ContactPointLegacyApiService,
    private readonly authService: AuthService,
    private readonly ticketConfigService: TicketConfigurationsApiService
  ) {
    this.cancelNote = '';
    this.refundCheckedVar = false;
  }

  async ionViewDidEnter(): Promise<void> {
    this.context = await this.authService.getContext();
    let ticketResponse = await this.ticketConfigurationService.getByEventID(this.eventId!);
    if (ticketResponse.isSuccess && ticketResponse.data) {
      this.ticketInfo = ticketResponse.data;
      this.ticketPools = this.ticketInfo.TicketPools;
      if (this.ticketPools && this.ticketPools?.length > 0) {
        for (let ticketPool of this.ticketPools) {
          this.ticketPoolsWithBoolean?.push({ dtoTicketPoolPublic: ticketPool, refund: 'true' });
        }
      }
      if (this.context.UserID) {
        let contactResponse = await this.contactPointService.getByUserID(this.context.UserID);
        if (contactResponse.isSuccess && contactResponse.data) {
          this.contactPoints = [];
          for (let contactPoint of contactResponse.data) {
            if (contactPoint.ContactType === 'Email' && contactPoint.ActivatedTime) {
              this.contactPoints.push(contactPoint);
            }
          }
        }
      }
    }
    if (this.IsAthletic) {
      this.groupsToNotify.Opponents = 'false';
    }
  }

  refundChecked() {
    this.refundCheckedVar = true;
  }

  refundUnChecked(pool: { dtoTicketPoolPublic: DtoTicketPoolPublic; refund: string }) {
    let oneIsNo: boolean = false;
    for (let ticketPool of this.ticketPoolsWithBoolean!) {
      if (ticketPool.refund === 'false' && ticketPool.dtoTicketPoolPublic.ID !== pool.dtoTicketPoolPublic.ID) {
        oneIsNo = true;
      }
    }
    if (!oneIsNo) {
      this.refundCheckedVar = false;
    }
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submitOrNext() {
    if (this.pageState === 1 && this.ticketPoolsWithBoolean && this.ticketPoolsWithBoolean.length > 0) {
      this.pageState = 2;
      return;
    }
    let ticketPoolsToRefund: number[] = [];
    if (this.ticketPoolsWithBoolean && this.ticketPoolsWithBoolean.length > 0) {
      for (let pool of this.ticketPoolsWithBoolean) {
        if (pool.refund === 'true') {
          ticketPoolsToRefund.push(pool.dtoTicketPoolPublic.ID);
        } else {
          let email: DtoTicketConfigurationEmailAll = {
            Message: this.refundPoolMessage,
            FromContactPointID: this.selectedRefundContactPoints?.ID,
          };
          let ticketResponse = await this.ticketConfigService.messageTicketHolders(
            pool.dtoTicketPoolPublic.ID,
            email
          );
          if (ticketResponse.isAnyError) {
            this.errorService.showError('There was an issue notifying a ticket group.');
          }
        }
      }
    }

    let dtoEventChangeStatus: dtoEventChangeStatus = {
      EventIDs: [this.eventId!],
      NotifySubscribers: this.groupsToNotify.Subscribers === 'true',
      NotifyOpponents: this.groupsToNotify.Opponents === 'true',
      NotifyWorkers: this.groupsToNotify.Workers === 'true',
      NotifySocialAccounts: this.groupsToNotify.SocialAccounts === 'true',
      RefundTicketPoolIDs: ticketPoolsToRefund,
      PublicCancelNote: this.cancelNote,
      RefundReason: this.refundPoolMessage,
    };
    dtoEventChangeStatus.EventIDs = [this.eventId!];

    const response = await this.eventService.cancel(dtoEventChangeStatus);
    if (response.isSuccess) {
      this.successService.showSuccess('Your event has been canceled', 5000, 'Event Canceled');
      await this.modalCtrl.dismiss(true, 'submit');
    } else if (response.isNotFound) {
      this.errorService.showError('Your event failed to cancel.');
    }
  }
}
