// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoCalendarShortForm } from "../models/legacy/dtoCalendarShortForm";
import { dtoINamed } from "../models/legacy/dtoINamed";
import { dtoCalendarCreate } from "../models/legacy/dtoCalendarCreate";
import { dtoCalendarLongForm } from "../models/legacy/dtoCalendarLongForm";
import { dtoCalendarSubSync } from "../models/legacy/dtoCalendarSubSync";
import { dtoCalendarSubShortForm } from "../models/legacy/dtoCalendarSubShortForm";
import { dtoCalendarUpdateStaffUser } from "../models/legacy/dtoCalendarUpdateStaffUser";
import { dtoCalendarUpdateCsr } from "../models/legacy/dtoCalendarUpdateCsr";
import { dtoCalendarSubUpdateVisible } from "../models/legacy/dtoCalendarSubUpdateVisible";
import { dtoCalendarSubAdd } from "../models/legacy/dtoCalendarSubAdd";

@Injectable({providedIn: 'root'})
export class CalendarLegacyApiService {
    private readonly module = "Calendar";

    constructor(private legacyApi: LegacyApiService) { }

    /** Gets an Organizations Public Calendars based upon Organization ID.
     * @param id Organization ID
     */
    getPublicByOrganizationID(id: Guid) {
        return this.legacyApi.get<dtoCalendarShortForm[] | undefined>(this.module, "GetPublicByOrganizationID", { id });
    }

    /** Gets all Calendars associated with an Organization by Organization ID.
     * @param id Organization ID
     */
    getAllByOrganizationID(id: Guid) {
        return this.legacyApi.get<dtoCalendarShortForm[] | undefined>(this.module, "GetAllByOrganizationID", { id });
    }

    /** Gets the subscribers of a Calendar by ID, user must either be a Calendar Admin or be an Admin User.
     * @param id Calender ID
     */
    getSubscribers(id: Guid) {
        return this.legacyApi.get<dtoINamed[] | undefined>(this.module, "GetSubscribers", { id });
    }

    /** Gets managers of a Calendar by ID, user must be a Calendar Admin.
     * @param id Calendar ID
     */
    getManagers(id: Guid) {
        return this.legacyApi.get<dtoINamed[] | undefined>(this.module, "GetManagers", { id });
    }

    /** Creates a new calendar, user must have either OrganizationSubscriptionTypes.Everything or be an admin user. They must also be a member of the organization if they want to subscribe to the calendar.
     * @param body 
     */
    create(body: dtoCalendarCreate) {
        return this.legacyApi.post<dtoCalendarLongForm | undefined>(this.module, "Create", {  }, body);
    }

    /** Deletes a calendar by ID, user must have either OrganizationSubscriptionTypes.Everything or be an admin user for every organization using the calendar. They must also be a Calendar Admin.
     * @param id Calendar ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** Syncs Subscriptions between Calendars for current user.
     * @param body 
     */
    syncSubscriptions(body: dtoCalendarSubSync) {
        return this.legacyApi.post<dtoCalendarSubShortForm[] | undefined>(this.module, "SyncSubscriptions", {  }, body);
    }

    /** This is the staff version of update. Requires a subscription to edit. User must be a calendar admin or coach with CanEditGames or CanEditPractices.
     * @param body 
     */
    update(body: dtoCalendarUpdateStaffUser) {
        return this.legacyApi.post<void>(this.module, "Update", {  }, body);
    }

    /** This is the CSR version. It Lets you update any calendar.
     * @param body 
     */
    updateCsr(body: dtoCalendarUpdateCsr) {
        return this.legacyApi.post<void>(this.module, "UpdateCsr", {  }, body);
    }

    /** Updates the current users calendar subscription visibility for the given calendar
     * @param body 
     */
    updateVisible(body: dtoCalendarSubUpdateVisible) {
        return this.legacyApi.post<dtoCalendarSubShortForm[] | undefined>(this.module, "UpdateVisible", {  }, body);
    }

    /** Allows a user to unsubscribe from a calendar they are subscribed too.
     * @param calendarID Calendar ID
     */
    unsubscribe(calendarID: Guid) {
        return this.legacyApi.post<void>(this.module, "Unsubscribe", { calendarID });
    }

    /** Adds Subscription to a Calendar, user must be a calendar admin.
     * @param body 
     */
    addSubscription(body: dtoCalendarSubAdd) {
        return this.legacyApi.post<void>(this.module, "AddSubscription", {  }, body);
    }

    /** Removes a subscriber from Calendar, user must be a Calendar admin.
     * @param userID user ID
     * @param calendarID calendar ID
     */
    removeSubscription(userID: Guid, calendarID: Guid) {
        return this.legacyApi.post<void>(this.module, "RemoveSubscription", { userID, calendarID });
    }
}