import { Component } from '@angular/core';
import { ModalController, ViewDidLeave } from '@ionic/angular';

@Component({
  selector: 'app-decline-assignment',
  templateUrl: './decline-assignment.component.html',
  styleUrls: ['./decline-assignment.component.scss'],
})
export class DeclineAssignmentComponent implements ViewDidLeave {
  reason: string = '';

  constructor(
    public modalCtrl: ModalController,
  ) {}

  ionViewDidLeave() {
    this.reason = '';
  }

  cancel() {
    this.modalCtrl.dismiss('cancel');
  }

  declineAssignment() {
    this.modalCtrl.dismiss({status:'decline', reason:this.reason});
  }
}
