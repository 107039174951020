import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { dtoAssigner } from 'src/app/core/services/api/models/legacy/dtoAssigner';
import { dtoContactPointShortForm } from 'src/app/core/services/api/models/legacy/dtoContactPointShortForm';
import { DtoEventOfficialSlotStatusChangeShortForm } from 'src/app/core/services/api/models/DtoEventOfficialSlotStatusChangeShortForm';
import { UtilsService } from 'src/app/core/services/utils/utils.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  @Input() inviter: dtoAssigner = {
    ID: undefined,
    Name: undefined,
    Type: undefined,
    ContactPoints: undefined,
  };
  @Input() inviterContactPoints?: dtoContactPointShortForm[] = [];
  @Input() timezone!: string;
  statusChange?: DtoEventOfficialSlotStatusChangeShortForm;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly utilsService: UtilsService
  ) {}

  async ngOnInit() {
    this.inviterContactPoints = this.inviter.ContactPoints?.map((contactPoint: dtoContactPointShortForm) => {
      return {
        ID: contactPoint.ID,
        UserID: contactPoint.UserID,
        ContactType: contactPoint.ContactType,
        Title: contactPoint.Title,
        Value: contactPoint.Value,
        ActivatedTime: contactPoint.ActivatedTime,
      };
    }).sort((a, b) => {
      if (a.ContactType === 'Phone') {
        return -1;
      } else if (b.ContactType === 'Email') {
        return 1;
      } else return 0;
    });
  }

  getDateTime(dateTime: string) {
    if (!this.timezone) {
      return '';
    }
    return this.utilsService.getDateTimeCustomFormat(dateTime, this.timezone, 'M/DD/YYYY - h:mm A z');
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
