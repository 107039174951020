// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { DtoStripePublishableKeyDetails } from "../models/DtoStripePublishableKeyDetails";
import { Guid } from "../models/ApiTypes";
import { DtoOrganizationStripeAccountCreate } from "../models/DtoOrganizationStripeAccountCreate";
import { DtoStripeAccount } from "../models/DtoStripeAccount";
import { DtoOrganizationStripeAccount } from "../models/DtoOrganizationStripeAccount";
import { DtoOrganizationStripeAccountBalanceDetails } from "../models/DtoOrganizationStripeAccountBalanceDetails";
import { DtoStripeBankAccountCreateRequest } from "../models/DtoStripeBankAccountCreateRequest";
import { DtoOrganizationStripeAccountUpdate } from "../models/DtoOrganizationStripeAccountUpdate";
import { DtoOrganizationStripeAccountUserCreateUpdate } from "../models/DtoOrganizationStripeAccountUserCreateUpdate";
import { DtoStripeConnectionToken } from "../models/DtoStripeConnectionToken";
import { DtoStripeLocationID } from "../models/DtoStripeLocationID";

@Injectable({providedIn: 'root'})
export class StripeApiService {
    private readonly controller = "Stripe";

    constructor(private v2Api: V2ApiService) { }

    /** 
     */
    getPublishableKey() {
        return this.v2Api.get<DtoStripePublishableKeyDetails>(this.controller, "GetPublishableKey", {  });
    }

    /** 
     * @param organizationID 
     * @param body 
     */
    createAccount(organizationID: Guid, body: DtoOrganizationStripeAccountCreate) {
        return this.v2Api.post<string>(this.controller, "CreateAccount", { organizationID }, body);
    }

    /** 
     * @param organizationStripeAccountID 
     * @param returnTo 
     */
    getStripeAccountLink(organizationStripeAccountID: Guid, returnTo: string) {
        return this.v2Api.get<string>(this.controller, "GetStripeAccountLink", { organizationStripeAccountID, returnTo });
    }

    /** 
     * @param organizationStripeAccountID 
     */
    getStripeInfo(organizationStripeAccountID: Guid) {
        return this.v2Api.get<DtoStripeAccount>(this.controller, "GetStripeInfo", { organizationStripeAccountID });
    }

    /** 
     * @param organizationID 
     */
    getAccounts(organizationID: Guid) {
        return this.v2Api.get<DtoOrganizationStripeAccount[]>(this.controller, "GetAccounts", { organizationID });
    }

    /** 
     * @param organizationStripeAccountID 
     */
    getAccountTransactions(organizationStripeAccountID: Guid) {
        return this.v2Api.get<DtoOrganizationStripeAccountBalanceDetails>(this.controller, "GetAccountTransactions", { organizationStripeAccountID });
    }

    /** 
     * @param organizationStripeAccountID 
     * @param body 
     */
    addBankAccount(organizationStripeAccountID: Guid, body: DtoStripeBankAccountCreateRequest) {
        return this.v2Api.post<void>(this.controller, "AddBankAccount", { organizationStripeAccountID }, body);
    }

    /** 
     * @param body 
     */
    updateOrganizationAccount(body: DtoOrganizationStripeAccountUpdate) {
        return this.v2Api.post<void>(this.controller, "UpdateOrganizationAccount", {  }, body);
    }

    /** 
     * @param body 
     */
    saveAccountUser(body: DtoOrganizationStripeAccountUserCreateUpdate) {
        return this.v2Api.post<void>(this.controller, "SaveAccountUser", {  }, body);
    }

    /** 
     * @param organizationStripeAccountID 
     * @param removeUserID 
     */
    removeAccountUser(organizationStripeAccountID: Guid, removeUserID: Guid) {
        return this.v2Api.post<void>(this.controller, "RemoveAccountUser", { organizationStripeAccountID, removeUserID });
    }

    /** Gets a connection token that can be used to authenticate a terminal for use in Eventlink.
     * @param organizationID 
     */
    createConnectionToken(organizationID: Guid | undefined) {
        return this.v2Api.post<DtoStripeConnectionToken>(this.controller, "CreateConnectionToken", { organizationID });
    }

    /** 
     */
    getTerminalConnectionToken() {
        return this.v2Api.post<void>(this.controller, "GetTerminalConnectionToken", {  });
    }

    /** 
     * @param organizationID 
     */
    getTerminalLocation(organizationID: Guid | undefined) {
        return this.v2Api.get<DtoStripeLocationID>(this.controller, "GetTerminalLocation", { organizationID });
    }
}