import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import IContextModel from "../../../models/context/context.model";


@Injectable({
  providedIn: 'root',
})
export class ApplicationTypeService {
  applicationType$ = new BehaviorSubject<string>("");
  organizationName$ = new BehaviorSubject<string>("");

  context!: IContextModel;

  constructor() {
    this.applicationType$.next(this.context ? this.context.ApplicationType! : '');
  }
}
