// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoAboundUserRead } from "../models/DtoAboundUserRead";
import { DtoAboundAccessTokenRead } from "../models/DtoAboundAccessTokenRead";
import { DtoAboundDocumentAccessTokenRequest } from "../models/DtoAboundDocumentAccessTokenRequest";

@Injectable({providedIn: 'root'})
export class AboundApiService {
    private readonly controller = "Abound";

    constructor(private v2Api: V2ApiService) { }

    /** Get Abound user information.
     * @param userToken The token on an Eventlink User.
     */
    getForUser(userToken: Guid) {
        return this.v2Api.get<DtoAboundUserRead>(this.controller, "GetForUser", { userToken });
    }

    /** Get Abound user information for the logged in user.
     */
    getForCurrentUser() {
        return this.v2Api.get<DtoAboundUserRead>(this.controller, "GetForCurrentUser", {  });
    }

    /** Get Abound user information using token exchange
     * @param exchangeableToken ExchangeableToken from TokenExchange endpoint
     */
    getForExchangedToken(exchangeableToken: Guid) {
        return this.v2Api.get<DtoAboundUserRead>(this.controller, "GetForExchangedToken", { exchangeableToken });
    }

    /** Creates an Abound auth token for a given user.
     * @param userToken The token on an Eventlink User.
     * @param aboundUserId The Abound User ID.
     */
    createTokenForUser(userToken: Guid, aboundUserId: string) {
        return this.v2Api.post<DtoAboundAccessTokenRead>(this.controller, "CreateTokenForUser", { userToken, aboundUserId });
    }

    /** Creates an Abound auth token for the current user.
     */
    createTokenForCurrentUser() {
        return this.v2Api.post<DtoAboundAccessTokenRead>(this.controller, "CreateTokenForCurrentUser", {  });
    }

    /** Creates an Abound auth token using token exchange
     * @param exchangeableToken ExchangeableToken from TokenExchange endpoint
     * @param aboundUserId 
     */
    createTokenForExchangedUser(exchangeableToken: Guid, aboundUserId: string) {
        return this.v2Api.post<DtoAboundAccessTokenRead>(this.controller, "CreateTokenForExchangedUser", { exchangeableToken, aboundUserId });
    }

    /** Creates an Abound auth token for the current user. The user must verify their password to receive the access token.
     * @param body 
     */
    createDocumentsTokenForCurrentUser(body: DtoAboundDocumentAccessTokenRequest) {
        return this.v2Api.post<DtoAboundAccessTokenRead>(this.controller, "CreateDocumentsTokenForCurrentUser", {  }, body);
    }

    /** Set electronic consent for an Abound User
     * @param userToken The token on an Eventlink User.
     */
    setElectronicConsentForUser(userToken: Guid) {
        return this.v2Api.post<void>(this.controller, "SetElectronicConsentForUser", { userToken });
    }

    /** Set electronic consent for the current user
     */
    setElectronicConsentForCurrentUser() {
        return this.v2Api.post<void>(this.controller, "SetElectronicConsentForCurrentUser", {  });
    }

    /** Set electronic consent for an Abound User using token exchange
     * @param exchangeableToken ExchangeableToken from TokenExchange endpoint
     */
    setElectronicConsentForExchangedUser(exchangeableToken: Guid) {
        return this.v2Api.post<void>(this.controller, "SetElectronicConsentForExchangedUser", { exchangeableToken });
    }
}