<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" style="min-width: 25%">
      <ion-button type="submit" (click)="cancel()" expand="block" >
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Issue Hall Pass</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>Student Name<ion-text class="required">*</ion-text></ion-row>
    <ion-row class="ion-padding-bottom">
      <ion-button id="open-modal" expand="block" [ngStyle]="{
                '--background':
               (!this.currentStudent)?'var(--eventlink-secondary)': 'transparent'}"><label *ngIf="currentStudent">{{currentStudent.Name}}</label><label *ngIf="!currentStudent">No student selected</label></ion-button>
      <ion-modal #modal trigger="open-modal" [initialBreakpoint]="0.75" [breakpoints]="[0.75]">
        <ng-template>
          <ion-content>
            <ion-searchbar (ionInput)="handleInput($event)" class="ion-padding-horizontal" placeholder="Search name..." ></ion-searchbar>
            <ion-list class="ion-padding-horizontal search-list">
              <ion-row *ngFor="let result of results" (click)="this.setStudent(result)" class="ion-padding-vertical name-search-row">{{result.Name}}</ion-row>
            </ion-list>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ion-row>
    <ion-row>Destination<ion-text class="required">*</ion-text></ion-row>
    <ion-select placeholder="No destination selected" (ionChange)="destinationChanged($event)">
      <ion-select-option *ngFor="let facility of facilities" [value]="facility">{{facility.Title}}</ion-select-option>
    </ion-select>
    <ion-row class="ion-padding-bottom">
      <ion-row>Scheduled Time: </ion-row>
    </ion-row>
    <ion-row class="ion-padding-bottom ion-margin-bottom last-row">
      <div *ngIf="!selectingTime">
        <ion-text style="vertical-align: sub">Now</ion-text>
        <ion-button style="--background:var(--eventlink-secondary); margin-left:16px" appPreventMultiClickEvents (preventMultiClicks)="this.selectingTime = true">Change</ion-button>
      </div>
      <ion-datetime-button
        *ngIf="selectingTime"
        color="secondary"
        datetime="datetime"
        class="change-time-btn"
        appPreventMultiClickEvents>
        Change Time
      </ion-datetime-button>
      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <form [formGroup]="formGroup">
            <ion-datetime id="datetime"  formControlName="selectTimeForm"></ion-datetime>
          </form>
        </ng-template>
      </ion-modal>
    </ion-row>
    <ion-row class="table-header">
      <ion-col class="table-column">Time Out</ion-col>
      <ion-col class="table-column">Time In</ion-col>
      <ion-col class="table-column-last">Destination</ion-col>
    </ion-row>
    <ion-row *ngIf="!this.currentStudent" class="ion-padding-top placeholderText">Select a student to view their recent hall pass history</ion-row>
    <ion-row *ngIf="this.currentStudent && currentHistory.length < 1" class="ion-padding-top placeholderText"><span style="justify-content: center">This student has no recent hall pass history</span></ion-row>
    <ion-row class="table-body" *ngFor="let pastPass of currentHistory">
      <ion-col class="table-column">{{pastPass.TimeOut}}</ion-col>
      <ion-col *ngIf="!pastPass.TimeIn" class="table-column">Active</ion-col>
      <ion-col *ngIf="pastPass.TimeIn"  class="table-column">{{pastPass.TimeIn}}</ion-col>
      <ion-col class="table-column-last">{{pastPass.Destination}}</ion-col>
    </ion-row>
  </ion-grid>
  <div class="content-sticky">
    <ion-button
      expand="block"
      class="hall-pass-tab-btn button-height"
      appPreventMultiClickEvents
      (preventMultiClicks)="issuePass()">
      <fa-icon icon="plus" size="lg" class="add-icon"></fa-icon>
      <ion-text style="padding-left: 5px;">Issue</ion-text>
    </ion-button>
  </div>
</ion-content>
