import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventAthleticsLegacyApiService } from '../../../../core/services/api/v1/EventAthleticsLegacyApi.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { Guid } from 'src/app/core/services/api/models/ApiTypes';
import { dtoEventLongForm } from 'src/app/core/services/api/models/legacy/dtoEventLongForm';

@Component({
  selector: 'app-linked-events',
  templateUrl: './linked-events.component.html',
  styleUrls: ['./linked-events.component.scss'],
})
export class LinkedEventsComponent implements OnInit {
  @Input() linkedID!: Guid;
  @Input() timezone!: string;
  linkedGames: dtoEventLongForm[] = [];

  constructor(
    public readonly modalCtrl: ModalController,
    private readonly eventAthleticsLegacyApiService: EventAthleticsLegacyApiService,
    private readonly utilsService: UtilsService
  ) {}

  async ngOnInit() {
    let response = await this.eventAthleticsLegacyApiService.getLinkedByEventID(this.linkedID);
    if (response.isAnyError) {
      return;
    }

    this.linkedGames = response.data ?? [];
  }

  cancel() {
    this.modalCtrl.dismiss('cancel');
  }

  getStartDateTime(dateTime: string, isAllDay: boolean) {
    if (!this.timezone) {
        return '';
    }
    if (isAllDay) {
        return this.utilsService.getDateTimeCustomFormat(dateTime, this.timezone, 'dddd, M/DD/YYYY ') + ' - TBD';
    }
    return this.utilsService.getDateTimeCustomFormat(dateTime, this.timezone, 'dddd, M/DD/YYYY - h:mm A z');
}
}
