<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Share Event</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding-horizontal ion-padding-top">
    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter email here..."
          [(ngModel)]="email">
          <div slot="label">Recipient Email Address <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-05">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="submit()"
          expand="block">
          <fa-icon icon="paper-plane" size="lg"></fa-icon> <ion-text> Send </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
