import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { SuccessService } from '../../../../core/services/success/success.service';
import { OAuthApiService } from 'src/app/core/services/api/v2/OAuthApi.service';
import { int } from 'src/app/core/services/api/models/ApiTypes';
import { AuthService } from '../../../../core/services/auth/auth.service';

interface ContactOption {
  ID: string;
  Title: string;
  ContactType: string;
}

@Component({
  selector: 'app-mfa-login',
  templateUrl: './mfa-login.component.html',
  styleUrls: ['./mfa-login.component.scss'],
})
export class MfaLoginComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() mfaCode: string;
  rememberDevice: boolean = false;
  contactPoints?: ContactOption[];
  sessionID!: int;
  sessionToken!: string;

  constructor(
    private readonly successService: SuccessService,
    public readonly modalCtrl: ModalController,
    private readonly oAuthApiService: OAuthApiService,
    private readonly authService: AuthService
  ) {
    if (!this.sessionID) {
      this.sessionID = 0;
    }
    if (!this.sessionToken) {
      this.sessionToken = '';
    }
    this.mfaCode = '';
  }

  async startMultiFactorAuth(value: string) {
    let response = await this.oAuthApiService.startMultiFactor(this.sessionID, this.sessionToken, value);
    if (response.isAnyError) {
      return;
    }

    this.successService.showSuccess('The code will arrive momentarily.', 5000, 'Code Sent');
  }

  async verifyMultiFactorAuth() {
    let model = {
      SessionID: this.sessionID,
      SessionToken: this.sessionToken,
      VerificationCode: this.mfaCode.trim(),
      RememberDevice: this.rememberDevice,
    };

    const response = await this.oAuthApiService.verifyMultiFactor(model);
    if (response.isAnyError) {
      return;
    }

    if (response?.data?.DeviceTicket) {
      const persistentContext = await this.authService.getPersistentContext();
      if (persistentContext && persistentContext.DeviceTicket) {
        persistentContext.DeviceTicket = response.data.DeviceTicket;
        await this.authService.updatePersistentContext(persistentContext);
        return;
      }

      await this.authService.insertPersistentContext({ DeviceTicket: response.data.DeviceTicket });
    }

    await this.modalCtrl.dismiss(response);
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
