<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Search Payments</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formGroup" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter payor..."
          type="text"
          autocapitalize="on"
          formControlName="payor">
          <div slot="label">Payor</div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-column">
      <ion-text style="font-size: smaller"> Amount</ion-text>
      <ion-item>
        <ion-text style="width: 10%">$</ion-text>
        <ion-input style="width: 40%" formControlName="lowerAmount" type="number"></ion-input>
        <ion-text style="width: 10%"> to $</ion-text>
        <ion-input style="margin-left: 5px; width: 40%" formControlName="upperAmount" type="number"></ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-select label-placement="stacked" formControlName="dateRange">
          <div slot="label">Date Range</div>
          <ion-select-option value="30" selected="selected">Last 30 Days</ion-select-option>
          <ion-select-option value="90">Last 90 Days</ion-select-option>
          <ion-select-option value="180">Last 180 Days</ion-select-option>
          <ion-select-option value="Custom">Custom Range</ion-select-option>
        </ion-select>
      </ion-item>
    </span>
    <ion-grid *ngIf="this.formGroup.get('dateRange')?.value === 'Custom'" style="margin-bottom: 30dvh">
      <ion-row class="ion-padding-right ion-padding-top">
        <ion-text style="margin-left: 20px"> From </ion-text>
      </ion-row>
      <ion-row class="ion-padding">
        <ion-datetime id="datetime1" presentation="date" formControlName="customEarlierDate"></ion-datetime>
      </ion-row>
      <ion-row class="ion-padding-right">
        <ion-text style="margin-left: 20px">To</ion-text>
      </ion-row>
      <ion-row class="ion-padding">
        <ion-datetime id="datetime2" presentation="date" formControlName="customLaterDate"></ion-datetime>
      </ion-row>
    </ion-grid>
  </form>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="search()"
          expand="block"
          [disabled]="!formGroup.valid">
          <ion-text> Search </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
