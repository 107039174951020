<ion-tabs id="main-content">
  <ion-tab-bar slot="home">
    <ion-tab-button tab="home">
      <div class="icon-container">
        <ion-img src="../../../../assets/images/appIcon/appicon.png" class="home-icon"></ion-img>
        <ion-text>Home</ion-text>
      </div>
    </ion-tab-button>
    <ion-tab-button tab="calendar">
      <div class="icon-container">
        <fa-icon icon="calendar-days" size="2x" class="icon"></fa-icon>
        Calendar
      </div>
    </ion-tab-button>
    <ion-tab-button *ngIf="applicationTypeService.applicationType$.getValue() === 'Officials'" tab="games">
      <div class="icon-container">
        <fa-icon icon="person-running" size="2x"></fa-icon>
        Games
      </div>
    </ion-tab-button>
    <ion-tab-button *ngIf="applicationTypeService.applicationType$.getValue() === 'Community'" tab="tickets">
      <div class="icon-container">
        <fa-icon icon="ticket" size="2x" class="icon"></fa-icon>
        Tickets
      </div>
    </ion-tab-button>
    <ion-tab-button *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'" tab="messages">
      <div class="icon-container">
        <fa-icon icon="envelope" size="2x" class="icon"></fa-icon>
        Messages
      </div>
    </ion-tab-button>
    <ion-tab-button (click)="openMenu()">
      <div class="icon-container">
        <fa-icon icon="bars" class="icon" size="2x"></fa-icon>
        Menu
      </div>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>

<ion-menu contentId="main-content" side="end" style="outline: none">
  <ion-header>
    <ion-toolbar>
      <ion-title style="text-align: center">Menu</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-tab-bar class="menu-tabs background-transparent" style="overflow: auto">
      <ion-tab-button
        tab="kiosk"
        *ngIf="applicationTypeService.applicationType$.getValue() === 'Community' && userHasKioskAccess.getValue()"
        (click)="kiosk()"
        onkeydown="kiosk()">
        <fa-icon icon="store"></fa-icon>
        Kiosk
      </ion-tab-button>
      <ion-tab-button
        *ngIf="applicationTypeService.applicationType$.getValue() !== 'Officials' && showRefresh"
        (click)="refresh()"
        ><fa-icon icon="arrows-rotate"></fa-icon>Refresh</ion-tab-button
      >
      <ion-tab-button
        *ngIf="applicationTypeService.applicationType$.getValue() !== 'Staff'"
        (click)="navigateTo('pay')"
        ><fa-icon icon="money-bill-1"></fa-icon> Pay</ion-tab-button
      >
      <ion-tab-button
        *ngIf="applicationTypeService.applicationType$.getValue() === 'Officials'"
        (click)="navigateTo('blocks')"
        ><fa-icon icon="ban"></fa-icon>Blocks</ion-tab-button
      >
      <ion-tab-button *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'"
      (click)="navigateTo('kiosk')"
        ><fa-icon icon="cash-register"></fa-icon>Kiosks</ion-tab-button
      >
      <ion-tab-button *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'"
        ><fa-icon icon="calendar-pen"></fa-icon> Registrations</ion-tab-button
      >
      <ion-tab-button *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'"
      (click)="navigateTo('articles')"
        ><fa-icon icon="newspaper"></fa-icon>Articles</ion-tab-button
      >
      <ion-tab-button
        *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'"
        (click)="navigateTo('pass')"
        ><fa-icon icon="id-badge"></fa-icon>Hall Pass</ion-tab-button
      >
      <ion-tab-button *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'"
        ><fa-icon icon="paintbrush"></fa-icon>Studio</ion-tab-button
      >
      <ion-tab-button
        *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff'"
        (click)="navigateTo('payment-list')"
        ><fa-icon icon="money-bill-1"></fa-icon> Payment List</ion-tab-button
      >
    </ion-tab-bar>
  </ion-content>
  <ion-title class="menu-title">Account</ion-title>
  <ion-content>
    <ion-tab-bar class="menu-tabs background-transparent" style="overflow: auto">
      <ion-accordion-group>
        <ion-accordion *ngIf="context && context.Community">
          <ion-item class="ion-no-padding dropdown-padding" slot="header"
            ><ion-label>Switch to Community</ion-label></ion-item
          >
          <div slot="content">
            <ion-button
              *ngFor="let organization of communityOrganizations"
              expand="block"
              (click)="setActiveOrganization('Community', organization)">
              <span class="dropdown-context" *ngIf="organization.OrganizationDisplayTitle">
                {{ organization.OrganizationDisplayTitle }}</span
              >
              <span class="dropdown-context" *ngIf="!organization.OrganizationDisplayTitle">
                {{ organization.OrganizationTitle }}</span
              >
            </ion-button>
            <ion-button
              *ngIf="!communityOrganizations"
              expand="block"
              (click)="setActiveOrganization('Community')">
              <span class="dropdown-context">Community</span>
            </ion-button>
          </div>
        </ion-accordion>
        <ion-accordion *ngIf="context && context.Staff">
          <ion-item class="ion-no-padding dropdown-padding" slot="header"
            ><ion-label>Switch to Staff</ion-label></ion-item
          >
          <div slot="content">
            <ion-button
              *ngFor="let organization of staffOrganizations"
              expand="block"
              (click)="setActiveOrganization('Staff', organization)">
              <span class="dropdown-context" *ngIf="organization.OrganizationDisplayTitle">
                {{ organization.OrganizationDisplayTitle }}</span
              >
              <span class="dropdown-context" *ngIf="!organization.OrganizationDisplayTitle">
                {{ organization.OrganizationTitle }}</span
              >
            </ion-button>
            <ion-button *ngIf="!staffOrganizations" expand="block" (click)="setActiveOrganization('Staff')">
              <span class="dropdown-context">Staff</span>
            </ion-button>
          </div>
        </ion-accordion>
        <ion-accordion *ngIf="context && context.Official">
          <ion-item class="ion-no-padding dropdown-padding" slot="header"
            ><ion-label>Switch to Officials</ion-label></ion-item
          >
          <div slot="content">
            <ion-button expand="block" (click)="accountSelection('Officials')">
              <span class="dropdown-context">Officials</span>
            </ion-button>
          </div>
        </ion-accordion>
        <ion-accordion>
          <ion-item class="ion-no-padding dropdown-padding" slot="header"
            ><ion-label>My Account</ion-label></ion-item
          >
          <div slot="content">
            <ion-tab-button
              *ngIf="
                applicationTypeService.applicationType$.getValue() === 'Officials' && manageNotificationFeatureFlag
              "
              (click)="navigateTo('notifications')"
              ><fa-icon icon="bell-on"></fa-icon>Notifications</ion-tab-button
            >
            <ion-tab-button (click)="navigateTo('profile')"
              ><fa-icon icon="user"></fa-icon>Manage Profile</ion-tab-button
            >
            <ion-tab-button><fa-icon icon="school"></fa-icon>Manage Schools</ion-tab-button>
            <ion-tab-button (click)="openChangePassword()"
              ><fa-icon icon="key"></fa-icon>Change Password</ion-tab-button
            >
          </div>
        </ion-accordion>
      </ion-accordion-group>
      <ion-tab-button>Terms of Service</ion-tab-button>
      <ion-tab-button (click)="uploadLogs()">Upload Support Log</ion-tab-button>
      <ion-tab-button (click)="logout()">Logout</ion-tab-button>
    </ion-tab-bar>
  </ion-content>
</ion-menu>
