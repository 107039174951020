import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { DtoCashTicketRequest } from 'src/app/core/services/api/models/DtoCashTicketRequest';
import { DtoKioskCashSubmission } from 'src/app/core/services/api/models/DtoKioskCashSubmission';
import { KioskPurchasesApiService } from 'src/app/core/services/api/v2/KioskPurchasesApi.service';
import { TicketPurchasesApiService } from 'src/app/core/services/api/v2/TicketPurchasesApi.service';

@Component({
  selector: 'app-checkout-cash',
  templateUrl: './cash-modal.component.html',
  styleUrls: ['./cash-modal.component.scss'],
})
export class CheckoutCashComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() cashSubmission!: DtoKioskCashSubmission | undefined;
  @Input() cashTicketRequests!: DtoCashTicketRequest[] | undefined;
  @Input() accessToken!: string;
  @Input() total!: number;
  @Input() ticketConfigurationID: string | undefined;

  cashGiven: number | undefined;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly kioskPurchasesService: KioskPurchasesApiService,
    private readonly ticketPurchasesService: TicketPurchasesApiService,
    private readonly alertController: AlertController
  ) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submitCash() {
    let response: any;

    if (!this.cashGiven) {
      response = await this.submitCashRequest();

      if (response.status === 400) {
        await this.modalCtrl.dismiss({ success: false }, 'error');
        return;
      }

      const alert = await this.alertController.create({
        header: 'Cash Transaction Complete',
        message: 'The transaction has been completed successfully.',
        buttons: ['OK'],
      });

      await alert.present();

      await this.dismissModal(response);

      return;
    }

    const cashBack = this.cashGiven - this.total;

    if (cashBack < 0) {
      const alert = await this.alertController.create({
        header: 'Not Enough Cash Received',
        message: 'Please enter a valid amount of cash.',
        buttons: ['OK'],
      });

      await alert.present();

      return;
    }

    response = await this.submitCashRequest();

    if (response.status === 400) {
      await this.modalCtrl.dismiss({ success: false }, 'error');
      return;
    }

    const alert = await this.alertController.create({
      header: 'Cash Back',
      message: 'Please give the customer $' + cashBack.toFixed(2) + ' back in cash.',
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            const alert = await this.alertController.create({
              header: 'Cash Transaction Complete',
              message: 'The transaction has been completed successfully.',
              buttons: ['OK'],
            });

            await alert.present();
          },
        },
      ],
    });

    await alert.present();

    await this.dismissModal(response);
  }

  async submitCashRequest() {
    let response: any = null;

    if (this.cashTicketRequests) {
      response = await this.ticketPurchasesService.submitCash(
        parseInt(this.ticketConfigurationID!),
        this.cashTicketRequests
      );
    } else {
      response = await this.kioskPurchasesService.submitCash(this.cashSubmission!);
    }

    if (response.isAnyError) {
      await this.modalCtrl.dismiss({ success: false }, 'error');

      return;
    }

    return response;
  }

  async dismissModal(response: any) {
    if (this.cashTicketRequests) {
      await this.modalCtrl.dismiss({ success: true }, 'success');
      return;
    }

    await this.modalCtrl.dismiss({ success: true, kioskOrderID: response.data.KioskOrderID }, 'success');
  }
}
