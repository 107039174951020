// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid, int } from "../models/ApiTypes";
import { DtoKioskSession } from "../models/DtoKioskSession";
import { DtoKioskSessionStart } from "../models/DtoKioskSessionStart";
import { DtoKioskSessionEnd } from "../models/DtoKioskSessionEnd";

@Injectable({providedIn: 'root'})
export class KioskSessionsApiService {
    private readonly controller = "KioskSessions";

    constructor(private v2Api: V2ApiService) { }

    /** Returns kiosk sessions for an organization. Use true for onlyOpen to get only sessions that have been started but not ended yet.
     * @param organizationID 
     * @param onlyOpen 
     */
    getByOrganizationID(organizationID: Guid, onlyOpen: boolean | undefined) {
        return this.v2Api.get<DtoKioskSession[]>(this.controller, "GetByOrganizationID", { organizationID, onlyOpen });
    }

    /** 
     * @param body 
     */
    start(body: DtoKioskSessionStart) {
        return this.v2Api.post<DtoKioskSession>(this.controller, "Start", {  }, body);
    }

    /** 
     * @param body 
     */
    end(body: DtoKioskSessionEnd) {
        return this.v2Api.post<void>(this.controller, "End", {  }, body);
    }

    /** 
     * @param paymentIntentID 
     * @param kioskSessionID 
     * @param organizationID 
     */
    refund(paymentIntentID: Guid, kioskSessionID: int, organizationID: Guid) {
        return this.v2Api.post<void>(this.controller, "Refund", { paymentIntentID, kioskSessionID, organizationID });
    }

    /** 
     * @param kioskSessionID 
     * @param kioskOrderID 
     * @param organizationID 
     */
    refundCash(kioskSessionID: int, kioskOrderID: int, organizationID: Guid) {
        return this.v2Api.post<void>(this.controller, "RefundCash", { kioskSessionID, kioskOrderID, organizationID });
    }

    /** 
     * @param kioskSessionID 
     */
    getByID(kioskSessionID: int) {
        return this.v2Api.get<DtoKioskSession>(this.controller, "GetByID", { kioskSessionID });
    }
}