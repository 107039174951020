<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="close()" onkeydown="close()" *ngIf="modalTitle === 'Past Orders'"
        >Close</ion-button
      >
      <ion-button color="medium" (click)="back()" onkeydown="back()" *ngIf="modalTitle === 'Order Details'"
        >Back</ion-button
      >
    </ion-buttons>
    <ion-title>{{ modalTitle }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="close()" onkeydown="close()" *ngIf="modalTitle === 'Order Details'"
        >Close</ion-button
      >
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div *ngIf="modalTitle === 'Past Orders'">
    <ion-list class="past-order-list">
      <ion-button
        fill="clear"
        color="medium"
        (click)="orderDetail(order)"
        onkeydown="orderDetail(order)"
        *ngFor="let order of pastOrders"
        class="past-order-item-button"
        slot="start">
        <div class="section" [ngStyle]="{ 'border-left': '4px solid var(--eventlink-primary)' }"></div>
        <div>
          <ion-row>
            <ion-text class="paid-text">Paid: {{ order.Total | currency }}</ion-text>
          </ion-row>
          <ion-row>
            <ion-text class="paid-sub">Items: {{ order.Items.length }}</ion-text>
          </ion-row>
          <ion-row>
            <ion-text class="paid-sub">{{ order.Type }}</ion-text>
            <ion-text class="paid-sub"> {{ order.Refunded ? 'Refunded' : '' }}</ion-text>
          </ion-row>
        </div>
      </ion-button>
    </ion-list>
  </div>

  <div *ngIf="modalTitle === 'Order Details'" class="container">
    <ion-list class="order-detail-list">
      <ion-item *ngFor="let item of selectedOrder.Items">
        <div class="order-detail-list-container">
          <ion-text class="item-detail-quantity">{{ item.Quantity }}</ion-text>

          <div
            class="section"
            [ngStyle]="{
              'border-left':
                item.Type === 'item'
                  ? '4px solid var(--eventlink-primary)'
                  : '4px solid var(--eventlink-secondary)'
            }"></div>

          <div class="text-container">
            <ion-label class="ion-text-wrap title-text">{{ item.Title }}</ion-label>
            <ion-text class="description-text">{{ item.Description }}</ion-text>
            <ion-text>{{ item.Cost * item.Quantity | currency }}</ion-text>
          </div>
        </div>
      </ion-item>
    </ion-list>

    <ion-text class="paid-text-order-detail">Paid: {{ selectedOrder.Total | currency }}</ion-text>
    <div class="button-container">
      <ion-button
        fill="clear"
        color="medium"
        (click)="refundPurchase()"
        onkeydown="refundPurchase()"
        *ngIf="!selectedOrder.Refunded"
        ><ion-text style="color: var(--eventlink-primary)">Refund Purchase</ion-text></ion-button
      >
      <ion-button
        fill="clear"
        color="medium"
        (click)="resendReceipt()"
        onkeydown="resendReceipt()"
        *ngIf="selectedOrder.Type === 'Credit'"
        ><ion-text style="color: var(--eventlink-primary)">Resend Receipt</ion-text></ion-button
      >
    </div>
  </div>
</ion-content>
