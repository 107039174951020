// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoINamed } from "../models/legacy/dtoINamed";

@Injectable({providedIn: 'root'})
export class OrganizationMemberLegacyApiService {
    private readonly module = "OrganizationMember";

    constructor(private legacyApi: LegacyApiService) { }

    /** Gets all members of an organization, the user must have staff access to the organization.
     * @param id Organization ID
     */
    getAllByOrganizationID(id: Guid) {
        return this.legacyApi.get<dtoINamed[] | undefined>(this.module, "GetAllByOrganizationID", { id });
    }
}