import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import IContextModel from "../../../models/context/context.model";
import IContextOrganizationModel from '../../../models/context/context-organization.models';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class ApplicationTypeService {
  applicationType$ = new BehaviorSubject<string>("");
  organizationName$ = new BehaviorSubject<string>("");
  applicationTypeDidChange = new EventEmitter();

  constructor(private readonly router: Router) {
  }

  public async setActive(applicationType: string, organization: IContextOrganizationModel | undefined,
                         context: IContextModel, saveContext: (c: IContextModel) => Promise<void>) {
    this.applicationType$.next(applicationType);
    context.ApplicationType = applicationType;
    context.OrganizationId = organization?.ID;
    this.organizationName$.next(organization?.OrganizationDisplayTitle ?? organization?.OrganizationTitle ?? "");

    await saveContext(context);
    await this.router.navigate(['/']);

    this.applicationTypeDidChange.emit();
  }
}
