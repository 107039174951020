import { Injectable } from '@angular/core';
import { EventlinkLegacyHttpResponse } from './EventlinkLegacyHttpResponse';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../../error/error.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import {
  EventlinkAllowedMethods,
  EventlinkApiServiceBase,
  EventlinkHeaders,
  EventlinkQueryParams,
  EventlinkRequest,
} from './EventlinkApiServiceBase';
import { LoadingService } from '../../loading/loading.service';
import { ContextRepository } from 'src/app/core/repositories/context.repository';
import { IsAuthenticatedService } from '../../auth/is-authenticated/is-authenticated.service';
import { AppInfoService } from '../../app-info/app-info.service';

@Injectable({
  providedIn: 'root',
})
export class LegacyApiService extends EventlinkApiServiceBase {
  constructor(
    contextRepository: ContextRepository,
    errorService: ErrorService,
    logger: NGXLogger,
    router: Router,
    private readonly loadingService: LoadingService,
    isAuthenticatedService: IsAuthenticatedService,
    appInfoService: AppInfoService
  ) {
    super(contextRepository, errorService, logger, router, isAuthenticatedService, appInfoService);
  }

  /**
   * HTTP GET
   * @param controller AKA module, the ?m= parameter
   * @param action AKA action, the &a= parameter
   * @param queryParams Additional query string parameters, if any
   * @returns Wrapped HTTP response
   */
  public async get<T>(
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any }
  ): Promise<EventlinkLegacyHttpResponse<T>> {
    return await this.loadingService.loadingWhile(
      this.buildAndExecuteRequest('GET', controller, action, queryParams)
    );
  }

  /**
   * HTTP POST
   * @param controller AKA module, the ?m= parameter
   * @param action AKA action, the &a= parameter
   * @param queryParams Additional query string parameters, if any
   * @param body Payload data, if any
   * @returns Wrapped HTTP response
   */
  public async post<T, TConflict = void>(
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any },
    body?: any
  ): Promise<EventlinkLegacyHttpResponse<T, TConflict>> {
    return await this.loadingService.loadingWhile(
      this.buildAndExecuteRequest<T, TConflict>('POST', controller, action, queryParams, body)
    );
  }

  private async buildAndExecuteRequest<T, TConflict>(
    type: 'GET' | 'POST',
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any },
    body?: any,
    useFormData = false
  ): Promise<EventlinkLegacyHttpResponse<T, TConflict>> {
    let wrapped: EventlinkLegacyHttpResponse<T, TConflict>;
    try {
      const request = await this.buildLegacyRequest(type, controller, action, queryParams, body);
      this.logger.debug(`Sending ${request.method} request to ?m=${controller}&a=${action}`);

      if (useFormData) {
        delete request.headers['Content-Type'];
      }

      const fetchOptions = this.createFetchOptions(request, useFormData);
      const response = await fetch(request.urlWithQuery(), fetchOptions);

      const rawData = await this.processResponse(response, controller, action);
      wrapped = new EventlinkLegacyHttpResponse<T, TConflict>(response, rawData);
    } catch (exception: any) {
      this.logger.error(exception);
      wrapped = new EventlinkLegacyHttpResponse<T, TConflict>({ status: -1 } as Response, undefined, exception);
    }

    if (wrapped.isAnyError) {
      await super.tryHandleErrors(wrapped, controller, action, 'legacy');
    }

    return wrapped;
  }

  private createFetchOptions(request: EventlinkRequest, useFormData: boolean): RequestInit {
    let fetchOptions: RequestInit = {
      method: request.method,
      headers: request.headers,
    };

    if (useFormData) {
      fetchOptions.body = this.createFormData(request.body);
    } else {
      fetchOptions.body = JSON.stringify(request.body);
    }

    return fetchOptions;
  }

  private createFormData(body: any): FormData {
    let formData = new FormData();

    for (let field in body) {
      let formValue = body[field];
      if (formValue instanceof FileList) {
        formValue = formValue[0];
      }

      if (Array.isArray(formValue)) {
        for (let i in formValue) {
          formData.set(`${field}[${i}]`, formValue[i]);
        }
      } else if (formValue != undefined && formValue != null) {
        formData.set(field, formValue);
      }
    }

    return formData;
  }

  private async processResponse(response: Response, controller: string, action: string): Promise<any> {
    let rawData: any = undefined;
    const contentType = response.headers.get('Content-Type');
    if (
      contentType &&
      ['application/json', 'application/problem+json; charset=utf-8', 'application/json; charset=utf-8'].indexOf(
        contentType
      ) >= 0
    ) {
      rawData = await response.json();
    } else if (response.status != 204) {
      this.logger.info(
        `${this.formatControllerAction(
          controller,
          action,
          'legacy'
        )} response content type couldn't be processed: ${contentType}`
      );
    }
    return rawData;
  }

  private async buildLegacyRequest(
    type: EventlinkAllowedMethods,
    controller: string,
    action: string,
    queryParams?: { [Key: string]: any },
    body?: any
  ): Promise<EventlinkRequest> {
    let query: EventlinkQueryParams = {};
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams[key] != undefined) query[key] = queryParams[key];
      }
    }
    query['m'] = controller;
    query['a'] = action;

    if (type == 'GET' && body) {
      throw new Error("GET requests can't have a body!");
    }

    let headers: EventlinkHeaders = {};

    const context = await this.contextRepository.getContext();
    if (context?.AccessToken) {
      headers['Authorization'] = `Bearer ${context.AccessToken}`;
    }

    await this.addVersionHeader(headers);

    return new EventlinkRequest(type, environment.api.endpoint, query, headers, body);
  }
}
