import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import IContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import IContextOrganizationModel from '../../../../core/models/context/context-organization.models';
import { EventLegacyApiService } from '../../../../core/services/api/v1/EventLegacyApi.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  @Input() event: any;
  context!: IContextModel;
  organization?: IContextOrganizationModel;
  foundEvent: any;
  contextOrganization!: IContextOrganizationModel;
  isHomeEvent: boolean = false;

  constructor(
    public readonly modalCtrl: ModalController,
    private readonly authService: AuthService,
    private readonly eventLegacyApiService: EventLegacyApiService
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();
    this.organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

    const response = await this.eventLegacyApiService.find(this.event.ID);

    if (response.isAnyError) {
      return;
    }

    this.foundEvent = response.data;

    if (this.event.StartDateTime) {
      let dateTime: string = this.event.StartDateTime.toString();

      let dateSplit = dateTime.split('T');

      let date = dateSplit[0];
      let components = date.split('-');

      if (Number(components[1]) < 10) {
        components[1] = components[1].replace('0', '');
      }
      if (Number(components[2]) < 10) {
        components[2] = components[2].replace('0', '');
      }
      let newDate = '';
      newDate = newDate.concat(components[1]);
      newDate = newDate.concat('/' + components[2]);
      newDate = newDate.concat('/' + components[0]);
      if (dateSplit.length > 1) {
        let newTime = this.splitTime(dateSplit);
        newDate = newDate.concat(', ' + newTime);

        if (this.event.EndDateTime && this.event.EndDateTime.split('T').length > 1) {
          let endTime = this.splitTime(this.event.EndDateTime.split('T'));
          newDate = newDate.concat(' - ' + endTime);
        }
      }
      this.foundEvent.StartDateTime = newDate;
    } else {
      this.foundEvent.StartDateTime = 'TBD';
    }
    this.contextOrganization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);
    if (
      this.foundEvent.HostingOrganizationID === this.contextOrganization.OrganizationId &&
      this.foundEvent.ContractID
    ) {
      this.isHomeEvent = true;
    }
  }

  private splitTime(dateSplit: string[]) {
    let newTime = '';
    let components = dateSplit[1].split(':');
    let m = '';
    let numberValue: number = +components[0];
    if (numberValue <= 12) {
      m = 'AM';
    } else {
      numberValue = numberValue - 12;
      components[0] = numberValue.toString();
      m = 'PM';
    }
    newTime = newTime.concat(components[0]);
    newTime = newTime.concat(':' + components[1]);
    return newTime.concat(' ' + m);
  }

  protected returnRepeatValuesStatement(rule: any) {
    let values = rule.Values;
    let statement = 'on the ';
    for (let i = 0; i < values.length; i++) {
      if (i == values.length - 1) {
        statement = statement.concat(values[i]);
        statement = statement.concat(this.checkEnding(values[i]));
      } else {
        statement = statement.concat(values[i]);
        statement = statement.concat(this.checkEnding(values[i]), ', ');
      }
    }
    return statement;
  }

  protected checkEnding(date: number) {
    if (date > 3 && date < 21) return 'th';
    switch (date % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
