// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { dtoEventLongForm } from "../models/legacy/dtoEventLongForm";
import { Guid, DateOnly } from "../models/ApiTypes";
import { dtoEventJob } from "../models/legacy/dtoEventJob";
import { dtoEventJobSlotCreateUpdate } from "../models/legacy/dtoEventJobSlotCreateUpdate";
import { dtoRemoveMultipleJobSlots } from "../models/legacy/dtoRemoveMultipleJobSlots";
import { dtoEventUserJobs } from "../models/legacy/dtoEventUserJobs";

@Injectable({providedIn: 'root'})
export class EventJobSlotLegacyApiService {
    private readonly module = "EventJobSlot";

    constructor(private legacyApi: LegacyApiService) { }

    /** Get Events that contain Job Slots
     * @param teamIDs string of TeamIDs seperated by commas
     * @param ranges string of ranges in json format
     */
    getEventsWithJobSlots(teamIDs: string, ranges: string) {
        return this.legacyApi.get<dtoEventLongForm[] | undefined>(this.module, "GetEventsWithJobSlots", { teamIDs, ranges });
    }

    /** Retrieves EventJob object(s) by eventID, user must be a member of the organization involving the event.
     * @param eventID 
     */
    getByEventID(eventID: Guid) {
        return this.legacyApi.get<dtoEventJob[] | undefined>(this.module, "GetByEventID", { eventID });
    }

    /** Creates EventJob object(s) from json, user must have either Athletic Permissions or Worker Permissions.
     * @param body 
     */
    createMany(body: dtoEventJobSlotCreateUpdate[]) {
        return this.legacyApi.post<dtoEventJob[] | undefined>(this.module, "CreateMany", {  }, body);
    }

    /** Updates EventJob object(s) from json, user must have either Athletic Permissions or Worker Permissions. Throws 400 if trying to update a worker job that has already been paid.
     * @param body 
     */
    updateMany(body: dtoEventJobSlotCreateUpdate[]) {
        return this.legacyApi.post<dtoEventJob[] | undefined>(this.module, "UpdateMany", {  }, body);
    }

    /** Removes a Job Slot by ID, user must be an organization user.
     * @param slotID 
     * @param body Runs when slotID is null
     */
    remove(slotID: Guid | undefined, body: dtoRemoveMultipleJobSlots) {
        return this.legacyApi.post<void>(this.module, "Remove", { slotID }, body);
    }

    /** Returns list of Event Open Job Slots an Organization has, throws 400 if user is not an approved worker for organization.
     * @param organizationID 
     * @param start 
     * @param end 
     */
    getOpen(organizationID: Guid, start: DateOnly, end: DateOnly) {
        return this.legacyApi.get<dtoEventUserJobs[] | undefined>(this.module, "GetOpen", { organizationID, start, end });
    }

    /** Returns list of Event Job Slots a user has been accepted for, for a specific organization.
     * @param organizationID 
     * @param start 
     * @param end 
     */
    getAccepted(organizationID: Guid, start: DateOnly, end: DateOnly) {
        return this.legacyApi.get<dtoEventUserJobs[] | undefined>(this.module, "GetAccepted", { organizationID, start, end });
    }

    /** Lets a user accept an Event Job Slot. Throws 400 if you are not an approved worker for the organization, the job has been filled, job is not open to being accepted, or job has passed. Accepting can be forced.   
     * @param jobSlotID 
     * @param force 
     * @returns This endpoint can return a 409 Conflict response.
     */
    accept(jobSlotID: Guid, force: Guid | undefined) {
        return this.legacyApi.post<void, dtoEventUserJobs[] | undefined>(this.module, "Accept", { jobSlotID, force });
    }
}