import { Component, Input } from '@angular/core';
import { ModalController, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import IContextOrganizationModel from 'src/app/core/models/context/context-organization.models';
import IContextModel from 'src/app/core/models/context/context.model';
import { DtoAthleticsContactMessage } from 'src/app/core/services/api/models/DtoAthleticsContactMessage';
import { dtoContactPointShortForm } from 'src/app/core/services/api/models/legacy/dtoContactPointShortForm';
import { ContactPointLegacyApiService } from 'src/app/core/services/api/v1/ContactPointLegacyApi.service';
import { AthleticsContactApiService } from 'src/app/core/services/api/v2/AthleticsContactApi.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-send-email-officials-workers',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss'],
})
export class SendEmailOfficialsWorkersModalComponent implements ViewDidEnter, ViewDidLeave {
  @Input() EventID!: string;
  @Input() RecipientsList!: {ID:string, Name:string}[];
  @Input() SelectedRecipientIDs!: string[];
  @Input() isForOfficials!: boolean;

  emailContent: DtoAthleticsContactMessage;
  contactPoints: dtoContactPointShortForm[] = [];
  context!: IContextModel;
  organization?: IContextOrganizationModel;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly athleticsContactApiService: AthleticsContactApiService,
    private readonly contactPointLegacyApiService: ContactPointLegacyApiService,
    private readonly authService: AuthService
  ) {
    this.emailContent = {
        FromContactPointID: undefined,
        Subject: '',
        Message: '',
        ContactIDs: [],
    }
  }

  async ionViewDidEnter() {
    this.emailContent = {
      FromContactPointID: undefined,
      Subject: '',
      Message: '',
      ContactIDs: this.SelectedRecipientIDs,
    };
    this.context = await this.authService.getContext();
    this.organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

    const response = await this.contactPointLegacyApiService.getByUserID(this.context.UserID!);
    if (response.isAnyError) {
      return;
    }
    this.contactPoints =
      response.data?.filter((cp) => {
        return cp.ContactType === 'Email';
      }) ?? [];
  }

  ionViewDidLeave() {
    this.emailContent = {
      FromContactPointID: undefined,
      Subject: '',
      Message: '',
      ContactIDs: [],
    };
  }

  async sendEmail() {
    const response =
      this.isForOfficials
        ? await this.athleticsContactApiService.messageEventOfficials(this.organization?.OrganizationId!, this.EventID, this.emailContent)
        : await this.athleticsContactApiService.messageEventWorkers(this.organization?.OrganizationId!, this.EventID, this.emailContent);

    if (response.isSuccess) {
      this.modalCtrl.dismiss('success');
    }
  }

  cancel() {
    this.modalCtrl.dismiss('cancel');
  }
}
