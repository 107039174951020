// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid, int, DateTime } from "../models/ApiTypes";
import { DtoFacilityManagersChangeRequest } from "../models/DtoFacilityManagersChangeRequest";
import { DtoFacilityWithManagers } from "../models/DtoFacilityWithManagers";

@Injectable({providedIn: 'root'})
export class FacilitiesApiService {
    private readonly controller = "Facilities";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param organizationID 
     * @param body 
     */
    addFacilityManagerToMultiple(organizationID: Guid, body: DtoFacilityManagersChangeRequest) {
        return this.v2Api.post<void>(this.controller, "AddFacilityManagerToMultiple", { organizationID }, body);
    }

    /** 
     * @param organizationID 
     * @param body 
     */
    removeFacilityManagerFromMultiple(organizationID: Guid, body: DtoFacilityManagersChangeRequest) {
        return this.v2Api.post<void>(this.controller, "RemoveFacilityManagerFromMultiple", { organizationID }, body);
    }

    /** Search all facilities for an organization.
     * 
     * The only required parameter is OrganizationID.
     * 
     * If owner is true, only facilities where the organization is the owner will be returned.
     * 
     * If TimeZone is not provided then the current user's timezone will be used.
     * 
     * Permissions: User.
     * @param organizationID 
     * @param owner 
     * @param title 
     * @param capacity 
     * @param start 
     * @param end 
     * @param timeZone 
     */
    search(organizationID: Guid, owner: boolean | undefined, title: string | undefined, capacity: int | undefined, start: DateTime | undefined, end: DateTime | undefined, timeZone: string | undefined) {
        return this.v2Api.get<DtoFacilityWithManagers[]>(this.controller, "Search", { organizationID, owner, title, capacity, start, end, timeZone });
    }
}