<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">{{ haveResultsChanged ? 'Close' : 'Cancel' }}</ion-button>
    </ion-buttons>
    <ion-title>Postgame</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="loaded">
  <div class="separator-container ion-padding-horizontal">
    <ion-text class="text-separator">
      <span>Event Scores</span>
    </ion-text>
  </div>
  <!-- event results -->
  <div
    class="results-container ion-padding-horizontal"
    *ngIf="eventScores && eventScores.EventResults && eventScores.EventResults.length">
    <app-score-card
      class="results-card"
      *ngFor="let eventScore of eventScores.EventResults"
      [Score]="eventScore"
      (DeleteScore)="deleteScore($event)"
      (EditScore)="editScore($event)">
    </app-score-card>
  </div>

  <div *ngIf="eventScores && eventScores.EventResults && !eventScores.EventResults.length">
    <div class="no-results-container">
      <ion-text class="no-results-text">No event results.</ion-text>
    </div>
  </div>

  <div *ngIf="!opponents!.length">
    <div class="no-results-container">
      <ion-text class="no-results-text">No opponents listed on this event.</ion-text>
    </div>
  </div>
  <!-- /event results -->

  <!-- season record -->
  <div class="separator-container ion-padding-horizontal">
    <ion-text class="text-separator">
      <span>Records</span>
    </ion-text>
  </div>

  <div
    class="season-container ion-padding-horizontal"
    *ngIf="eventScores && eventScores.SeasonRecord"
    [ngClass]="{ 'margin-bottom-12': !HasSitesPermissionOrCoachPermission }">
    <div class="results-card">
      <div class="card-header">
        <ion-text class="header-text"> This Season </ion-text>
      </div>

      <div class="seasonRecord-card-content">
        <ion-text class="seasonRecord-details">
          Win:
          <ion-text style="font-weight: 400; padding-right: 3em">{{ eventScores.SeasonRecord.Wins }}</ion-text>
        </ion-text>
        <ion-text class="seasonRecord-details">
          Loss:
          <ion-text style="font-weight: 400; padding-right: 3em">{{ eventScores.SeasonRecord.Losses }}</ion-text>
        </ion-text>
        <ion-text class="seasonRecord-details">
          Tie:
          <ion-text style="font-weight: 400">{{ eventScores.SeasonRecord.Ties }}</ion-text>
        </ion-text>
      </div>
    </div>

    <div class="results-card">
      <div class="card-header">
        <ion-text class="header-text"> This Event </ion-text>
      </div>

      <div class="seasonRecord-card-content">
        <ion-text class="seasonRecord-details">
          Win:
          <ion-text style="font-weight: 400; padding-right: 3em">{{ getCount('Win') }}</ion-text>
        </ion-text>
        <ion-text class="seasonRecord-details">
          Loss:
          <ion-text style="font-weight: 400; padding-right: 3em">{{ getCount('Loss') }}</ion-text>
        </ion-text>
        <ion-text class="seasonRecord-details">
          Tie:
          <ion-text style="font-weight: 400">{{ getCount('Tie') }}</ion-text>
        </ion-text>
      </div>
    </div>
  </div>

  <div *ngIf="eventScores && !eventScores.SeasonRecord">
    <div class="no-results-container">
      <ion-text class="no-results-text">No records for this season.</ion-text>
    </div>
  </div>
  <!-- /season record -->

  <span class="create-announcement-container" *ngIf="HasSitesPermissionOrCoachPermission">
    <ion-item lines="none">
      <div class="flex">
        <ion-checkbox [(ngModel)]="postToSites" [checked]="postToSites" justify="start" labelPlacement="end">
          Create Sites Announcement
        </ion-checkbox>
        <div>
          <i
            >When checked you will be able to create a news article with the results of this event after the scores
            are saved.</i
          >
        </div>
      </div>
    </ion-item>
  </span>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="secondary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="openAddScores()"
          expand="block"
          [disabled]="!opponents!.length">
          <fa-icon icon="plus" size="lg"></fa-icon> <ion-text> Add Score </ion-text>
        </ion-button>
      </div>
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="saveScores()"
          expand="block">
          <fa-icon icon="floppy-disk" size="lg"></fa-icon> <ion-text> Save </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>

<ion-content *ngIf="!loaded">
  <div class="no-results-container">
    <ion-text class="no-results-text">Loading...</ion-text>
  </div>
</ion-content>
