// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid } from "../models/ApiTypes";
import { dtoContactPointShortForm } from "../models/legacy/dtoContactPointShortForm";
import { dtoContactPointActivate } from "../models/legacy/dtoContactPointActivate";
import { dtoContactPointCreateUpdate } from "../models/legacy/dtoContactPointCreateUpdate";

@Injectable({providedIn: 'root'})
export class ContactPointLegacyApiService {
    private readonly module = "ContactPoint";

    constructor(private legacyApi: LegacyApiService) { }

    /** Finds Contact Point with Given ID
     * @param id Contact Point ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoContactPointShortForm | undefined>(this.module, "Find", { id });
    }

    /** Get Contact Points by User ID
     * @param userid UserID
     */
    getByUserID(userid: Guid) {
        return this.legacyApi.get<dtoContactPointShortForm[] | undefined>(this.module, "GetByUserID", { userid });
    }

    /** Activate a Contact Point
     * @param body 
     */
    activate(body: dtoContactPointActivate) {
        return this.legacyApi.post<dtoContactPointShortForm | undefined>(this.module, "Activate", {  }, body);
    }

    /** Force Activate a Contact Point
     * @param id Contact Point ID
     */
    forceActivate(id: Guid) {
        return this.legacyApi.post<void>(this.module, "ForceActivate", { id });
    }

    /** Resend a Contact Point
     * @param id ID for ContactPoint
     */
    resend(id: Guid) {
        return this.legacyApi.post<dtoContactPointShortForm | undefined>(this.module, "Resend", { id });
    }

    /** Creates a new Contact Point
     * @param body 
     */
    create(body: dtoContactPointCreateUpdate) {
        return this.legacyApi.post<dtoContactPointShortForm | undefined>(this.module, "Create", {  }, body);
    }

    /** Updates an existing Contact Point
     * @param body 
     */
    update(body: dtoContactPointCreateUpdate) {
        return this.legacyApi.post<dtoContactPointShortForm | undefined>(this.module, "Update", {  }, body);
    }

    /** Deletes a Contact Point, throws 400 if user tries to delete contact point with their only activated email address.
     * @param id ContactPoint ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }
}