import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { NGXLogger } from 'ngx-logger';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  public VersionName: string = '';
  public VersionBuildCode: string = '';
  public BundleVersionCode: string | undefined | null = '';

  constructor(private readonly logger: NGXLogger) {}

  public getVersionDisplay() {
    if (this.VersionName != '' && this.VersionBuildCode != '') {
      return `${this.VersionName} (${this.BundleVersionCode?.substring(0, 7) ?? this.VersionBuildCode})`;
    }

    return '';
  }

  public async init() {
    try {
      if (Capacitor.getPlatform() === 'web') {
        return;
      }

      const info = await App.getInfo();
      this.VersionName = info.version;
      this.VersionBuildCode = info.build;

      const bundleResult = await LiveUpdate.getCurrentBundle();
      this.BundleVersionCode = bundleResult?.bundleId;
    } catch (error) {
      this.logger.info('Error getting version info.', error);
    }
  }
}
