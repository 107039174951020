import { Injectable, ViewContainerRef, ApplicationRef, EmbeddedViewRef } from '@angular/core';

import { SuccessComponent } from '../../../shared/components/modal/success/success.component';

@Injectable({
  providedIn: 'root',
})
export class SuccessService {
  vcRef?: ViewContainerRef;
  constructor(private appRef: ApplicationRef) {}

  public showSuccess(message: string, duration: number, title?: string) {
    if (this.vcRef) {
      const componentRef = this.vcRef.createComponent(SuccessComponent);

      componentRef.instance.message = message;
      componentRef.instance.duration = duration;
      if (title) {
        componentRef.instance.title = title;
      }

      const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

      document.body.appendChild(domElem);

      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }, duration * 1.25);
    }
  }
}
