import { EventlinkHttpResponse } from './EventlinkHttpResponse';

export class EventlinkLegacyHttpResponse<T, TConflict = void> extends EventlinkHttpResponse<T, TConflict> {
  protected override parseErrorMessage(): string | undefined {
    // These errors pass their message consistently
    if (super.isUserOrValidationError || super.isInternalError || super.isAccessError) {
      let error = this.rawData.Error;
      if (error) {
        if (typeof error != 'string') {
          if (error.Message) {
            return error.Message;
          }

          // This shouldn't happen
          return undefined;
        }

        return error;
      }

      return undefined;
    }
    // These errors never actually got a response so we get to make one up
    if (super.isTimeoutError) {
      return 'It is taking longer than usual to process your request. Please wait a little while and try again.';
    }
    if (super.isUnavailableError) {
      return 'Our servers are temporarily unavailable. Please try again.';
    }

    return undefined;
  }

  protected override parseData<TData>(): TData | undefined {
    // Legacy always puts its result into "Data" key
    if (this.rawData && this.rawData['Data']) {
      return this.rawData['Data'] as TData;
    }

    return undefined;
  }
}
