import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { StripeTerminal } from '@capacitor-community/stripe-terminal';
import { KioskPurchasesApiService } from 'src/app/core/services/api/v2/KioskPurchasesApi.service';
import { DtoKioskOrderRequest } from 'src/app/core/services/api/models/DtoKioskOrderRequest';
import { DtoKioskOrderResponse } from 'src/app/core/services/api/models/DtoKioskOrderResponse';

@Component({
  selector: 'app-kiosk-card-checkout',
  templateUrl: './kiosk-card-modal.component.html',
  styleUrls: ['./kiosk-card-modal.component.scss'],
})
export class KioskCheckoutComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() kioskCheckoutRequest!: DtoKioskOrderRequest;
  @Input() accessToken!: string;

  sendReceiptPhoneNumber: string = '';
  kioskCheckoutResponse!: DtoKioskOrderResponse;
  modalTitle: string = 'Send Receipt';

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly alertController: AlertController,
    private readonly kioskPurchaseService: KioskPurchasesApiService
  ) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async skipPhoneNumber() {
    await this.kioskCardCheckout();
  }

  async applyPhoneNumber() {
    if (!isValidPhoneNumber('+1 ' + this.sendReceiptPhoneNumber)) {
      const alert = await this.alertController.create({
        header: 'Invalid Phone Number',
        message: 'Please enter a valid phone number.',
        buttons: ['OK'],
      });

      await alert.present();

      return;
    }

    this.kioskCheckoutRequest.ReceiptPhoneNumber = this.sendReceiptPhoneNumber;

    await this.kioskCardCheckout();
  }

  async kioskCardCheckout() {
    this.modalTitle = 'Checkout';

    const response = await this.kioskPurchaseService.startCheckout(this.kioskCheckoutRequest);

    if (response.isAnyError || !response.data?.StripePaymentIntentID) {
      await this.modalCtrl.dismiss({ success: false, phoneNumber: '', paymentIntentID: '' }, 'error');
    }

    this.kioskCheckoutResponse = response.data!;
  }

  async swipeCreditCard() {
    const alert = await this.alertController.create({
      header: 'Transaction in Progress',
      message: 'Please swipe, tap, or insert your card to complete the transaction.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          handler: async () => {
            await StripeTerminal.cancelCollectPaymentMethod();
            return this.modalCtrl.dismiss(
              {
                success: false,
                phoneNumber: '',
                paymentIntentID: '',
              },
              'cancel'
            );
          },
        },
      ],
    });

    await alert.present();

    await StripeTerminal.collectPaymentMethod({
      paymentIntent: this.kioskCheckoutResponse.PaymentIntentClientSecret!,
    });

    try {
      await StripeTerminal.confirmPaymentIntent();
    } catch (error: any) {
      await alert.dismiss();

      const declineAlert = await this.alertController.create({
        header: 'Transaction Declined',
        message: error.message,
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
          },
        ],
      });

      await declineAlert.present();

      await StripeTerminal.cancelCollectPaymentMethod();
      return;
    }

    await alert.dismiss();

    return this.modalCtrl.dismiss(
      {
        success: true,
        phoneNumber: this.sendReceiptPhoneNumber,
        paymentIntentID: this.kioskCheckoutResponse.StripePaymentIntentID,
      },
      'confirm'
    );
  }
}
