<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()" onkeydown="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Checkout Cash</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container ion-padding-horizontal">
    <ion-row class="cash-given-row">
      <ion-input
        type="number"
        fill="outline"
        labelPlacement="stacked"
        label="Enter how much cash you were given:"
        [(ngModel)]="cashGiven"
        placeholder="123.45"
        (keyup.enter)="submitCash()">
      </ion-input>
    </ion-row>
    <ion-row>
      <ion-label class="title-bold">Total:</ion-label>
      <ion-label> {{ total | currency }} </ion-label>
    </ion-row>
  </div>

  <div class="content-sticky-footer bottom-bkp-08">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          (click)="submitCash()"
          onkeydown="submitCash()"
          expand="block">
          <fa-icon icon="money-bill-1" class="cash-icon" size="lg"></fa-icon>
          <ion-text>Submit {{ cashGiven | currency }}</ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
