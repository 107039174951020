import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private readonly busStream$ = new EventEmitter<BusArgs>();

  constructor() { }

  public send(
    eventName: BusEvents,
    args: any)
  {
    this.busStream$.emit(new BusArgs(eventName, args));
  }

  /**
   * Subscribe to receive all events on the bus. This returns an object that must be manually unsubscribed or you'll leak memory.
   */
  public subscribe(next: (value: BusArgs) => void) {
    return this.busStream$.subscribe(next);
  }
}

class BusArgs {
  constructor(public name: BusEvents, public args: any) {
  }
}

export type BusEvents = "FooterOpenSubscriptions";
