import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { PaymentLegacyApiService } from 'src/app/core/services/api/v1/PaymentLegacyApi.service';
import { UserLegacyApiService } from 'src/app/core/services/api/v1/UserLegacyApi.service';
import { dtoUserLongForm } from 'src/app/core/services/api/models/legacy/dtoUserLongForm';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss'],
})
export class RegisterAccountComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  states: any = [];

  user: dtoUserLongForm = {
    ID: undefined,
    Username: undefined,
    FirstName: undefined,
    LastName: undefined,
    Address1: undefined,
    Address2: undefined,
    County: undefined,
    City: undefined,
    State: undefined,
    Zip: undefined,
    TimeZone: undefined,
    Latitude: undefined,
    Longitude: undefined,
    LastSignedIn: undefined,
    DefaultApplication: undefined,
    ContactPoints: undefined,
  };

  customerData: any = {
    ShouldHaveAddress: undefined,
    FirstName: undefined,
    LastName: undefined,
    TimeZone: undefined,
    Address1: undefined,
    Address2: undefined,
    County: undefined,
    City: undefined,
    State: undefined,
    Zip: undefined,
    DefaultApplication: undefined,
    Email: undefined,
  };

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly dialogService: DialogService,
    private readonly paymentService: PaymentLegacyApiService,
    private readonly utilsService: UtilsService,
    private readonly userService: UserLegacyApiService
  ) {}

  async ngOnInit(): Promise<void> {
    this.states = this.utilsService.getStates();
    this.context = await this.authService.getContext();
    const userResponse = await this.userService.find(this.context.UserID!);
    if (userResponse.isAnyError) {
      return;
    }
    this.user = userResponse.data!;

    this.customerData = {
      FirstName: this.user.FirstName,
      LastName: this.user.LastName,
      Email: this.user.ContactPoints?.find((cp) => {
        return cp.ContactType === 'Email';
      })?.Value,
    };
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  private async isInvalidForm(): Promise<boolean> {
    if (
      this.customerData.FirstName === '' ||
      this.customerData.LastName === '' ||
      this.customerData.Email === ''
    ) {
      await this.dialogService.showAlert({
        header: 'Missing Information',
        message: 'Please fill out all required fields',
        buttons: ['OK'],
      });
      return true;
    }

    return false;
  }

  async saveAccount(customerData: any) {
    if (await this.isInvalidForm()) return;

    const response = await this.paymentService.registerCustomerForUser(this.user.ID!, undefined, customerData);
    if (response.isAnyError) {
      return;
    }
    this.modalCtrl.dismiss(true);
  }
}
