// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { dtoMessageCreateUpdate } from "../models/legacy/dtoMessageCreateUpdate";
import { dtoMessageShortForm } from "../models/legacy/dtoMessageShortForm";
import { dtoSendEventContract } from "../models/legacy/dtoSendEventContract";
import { Guid, DateTime } from "../models/ApiTypes";
import { dtoMessageLongForm } from "../models/legacy/dtoMessageLongForm";

@Injectable({providedIn: 'root'})
export class MessageLegacyApiService {
    private readonly module = "Message";

    constructor(private legacyApi: LegacyApiService) { }

    /** Sends a message. Splits into two main branches of messages, organization or calendar. For organization messages the user must have message permission for those organizations or all message permissions. For calendar messages the user must either have all message permissions, calendar message permissions, or be a calendar admin for that specific calendar. If messaging requires full school is true at runtime then either the user must be admin or the school must have an everything subscription.
     * @param body 
     */
    send(body: dtoMessageCreateUpdate) {
        return this.legacyApi.post<dtoMessageShortForm | undefined>(this.module, "Send", {  }, body);
    }

    /** Sends password reset email, searches by username first and if that doesn't work it tries using email.
     * @param username Username for account trying to get password rest email
     */
    sendPasswordResetEmail(username: string) {
        return this.legacyApi.post<void>(this.module, "SendPasswordResetEmail", { username });
    }

    /** Sends username of account associated to email specified in the query, if it exists.
     * @param email 
     */
    sendUsernameRetrievalEmail(email: string) {
        return this.legacyApi.post<void>(this.module, "SendUsernameRetrievalEmail", { email });
    }

    /** Sends out Event Contracts from the current user to a contact point through email
     * @param body 
     */
    sendEventContract(body: dtoSendEventContract) {
        return this.legacyApi.post<void>(this.module, "SendEventContract", {  }, body);
    }

    /** Finds a specified message in the database by ID. User must have all message permission, calendar message permissions, or organization message permissions. If messaging requires full school is true at runtime then either the user must be admin or the school must have an everything subscription.
     * @param id ID for Message ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoMessageLongForm | undefined>(this.module, "Find", { id });
    }

    /** Searches for a message through a number of optional parameters, this includes searching both organization and calendar messages as options. For organization messages the user must have message permission for those organizations or all message permissions. For calendar messages the user must either have all message permissions, calendar message permissions, or be a calendar admin for that specific calendar. If messaging requires full school is true at runtime then either the user must be admin or the school must have an everything subscription.
     * @param sentByOrganizationID 
     * @param organizationids 
     * @param calendarids 
     * @param subject 
     * @param start 
     * @param end 
     */
    search(sentByOrganizationID: Guid, organizationids: string | undefined, calendarids: string | undefined, subject: string | undefined, start: DateTime | undefined, end: DateTime | undefined) {
        return this.legacyApi.get<dtoMessageShortForm[] | undefined>(this.module, "Search", { sentByOrganizationID, organizationids, calendarids, subject, start, end });
    }

    /** Deletes a message by ID. User must either have all message permissions, calendar message permissions, or organization message permissions. If messaging requires full school is true at runtime then either the user must be admin or the school must have an everything subscription.
     * @param id ID for Message ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** 
     */
    callback() {
        return this.legacyApi.post<void>(this.module, "Callback", {  });
    }
}