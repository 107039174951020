import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutoMarginKeyboardFocus]',
})
export class AutoMarginKeyboardFocusDirective implements AfterViewInit {
  @Input() appAutoMarginKeyboardFocus: string = '';

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit() {
    this.setMargin();
  }

  private setMargin() {
    const targetElement = document.getElementById(this.appAutoMarginKeyboardFocus);

    if (!targetElement) {
      return;
    }

    const targetHeight = targetElement.offsetHeight;
    if (targetHeight > 0) {
      this.el.nativeElement.style.marginBottom = `${targetHeight + 5}px`;
    } else {
      setTimeout(() => this.setMargin(), 100);
    }
  }
}
