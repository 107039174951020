import { Component, Input } from '@angular/core';
import { ModalController, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { AddScoreModalComponent } from '../add-score-modal/add-score-modal.component';
import { dtoEventLongForm } from 'src/app/core/services/api/models/legacy/dtoEventLongForm';
import { EventResultLegacyApiService } from 'src/app/core/services/api/v1/EventResultLegacyApi.service';
import { dtoEventResultsLongForm } from 'src/app/core/services/api/models/legacy/dtoEventResultsLongForm';
import { DtoArticleCreateUpdate } from 'src/app/core/services/api/models/DtoArticleCreateUpdate';
import { Level } from 'src/app/core/services/api/models/legacy/Level';
import { LevelLegacyApiService } from 'src/app/core/services/api/v1/LevelLegacyApi.service';
import { dtoEventResult } from 'src/app/core/services/api/models/legacy/dtoEventResult';
import { ICustomEventOpponentDetail } from 'src/app/core/services/api/models/events/ICustomEventOpponentDetail';
import { ICustomITitled } from 'src/app/core/services/api/models/events/ICustomITitled';
import { EventResult } from 'src/app/core/services/api/models/legacy/EventResult';
import * as moment from 'moment';
import { SuccessService } from 'src/app/core/services/success/success.service';
import { dtoTeamLevel } from '../../../../../core/services/api/models/legacy/dtoTeamLevel';

@Component({
  selector: 'app-scores',
  templateUrl: './scores-modal.component.html',
  styleUrls: ['./scores-modal.component.scss'],
})
export class ScoresModalComponent implements ViewDidEnter, ViewDidLeave {
  @Input() Event!: dtoEventLongForm;
  @Input() OpponentDetails!: ICustomEventOpponentDetail[];
  @Input() OrganizationID!: string;
  @Input() HasSitesPermissionOrCoachPermission!: boolean;
  @Input() TeamLevel: dtoTeamLevel | undefined;

  eventScores?: dtoEventResultsLongForm;
  haveResultsChanged: boolean = false;
  postToSites: boolean = false;
  loaded: boolean = false;
  opponents?: (ICustomITitled | undefined)[];
  levels?: Level[];

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly dialogService: DialogService,
    private readonly eventResultLegayApiService: EventResultLegacyApiService,
    private readonly levelLegacyApiService: LevelLegacyApiService,
    private readonly successService: SuccessService
  ) {}

  ionViewDidEnter() {
    this.refreshData();
  }

  ionViewDidLeave() {
    this.haveResultsChanged = false;
    this.postToSites = false;
    this.loaded = false;
  }

  async refreshData() {
    // get all event results
    const response = await this.eventResultLegayApiService.getAllByEventID(this.Event.ID!);
    if (response.isAnyError) {
      return;
    }
    this.eventScores = response.data;

    //get event opponents
    this.opponents =
      this.OpponentDetails.map((opp) => {
        return opp.Opponent;
      }) ?? [];

    // get levels
    const responseLevels = await this.levelLegacyApiService.getAll();
    if (responseLevels.isAnyError) {
      return;
    }
    this.levels = responseLevels.data ?? [];
    this.levels = [...this.levels].sort((a, b) => {
      if (a.Ranking! < b.Ranking!) {
        return 1;
      } else return -1;
    });

    this.loaded = true;
  }

  getCount(result: string) {
    return this.eventScores?.EventResults?.filter((res) => {
      return res.Result == result;
    }).length;
  }

  async openAddScores(scoreModel?: dtoEventResult) {
    let score = scoreModel ?? {
      ID: undefined,
      EventID: this.Event.ID,
      OpponentID: '',
      LevelID: '',
      ScoreSelf: undefined,
      ScoreOpponent: undefined,
      Result: 'Unknown',
      Description: '',
      Opponent: undefined,
      Level: undefined,
    };

    const modal = await this.dialogService.showModal({
      component: AddScoreModalComponent,
      componentProps: {
        Edit: !!this.eventScores?.ID,
        Score: score,
        Levels: this.levels,
        Opponents: this.opponents,
        DefaultLevel: this.TeamLevel,
      },
      initialBreakpoint: 0.9,
    });

    const { data } = await modal.onDidDismiss();
    if (data === 'cancel' || !data) {
      return;
    }

    if (data.score) {
      // push score into results
      this.eventScores?.EventResults?.push(data.score);
      this.successService.showSuccess('Your score has been added.', 5000, 'Score Added');
    }
  }

  async editScore(scoreID: string) {
    const response = await this.eventResultLegayApiService.find(scoreID);
    if (response.isAnyError) {
      return;
    }
    let score = response.data;
    this.openAddScores(score);
  }

  async deleteScore(scoreID: string) {
    this.eventScores!.EventResults = [...(this.eventScores!.EventResults as dtoEventResult[])].filter((res) => {
      return res.ID !== scoreID;
    });
  }

  async saveScores() {
    let body = this.eventScores?.EventResults?.map((res) => {
      return {
        ID: res.ID,
        EventID: res.EventID,
        OpponentID: res.OpponentID,
        LevelID: res.LevelID,
        ScoreSelf: res.ScoreSelf,
        ScoreOpponent: res.ScoreOpponent,
        Result: res.Result,
        Description: res.Description,
        Opponent: undefined,
        Level: undefined,
      };
    }) as EventResult[];

    const response = await this.eventResultLegayApiService.save(this.Event.ID!, body);
    if (response.isSuccess) {
      if (this.postToSites) {
        let dataForArticle = await this.createSitesAnnouncement(body);
        this.modalCtrl.dismiss({ status: 'successAndCreate', value: dataForArticle });
      } else {
        this.modalCtrl.dismiss('success');
      }
    }
  }

  async createSitesAnnouncement(results: EventResult[]) {
    let body: string = '';
    for (let result of results) {
      let level = this.levels?.find((l) => l.ID === result.LevelID);
      let opponent = this.opponents?.find((opp) => opp?.ID === result.OpponentID);
      body += result.Description ? result.Description + '\n' : '';
      body += level?.Title + ' vs ' + opponent?.Title + ' ';
      body +=
        result.ScoreSelf && result.ScoreOpponent ? '(' + result.ScoreSelf + '-' + result.ScoreOpponent + ') ' : '';
      body += result.Result ? result.Result : '';
      body += '\n';
    }

    let dataForArticle: DtoArticleCreateUpdate = {
      ID: undefined,
      OrganizationID: this.Event.Organization!.ID!,
      VisibleAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      VisibleUntil: undefined,
      ArticleType: 'News',
      Title: this.Event.Team?.Title + ' Scores',
      Body: body,
      EventID: this.Event.ID,
      CalendarIDs: [this.Event.Calendar?.ID!],
    };
    return dataForArticle;
  }

  cancel() {
    this.haveResultsChanged ? this.modalCtrl.dismiss('close') : this.modalCtrl.dismiss('cancel');
  }
}
