import {Component} from "@angular/core";

@Component({
    selector: 'app-florida-component',
    templateUrl: './_florida_fhsaa.html',
  styleUrl: './contract.scss',
})

export class FloridaComponent{

}
