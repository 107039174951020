import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { IKioskPastOrder } from 'src/app/core/models/kiosks/kiosk-past-order.model';
import { KioskPurchasesApiService } from 'src/app/core/services/api/v2/KioskPurchasesApi.service';
import { KioskSessionsApiService } from 'src/app/core/services/api/v2/KioskSessionsApi.service';

@Component({
  selector: 'app-kiosk-past-order',
  templateUrl: './past-order-modal.component.html',
  styleUrls: ['./past-order-modal.component.scss'],
})
export class KioskPastOrderComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() pastOrders!: IKioskPastOrder[];
  @Input() kioskSessionID!: number;
  @Input() organizationID!: number;
  @Input() accessToken!: string;

  sendReceiptPhoneNumber: string = '';
  selectedOrder: IKioskPastOrder = {} as IKioskPastOrder;
  modalTitle: 'Past Orders' | 'Order Details' = 'Past Orders';

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private kioskSessionsService: KioskSessionsApiService,
    private kioskPurchasesService: KioskPurchasesApiService
  ) {}

  close() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  back() {
    this.modalTitle = 'Past Orders';
  }

  orderDetail(order: IKioskPastOrder) {
    this.selectedOrder = order;
    this.modalTitle = 'Order Details';
  }

  async resendReceipt() {
    const alert = await this.alertController.create({
      header: 'Resend Text Receipt',
      inputs: [
        {
          placeholder: 'Phone Number',
          type: 'number',
          name: 'phoneNumber',
          value: this.selectedOrder.PhoneNumber,
        },
      ],
      message: 'If the original phone number was incorrect, change it below.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          handler: async (alertData) => {
            if (!alertData.phoneNumber) {
              return;
            }

            if (!isValidPhoneNumber('+1 ' + alertData.phoneNumber)) {
              const alert = await this.alertController.create({
                header: 'Invalid Phone Number',
                message: 'Please enter a valid phone number.',
                buttons: ['OK'],
              });

              await alert.present();

              return;
            }

            const response = await this.kioskPurchasesService.sendReceipt(
              this.kioskSessionID,
              this.selectedOrder.PaymentIntentID!,
              alertData.phoneNumber
            );
            if (response.isAnyError) {
              return;
            }

            const alert = await this.alertController.create({
              header: 'Resend Text Receipt',
              message: 'The receipt has been resent successfully.',
              buttons: ['OK'],
            });

            await alert.present();
            
          },
        },
      ],
    });

    await alert.present();
  }

  async refundPurchase() {
    const foundOrder = this.pastOrders.find((o) => o === this.selectedOrder);
    if (foundOrder) {
      foundOrder.Refunded = true;
    }

    if (this.selectedOrder.Type === 'Credit') {
      const response = await this.kioskSessionsService.refund(
        this.selectedOrder.PaymentIntentID!,
        this.kioskSessionID,
        this.organizationID.toString()
      );
      if (response.isAnyError) {
        return;
      }
    } else {
      const response = await this.kioskSessionsService.refundCash(
        this.selectedOrder.KioskOrderID!,
        this.kioskSessionID,
        this.organizationID.toString()
      );
      if (response.isAnyError) {
        return;
      }
    }

    const alert = await this.alertController.create({
      header: 'Refund Complete',
      message: 'The refund has been completed successfully.',
      buttons: ['OK'],
    });

    await alert.present();
    
  }
}
