import { Injectable, ErrorHandler } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';
import { CrashlyticsService } from '../firebase/crashlytics/crashlytics.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private readonly logger: NGXLogger,
    private readonly toastController: ToastController,
    private readonly crashlyticsService: CrashlyticsService
  ) {}

  async handleError(error: any): Promise<void> {
    let errorMessage = error.error?.errors?._User?.length > 0 ? error.error.errors._User[0] : error.message;

    this.logger.error(error);

    this.crashlyticsService.recordException(errorMessage, error);

    if (!environment.production && errorMessage) {
      const toast = await this.toastController.create({ message: errorMessage, duration: 3500, color: 'danger' });
      await toast.present();
    }
  }
}
