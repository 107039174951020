<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Forgot Username?</ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="submit()"
        expand="block">
        <ion-text> Submit </ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-input labelPlacement="stacked" placeholder="Click to enter your email here." [(ngModel)]="email" style="margin-bottom: 10px; --keyboard-offset: 5px;">
  </ion-input>
</ion-content>
