import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import IContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import IContextOrganizationModel from '../../../../core/models/context/context-organization.models';
import { EventLegacyApiService } from '../../../../core/services/api/v1/EventLegacyApi.service';
import { ApplicationTypeService } from '../../../../core/services/auth/application-type/application-type.service';
import { TicketConfigurationsApiService } from '../../../../core/services/api/v2/TicketConfigurationsApi.service';
import { DialogService } from '../../../../core/services/dialog/dialog.service';
import { LinkedEventsComponent } from '../linked-events/linked-events.component';
import { OrganizationLegacyApiService } from '../../../../core/services/api/v1/OrganizationLegacyApi.service';
import { dtoOrganizationWithSpecialUsers } from '../../../../core/services/api/models/legacy/dtoOrganizationWithSpecialUsers';
import { dtoOrganizationSpecialUser } from '../../../../core/services/api/models/legacy/dtoOrganizationSpecialUser';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  @Input() event: any;
  context!: IContextModel;
  organization?: IContextOrganizationModel;
  foundEvent: any | undefined;
  contextOrganization!: IContextOrganizationModel;
  isHomeEvent: boolean = false;
  hostingOrganization: dtoOrganizationWithSpecialUsers | undefined;
  athleticDirector: dtoOrganizationSpecialUser | undefined;

  constructor(
    public readonly modalCtrl: ModalController,
    private readonly authService: AuthService,
    protected readonly eventLegacyApiService: EventLegacyApiService,
    protected readonly applicationTypeService: ApplicationTypeService,
    protected readonly ticketConfigurationsApiService: TicketConfigurationsApiService,
    private readonly dialogService: DialogService,
    protected readonly organizationService: OrganizationLegacyApiService
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();
    this.organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

    const response = await this.eventLegacyApiService.find(this.event.ID);

    if (response.isAnyError) {
      return;
    }

    this.foundEvent = response.data;

    if (this.event.StartDateTime && this.foundEvent) {
      let dateTime: string = this.event.StartDateTime.toString();

      let dateSplit = dateTime.split('T');

      let date = dateSplit[0];
      let components = date.split('-');

      if (Number(components[1]) < 10) {
        components[1] = components[1].replace('0', '');
      }
      if (Number(components[2]) < 10) {
        components[2] = components[2].replace('0', '');
      }
      let newDate = '';
      newDate = newDate.concat(components[1]);
      newDate = newDate.concat('/' + components[2]);
      newDate = newDate.concat('/' + components[0]);
      if (dateSplit.length > 1) {
        let newTime = this.splitTime(dateSplit);
        newDate = newDate.concat(', ' + newTime);

        if (this.event.EndDateTime && this.event.EndDateTime.split('T').length > 1) {
          let endTime = this.splitTime(this.event.EndDateTime.split('T'));
          newDate = newDate.concat(' - ' + endTime);
        }
      }
      this.foundEvent.StartDateTime = newDate;
    } else {
      if (this.foundEvent) {
        this.foundEvent.StartDateTime = 'TBD';
      }
    }
    this.contextOrganization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);
    if (
      this.foundEvent &&
      this.foundEvent.HostingOrganizationID === this.contextOrganization.OrganizationId &&
      this.foundEvent.ContractID
    ) {
      this.isHomeEvent = true;
    }
    if (this.applicationTypeService.applicationType$.getValue() === 'Officials') {
      this.foundEvent.RepeatRules = [];
      let response = await this.organizationService.find(this.foundEvent.HostingOrganizationID);
      if (!response.isAnyError && response.data) {
        this.hostingOrganization = response.data;
        if (this.hostingOrganization.SpecialUsers) {
          for (let specialUser of this.hostingOrganization.SpecialUsers) {
            if (specialUser.SpecialUserType === 'AthleticDirector') {
              this.athleticDirector = specialUser;
            }
          }
        }
      }
    } else if (this.applicationTypeService.applicationType$.getValue() === 'Staff') {
      //Check if user can Cancel an event, user must be a calendar admin,
      // have Athletic Permissions with an active event to be canceled or
      // lastly be a coach with permission to edit that specific calendar the event is on. Returns 400 if cancel note or refund reason is over 200 characters.
    }
  }

  private splitTime(dateSplit: string[]) {
    let newTime = '';
    let components = dateSplit[1].split(':');
    let m = '';
    let numberValue: number = +components[0];
    if (numberValue <= 12) {
      m = 'AM';
    } else {
      numberValue = numberValue - 12;
      components[0] = numberValue.toString();
      m = 'PM';
    }
    newTime = newTime.concat(components[0]);
    newTime = newTime.concat(':' + components[1]);
    return newTime.concat(' ' + m);
  }

  protected returnRepeatValuesStatement(rule: any) {
    let values = rule.Values;
    let statement = 'on the ';
    for (let i = 0; i < values.length; i++) {
      if (i == values.length - 1) {
        statement = statement.concat(values[i]);
        statement = statement.concat(this.checkEnding(values[i]));
      } else {
        statement = statement.concat(values[i]);
        statement = statement.concat(this.checkEnding(values[i]), ', ');
      }
    }
    return statement;
  }

  protected checkEnding(date: number) {
    if (date > 3 && date < 21) return 'th';
    switch (date % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  protected editDetails() {
    //Placeholder for taking you to an athletics page later to mess with details
  }

  async deleteEvent() {
    //this.eventLegacyApiService.delete();
  }

  async cancelEvent() {
    let response = await this.ticketConfigurationsApiService.getByEventID(this.foundEvent?.ID!);
    if (response.isAnyError) {
      return;
    }
    if (response.data) {
      let ticketPoolObjects = [];
      let ticketConfiguration = response.data;
      for (let ticketPool of ticketConfiguration.TicketPools!) {
        let newPoolWithSelected: any = {
          ID: ticketPool.ID,
          Note: ticketPool.Note,
          Title: ticketPool.Title,
          TicketCostDollars: ticketPool.TicketCostDollars,
        };
        ticketPoolObjects.push(ticketPool);
      }
      //Do the rest of ticket pool checks here then cancel
    } else {
      await this.dialogService.showAlert({
        header: 'Cancel Event',
        message: '\n' + 'Are you sure you want to cancel this event?',
        buttons: [
          {
            text: 'No',
          },
          {
            text: 'Yes',
            handler: async () => {
              // Need to check for notify canceled event parties here
              /* let body : dtoEventChangeStatus = {
                EventIDs: Guid[] | undefined;
                NotifySubscribers: boolean | undefined;
                NotifyOpponents: boolean | undefined;
                NotifyWorkers: boolean | undefined;
                NotifySocialAccounts: boolean | undefined;
                RefundTicketPoolIDs: int[] | undefined;
                PublicCancelNote: string | undefined;
                RefundReason: string | undefined;
              };
              this.eventLegacyApiService.cancel(body);
              if (response.isSuccess) {
                this.popoverController.dismiss();
                this.DeleteBlockEvent.emit();
              }*/
            },
          },
        ],
      });
    }
  }

  async openLinkedEvents(eventId: any) {
    const modal = await this.modalCtrl.create({
      component: LinkedEventsComponent,
      componentProps: {
        linkedId: eventId,
      },
      breakpoints: [0.8],
      initialBreakpoint: 0.8,
    });

    modal.present();
  }

  async extendEvent() {}
}
