import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading/loading.service';

@Directive({
  selector: '[appPreventMultiClickEvents]',
})
export class PreventMultiClickEventsDirective implements OnInit, OnDestroy {
  @Output()
  preventMultiClicks = new EventEmitter();

  private readonly clicks = new Subject();
  private subscription!: Subscription;
  private isLoading: boolean = false;

  constructor(private readonly loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.loading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });

    this.subscription = this.clicks.subscribe((e: any) => {
      if (!this.isLoading) {
        this.preventMultiClicks.emit(e);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    this.clicks.next(event);
  }
}
