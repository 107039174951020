// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoUserAccessGridRead } from "../models/DtoUserAccessGridRead";
import { DtoValidateUsername } from "../models/DtoValidateUsername";

@Injectable({providedIn: 'root'})
export class UserApiService {
    private readonly controller = "User";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param organizationID 
     * @param userID 
     */
    getUserAccessGrid(organizationID: Guid, userID: Guid) {
        return this.v2Api.get<DtoUserAccessGridRead>(this.controller, "GetUserAccessGrid", { organizationID, userID });
    }

    /** Validates whether a username can be used when creating a new account.
     * @param username 
     */
    validateUsername(username: string) {
        return this.v2Api.get<DtoValidateUsername>(this.controller, "ValidateUsername", { username });
    }
}