import { Component, Inject, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { ErrorService } from './core/services/error/error.service';
import { SuccessService } from './core/services/success/success.service';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Network } from '@capacitor/network';
import { NGXLogger } from 'ngx-logger';
import { NgxLoggerMonitor } from './core/ngx/ngx-logger-monitor';
import { ApplicationTypeService } from './core/services/auth/application-type/application-type.service';
import { IsAuthenticatedService } from './core/services/auth/is-authenticated/is-authenticated.service';
import { TicketTakersApiService } from './core/services/api/v2/TicketTakersApi.service';
import { DtoTicketTakerScan } from './core/services/api/models/DtoTicketTakerScan';
import { ITicketTakerOfflineScan } from './core/models/ticket-takers/ticket-taker-offline-scan.model';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { Platform } from '@ionic/angular';
import { Environment } from '../environments/environment.model';
import { ENV } from '../environments/environment.provider';
import { AppInfoService } from './core/services/app-info/app-info.service';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated$ = this.isAuthenticatedService.isAuthenticated$;
  applicationType$ = this.applicationTypeService.applicationType$;

  constructor(
    private readonly authService: AuthService,
    private readonly errorService: ErrorService,
    private readonly successService: SuccessService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly ticketTakersService: TicketTakersApiService,
    private readonly ngZone: NgZone,
    private readonly logger: NGXLogger,
    protected applicationTypeService: ApplicationTypeService,
    private readonly isAuthenticatedService: IsAuthenticatedService,
    private readonly platform: Platform,
    private readonly appInfoService: AppInfoService,
    private readonly router: Router,
    @Inject(ENV) private readonly env: Environment
  ) {
    this.errorService.vcRef = this.viewContainerRef;
    this.successService.vcRef = this.viewContainerRef;

    this.logger.registerMonitor(new NgxLoggerMonitor());
  }

  async ngOnInit() {
    this.platform.ready().then(async () => {
      if (!this.env.capawesome.liveUpdatesEnabled) return;

      await LiveUpdate.ready();

      const channelOption = { channel: `${this.env.environment}-${this.appInfoService.VersionName}` };

      await LiveUpdate.setChannel(channelOption);

      const result = await LiveUpdate.sync(channelOption);

      await this.authService.insertLog({
        Message: `Live Update: ${JSON.stringify(result)}`,
        File: 'app.component.ts',
        Method: 'ngOnInit()',
        DateTime: new Date(),
      });

      if (result.nextBundleId) {
        await LiveUpdate.reload();
      }
    });

    Keyboard.addListener('keyboardDidShow', () => {
      // What we ended up doing was adding a timeout of 450ms to scroll the focused element into view after the keyboard is shown to prevent the keyboard from covering the focused input
      // We added the 450ms timeout because ionic adds a cloned element which we cannot access as well as being the least amount of time to wait until the cloned element is removed
      // An example of seeing this at play would be to open up dev tools, navigate to the create article modal, find the most inner text area element for the input "Body". Right click and
      // select break on > attribute modified. If you step through you'll be able to see the file that includes logic that handles the scroll beahaivor, cloning, and spacing of an element
      // when the keyboard is shown and then hidden.

      const focusedElement = document.activeElement;

      if (!focusedElement) {
        return;
      }

      setTimeout(() => {
        focusedElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 450);
    });

    if (Capacitor.getPlatform() !== 'web') {
      await this.authService.runUpdateProtocol();

      await StatusBar.setStyle({ style: Style.Dark });
      await StatusBar.setBackgroundColor({ color: '#1a1a1a' });

      try {
        await ScreenOrientation.lock({ orientation: 'portrait' });
      } catch (error) {
        this.logger.info('Error setting screen orientation.', error);
      }
    }

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.ngZone.run(() => {
        const slug = event.url.split('.com').pop();

        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If there's no match - let regular routing
        // logic take over
      });
    });

    await Network.addListener('networkStatusChange', async (status) => {
      await this.ngZone.run(async () => {
        const scannedQRCodes = await this.authService.getScannedQRCodes();

        if (scannedQRCodes.length < 1) return;

        if (!status.connected) return;

        const ticketConfigurationIDs = [...new Set(scannedQRCodes.map((code) => code.TicketConfigurationID))];

        ticketConfigurationIDs.forEach(async (ticketConfigurationID) => {
          let filteredScannedQRCodes: ITicketTakerOfflineScan[] = scannedQRCodes.filter(
            (code) => code.TicketConfigurationID === ticketConfigurationID
          );

          let body: DtoTicketTakerScan[] = filteredScannedQRCodes.map((code) => {
            return { QrCodeValue: code.QrCodeValue, ScannedAt: new Date(code.ScannedAt!).toUTCString() };
          });

          const response = await this.ticketTakersService.submitScan(parseInt(ticketConfigurationID), body);
          if (response.isAnyError) {
            return;
          }
        });

        await this.authService.deleteScannedQRCodes();
      });
    });
  }

  async purgeLogsCheck() {
    const logTableRowCount = await this.authService.getTableRowCount('Logs');

    if (logTableRowCount > 10000) {
      this.logger.info('Purging logs');
      await this.authService.deleteLogs();
    }
  }
}
