<button
  class="c-btn"
  [class.c-btn--lg]="size === 'lg'"
  [class.c-btn--sm]="size === 'sm'"
  [class.c-btn--outline]="outline"
  [class.c-btn--clear]="clear"
  [class.c-btn--inverted]="inverted"
  [disabled]="disabled">
  <span *ngIf="loading" class="c-btn__loading">
    <img
      alt="White Loading Icon"
      *ngIf="!inverted && !disabled"
      src="../../../../assets/images/loading/loading-white.svg" />
    <img
      alt="Gray Loading Icon"
      *ngIf="!inverted && disabled"
      src="../../../../assets/images/loading/loading-gray.svg" />
    <img alt="Colored Loading Icon" *ngIf="inverted" src="../../../../assets/images/loading/loading-colored.svg" />
  </span>

  <ion-icon class="c-btn__icon" *ngIf="!loading && icon" [name]="icon"></ion-icon>

  <span>
    <ng-content></ng-content>
  </span>

  <ion-icon class="c-btn__arrow" *ngIf="arrow" name="chevron-forward-outline"></ion-icon>
</button>
