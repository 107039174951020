import {Component} from "@angular/core";

@Component({
    selector: 'app-colorado-component',
    templateUrl: './_colorado_chsaa.html',
  styleUrl: './contract.scss',
})

export class ColoradoComponent{

}

