import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appEnterTab]',
})
export class EnterTabDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appEnterTab') nextControl: any;

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.nextControl.focus) {
      this.nextControl.focus();
      event.preventDefault();
      return false;
    }

    return true;
  }
}
