import { EventlinkHttpResponse } from "./EventlinkHttpResponse";

export class EventlinkV2HttpResponse<T, TConflict = void> extends EventlinkHttpResponse<T, TConflict> {

    protected override parseErrorMessage(): string | undefined {
        // These errors pass their message consistently
        if (super.isUserOrValidationError || super.isInternalError || super.isAccessError) {
            const errors = this.rawData.errors;
            let errorMessages: string[] = [];
            for (let key in errors) {
                const error = errors[key];
                if (error instanceof Array) {
                    for (let message of error) {
                        errorMessages.push(message as string);
                    }
                }
            }

            if (errorMessages.length == 0) {
                return undefined;
            }

            return errorMessages.join(' ');
        }
        // These errors never actually got a response so we get to make one up
        if (super.isTimeoutError) {
            return "It is taking longer than usual to process your request. Please wait a little while and try again.";
        }
        if (super.isUnavailableError) {
            return "Our servers are temporarily unavailable. Please try again.";
        }

        return undefined;
    }

    protected override parseData<TData>(): TData | undefined {
        return this.rawData as TData;
    }
    
}