import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DwollaCustomApiService {
  private readonly rootUrl: string = environment.dwolla.url;

  async addFundingSource(
    name: string,
    type: string,
    routingNumber: string,
    accountNumber: string,
    customerId: string,
    clientToken: any
  ) {
    let body: BodyInit = JSON.stringify({
      name: name,
      type: type,
      routingNumber: routingNumber,
      accountNumber: accountNumber,
    }); 

    return await fetch(this.rootUrl + `/customers/${customerId}/funding-sources`, {
      method: 'POST',
      body: body,
      headers: {
        Accept: 'application/vnd.dwolla.v1.hal+json',
        'Content-Type': 'application/vnd.dwolla.v1.hal+json',
        Authorization: 'Bearer ' + clientToken['token'],
      },
    });
  }
}
