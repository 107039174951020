// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid, DateOnly } from "../models/ApiTypes";
import { dtoOrganizationWithSpecialUsers } from "../models/legacy/dtoOrganizationWithSpecialUsers";
import { dtoOrganizationShortForm } from "../models/legacy/dtoOrganizationShortForm";
import { dtoUserOrganizations } from "../models/legacy/dtoUserOrganizations";
import { dtoINamed } from "../models/legacy/dtoINamed";
import { dtoUserWithContactPoints } from "../models/legacy/dtoUserWithContactPoints";
import { dtoOrganizationSpecialUser } from "../models/legacy/dtoOrganizationSpecialUser";
import { dtoOrganizationClaimFormNumber } from "../models/legacy/dtoOrganizationClaimFormNumber";
import { dtoOrganizationSubscriptionSync } from "../models/legacy/dtoOrganizationSubscriptionSync";
import { dtoOrganizationSubscriptionShortForm } from "../models/legacy/dtoOrganizationSubscriptionShortForm";
import { dtoOrganizationCreateUpdate } from "../models/legacy/dtoOrganizationCreateUpdate";
import { dtoOrganizationAddRemoveUsers } from "../models/legacy/dtoOrganizationAddRemoveUsers";
import { dtoUserShortForm } from "../models/legacy/dtoUserShortForm";
import { dtoOrganizationSettingsRead } from "../models/legacy/dtoOrganizationSettingsRead";
import { dtoOrganizationSettingsWrite } from "../models/legacy/dtoOrganizationSettingsWrite";
import { dtoOrganizationContractSettingRead } from "../models/legacy/dtoOrganizationContractSettingRead";
import { dtoOrganizationCustomer } from "../models/legacy/dtoOrganizationCustomer";
import { dtoOrganizationRegistrationSettings } from "../models/legacy/dtoOrganizationRegistrationSettings";

@Injectable({providedIn: 'root'})
export class OrganizationLegacyApiService {
    private readonly module = "Organization";

    constructor(private legacyApi: LegacyApiService) { }

    /** Find Organization by ID
     * @param id Organization ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoOrganizationWithSpecialUsers | undefined>(this.module, "Find", { id });
    }

    /** Get Organizations by a District
     * @param districtID District ID
     */
    getByDistrictID(districtID: Guid) {
        return this.legacyApi.get<dtoOrganizationShortForm[] | undefined>(this.module, "GetByDistrictID", { districtID });
    }

    /** Get all organizations and districts associated with a user ID
     * @param id User ID
     */
    getAllByUserID(id: Guid) {
        return this.legacyApi.get<dtoUserOrganizations | undefined>(this.module, "GetAllByUserID", { id });
    }

    /** Get Managers of an Organization, current user must be a member of the organization
     * @param id Organization ID
     */
    getManagers(id: Guid) {
        return this.legacyApi.get<dtoINamed[] | undefined>(this.module, "GetManagers", { id });
    }

    /** This is CSR version of get managers for an organization, user must be an Admin User
     * @param id Organization ID
     */
    getManagersCsr(id: Guid) {
        return this.legacyApi.get<dtoINamed[] | undefined>(this.module, "GetManagersCsr", { id });
    }

    /** Gets subscribers of an organization, user must be apart of the organization. Additional options include searching by first or last name.
     * @param id Organization ID
     * @param first First Name
     * @param last Last Name
     */
    getSubscribers(id: Guid, first: string | undefined, last: string | undefined) {
        return this.legacyApi.get<dtoINamed[] | undefined>(this.module, "GetSubscribers", { id, first, last });
    }

    /** Gets all organization users, user must be an organization user. Can filter by first name, or last name.
     * @param id Organization ID
     * @param first First Name
     * @param last Last Name
     * @param includeDistrict 
     */
    getUsers(id: Guid, first: string | undefined, last: string | undefined, includeDistrict: string | undefined) {
        return this.legacyApi.get<dtoUserWithContactPoints[] | undefined>(this.module, "GetUsers", { id, first, last, includeDistrict });
    }

    /** Gets special users within an organization
     * @param id Organization ID
     */
    getSpecialUsers(id: Guid) {
        return this.legacyApi.get<dtoOrganizationSpecialUser[] | undefined>(this.module, "GetSpecialUsers", { id });
    }

    /** Gets claim form number for an organization
     * @param id Organization ID
     */
    getClaimFormNumber(id: Guid) {
        return this.legacyApi.get<dtoOrganizationClaimFormNumber | undefined>(this.module, "GetClaimFormNumber", { id });
    }

    /** Sync subscriptions for an organization
     * @param body 
     */
    syncSubscriptions(body: dtoOrganizationSubscriptionSync[]) {
        return this.legacyApi.post<dtoOrganizationSubscriptionShortForm[] | undefined>(this.module, "SyncSubscriptions", {  }, body);
    }

    /** Creates a new organization, throws 400 if  selected school is already associated with another customer.
     * @param body 
     */
    create(body: dtoOrganizationCreateUpdate) {
        return this.legacyApi.post<dtoOrganizationWithSpecialUsers | undefined>(this.module, "Create", {  }, body);
    }

    /** Updates an organization, user must have Settings Permissions.
     * @param body 
     */
    update(body: dtoOrganizationCreateUpdate) {
        return this.legacyApi.post<dtoOrganizationWithSpecialUsers | undefined>(this.module, "Update", {  }, body);
    }

    /** Add or remove managers from an organization, user must be an organization manager.
     * @param body 
     */
    addRemoveManagers(body: dtoOrganizationAddRemoveUsers) {
        return this.legacyApi.post<dtoUserShortForm[] | undefined>(this.module, "AddRemoveManagers", {  }, body);
    }

    /** Add or remove user from an organization, user must have Settings Permission. Throws 400 if organization does not have everything subscription type and they try and add more than 5 users with staff access.
     * @param body 
     */
    addRemoveUsers(body: dtoOrganizationAddRemoveUsers) {
        return this.legacyApi.post<dtoUserShortForm[] | undefined>(this.module, "AddRemoveUsers", {  }, body);
    }

    /** Gets settings for an organization, user must have Athletic Permissions
     * @param id Organization ID
     */
    getSettings(id: Guid) {
        return this.legacyApi.get<dtoOrganizationSettingsRead | undefined>(this.module, "GetSettings", { id });
    }

    /** Update an organizations settings, user must have Settings Permissions.
     * @param body 
     */
    updateSettings(body: dtoOrganizationSettingsWrite) {
        return this.legacyApi.post<dtoOrganizationSettingsRead | undefined>(this.module, "UpdateSettings", {  }, body);
    }

    /** Delete an Organization by ID
     * @param id Organization ID
     */
    delete(id: Guid) {
        return this.legacyApi.post<void>(this.module, "Delete", { id });
    }

    /** Gets an organization's contract settings, user must have Setiings Permissions
     * @param id Organization ID
     */
    getContractSettings(id: Guid) {
        return this.legacyApi.get<dtoOrganizationContractSettingRead | undefined>(this.module, "GetContractSettings", { id });
    }

    /** Update an organization's contract settings, user must have Settings Permission.
     */
    updateContractSettings() {
        return this.legacyApi.post<dtoOrganizationContractSettingRead | undefined>(this.module, "UpdateContractSettings", {  });
    }

    /** Add subscription to an organization.
     * @param userID 
     * @param organizationID 
     */
    addSubscription(userID: Guid, organizationID: Guid) {
        return this.legacyApi.post<void>(this.module, "AddSubscription", { userID, organizationID });
    }

    /** Remove subscription from an organization
     * @param userID 
     * @param organizationID 
     */
    removeSubscription(userID: Guid, organizationID: Guid) {
        return this.legacyApi.post<void>(this.module, "RemoveSubscription", { userID, organizationID });
    }

    /** Customer search for a customer of an organization
     * @param district 
     * @param org Organization Title
     */
    customerSearch(district: Guid, org: string) {
        return this.legacyApi.get<dtoOrganizationCustomer[] | undefined>(this.module, "CustomerSearch", { district, org });
    }

    /** Activates a subscription in an organization
     * @param id Organization ID
     * @param date 
     * @param tz 
     */
    activateSubscription(id: Guid, date: DateOnly, tz: string) {
        return this.legacyApi.post<dtoOrganizationShortForm | undefined>(this.module, "ActivateSubscription", { id, date, tz });
    }

    /** Deactivates a subscription in an organization
     * @param id Organization ID
     */
    deactivateSubscription(id: Guid) {
        return this.legacyApi.post<dtoOrganizationShortForm | undefined>(this.module, "DeactivateSubscription", { id });
    }

    /** Uploads an organizations handbook
     */
    uploadHandbook() {
        return this.legacyApi.post<void>(this.module, "UploadHandbook", {  });
    }

    /** Retrieves an Organization's Handbook by organization ID
     * @param id Organization ID
     */
    getOrganizationHandbook(id: Guid) {
        return this.legacyApi.get<Blob | undefined>(this.module, "GetOrganizationHandbook", { id });
    }

    /** Sets the Athletic Director for an organization, user must either have Athletic Permissions or must be a Team Assigner. Throws 403 if new athletic director doesn't have athletic permissions or isn't a team assigner.
     * @param organizationID 
     * @param userID 
     * @param keepPreviousManager 
     */
    setAthleticDirector(organizationID: Guid, userID: Guid, keepPreviousManager: boolean | undefined) {
        return this.legacyApi.post<void>(this.module, "SetAthleticDirector", { organizationID, userID, keepPreviousManager });
    }

    /** Gets Registration Settings for an organization.
     * @param ID Organization ID
     */
    getRegistrationsSettings(ID: Guid) {
        return this.legacyApi.get<dtoOrganizationRegistrationSettings | undefined>(this.module, "GetRegistrationsSettings", { ID });
    }

    /** Updates an organization's registration settings, user must have forms and registration permission.
     * @param ID Organization ID
     * @param body 
     */
    updateRegistrationsSettings(ID: Guid, body: dtoOrganizationRegistrationSettings) {
        return this.legacyApi.post<void>(this.module, "UpdateRegistrationsSettings", { ID }, body);
    }
}