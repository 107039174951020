// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid } from "../models/ApiTypes";
import { DtoJobTypeLongForm } from "../models/DtoJobTypeLongForm";
import { DtoJobTypeShortForm } from "../models/DtoJobTypeShortForm";
import { DtoJobTypeCreateUpdate } from "../models/DtoJobTypeCreateUpdate";

@Injectable({providedIn: 'root'})
export class JobTypesApiService {
    private readonly controller = "JobTypes";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param id 
     */
    getByID(id: Guid) {
        return this.v2Api.get<DtoJobTypeLongForm>(this.controller, "GetByID", { id });
    }

    /** 
     * @param organizationID 
     */
    getAllByOrganizationID(organizationID: Guid) {
        return this.v2Api.get<DtoJobTypeShortForm[]>(this.controller, "GetAllByOrganizationID", { organizationID });
    }

    /** 
     * @param body 
     */
    create(body: DtoJobTypeCreateUpdate) {
        return this.v2Api.post<void>(this.controller, "Create", {  }, body);
    }

    /** 
     * @param body 
     */
    update(body: DtoJobTypeCreateUpdate) {
        return this.v2Api.post<void>(this.controller, "Update", {  }, body);
    }

    /** 
     * @param id 
     */
    delete(id: Guid) {
        return this.v2Api.post<void>(this.controller, "Delete", { id });
    }
}