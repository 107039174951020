// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { int, Guid } from "../models/ApiTypes";
import { DtoCashTicketRequest } from "../models/DtoCashTicketRequest";
import { DtoDuplicatePurchaseResponse } from "../models/DtoDuplicatePurchaseResponse";
import { DtoTicketCheckoutRequest } from "../models/DtoTicketCheckoutRequest";
import { DtoTicketCheckoutAvailabilityResponse } from "../models/DtoTicketCheckoutAvailabilityResponse";
import { DtoTicketCheckoutRequestDtoTicketCheckoutResponse } from "../models/DtoTicketCheckoutRequestDtoTicketCheckoutResponse";
import { DtoSessionCheckoutRequest } from "../models/DtoSessionCheckoutRequest";
import { DtoSessionCheckoutRequestDtoTicketCheckoutResponse } from "../models/DtoSessionCheckoutRequestDtoTicketCheckoutResponse";

@Injectable({providedIn: 'root'})
export class TicketPurchasesApiService {
    private readonly controller = "TicketPurchases";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param ticketConfigurationID 
     * @param body 
     */
    submitCash(ticketConfigurationID: int, body: DtoCashTicketRequest[]) {
        return this.v2Api.post<void>(this.controller, "SubmitCash", { ticketConfigurationID }, body);
    }

    /** 
     * @param ticketConfigurationID 
     * @param emailAddress 
     */
    checkForDuplicate(ticketConfigurationID: int, emailAddress: string) {
        return this.v2Api.get<DtoDuplicatePurchaseResponse>(this.controller, "CheckForDuplicate", { ticketConfigurationID, emailAddress });
    }

    /** Checks if the purchase request is still valid. This should be checked immediately before confirming payment with Stripe. You can supply either our payment intent ID which will be checked or
     * 
     * a checkout request containing quantities to be checked. Do not send both - only 1 will be checked.
     * @param stripePaymentIntentID 
     * @param body 
     */
    confirmAvailability(stripePaymentIntentID: Guid | undefined, body: DtoTicketCheckoutRequest | undefined) {
        return this.v2Api.post<DtoTicketCheckoutAvailabilityResponse>(this.controller, "ConfirmAvailability", { stripePaymentIntentID }, body);
    }

    /** This is no longer needed and will be removed in a future version.
     * @param paymentIntentID 
     */
    captureIntent(paymentIntentID: Guid) {
        return this.v2Api.post<void>(this.controller, "CaptureIntent", { paymentIntentID });
    }

    /** Gets a Payment Intent client secret for a purchase request. There should be no duplicate ticket pools and all ticket pools should belong to the requested ticket configuration.
     * 
     * For in-person card-present transactions Source needs to be "InPerson" to use the alternate payment flow. For InPerson payments ticket maximums aren't validated.
     * @param body 
     */
    startCheckout(body: DtoTicketCheckoutRequest) {
        return this.v2Api.post<DtoTicketCheckoutRequestDtoTicketCheckoutResponse>(this.controller, "StartCheckout", {  }, body);
    }

    /** 
     * @param body 
     */
    startSessionCheckout(body: DtoSessionCheckoutRequest) {
        return this.v2Api.post<DtoSessionCheckoutRequestDtoTicketCheckoutResponse>(this.controller, "StartSessionCheckout", {  }, body);
    }
}